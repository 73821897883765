module Detector {

    var webglParams = {
        webglVersion: "",
        shadingLanguageVersion: "",
        webglVendor: "",
        webglRenderer: "",
        maxAnisotropy: 1,
        maxFloatPrecision: "mediump",
        supportsInstancedArrays: false,
        maxTextureSize: 1024,
        antialias: false,
        preserveDrawingBuffer: false,
        compressedTexture: false,
        depthTexture: false
    };

    function getWebglContextName() {
        if (!!window["WebGLRenderingContext"]) {
            var canvas = document.createElement("canvas"),
                names = ["webgl", "experimental-webgl", "moz-webgl"],
                result: string = null,
                gl: WebGLRenderingContext = null;

            for (var i in names) {
                try {
                    gl = canvas.getContext(names[i] as string) as WebGLRenderingContext;
                    if (gl && typeof gl.getParameter == "function") {
                        result = names[i];
                        break;
                    }
                } catch (e) { }
            }

            if (result && gl) {
                try {
                    webglParams.webglVersion = "" + gl.getParameter(gl.VERSION);
                    webglParams.shadingLanguageVersion = "" + gl.getParameter(gl.SHADING_LANGUAGE_VERSION);
                    webglParams.webglVendor = "" + gl.getParameter(gl.VENDOR);
                    webglParams.webglRenderer = "" + gl.getParameter(gl.RENDERER);

                    var anisotropyExtension = gl.getExtension('EXT_texture_filter_anisotropic') || gl.getExtension('MOZ_EXT_texture_filter_anisotropic') || gl.getExtension('WEBKIT_EXT_texture_filter_anisotropic');

                    if (anisotropyExtension) {
                        webglParams.maxAnisotropy = gl.getParameter(anisotropyExtension.MAX_TEXTURE_MAX_ANISOTROPY_EXT);
                        if (webglParams.maxAnisotropy === 0)
                            webglParams.maxAnisotropy = 2;
                    } else
                        webglParams.maxAnisotropy = 0;
                    
                    webglParams.supportsInstancedArrays = !!gl.getExtension("ANGLE_instanced_arrays");
                    webglParams.maxTextureSize = gl.getParameter(gl.MAX_TEXTURE_SIZE);
                    if (gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.HIGH_FLOAT).precision > 0 && gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.HIGH_FLOAT).precision > 0)
                        webglParams.maxFloatPrecision = 'highp';   
                    else if (gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.MEDIUM_FLOAT).precision > 0 && gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.MEDIUM_FLOAT).precision > 0)
                        webglParams.maxFloatPrecision = 'mediump';
                    else
                        webglParams.maxFloatPrecision = 'lowp';
                    
                    var webGLContextAttributes = gl.getContextAttributes();

                    webglParams.antialias = !!webGLContextAttributes.antialias;
                    webglParams.preserveDrawingBuffer = !!webGLContextAttributes.preserveDrawingBuffer;
                    webglParams.compressedTexture = !!(gl.getExtension('WEBGL_compressed_texture_s3tc') || gl.getExtension('MOZ_WEBGL_compressed_texture_s3tc') || gl.getExtension('WEBKIT_WEBGL_compressed_texture_s3tc'));
                    webglParams.depthTexture = !!gl.getExtension('WEBGL_depth_texture');

                } catch (e) { }
                return result;
            }

            return null;
        }
 
        return null;
    }

    export function getBrowserInfo() {
        var ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [] as RegExpMatchArray;
        if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return { name: 'IE', version: (tem[1] || '') };
        }
        if (M[1] === 'Chrome') {
            tem = ua.match(/\bOPR\/(\d+)/);
            if (tem != null) { return { name: 'Opera', version: tem[1] }; }
        }
        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = ua.match(/version\/(\d+)/i)) != null) { M.splice(1, 1, tem[1]); }
        return {
            name: M[0],
            version: M[1]
        };
    }
    export var browserInfo = getBrowserInfo();
    export var webglContextName = getWebglContextName();
    export var canvas = !!window.CanvasRenderingContext2D;
    export var webgl = !!webglContextName;
    export var webglParameters = webglParams;
    export var workers = !!window.Worker;
    export var sharedWorkers = !!window['SharedWorker'];
    export var fileapi = !!(window.File && window.FileReader && window.FileList && window.Blob);
}

var sptBrowserCompatibilityCheck = (() => {
    var sptBrowserRequirements = {
        'MSIE': 10,
        'IE': 10,
        'Chrome': 23,
        'Firefox': 35,
        'Opera': 15,
        'Safari': 6
    };

    return () => {
        var browser = Detector.getBrowserInfo();

        //minimum requirements
        if (typeof sptBrowserRequirements[browser.name] !== "undefined" && browser.version < sptBrowserRequirements[browser.name]) {
            return true;
        }
        return false;
    };
})();