interface Array<T> {
    sum(callbackfn: (currentValue: T) => number): number;
    max(callbackfn: (currentValue: T) => number): number;
    min(callbackfn: (currentValue: T) => number): number;
    pushAll(array: T[]): T[];
    distinctByKey(callbackfn: (currentValue: T) => number | string): T[];
}

if (!Array.prototype.sum) {
    Array.prototype.sum = function <T>(this: T[], callbackfn: (currentValue: T) => number): number {
        return this.reduce((a, e) => a + callbackfn(e), 0);
    }
}

if (!Array.prototype.max) {
    Array.prototype.max = function <T>(this: T[], callbackfn: (currentValue: T) => number): number {
        return Math.max(...this.map(callbackfn));
    }
}

if (!Array.prototype.min) {
    Array.prototype.min = function <T>(this: T[], callbackfn: (currentValue: T) => number): number {
        return Math.min(...this.map(callbackfn));
    }
}

if (!Array.prototype.pushAll) {
    Array.prototype.pushAll = function <T>(this: T[], array: T[]): T[] {
        this.push.apply(this, array);
        return this;
    }
}

if (!Array.prototype.distinctByKey) {
    Array.prototype.distinctByKey = function <T>(this: T[], callbackfn: (currentValue: T) => number | string): T[] {
        var hashTable: any = {};
        for (var i = this.length; i--;)
            hashTable[callbackfn(this[i])] = this[i];
        return Object.keys(hashTable).map(key => hashTable[key]);
    }
}

