module spt.ThreeJs.utils {

    var utilVec31Search = new THREE.Vector3();
    var utilVec32Search = new THREE.Vector3();
    var utilVec3Center = new THREE.Vector3();
    var utilVec3Target = new THREE.Vector3();
    var utilVec3Normal = new THREE.Vector3();
    var utilVec3Size = new THREE.Vector3();
    var utilMatrix4 = new THREE.Matrix4();
    var utilSphere = new THREE.Sphere();
    var utilBox3 = new THREE.Box3();
    var utilRay = new THREE.Ray();
    var utilPlane = new THREE.Plane();

    export interface IVertex2 {
        x: number;
        y: number;
    }

    export interface edgeIntersectResult {
        id: string;
        bounds: THREE.Box3;
        direction: THREE.Vector3;
        center: THREE.Vector3;
        size: THREE.Vector3;
        target: THREE.Vector3;
    }

    export interface IVertex2DataJson {
        array: number[];
        type: string;
        itemSize: number | string;
    }

    export interface IBufferGeometryAttributesJson {
        index?: IVertex2DataJson;
        position: IVertex2DataJson;
        normal?: IVertex2DataJson;
        uv?: IVertex2DataJson;
        color?: IVertex2DataJson;
    }

    export interface IDisposableThreeJsObject {
        geometry?: THREE.Geometry | THREE.BufferGeometry;
        children?: IDisposableThreeJsObject[];
        material?: THREE.Material | THREE.Material[] | THREE.MultiMaterial;
        texture?: THREE.Texture;
        dispose?: () => void;
    }

    export function ConvertThreeJsObject(obj: any): any {
        var converter = new JsonConverter();
        var result = converter.convertThreeJsObject(obj);
        converter.dispose();
        return result;
    }

    export var applyPlaneRotationToObject = (() => {
        var vFrom = new THREE.Vector3(0, 0, 1),
            EPS = 0.999999;
        return (plane: THREE.Plane, obj: THREE.Object3D) => {
            var vTo = plane.normal;
            if (vTo.z >= EPS) {
                obj.quaternion.set(0, 0, 0, 1);
                return;
            }

            obj.quaternion.setFromUnitVectors(vFrom, vTo);
        };
    })();

    export var getBoxIntersections = (() => {
        var box1 = new THREE.Box3(),
            v1 = new THREE.Vector3();

        return (searchBox: THREE.Box3, octree: spt.ThreeJs.utils.Octree) => {
            var radius = searchBox.getSize(v1).setZ(0).length() * 0.5;
            searchBox.getCenter(v1);
            var octreeObjects = octree.search(v1, radius, false),
                selected: THREE.Object3D[] = [];
            for (var i = octreeObjects.length; i--;) {
                var octreeObject = octreeObjects[i],
                    object = <THREE.Mesh>octreeObject.object,
                    tbox = box1.copy(object.geometry.boundingBox).applyMatrix4(object.matrixWorld);
                //if (object.parent)
                //    tbox.applyMatrix4(object.parent.matrixWorld);
                if (searchBox.intersectsBox(tbox))
                    selected.push(object);
            }
            return selected.filter((inst) => {
                if (inst instanceof LS.Client3DEditor.ClientObjectInstance && inst.clientObject.hasNoSimpleGeometry)
                    return BoxisIntersectingMesh(searchBox, inst);
                return true;
            });
        };
    })();

    export function BoxisIntersectingMesh(box: THREE.Box3, mesh: THREE.Mesh) {
        var isIntersecting = false;

        traverseMeshTriangles(mesh, (pt1, pt2, pt3) => {
            if (BoxisIntersectingTriangle(pt1, pt2, pt3, box)) {
                isIntersecting = true;
                return false;
            }
            return true;
        });

        return isIntersecting;
    }

    export class TestVolume {
        private static EPS = 0.999999;

        constructor(points?: THREE.Vector3[], normals?: THREE.Vector3[], testAxis?: THREE.Vector3[], crossAxis?: THREE.Vector3[]) {
            this.points = points || [];
            this.normals = normals || [];
            this.testAxis = testAxis || [];
            this.crossAxis = crossAxis || [];
        }

        copy(other: TestVolume): this {
            this.points = other.points.map(p => p.clone());
            this.normals = other.normals.map(p => p.clone());
            this.testAxis = other.testAxis.map(p => p.clone());
            this.crossAxis = other.crossAxis.map(p => p.clone());
            this.boundingBox.copy(other.boundingBox);
            this.boundingSphere.copy(other.boundingSphere);

            return this;
        }

        calculate(raycaster: THREE.Raycaster, camera: THREE.PerspectiveCamera | THREE.OrthographicCamera, view2D?: THREE.Box2, axisToTest?: THREE.Vector3[]): this {
            if (!raycaster || !camera)
                return this;

            if (view2D) {
                var vSize = view2D.getSize(new THREE.Vector2());
                if (vSize.x <= 0 || vSize.y <= 0) {
                    var vCenter = view2D.getCenter(new THREE.Vector2());
                    vSize.set(Math.max(0.0001, vSize.x / 2), Math.max(0.0001, vSize.y / 2));
                    view2D = new THREE.Box2(vCenter.clone().sub(vSize), vCenter.clone().add(vSize));
                }
            }

            var near = camera.near,
                far = camera.far,
                v2D = new THREE.Vector2(),
                directions: THREE.Vector3[] = [],
                origins: THREE.Vector3[] = [],
                min = view2D ? view2D.min : new THREE.Vector2(-1, -1),
                max = view2D ? view2D.max : new THREE.Vector2(1, 1);

            raycaster.setFromCamera(min, camera);
            origins.push(raycaster.ray.origin.clone());
            directions.push(raycaster.ray.direction.clone());

            raycaster.setFromCamera(v2D.copy(min).setX(max.x), camera);
            origins.push(raycaster.ray.origin.clone());
            directions.push(raycaster.ray.direction.clone());

            raycaster.setFromCamera(max, camera);
            origins.push(raycaster.ray.origin.clone());
            directions.push(raycaster.ray.direction.clone());

            raycaster.setFromCamera(v2D.copy(min).setY(max.y), camera);
            origins.push(raycaster.ray.origin.clone());
            directions.push(raycaster.ray.direction.clone());

            var v0 = origins[0].clone().add(directions[0]),
                v01 = origins[1].clone().add(directions[1]).sub(v0),
                v03 = origins[3].clone().add(directions[3]).sub(v0);

            if (v01.lengthSq() <= 0 || v03.lengthSq() <= 0)
                return this;

            v01.normalize();
            v03.normalize();

            if ((<any>camera).isOrthographicCamera)
                this.normals = [v01, v03, v01.clone().cross(v03).normalize()];
            else {
                this.normals = [
                    //v01,
                    //v03,
                    //v01.clone().cross(v03).normalize(),
                    directions[0].clone().cross(directions[1]).normalize(),
                    directions[1].clone().cross(directions[2]).normalize(),
                    directions[2].clone().cross(directions[3]).normalize(),
                    directions[3].clone().cross(directions[0]).normalize()
                ];

                //this.normals = normals.concat(directions.filter(d => normals.every(n => Math.abs(d.dot(n)) < TestVolume.EPS)));
            }


            var points = this.points = [
                directions[0].clone().multiplyScalar(near).add(origins[0]),
                directions[0].clone().multiplyScalar(far).add(origins[0]),
                directions[1].clone().multiplyScalar(near).add(origins[1]),
                directions[1].clone().multiplyScalar(far).add(origins[1]),
                directions[2].clone().multiplyScalar(near).add(origins[2]),
                directions[2].clone().multiplyScalar(far).add(origins[2]),
                directions[3].clone().multiplyScalar(near).add(origins[3]),
                directions[3].clone().multiplyScalar(far).add(origins[3])
            ];

            this.boundingBox.setFromPoints(points).getBoundingSphere(this.boundingSphere);

            return this.setTestAxis(axisToTest);
        }

        searchOctree(octree: spt.ThreeJs.utils.Octree): spt.ThreeJs.utils.OctreeObjectData[] {
            var node = octree.root,
                objects = (<any>[]).concat(node.objects) as spt.ThreeJs.utils.OctreeObjectData[];

            // search nodes
            for (var i = 0, l = node.nodesIndices.length; i < l; i++) {
                objects = this.searchOctreeNode(node.nodesByIndex[node.nodesIndices[i]], objects);
            }

            return objects;
        }

        private searchOctreeNode(node: spt.ThreeJs.utils.OctreeNode, objects: spt.ThreeJs.utils.OctreeObjectData[]): spt.ThreeJs.utils.OctreeObjectData[] {
            if (!this.testOctreeNodeForSeparationAxis(node)) {
                // gather objects
                objects = objects.concat(node.objects);

                // search subtree
                for (var i = 0, l = node.nodesIndices.length; i < l; i++) {
                    objects = this.searchOctreeNode(node.nodesByIndex[node.nodesIndices[i]], objects);
                }
            }

            return objects;
        }

        setTestAxis(axis?: THREE.Vector3[]): this {
            if (!axis)
                axis = [new THREE.Vector3(1, 0, 0), new THREE.Vector3(0, 1, 0), new THREE.Vector3(0, 0, 1)]; //bounding box axes
            var normals = this.normals,
                eps = TestVolume.EPS;
            for (var i = 0, l = normals.length; i < l; i++) {
                for (var j = i + 1; j < l; j++) {
                    var d = normals[i].clone().cross(normals[j]);
                    if (normals.every(n => Math.abs(d.dot(n)) < eps))
                        normals.push(d);
                }
            }
            var testAxis = this.testAxis = axis.filter(bx => normals.every(ax => Math.abs(ax.dot(bx)) < eps));
            var tAxis = testAxis.concat(normals);
            this.crossAxis = [].concat.apply([], normals.map(ax => testAxis.map(bx => ax.clone().cross(bx).normalize()).filter(bx => tAxis.every(cx => Math.abs(cx.dot(bx)) < eps)))) as THREE.Vector3[];

            return this;
        }

        pointIsInside(p: THREE.Vector3) {
            return !this.checkAxis([p], this.normals);
        }

        //returns true if there is a separation on a given axis
        testOctreeNodeForSeparationAxis(node: spt.ThreeJs.utils.OctreeNode): boolean {
            return this.testForSeparationAxis(setPointsByOctreeNode(node, this._boxPts));
        }

        testEdgesForSeparationAxis(edges: THREE.Vector3[][]) {
            if (!edges || !edges.length)
                return true;
            return edges.some(e => this.testEdgeForSeparationAxis(e));
        }

        testEdgeForSeparationAxis(e: THREE.Vector3[]) {
            //SAT - separating axis theorem
            //test if we find a separating axis

            if (!e || e.length < 2)
                return true;

            var normals = this.normals,
                eps = TestVolume.EPS;

            if (this.checkAxis(e, normals))
                return true;

            var dir = e[1].clone().sub(e[0]).normalize(),
                n = Math.abs(dir.z) < 0.99 ? new THREE.Vector3(0, 0, 1).cross(dir).normalize() : new THREE.Vector3(1, 0, 0).cross(dir).normalize(),
                t = dir.clone().cross(n).normalize(),
                tAxis = [dir, n, t].filter(bx => normals.every(ax => Math.abs(ax.dot(bx)) < eps));

            if (this.checkAxis(e, tAxis))
                return true;

            var cAxis = [].concat.apply([], normals.map(ax => tAxis.map(bx => ax.clone().cross(bx).normalize()).filter(bx => tAxis.every(cx => Math.abs(cx.dot(bx)) < eps)))) as THREE.Vector3[];

            if (this.checkAxis(e, cAxis))
                return true;
        }

        //returns true if there is a separation on a given axis
        testMeshForSeparationAxis(mesh: THREE.Mesh): boolean {
            var box = this._box.copy(mesh.geometry.boundingBox).applyMatrix4(mesh.matrixWorld);//.translate(mesh.position);
            //if (mesh.parent)
            //    box.applyMatrix4(mesh.parent.matrixWorld);
            return this.testBoxForSeparationAxis(box);
        }

        //returns axis if there is a separation on a given axis
        getMeshSeparationAxis(mesh: THREE.Mesh): THREE.Vector3 {
            var box = this._box.copy(mesh.geometry.boundingBox).translate(mesh.position);
            if (mesh.parent)
                box.applyMatrix4(mesh.parent.matrixWorld);
            return this.getBoxSeparationAxis(box);
        }

        //returns true if there is a separation on a given axis
        testBoxForSeparationAxis(box: THREE.Box3): boolean {
            return this.testForSeparationAxis(setPointsByBox(box, this._boxPts));
        }

        //returns true if there is a separation on a given axis
        testBufferGeometryForSeparationAxis(geo: THREE.BufferGeometry, m?: THREE.Matrix4): boolean {
            var normals = this.normals,
                pts: THREE.Vector3[] = [],
                positions = geo.getAttribute('position');

            if (!m)
                m = new THREE.Matrix4();

            for (var i = 0, l = positions.count; i < l; i++) {

                var x = positions.getX(i);
                var y = positions.getY(i);
                var z = positions.getZ(i);

                var p = new THREE.Vector3(x, y, z).applyMatrix4(m);

                pts.push(p);
            }

            if (this.checkAxis(pts, normals))
                return true;

            if (geo.index) {
                var indices = geo.index.array;

                for (var i = 0, il = geo.index.count; i < il; i += 3) {

                    if (!this.testTriangleForSeparationAxis([pts[indices[i]], pts[indices[i + 1]], pts[indices[i + 2]]]))
                        return false;

                }
            } else {
                for (var i = 0, il = pts.length; i < il; i += 3) {

                    if (!this.testTriangleForSeparationAxis([pts[i], pts[i + 1], pts[i + 2]]))
                        return false;
                }
            }

            return true;
        }

        testTriangleForSeparationAxis(pts: THREE.Vector3[]): boolean {
            var normals = this.normals;

            if (this.checkAxis(pts, normals))
                return true;

            var pA = pts[0],
                pB = pts[1],
                pC = pts[2];

            var n = this._v0.crossVectors(this._v1.subVectors(pC, pB), this._v2.subVectors(pA, pB));

            var nLengthSq = n.lengthSq();
            if (nLengthSq <= 0)
                return true;

            n.multiplyScalar(1 / Math.sqrt(nLengthSq));

            var textAxis = [n,
                this._v1.subVectors(pB, pA).cross(n),
                this._v2.subVectors(pC, pB).cross(n),
                this._v3.subVectors(pA, pC).cross(n)
            ];

            if (this.checkAxis(pts, textAxis))
                return true;

            var tAxis = textAxis.concat(normals),
                eps = TestVolume.EPS,
                crossAxis = [].concat.apply([], normals.map(ax => textAxis.map(bx => ax.clone().cross(bx).normalize()).filter(bx => tAxis.every(cx => Math.abs(cx.dot(bx)) < eps)))) as THREE.Vector3[];

            return this.checkAxis(pts, crossAxis);
        }

        //returns axis if there is a separation on a given axis
        getBoxSeparationAxis(box: THREE.Box3): THREE.Vector3 {
            return this.getSeparationAxis(setPointsByBox(box, this._boxPts));
        }

        //returns true if there is a separation on a given axis
        testForSeparationAxis(points: THREE.Vector3[]): boolean {
            //SAT - separating axis theorem
            //test if we find a separating axis

            return this.checkAxis(points, this.normals) || this.checkAxis(points, this.testAxis) || this.checkAxis(points, this.crossAxis);
        }

        //returns axis if there is a separation on a given axis
        getSeparationAxis(points: THREE.Vector3[]): THREE.Vector3 {
            //SAT - separating axis theorem
            //test if we find a separating axis

            return this.getAxis(points, this.normals) || this.getAxis(points, this.testAxis) || this.getAxis(points, this.crossAxis);
        }

        private checkAxis(points: THREE.Vector3[], axis: THREE.Vector3[]) {
            for (var i = 0, l = axis.length; i < l; i++) {
                if (isSeparatedOnAxis(this.points, points, axis[i]))
                    return true;
            }

            return false;
        }

        private getAxis(points: THREE.Vector3[], axis: THREE.Vector3[]): THREE.Vector3 {
            for (var i = 0, l = axis.length; i < l; i++) {
                if (isSeparatedOnAxis(this.points, points, axis[i]))
                    return axis[i];
            }

            return null;
        }

        getLastTestedBox() {
            return this._box;
        }

        getLastTestedBoxPoints() {
            return this._boxPts;
        }

        getAsBuffergeometry(): THREE.BufferGeometry {
            var points = this.points,
                pts = new Float32Array(points.length * 3),
                i = 0;
            points.forEach(p => {
                pts[i++] = p.x;
                pts[i++] = p.y;
                pts[i++] = p.z;
            });

            var geo = new THREE.BufferGeometry();
            geo.setAttribute('position', new THREE.BufferAttribute(pts, 3));
            geo.setIndex(new THREE.BufferAttribute(new Uint16Array([
                0, 2, 6,
                6, 2, 4,
                1, 0, 6,
                1, 6, 7,
                7, 6, 4,
                7, 4, 5,
                2, 3, 4,
                3, 5, 4,
                1, 7, 3,
                7, 5, 3,
                0, 1, 2,
                2, 1, 3
            ]), 1));

            return geo;
        }

        applyMatrix4(m: THREE.Matrix4): this {
            if (!m)
                return this;

            var mn = new THREE.Matrix3().getNormalMatrix(m);

            this.points.forEach(p => { p.applyMatrix4(m); });
            this.normals.forEach(p => { p.applyMatrix3(mn).normalize(); });
            this.testAxis.forEach(p => { p.applyMatrix3(mn).normalize(); });
            this.crossAxis.forEach(p => { p.applyMatrix3(mn).normalize(); });

            this.boundingBox.setFromPoints(this.points).getBoundingSphere(this.boundingSphere);
            return this;
        }

        points: THREE.Vector3[];
        normals: THREE.Vector3[];
        testAxis: THREE.Vector3[];
        crossAxis: THREE.Vector3[];
        boundingBox: THREE.Box3 = new THREE.Box3();
        boundingSphere: THREE.Sphere = new THREE.Sphere();
        private _v0: THREE.Vector3 = new THREE.Vector3();
        private _v1: THREE.Vector3 = new THREE.Vector3();
        private _v2: THREE.Vector3 = new THREE.Vector3();
        private _v3: THREE.Vector3 = new THREE.Vector3();
        private _v4: THREE.Vector3 = new THREE.Vector3();
        private _v5: THREE.Vector3 = new THREE.Vector3();
        private _box: THREE.Box3 = new THREE.Box3();
        private _boxPts: THREE.Vector3[] = [new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3()];
    }

    export function getIntersectionsByViewVolume(viewVolume: TestVolume, octree: spt.ThreeJs.utils.Octree): THREE.Object3D[] {
        if (!viewVolume || !viewVolume.points.length || !viewVolume.normals.length)
            return [];

        //var octreeObjects = octree.search(viewVolume.boundingSphere.center, viewVolume.boundingSphere.radius, true),
        //    selected: THREE.Object3D[] = [];

        var octreeObjects = viewVolume.searchOctree(octree),
            selected: THREE.Object3D[] = [];

        if (octreeObjects.length) {
            for (var i = octreeObjects.length; i--;) {
                var octreeObject = octreeObjects[i],
                    object = <THREE.Mesh>octreeObject.object;

                if (!viewVolume.testMeshForSeparationAxis(object))
                    selected.push(object);
            }
        }

        //debug
        //if ((<any>window).Controller.Current.viewModel.shiftDown) {
        //    //var scene = LS.Client3DEditor.Controller.Current.scene;
        //    var scene = (<any>window).Controller.Current.viewModel.currentInstance.instanceContainer;

        //    var mat = new THREE.MeshBasicMaterial({ color: 0xFF0000 });
        //    var mesh = new THREE.Mesh(viewVolume.getAsBuffergeometry(), mat);

        //    scene.add(mesh);
        //    var bx = new THREE.Box3(),
        //        matGreen = new THREE.MeshBasicMaterial({ color: 0x00FF00 }),
        //        matBlue = new THREE.MeshBasicMaterial({ color: 0x0000FF }),
        //        geo2 = new THREE.BoxBufferGeometry(1, 1, 1);

        //    octree.objects.forEach((mesh: THREE.Mesh) => {

        //        var m = new THREE.Mesh(geo2, selected.indexOf(mesh) != -1 ? matGreen : matBlue);

        //        bx.copy(mesh.geometry.boundingBox).translate(mesh.position);
        //        if (mesh.parent)
        //            bx.applyMatrix4(mesh.parent.matrixWorld);
        //        bx.getSize(m.scale);
        //        bx.getCenter(m.position);
        //        m.position.z += 5;

        //        scene.add(m);
        //    });

        //}

        return selected.filter((inst) => {
            //if (inst instanceof LS.Client3DEditor.ClientObjectInstance) {
            //    if ((inst.clientObject.userOptions & Client3DObectOptions.NoSimpleGeometry) !== 0) {
            //        var geo = inst.geometry as THREE.Geometry;
            //        if (geo.faces.some(f => BoxisIntersectingTriangle(new THREE.Vector3().addVectors(geo.vertices[f.a], inst.position), new THREE.Vector3().addVectors(geo.vertices[f.b], inst.position), new THREE.Vector3().addVectors(geo.vertices[f.c], inst.position), searchBox)))
            //            return true;
            //        return false;
            //    }
            //}
            return true;
        });
    }

    function setPointsByOctreeNode(node: spt.ThreeJs.utils.OctreeNode, target: THREE.Vector3[]): THREE.Vector3[] {
        var left = node.left,
            right = node.right,
            bottom = node.bottom,
            top = node.top,
            back = node.back,
            front = node.front;

        target[0].set(left, bottom, back);
        target[1].set(right, bottom, back);
        target[2].set(right, top, back);
        target[3].set(left, top, back);
        target[4].set(left, bottom, front);
        target[5].set(right, bottom, front);
        target[6].set(right, top, front);
        target[7].set(left, top, front);

        return target;
    }

    function setPointsByBox(box: THREE.Box3, target: THREE.Vector3[]): THREE.Vector3[] {
        var min = box.min,
            max = box.max;

        target[0].set(min.x, min.y, min.z);
        target[1].set(max.x, min.y, min.z);
        target[2].set(max.x, max.y, min.z);
        target[3].set(min.x, max.y, min.z);
        target[4].set(min.x, min.y, max.z);
        target[5].set(max.x, min.y, max.z);
        target[6].set(max.x, max.y, max.z);
        target[7].set(min.x, max.y, max.z);

        return target;
    }

    export var projectDirectionAxis = (() => {
        var vx = new THREE.Vector3(),
            vy = new THREE.Vector3();
        return (direction: THREE.Vector3, xAxis: THREE.Vector3, yAxis: THREE.Vector3) => {
            var x = xAxis.dot(direction),
                y = yAxis.dot(direction);

            return x > 0 || y > 0 ? vx.copy(xAxis).multiplyScalar(x).add(vy.copy(yAxis).multiplyScalar(y)).clone().normalize() : new THREE.Vector3();
        };
    })();

    export function projectPointsOnAxis(points: THREE.Vector3[], axis: THREE.Vector3, result: number[]) {
        let min = Number.MAX_VALUE,
            max = -Number.MAX_VALUE,
            len = points.length;
        for (let i = 0; i < len; i++) {
            var d = points[i].dot(axis);
            if (d < min) { min = d; }
            if (d > max) { max = d; }
        }
        result[0] = min; result[1] = max;
    }

    export var isSeparatedOnAxis = (() => {
        var rangeA = [0, 0],
            rangeB = [0, 0],
            v = new THREE.Vector3();
        return (pointsA: THREE.Vector3[], pointsB: THREE.Vector3[], axis: THREE.Vector3, positionA?: THREE.Vector3, positionB?: THREE.Vector3) => {
            if (axis.lengthSq() <= 0)
                return false;
            projectPointsOnAxis(pointsA, axis, rangeA);
            projectPointsOnAxis(pointsB, axis, rangeB);

            //if points are local geometry, we use the position to apply an offset
            if (positionA && positionB) {
                var projectedOffset = v.copy(positionB).sub(positionA).dot(axis);

                rangeB[0] += projectedOffset;
                rangeB[1] += projectedOffset;
            }

            return rangeA[0] > rangeB[1] || rangeB[0] > rangeA[1];
        };
    })();

    export function testForSeparatingAxis(pointsA: THREE.Vector3[], pointsB: THREE.Vector3[], axisA: THREE.Vector3[], axisB?: THREE.Vector3[], crossAxis?: THREE.Vector3[], positionA?: THREE.Vector3, positionB?: THREE.Vector3): boolean {

        //SAT - separating axis theorem
        //test if we find a separating axis

        var l: number, j: number;

        for (j = 0, l = axisA.length; j < l; j++) {
            if (isSeparatedOnAxis(pointsA, pointsB, axisA[j], positionA, positionB))
                return true;
        }

        if (axisB) {
            for (j = 0, l = axisB.length; j < l; j++) {
                if (isSeparatedOnAxis(pointsA, pointsB, axisB[j], positionA, positionB))
                    return true;
            }

            if (!crossAxis)
                crossAxis = [].concat.apply([], axisA.map(ax => axisB.filter(bx => Math.abs(ax.dot(bx)) < 1).map(bx => ax.clone().cross(bx).normalize()))) as THREE.Vector3[];

            for (j = 0, l = crossAxis.length; j < l; j++) {
                if (isSeparatedOnAxis(pointsA, pointsB, crossAxis[j], positionA, positionB))
                    return true;
            }
        }

        return false;
    }

    export var GetClosestPointToLine = (() => {
        var vw = new THREE.Vector3(),
            vp = new THREE.Vector3();
        return (start: THREE.Vector3, end: THREE.Vector3, point: THREE.Vector3) => {
            vw.copy(end).sub(start);
            var l2 = vw.lengthSq();
            if (l2 === 0)
                return { p: vp.copy(start), l: 0 };
            vp.copy(point).sub(start);
            var t = vp.dot(vw) / l2;
            return t < 0 ? { p: vp.copy(start), l: 0 } : (t > 1 ? { p: vp.copy(end), l: 1 } : { p: vp.copy(start).add(vw.multiplyScalar(t)), l: t });
        };
    })();

    export var GetClosestPointOnLine = (() => {
        var se = new THREE.Vector3();
        return (start: THREE.Vector3, end: THREE.Vector3, point: THREE.Vector3, target: THREE.Vector3) => {
            se.subVectors(end, start);
            var l2 = se.lengthSq();
            if (l2 === 0)
                return target.copy(start);
            target.subVectors(point, start);
            var t = target.dot(se) / l2;
            return t < 0 ? target.copy(start) : (t > 1 ? target.copy(end) : target.copy(start).add(se.multiplyScalar(t)));
        };
    })();

    export function ray2DintersecsBox2D(origin: IVertex2, direction: IVertex2, lb: IVertex2, rt: IVertex2) {
        // r.dir is unit direction vector of ray
        var dirfracx = 1 / direction.x;
        var dirfracy = 1 / direction.y;
        // lb is the corner of AABB with minimal coordinates - left bottom, rt is maximal corner
        // r.org is origin of ray
        var t1 = (lb.x - origin.x) * dirfracx;
        var t2 = (rt.x - origin.x) * dirfracx;
        var t3 = (lb.y - origin.y) * dirfracy;
        var t4 = (rt.y - origin.y) * dirfracy;

        var tmin = Math.max(Math.max(Math.min(t1, t2), Math.min(t3, t4)));
        var tmax = Math.min(Math.min(Math.max(t1, t2), Math.max(t3, t4)));

        // if tmax < 0, ray (line) is intersecting AABB, but the whole AABB is behind us
        if (tmax < 0) {
            //t = tmax;
            return false;
        }

        // if tmin > tmax, ray doesn't intersect AABB
        if (tmin > tmax) {
            //t = tmax;
            return false;
        }

        //t = tmin;
        return true;
    }

    export var ray2DintersecsSegment = (() => {

        var v = new THREE.Vector2(),
            os = new THREE.Vector2(),
            oe = new THREE.Vector2();

        return (origin: IVertex2, direction: IVertex2, segmentStart: IVertex2, segmentEnd: IVertex2) => {
            v.set(-direction.y, direction.x);
            os.set(segmentStart.x - origin.x, segmentStart.y - origin.y);
            oe.set(segmentEnd.x - origin.x, segmentEnd.y - origin.y);
            if (os.dot(v) >= 0 === oe.dot(v) >= 0)
                return false;

            v.copy(<THREE.Vector2>segmentEnd).sub(<THREE.Vector2>segmentStart);

            var a = direction.x * v.y - direction.y * v.x;
            if (Math.abs(a) < 8.88178419700125E-16)
                return false;

            var f = 1 / a;
            if ((os.x * v.y - os.y * v.x) * f < 0)
                return false;

            var b = (os.x * direction.y - os.y * direction.x) * f;
            if (b < 0 || b > 1)
                return false;

            return true;
        };
    })();

    export var ray2DSegmentIntersection = (() => {

        var v = new THREE.Vector2(),
            os = new THREE.Vector2(),
            oe = new THREE.Vector2();

        return (origin: IVertex2, direction: IVertex2, segmentStart: IVertex2, segmentEnd: IVertex2) => {
            v.set(-direction.y, direction.x);
            os.set(segmentStart.x - origin.x, segmentStart.y - origin.y);
            oe.set(segmentEnd.x - origin.x, segmentEnd.y - origin.y);
            if (os.dot(v) >= 0 === oe.dot(v) >= 0)
                return null;

            v.copy(<THREE.Vector2>segmentEnd).sub(<THREE.Vector2>segmentStart);

            var a = direction.x * v.y - direction.y * v.x;
            if (Math.abs(a) < 8.88178419700125E-16)
                return null;

            var f = 1 / a;
            var t = (os.x * v.y - os.y * v.x) * f;
            if (t < 0)
                return null;

            var b = (os.x * direction.y - os.y * direction.x) * f;
            if (b < 0 || b > 1)
                return null;

            return v.copy(<THREE.Vector2>direction).multiplyScalar(t).add(<THREE.Vector2>origin);
        };
    })();

    export function LineIntersecsBox(s: THREE.Vector3, e: THREE.Vector3, b: THREE.Box3) {

        if (b.containsPoint(s) && b.containsPoint(e))
            return true;

        var dx = e.x - s.x;
        var dy = e.y - s.y;
        var dz = e.z - s.z;

        var lsq = dx * dx + dy * dy + dz * dz;

        if (lsq <= 0)
            return b.containsPoint(s);

        var l = Math.sqrt(lsq);

        var rix = l / dx;
        var riy = l / dy;
        var riz = l / dz;

        var tx1 = (b.min.x - s.x) * rix;
        var tx2 = (b.max.x - s.x) * rix;
        var ty1 = (b.min.y - s.y) * riy;
        var ty2 = (b.max.y - s.y) * riy;
        var tz1 = (b.min.z - s.z) * riz;
        var tz2 = (b.max.z - s.z) * riz;

        var tmin = Math.max(Math.min(tx1, tx2), Math.max(Math.min(ty1, ty2), Math.min(tz1, tz2)));
        var tmax = Math.min(Math.max(tx1, tx2), Math.min(Math.max(ty1, ty2), Math.max(tz1, tz2)));

        return tmax >= tmin && ((tmax >= 0 && tmax <= l) || (tmin >= 0 && tmin <= l));
    }

    export function pointOnLeftSideOfLine(s: THREE.Vector3, e: THREE.Vector3, p: THREE.Vector3) {
        return (p.x - s.x) * (s.y - e.y) + (e.x - s.x) * (p.y - s.y);
    }

    export function LineClosestDistanceToPoint(start: THREE.Vector3, end: THREE.Vector3, point: THREE.Vector3) {
        return GetClosestPointToLine(start, end, point).p.distanceTo(point);
    }

    export function getInstanceIntersections(raycaster: THREE.Raycaster, octree: spt.ThreeJs.utils.Octree, filterOptions?: number): LS.Client3DEditor.ClientObjectInstance[] {
        //var ray = raycaster.ray;
        //var octreeObjects = octree.search(ray.origin, raycaster.far, true, ray.direction);

        var octreeObjects = searchOctree(octree, raycaster);

        if (!filterOptions)
            filterOptions = Client3DObectOptions.CanSelectInstances;

        return raycasterIntersectOctreeObjects(raycaster, octreeObjects).map((m) => m.object as LS.Client3DEditor.ClientObjectInstance).filter((o: LS.Client3DEditor.ClientObjectInstance) => {
            return o.clientObject.userOptions & filterOptions;
        }) as LS.Client3DEditor.ClientObjectInstance[];
    }

    export function raycasterIntersectOctreeObjects(raycaster: THREE.Raycaster, objects: IOctreeObjectData[], recursive?: boolean) {
        var i: number, il: number,
            intersects: IOctreeObjectData[] = [],
            extendedIntersects: { object: THREE.Object3D, dist?: number }[] = [],
            object: { object: THREE.Object3D, dist?: number },
            obj: THREE.Mesh,
            geometry: THREE.Geometry,
            octreeObject: { object: THREE.Object3D, faces?: THREE.Face3[] },
            facesAll: THREE.Face3[],
            facesSearch: THREE.Face3[],
            objInstance: LS.Client3DEditor.ClientObjectInstance,
            sphere = utilSphere,
            boundingBox = utilBox3,
            target = utilVec3Target,
            linePrecision = raycaster.params.Line.threshold * 0.5,
            inter: THREE.Intersection[],
            isExtendedInter: boolean;

        for (i = 0, il = objects.length; i < il; i++) {

            object = objects[i];

            if (object.object instanceof LS.Client3DEditor.ClientObjectInstance && (object.object.clientObject.userOptions & Client3DObectOptions.NoSimpleGeometry) === 0) {
                objInstance = object.object;
                geometry = objInstance.geometry as THREE.Geometry;

                if (!geometry.boundingSphere) geometry.computeBoundingSphere();

                sphere.copy(geometry.boundingSphere);
                sphere.applyMatrix4(objInstance.matrixWorld);
                sphere.radius += linePrecision;

                if (!raycaster.ray.intersectsSphere(sphere)) continue;

                if (!geometry.boundingBox) geometry.computeBoundingBox();

                boundingBox.copy(geometry.boundingBox).applyMatrix4(objInstance.matrixWorld);

                boundingBox.expandByScalar(linePrecision);

                if (!raycaster.ray.intersectsBox(boundingBox)) continue;

                isExtendedInter = true;

                object.dist = raycaster.ray.distanceSqToPoint(boundingBox.getCenter(target));
            }

            if (object.object instanceof THREE.Object3D) {
                octreeObject = object;
                obj = octreeObject.object as THREE.Mesh;

                // temporarily replace object geometry's faces with octree object faces
                facesSearch = octreeObject.faces;
                geometry = obj.geometry as THREE.Geometry;
                facesAll = geometry.faces;

                if (facesSearch.length > 0)
                    geometry.faces = facesSearch;

                // intersect
                inter = raycaster.intersectObject(obj, recursive);

                // revert object geometry's faces
                if (facesSearch.length > 0)
                    geometry.faces = facesAll;
            }
            else {
                inter = raycaster.intersectObject(object as any, recursive);

                intersects.concat(raycaster.intersectObject(object as any, recursive));
            }

            if (inter && inter.length)
                intersects.push(object);
            else if (isExtendedInter)
                extendedIntersects.push(object);

        }

        if (extendedIntersects.length > 1)
            extendedIntersects.sort((a, b) => a.dist - b.dist);

        return intersects.concat(extendedIntersects);
    }

    export function searchOctree(octree: spt.ThreeJs.utils.Octree, raycaster: THREE.Raycaster) {
        var ray = raycaster.ray,
            position = ray.origin,
            distance = raycaster.far,
            organizeByObject: boolean = true,
            direction = ray.direction,
            linePrecision = raycaster.params.Line.threshold * 0.5;

        var i: number, l: number,
            node: spt.ThreeJs.utils.OctreeNode,
            objects: spt.ThreeJs.utils.OctreeObjectData[],
            objectData: { object: THREE.Object3D, faces: any[], vertices: any[] }/*spt.ThreeJs.utils.OctreeObjectData*/,
            object: THREE.Object3D,
            results: IOctreeObjectData[],
            resultData: { object: THREE.Object3D, faces: any[], vertices: any[] }/*spt.ThreeJs.utils.OctreeObjectData*/,
            resultsObjectsIndices: THREE.Object3D[],
            resultObjectIndex: number,
            directionPct: THREE.Vector3;

        // add root objects
        objects = [].concat(octree.root.objects as any);

        // ensure distance of ray is a number
        if (!(distance > 0))
            distance = Number.MAX_VALUE;

        // normalize direction and find pct
        direction = utilVec31Search.copy(direction).normalize();
        directionPct = utilVec32Search.set(1, 1, 1).divide(direction);

        // search each node of root
        for (i = 0, l = octree.root.nodesIndices.length; i < l; i++) {
            node = octree.root.nodesByIndex[octree.root.nodesIndices[i]];
            objects = searchOctreeNode(node, position, distance, objects, direction, directionPct, linePrecision);
        }

        // if should organize results by object
        if (organizeByObject) {

            results = [];
            resultsObjectsIndices = [];

            // for each object data found

            for (i = 0, l = objects.length; i < l; i++) {

                objectData = objects[i] as any;
                object = objectData.object;

                resultObjectIndex = resultsObjectsIndices.indexOf(object);

                // if needed, create new result data

                if (resultObjectIndex === - 1) {

                    resultData = {
                        object: object,
                        faces: [],
                        vertices: []
                    } as any;

                    results.push(resultData);

                    resultsObjectsIndices.push(object);

                } else {

                    resultData = results[resultObjectIndex] as any;

                }

                // object data has faces or vertices, add to list

                if (objectData.faces) {

                    resultData.faces.push(objectData.faces);

                } else if (objectData.vertices) {

                    resultData.vertices.push(objectData.vertices);

                }

            }

        }
        else
            results = objects;

        return results;
    }

    export function searchOctreeNode(node: spt.ThreeJs.utils.OctreeNode, position: THREE.Vector3, distance: number, objects: spt.ThreeJs.utils.OctreeObjectData[], direction: THREE.Vector3, directionPct: THREE.Vector3, linePrecision: number): spt.ThreeJs.utils.OctreeObjectData[] {
        var i: number, l: number;

        // test intersects by parameters
        var intersects = OctreeNodeIntersectRay(node, position, direction, distance, directionPct, linePrecision);

        // if intersects
        if (intersects === true) {
            // gather objects
            objects = objects.concat(node.objects);

            // search subtree
            for (i = 0, l = node.nodesIndices.length; i < l; i++) {

                var child = node.nodesByIndex[node.nodesIndices[i]];
                objects = searchOctreeNode(child, position, distance, objects, direction, directionPct, linePrecision);
            }
        }

        return objects;
    }

    export function OctreeNodeIntersectRay(node: spt.ThreeJs.utils.OctreeNode, origin: THREE.Vector3, direction: THREE.Vector3, distance: number, directionPct: THREE.Vector3, linePrecision: number): boolean {
        var t1 = (node.left - linePrecision - origin.x) * directionPct.x,
            t2 = (node.right + linePrecision - origin.x) * directionPct.x,
            t3 = (node.bottom - linePrecision - origin.y) * directionPct.y,
            t4 = (node.top + linePrecision - origin.y) * directionPct.y,
            t5 = (node.back - linePrecision - origin.z) * directionPct.z,
            t6 = (node.front + linePrecision - origin.z) * directionPct.z,
            tmax = Math.min(Math.min(Math.max(t1, t2), Math.max(t3, t4)), Math.max(t5, t6)),
            tmin: number;

        // ray would intersect in reverse direction, i.e. this is behind ray
        if (tmax < 0)
            return false;

        tmin = Math.max(Math.max(Math.min(t1, t2), Math.min(t3, t4)), Math.min(t5, t6));

        // if tmin > tmax or tmin > ray distance, ray doesn't intersect AABB
        return tmin <= tmax && tmin <= distance;
    }

    export function toNearestAxisNormal(n: THREE.Vector3, optionalTarget?: THREE.Vector3) {
        var res = optionalTarget || new THREE.Vector3();
        res.set(0, 0, 0);
        var nax = Math.abs(n.x),
            nay = Math.abs(n.y),
            naz = Math.abs(n.z),
            idx = nax > nay && nax > naz ? 'x' : (nay > naz ? 'y' : 'z');
        res[idx] = n[idx] < 0 ? -1 : 1;
        return res;
    }

    export function GetBoxOffsetByDirection(dir: THREE.Vector3 | THREE.Vector2, w: number, h: number) {
        return new THREE.Vector3(dir.x < 0 ? -w : (dir.x > 0 ? w : 0), dir.y < 0 ? -h : (dir.y > 0 ? h : 0), 0);
    }

    export function raycasterIntersectEdge(raycaster: THREE.Raycaster, object: LS.Client3DEditor.ClientObjectInstance) {
        var geometry = object.geometry as THREE.Geometry,
            plane = utilPlane,
            boundingBox = utilBox3.makeEmpty(),
            target = utilVec3Target,
            center = utilVec3Center,
            size = utilVec3Size,
            normal = utilVec3Normal.set(0, 0, 1),
            linePrecision = raycaster.params.Line.threshold * 0.5;

        if (!geometry.boundingBox) geometry.computeBoundingBox();

        boundingBox.copy(geometry.boundingBox).applyMatrix4(object.matrixWorld);

        boundingBox.getCenter(center);

        boundingBox.getSize(size);

        plane.setFromNormalAndCoplanarPoint(normal, center);

        raycaster.ray.intersectPlane(plane, target);

        if (Math.abs(target.x - center.x) / Math.abs(target.y - center.y) >= (boundingBox.max.x - boundingBox.min.x) / (boundingBox.max.y - boundingBox.min.y)) {
            //left or right edge

            linePrecision = Math.min(linePrecision, boundingBox.max.x - boundingBox.min.x);

            if (target.x < center.x) {
                //left
                if (target.x <= boundingBox.min.x + linePrecision) {
                    boundingBox.max.setX(boundingBox.min.x + linePrecision);
                    normal.set(-1, 0, 0);
                } else return null;
            } else {
                //right
                if (target.x >= boundingBox.max.x - linePrecision) {
                    boundingBox.min.setX(boundingBox.max.x - linePrecision);
                    normal.set(1, 0, 0);
                } else return null;
            }
        } else {
            //upper or lower edge

            linePrecision = Math.min(linePrecision, boundingBox.max.y - boundingBox.min.y);

            if (target.y < center.y) {
                //lower
                if (target.y <= boundingBox.min.y + linePrecision) {
                    boundingBox.max.setY(boundingBox.min.y + linePrecision);
                    normal.set(0, -1, 0);
                } else return null;
            } else {
                //upper
                if (target.y >= boundingBox.max.y - linePrecision) {
                    boundingBox.min.setY(boundingBox.max.y - linePrecision);
                    normal.set(0, 1, 0);
                } else return null;
            }
        }

        return { bounds: boundingBox, direction: normal, center: center, id: object.instanceData.Id, size: size, target: target } as edgeIntersectResult;
    }

    export function getUpNormal(n: THREE.Vector3, optionalTarget?: THREE.Vector3) {
        var res = optionalTarget || new THREE.Vector3();
        if (n.z >= 0.9999)
            res.set(0, 1, 0);
        else if (n.z <= -0.9999)
            res.set(0, -1, 0);
        else if (n.x >= 1)
            res.set(0, 0, 1);
        else if (n.x <= -0.9999)
            res.set(0, 0, 1);
        else if (n.y >= 1)
            res.set(0, 0, 1);
        else //if (n.y <= 0.9999)
            res.set(0, 0, 1);
        return res;
    }

    //get normal from triangle
    export var getNormal = (() => {

        var ab = new THREE.Vector3(), ac = new THREE.Vector3();

        return (a: THREE.Vector3, b: THREE.Vector3, c: THREE.Vector3, target?: THREE.Vector3) => {
            ab.subVectors(b, a);
            ac.subVectors(c, a);
            return (target || new THREE.Vector3()).crossVectors(ab, ac).normalize() as THREE.Vector3;
        }
    })();

    export function CalculateViewPositionFromFOV(fov: number, viewDirection: THREE.Vector3, centerDiameter: number): THREE.Vector3 {
        return new THREE.Vector3().copy(viewDirection).normalize().multiplyScalar((centerDiameter / 2) / Math.tan((fov / 2) / 180 * Math.PI));
    }

    export function ComputeProjectionUpdate(perspectiveCamera: THREE.PerspectiveCamera, orthographicCamera: THREE.OrthographicCamera, globalSphere: THREE.Sphere, orthoCameraDistance: number) {

        var campos = orthographicCamera.getWorldPosition(new THREE.Vector3()),
            camdir = orthographicCamera.getWorldDirection(new THREE.Vector3());

        var distance = Math.abs(camdir.dot(campos.subVectors(globalSphere.center, campos)));
        orthographicCamera.near = Math.max(100, Math.min(9999900, distance - globalSphere.radius - 100));
        orthographicCamera.far = Math.max(orthographicCamera.near + 100, Math.min(10000000, distance + globalSphere.radius + 100));

        THREE.OrthographicCamera.prototype.updateProjectionMatrix.call(orthographicCamera);

        var aspect = (orthographicCamera.right - orthographicCamera.left) / (orthographicCamera.top - orthographicCamera.bottom);
        var dx = (orthographicCamera.right - orthographicCamera.left) / (2 * aspect * orthographicCamera.zoom);

        var angle = perspectiveCamera.fov * Math.PI / 360; //half angle radian
        perspectiveCamera.position.z = (dx / Math.tan(angle)) - orthoCameraDistance;
        //perspectiveCamera.near = Math.max(orthographicCamera.near, orthographicCamera.near + perspectiveCamera.position.z);
        //perspectiveCamera.far = Math.max(orthographicCamera.far, orthographicCamera.far + perspectiveCamera.position.z);

        perspectiveCamera.getWorldPosition(campos);
        perspectiveCamera.getWorldDirection(camdir);

        //var perspectiveDistance = globalSphere.center.distanceTo(perspectiveCamera.getWorldPosition(new THREE.Vector3()));
        distance = Math.abs(camdir.dot(campos.subVectors(globalSphere.center, campos)));
        perspectiveCamera.near = Math.max(100, Math.min(9999900, distance - globalSphere.radius - 100));
        perspectiveCamera.far = Math.max(perspectiveCamera.near + 100, Math.min(10000000, distance + globalSphere.radius + 100));

        perspectiveCamera.aspect = aspect;

        THREE.PerspectiveCamera.prototype.updateProjectionMatrix.call(perspectiveCamera);
    }

    //spt.ThreeJs.utils.DownloadSceneAsObj(Scene3DModel.Scene)
    export function DownloadSceneAsObj(scene: THREE.Scene) {
        new spt.ThreeJs.utils.OBJExporter(4, true, false, false, false).parse(scene).download();
    }

    //export function SerializeSceneToObj(scene: THREE.Scene, input?: Utils.ObjSerializedResult): string[]Utils.ObjSerializedResult {
    //    var res = input || {
    //        output: [],
    //        indexCount: 0,
    //        vertexCount: 0,
    //        uvCount: 0
    //    };

    //    var objIdx = 0;

    //    scene.traverse((obj: THREE.Mesh) => {
    //        if (obj.isMesh && !(<any>obj).isPoints && !(<any>obj).isLine) {
    //            var mesh = obj as THREE.Mesh;
    //            mesh.updateMatrixWorld(true);

    //            var geometry = mesh.geometry;
    //            var bufferGeometry: THREE.BufferGeometry;

    //            if (geometry instanceof THREE.BufferGeometry)
    //                bufferGeometry = geometry;
    //            else
    //                bufferGeometry = new THREE.BufferGeometry().fromGeometry(<THREE.Geometry>geometry);

    //            SerializeBufferGeometryToObj(bufferGeometry, mesh.matrixWorld, "geo" + (objIdx++), res);
    //        }
    //    });

    //    console.log("Finished serializing scene to obj");

    //    return res;
    //}

    //function formatNumberForObj(v: number, precision: number) {

    //    if (isNaN(v) || !isFinite(v) || !v)
    //        return "0";

    //    let s = v.toFixed(precision);

    //    if (s.indexOf(".") !== -1) {
    //        let lIdx = s.length - 1;
    //        while (s[lIdx] === '0')
    //            lIdx--;
    //        if (s[lIdx] === '.')
    //            lIdx--;
    //        lIdx++;
    //        if (lIdx < s.length)
    //            s = s.substring(0, lIdx);
    //    }
    //    return s;
    //}

    //export function SerializeBufferGeometryToObj(bufferGeometry: THREE.BufferGeometry, transformation: THREE.Matrix4, name?: string, input?: Utils.ObjSerializedResult, precision?: number): Utils.ObjSerializedResult {
    //    if (!bufferGeometry || !bufferGeometry.attributes || !bufferGeometry.attributes['position']) {
    //        return null;
    //    }

    //    if (precision === undefined)
    //        precision = 4;

    //    if (!bufferGeometry.attributes['normal']) {
    //        console.log("Computing Normals.");
    //        bufferGeometry.computeVertexNormals();
    //    }

    //    var res = input || {
    //        output: [],
    //        indexCount: 0,
    //        vertexCount: 0,
    //        uvCount: 0
    //    };

    //    var normalTransform = new THREE.Matrix3().getNormalMatrix(transformation);

    //    var hasIndex = !!bufferGeometry.index;

    //    var output = [];

    //    output.push("\no " + (name || ("BufferGeometry " + bufferGeometry.id)));

    //    console.log("Serializing BufferGeometry " + bufferGeometry.id);

    //    var positions = (<THREE.BufferAttribute>bufferGeometry.attributes['position']).array as number[];

    //    var hasUvs = !!bufferGeometry.attributes['uv'];

    //    console.log("Vertex length: " + (positions.length / 3));

    //    var v = new THREE.Vector3();

    //    for (var i = 0, j = positions.length; i < j; i += 3) {
    //        var x = positions[i],
    //            y = positions[i + 1],
    //            z = positions[i + 2];

    //        v.set(x, y, z).applyMatrix4(transformation);

    //        output.push("v " + formatNumberForObj(v.x, precision) + " " + formatNumberForObj(v.y, precision) + " " + formatNumberForObj(v.z, precision));
    //    }

    //    if (hasUvs) {
    //        var uvs = (<THREE.BufferAttribute>bufferGeometry.attributes['uv']).array as number[];
    //        for (var i = 0, j = uvs.length; i < j; i += 2) {
    //            var x = uvs[i],
    //                y = uvs[i + 1];

    //            output.push("vt " + formatNumberForObj(x, 4) + " " + formatNumberForObj(y, 4));
    //        }
    //    }

    //    var normals = (<THREE.BufferAttribute>bufferGeometry.attributes['normal']).array as number[];
    //    for (var i = 0, j = normals.length; i < j; i += 3) {
    //        var x = normals[i],
    //            y = normals[i + 1],
    //            z = normals[i + 2];

    //        v.set(x, y, z).applyMatrix3(normalTransform).normalize();

    //        output.push("vn " + formatNumberForObj(v.x, 4) + " " + formatNumberForObj(v.y, 4) + " " + formatNumberForObj(v.z, 4));
    //    }

    //    output.push("s off");

    //    var idxOffset = res.vertexCount + 1;
    //    var uvIdxOffset = res.uvCount + 1;

    //    if (hasIndex) {
    //        var indices = bufferGeometry.getIndex().array;
    //        for (var k = 0, l = indices.length; k < l; k += 3) {
    //            var idx1 = idxOffset + indices[k],
    //                idx2 = idxOffset + indices[k + 1],
    //                idx3 = idxOffset + indices[k + 2];

    //            if (hasUvs)
    //                output.push("f " + idx1 + "/" + (uvIdxOffset + indices[k]) + "/" + idx1 + " " + idx2 + "/" + (uvIdxOffset + indices[k + 1]) + "/" + idx2 + " " + idx3 + "/" + (uvIdxOffset + indices[k + 2]) + "/" + idx3);
    //            else
    //                output.push("f " + idx1 + "//" + idx1 + " " + idx2 + "//" + idx2 + " " + idx3 + "//" + idx3);
    //        }
    //    } else {
    //        for (var m = 0, n = positions.length / 3; m < n; m += 3) {
    //            var idx1 = idxOffset + m,
    //                idx2 = idxOffset + m + 1,
    //                idx3 = idxOffset + m + 2;

    //            if (hasUvs)
    //                output.push("f " + idx1 + "/" + (uvIdxOffset + m) + "/" + idx1 + " " + idx2 + "/" + (uvIdxOffset + m + 1) + "/" + idx2 + " " + idx3 + "/" + (uvIdxOffset + m + 2) + "/" + idx3);
    //            else
    //                output.push("f " + idx1 + "//" + idx1 + " " + idx2 + "//" + idx2 + " " + idx3 + "//" + idx3);

    //        }
    //    }

    //    res.vertexCount += positions.length / 3;
    //    if (hasUvs)
    //        res.uvCount += positions.length / 3;
    //    res.indexCount += hasIndex ? bufferGeometry.getIndex().array.length : res.vertexCount;

    //    console.log("Finished: BufferGeometry " + bufferGeometry.id);
    //    console.log("output lines count: " + output.length);
    //    console.log("\n");

    //    res.output.push(output.join("\n"));

    //    return res;
    //}

    export function BufferGeometryFromJsonObject(obj: IBufferGeometryAttributesJson): THREE.BufferGeometry {
        var result = new THREE.BufferGeometry();

        //var hasNormals = false;

        for (var name in obj) {
            var attr = <IVertex2DataJson>obj[name];
            var typedArray;
            switch (attr.type) {
                case "Float32Array":
                    typedArray = new Float32Array(attr.array);
                    break;
                case "Uint16Array":
                    typedArray = new Uint16Array(attr.array);
                    break;
                case "Int8Array":
                    typedArray = new Int8Array(attr.array);
                    break;
                case "Uint8Array":
                    typedArray = new Uint8Array(attr.array);
                    break;
                case "Uint8ClampedArray":
                    typedArray = new Uint8ClampedArray(attr.array);
                    break;
                case "Int16Array":
                    typedArray = new Int16Array(attr.array);
                    break;
                case "Int32Array":
                    typedArray = new Int32Array(attr.array);
                    break;
                case "Uint32Array":
                    typedArray = new Uint32Array(attr.array);
                    break;
                case "Float64Array":
                    typedArray = new Float64Array(attr.array);
                    break;
                default:
                    typedArray = new Float32Array(attr.array);
            }

            if (name === "index")
                result.setIndex(new THREE.BufferAttribute(typedArray, 1));
            else
                result.setAttribute(name, new THREE.BufferAttribute(typedArray, parseInt(<string>attr.itemSize)));
            //if (name === "normal")
            //    hasNormals = true;
        }
        //if (!hasNormals)
        //    result.computeVertexNormals();
        //if (!result.boundingSphere)
        //    result.computeBoundingSphere();

        return result;
    }

    export function DuplicateBufferGeometries(geometry: THREE.BufferGeometry, transforms: number[][]) {
        var result = new THREE.BufferGeometry();

        if (!geometry || !geometry.attributes["position"] || !transforms || !transforms.length)
            return result;

        if (geometry.index)
            geometry = geometry.toNonIndexed();

        var vertArray = (<THREE.BufferAttribute>geometry.attributes["position"]).array as number[];
        var pointCount = vertArray.length / 3;
        var geometriesCount = transforms.length;

        if (pointCount <= 0)
            return result;

        var hasUv = !!geometry.attributes["uv"];
        var hasColor = !!geometry.attributes["color"];
        var hasNormal = !!geometry.attributes["normal"];

        var vertices = new Float32Array(geometriesCount * pointCount * 3) as any;
        var colors = hasColor ? new Float32Array(geometriesCount * pointCount * 3) as any : null;
        var normals = hasNormal ? new Float32Array(geometriesCount * pointCount * 3) as any : null;
        var uvs = hasUv ? new Float32Array(geometriesCount * pointCount * 2) as any : null;

        var colArray = hasColor ? (<THREE.BufferAttribute>geometry.attributes["color"]).array as number[] : null;
        var normalArray = hasNormal ? (<THREE.BufferAttribute>geometry.attributes["normal"]).array as number[] : null;
        var uvArray = hasUv ? (<THREE.BufferAttribute>geometry.attributes["uv"]).array as number[] : null;

        var transform = new THREE.Matrix4();
        var normalTransform = new THREE.Matrix3();
        var vec3 = new THREE.Vector3();

        var off3 = 0;
        var off2 = 0;

        for (var i = 0; i < geometriesCount; i++) {

            transform.fromArray(transforms[i]);

            for (var k = 0, l = vertArray.length; k < l; k += 3) {
                vec3.fromArray(vertArray, k).applyMatrix4(transform).toArray(vertices, k + off3);
            }

            if (hasNormal) {

                normalTransform.getNormalMatrix(transform);

                for (var m = 0, n = normalArray.length; m < n; m += 3) {
                    vec3.fromArray(normalArray, m).applyMatrix3(normalTransform).toArray(normals, m + off3);
                }
            }

            if (hasColor) {
                colors.set(colArray, off3);
            }

            if (hasUv) {
                uvs.set(uvArray, off2);
                off2 += pointCount * 2;
            }

            off3 += pointCount * 3;
        }

        result.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
        if (hasColor)
            result.setAttribute('color', new THREE.BufferAttribute(colors, 3));
        if (hasNormal)
            result.setAttribute('normal', new THREE.BufferAttribute(normals, 3));
        if (hasUv)
            result.setAttribute('uv', new THREE.BufferAttribute(uvs, 2));

        return result;
    }

    export function MergeBufferGeometries(geometries: THREE.BufferGeometry[], transforms?: number[][]) {
        var result = new THREE.BufferGeometry();

        if (!geometries || !geometries.length)
            return result;

        geometries = geometries.map(geo => geo.index ? geo.toNonIndexed() : geo);

        var hasUv: boolean = false;
        var hasColor: boolean = false;
        var hasNormal: boolean = false;
        var hasTransforms = !!transforms && transforms.length === geometries.length;

        var pointCount = geometries.reduce((prev, cur) => {
            if (cur.attributes["uv"] as any) hasUv = true;
            if (cur.attributes["color"] as any) hasColor = true;
            if (cur.attributes["normal"] as any) hasNormal = true;
            return prev + cur.attributes["position"].array.length / 3;
        }, 0);

        if (pointCount <= 0)
            return result;

        var vertices = new Float32Array(pointCount * 3) as any;
        var colors = hasColor ? new Float32Array(pointCount * 3) as any : null;
        var normals = hasNormal ? new Float32Array(pointCount * 3) as any : null;
        var uvs = hasUv ? new Float32Array(pointCount * 2) as any : null;

        var transform = new THREE.Matrix4();
        var normalTransform = new THREE.Matrix3();
        var vec3 = new THREE.Vector3();

        var off3 = 0;
        var off2 = 0;

        for (var i = 0, j = geometries.length; i < j; i++) {
            var geometry = geometries[i];

            if (hasNormal && !geometry.attributes["normal"])
                geometry.computeVertexNormals();

            var vertArray = (<THREE.BufferAttribute>geometry.attributes["position"]).array as number[];

            if (hasTransforms) {

                transform.fromArray(transforms[i]);

                for (var k = 0, l = vertArray.length; k < l; k += 3) {
                    vec3.fromArray(vertArray, k).applyMatrix4(transform).toArray(vertices, k + off3);
                }

                if (hasNormal && geometry.attributes["normal"]) {

                    var normalArray = (<THREE.BufferAttribute>geometry.attributes["normal"]).array as number[];

                    normalTransform.getNormalMatrix(transform);

                    for (var m = 0, n = normalArray.length; m < n; m += 3) {
                        vec3.fromArray(normalArray, m).applyMatrix3(normalTransform).toArray(normals, m + off3);
                    }
                }

            } else {

                vertices.set(vertArray, off3);

                if (hasNormal && geometry.attributes["normal"])
                    normals.set((<THREE.BufferAttribute>geometry.attributes["normal"]).array as number[], off3);

            }

            if (hasColor && geometry.attributes["color"])
                colors.set((<THREE.BufferAttribute>geometry.attributes["color"]).array as number[], off3);

            if (hasUv && geometry.attributes["uv"])
                uvs.set((<THREE.BufferAttribute>geometry.attributes["uv"]).array as number[], off2);

            var pCount = vertArray.length / 3;

            off2 += pCount * 2;
            off3 += pCount * 3;
        }

        result.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
        if (hasColor)
            result.setAttribute('color', new THREE.BufferAttribute(colors, 3));
        if (hasNormal)
            result.setAttribute('normal', new THREE.BufferAttribute(normals, 3));
        if (hasUv)
            result.setAttribute('uv', new THREE.BufferAttribute(uvs, 2));

        return result;
    }

    export function disposeObject3D(obj: IDisposableThreeJsObject, noMaterial?: boolean, noTexture?: boolean, noGeometry?: boolean) {
        if (obj) {
            if (typeof obj.dispose == "function") {
                obj.dispose();
            } else {
                if (obj.children) {
                    for (var i = 0; i < obj.children.length; i++) {
                        disposeObject3D(obj.children[i], noMaterial, noTexture, noGeometry);
                    }
                }
                if (obj.geometry && !noGeometry) {
                    obj.geometry.dispose();
                    delete obj.geometry;
                }
                if (obj.material && !noMaterial) {
                    if (Array.isArray(obj.material)) {
                        for (i = 0; i < obj.material.length; i++) {
                            obj.material[i].dispose();
                        }
                    } else if ((obj.material as THREE.MultiMaterial).materials) {
                        for (i = 0; i < (obj.material as THREE.MultiMaterial).materials.length; i++) {
                            (obj.material as THREE.MultiMaterial).materials[i].dispose();
                        }
                    }
                    else {
                        obj.material.dispose();
                    }
                    delete obj.material;
                }
                if (obj.texture && !noTexture) {
                    obj.texture.dispose();
                    delete obj.texture;
                }
            }
        }
    }

    export function emptyObject3D(obj: THREE.Object3D, noMaterial?: boolean, noTexture?: boolean, noGeometry?: boolean) {
        if (obj.children && obj.children.length) {
            var children: THREE.Object3D[] = [];
            for (var i = 0, j = obj.children.length; i < j; i++) {
                children.push(obj.children[i]);
            }
            for (var i = 0, j = children.length; i < j; i++) {
                var child = children[i];
                obj.remove(child);
                spt.ThreeJs.utils.disposeObject3D(child, noMaterial, noTexture, noGeometry);
            }
        }
    }

    export function GetCirclePoints(radius: number = 1, segmentLength: number = 1, thetaStart: number = 0, thetaEnd: number = Math.PI * 2) {
        if (thetaStart === thetaEnd)
            thetaEnd += Math.PI * 2;

        var r = radius > 0 ? radius : 1,
            thetaLength = Math.min(Math.PI * 2, Math.abs(thetaEnd - thetaStart)),
            u = r * thetaLength,
            sl = Math.min(u / 3, Math.max(u / 64, segmentLength > 0 ? segmentLength : u / 10)),
            count = Math.round(u / sl),
            thetaStep = (thetaEnd - thetaStart) / count,
            fullCircle = thetaLength / Math.PI * 180 > 359.99;

        var positions: THREE.Vector3[] = [];

        for (var i = fullCircle ? 1 : 0; i <= count; i++) {

            var rd = thetaStart + i * thetaStep,
                p = new THREE.Vector3(Math.cos(rd) * r, Math.sin(rd) * r, 0);

            positions.push(p);
        }


        return positions;
    }

    export function GetCirclePointsSegments(count?: number, thetaStart?: number, thetaLength?: number) {
        count = Math.max(3, count || 0);

        thetaStart = thetaStart !== undefined ? thetaStart : 0;
        thetaLength = thetaLength !== undefined ? thetaLength : Math.PI * 2;

        var positions: THREE.Vector3[] = [new THREE.Vector3(Math.cos(thetaStart), Math.sin(thetaStart), 0)];

        for (var i = 1; i < count; i++) {

            var r = thetaStart + i / count * thetaLength;

            var p = new THREE.Vector3(Math.cos(r), Math.sin(r), 0);

            positions.push(p);
            positions.push(p);
        }

        positions.push(positions[0]);

        return positions;
    }

    export class CircleLineBufferGeometry extends THREE.BufferGeometry {
        constructor(radius: number, segments?: number, thetaStart?: number, thetaLength?: number) {
            super();
            this.type = 'CircleLineBufferGeometry';

            radius = radius || 50;
            segments = segments !== undefined ? Math.max(8, segments) : Math.max(8, Math.round(radius * 0.3));

            thetaStart = thetaStart !== undefined ? thetaStart : 0;
            thetaLength = thetaLength !== undefined ? thetaLength : Math.PI * 2;

            var positions = new Float32Array((segments + 1) * 3);
            var index = new Uint16Array(segments * 2);

            positions[0] = radius * Math.cos(thetaStart);
            positions[1] = radius * Math.sin(thetaStart);

            for (var s = 1, i = 3, j = 0; s <= segments; s++ , i += 3, j += 2) {

                var segment = thetaStart + s / segments * thetaLength;

                positions[i] = radius * Math.cos(segment);
                positions[i + 1] = radius * Math.sin(segment);

                index[j] = s - 1;
                index[j + 1] = s;
            }

            this.setAttribute('position', new THREE.BufferAttribute(positions, 3));
            this.setIndex(new THREE.BufferAttribute(index, 1));

            this.boundingSphere = new THREE.Sphere(new THREE.Vector3(), radius);
        }
    }

    export class CircleLineBufferGeometryCollection extends THREE.BufferGeometry {
        constructor(transforms: number[][], radius: number, segments?: number) {
            super();
            this.type = 'CircleLineBufferGeometryCollection';

            radius = radius || 50;
            segments = segments !== undefined ? Math.max(8, segments) : Math.max(8, Math.round(radius * 0.3));

            var thetaLength = Math.PI * 2;

            var count = transforms.length;

            var vertex = new Float32Array(segments * 3 * count);
            var index = new Uint16Array(segments * 2 * count);

            var cvertex = new Float32Array(segments * 3);
            var cindex = new Uint16Array(segments * 2);

            var transform = new THREE.Matrix4(),
                vt = new THREE.Vector3(),
                min = new THREE.Vector3(Infinity, Infinity, Infinity),
                max = new THREE.Vector3(- Infinity, - Infinity, - Infinity),
                i = 0,
                j = 0;

            for (var s = 0, ci = 0, cj = 0; s < segments; s++ , ci += 3, cj += 2) {

                var segment = s / segments * thetaLength;

                cvertex[ci] = radius * Math.cos(segment);
                cvertex[ci + 1] = radius * Math.sin(segment);

                cindex[cj] = s;
                cindex[cj + 1] = (s + 1) % segments;
            }

            ArrayHelper.iterateTimes(count, (c) => {
                var off = segments * c;
                transform.fromArray(transforms[c]);

                for (var s = 0, ci = 0, cj = 0; s < segments; s++ , i += 3, j += 2, ci += 3, cj += 2) {

                    vt.set(cvertex[ci], cvertex[ci + 1], 0).applyMatrix4(transform);

                    min.min(vt);
                    max.max(vt);

                    vertex[i] = vt.x;
                    vertex[i + 1] = vt.y;

                    index[j] = cindex[cj] + off;
                    index[j + 1] = cindex[cj + 1] + off;
                }
            });

            this.setAttribute('position', new THREE.BufferAttribute(vertex, 3));
            this.setIndex(new THREE.BufferAttribute(index, 1));

            min.x -= radius;
            min.y -= radius;
            min.z -= radius;

            max.x += radius;
            max.y += radius;
            max.z += radius;

            var box = new THREE.Box3(min, max);

            this.boundingBox = box;
            this.boundingSphere = box.getBoundingSphere(new THREE.Sphere());
        }
    }

    export function SaveScreenshotFromRenderer(renderer: THREE.WebGLRenderer, isOverallView?: boolean) {
        if (renderer && typeof THREE != "undefined") {
            var domElement = renderer.domElement;
            if (domElement && typeof domElement.toDataURL == "function") {
                LoaderManager.addLoadingJob();
                try {
                    AManager.Ajax('AppServices.asmx', 'SaveScreenshot', "{\"src\":\"" + domElement.toDataURL("image/png") + "\", \"isOverall\":" + (isOverallView ? "true" : "false") + "}", (result) => {
                        if (result && result.Data && result.Data.success) {
                            DManager.showInfoWindow($('#scene3DScreenshotSaved').html() + " " + result.Data.fileName);
                        } else {
                            DManager.showErrorMessage($('#scene3DScreenshotError').html());
                        }
                        LoaderManager.finishLoadingJob();
                    }, () => {
                        LoaderManager.finishLoadingJob();
                        DManager.showErrorMessage($('#scene3DScreenshotError').html());
                    });
                } catch (e) {
                    LoaderManager.finishLoadingJob();
                    DManager.showErrorMessage($('#scene3DScreenshotError').html());
                }
            } else
                DManager.showErrorMessage($('#scene3DScreenshotError').html());
        } else
            DManager.showErrorMessage($('#scene3DScreenshotError').html());
    }

    export function triangulateToGeometry(poly: THREE.Vector2[], target?: THREE.Geometry): THREE.Geometry {
        if (!poly || !poly.length)
            return null;

        var geo = target || new THREE.Geometry();

        var index = geo.vertices.length,
            box = new THREE.Box2().setFromPoints(poly),
            size = box.getSize(new THREE.Vector2()),
            s = Math.max(size.x, size.y),
            tempUVs = poly.map(p => p.clone().sub(box.min).divideScalar(s));

        for (var i = 0, l = poly.length; i < l; i++) {
            var v = poly[i];
            geo.vertices.push(new THREE.Vector3(v.x, v.y, 0));
        }

        var vIndizes = THREE.ShapeUtils.triangulateShape(poly, []);
        //var vIndizes = THREE.ShapeUtils.triangulate(poly, true) as number[][];

        for (var m = 0, l2 = vIndizes.length; m < l2; m++) {
            var ind = vIndizes[m];

            var a = ind[0],
                b = ind[1],
                c = ind[2];

            geo.faces.push(new THREE.Face3(a + index, b + index, c + index, new THREE.Vector3(0, 0, 1)));

            geo.faceVertexUvs[0].push([tempUVs[a].clone(), tempUVs[b].clone(), tempUVs[c].clone()]);
        }

        return geo;
    }

    //export function SaveScreenshotFromRenderers(renderers: THREE.WebGLRenderer[], isOverallView?: boolean) {
    //    if (LoaderManager.isLoading())
    //        return;

    //    if (typeof THREE != "undefined" && renderers && renderers.length) {
    //        var canvas: HTMLCanvasElement = null,
    //            ctx: CanvasRenderingContext2D = null,
    //            counter = renderers.length,
    //            i = 0;

    //        function renderToCanvas() {
    //            if (i >= counter) {
    //                if (canvas && typeof canvas.toDataURL == "function") {
    //                    try {
    //                        var data = canvas.toDataURL("image/png");
    //                        AManager.AjaxWithLoading('AppServices.asmx', 'SaveScreenshot', `{"src":"${data}", "isOverall":${isOverallView ? "true" : "false"}}`, (result) => {
    //                                if (result && result.Data && result.Data.success) {
    //                                    DManager.showInfoWindow($('#scene3DScreenshotSaved').html() + " " + result.Data.fileName);
    //                                } else
    //                                    DManager.showErrorMessage($('#scene3DScreenshotError').html());
    //                            }, () => {
    //                                DManager.showErrorMessage($('#scene3DScreenshotError').html());
    //                            });
    //                    } catch (e) {
    //                        DManager.showErrorMessage($('#scene3DScreenshotError').html());
    //                    }
    //                }
    //                if (canvas) {
    //                    document.body.removeChild(canvas);
    //                }
    //            } else {
    //                var r = renderers[i++],
    //                    domElement = r.domElement;
    //                if (domElement && typeof domElement.toDataURL == "function") {
    //                    try {
    //                        var image = new Image();
    //                        image.onload = () => {
    //                            if (image.width && image.height) {
    //                                if (!ctx) {
    //                                    var cr = domElement.getBoundingClientRect();

    //                                    canvas = document.createElement("canvas");
    //                                    canvas.style.width = (canvas.width = image.width) + "px";
    //                                    canvas.style.height = (canvas.height = image.height) + "px";
    //                                    canvas.style.position = "absolute";
    //                                    canvas.style.left = cr.left + "px";
    //                                    canvas.style.top = cr.top + "px";

    //                                    document.body.appendChild(canvas);

    //                                    ctx = canvas.getContext("2d");
    //                                }
    //                            }
    //                            ctx.drawImage(image, 0, 0);
    //                            renderToCanvas();
    //                        };
    //                        image.src = domElement.toDataURL("image/png");
    //                    } catch (e) {
    //                        renderToCanvas();
    //                    }
    //                } else
    //                    renderToCanvas();
    //            }
    //        }

    //        renderToCanvas();
    //    }

    export var BoxisIntersectingTriangle = (() => {

        var center = new THREE.Vector3(),
            extents = new THREE.Vector3(),
            v0 = new THREE.Vector3(),
            v1 = new THREE.Vector3(),
            v2 = new THREE.Vector3(),
            f0 = new THREE.Vector3(),
            f1 = new THREE.Vector3(),
            f2 = new THREE.Vector3(),
            a00 = new THREE.Vector3(),
            a01 = new THREE.Vector3(),
            a02 = new THREE.Vector3(),
            a10 = new THREE.Vector3(),
            a11 = new THREE.Vector3(),
            a12 = new THREE.Vector3(),
            a20 = new THREE.Vector3(),
            a21 = new THREE.Vector3(),
            a22 = new THREE.Vector3(),
            plane = new THREE.Plane();

        return (a: THREE.Vector3, b: THREE.Vector3, c: THREE.Vector3, aabb: THREE.Box3) => {

            var p0: number, p1: number, p2: number, r: number;

            // Compute box center and extents of AABoundingBox (if not already given in that format)
            aabb.getCenter(center);
            extents.subVectors(aabb.max, center);

            // Translate triangle as conceptually moving AABB to origin
            v0.subVectors(a, center);
            v1.subVectors(b, center);
            v2.subVectors(c, center);

            // Compute edge vectors for triangle
            f0.subVectors(v1, v0);
            f1.subVectors(v2, v1);
            f2.subVectors(v0, v2);

            // Test axes a00..a22 (category 3)
            a00.set(0, -f0.z, f0.y);
            a01.set(0, -f1.z, f1.y);
            a02.set(0, -f2.z, f2.y);
            a10.set(f0.z, 0, -f0.x);
            a11.set(f1.z, 0, -f1.x);
            a12.set(f2.z, 0, -f2.x);
            a20.set(-f0.y, f0.x, 0);
            a21.set(-f1.y, f1.x, 0);
            a22.set(-f2.y, f2.x, 0);

            // Test axis a00
            p0 = v0.dot(a00);
            p1 = v1.dot(a00);
            p2 = v2.dot(a00);
            r = extents.y * Math.abs(f0.z) + extents.z * Math.abs(f0.y);

            if (Math.max(-Math.max(p0, p1, p2), Math.min(p0, p1, p2)) > r) {

                return false; // Axis is a separating axis

            }

            // Test axis a01
            p0 = v0.dot(a01);
            p1 = v1.dot(a01);
            p2 = v2.dot(a01);
            r = extents.y * Math.abs(f1.z) + extents.z * Math.abs(f1.y);

            if (Math.max(-Math.max(p0, p1, p2), Math.min(p0, p1, p2)) > r) {

                return false; // Axis is a separating axis

            }

            // Test axis a02
            p0 = v0.dot(a02);
            p1 = v1.dot(a02);
            p2 = v2.dot(a02);
            r = extents.y * Math.abs(f2.z) + extents.z * Math.abs(f2.y);

            if (Math.max(-Math.max(p0, p1, p2), Math.min(p0, p1, p2)) > r) {

                return false; // Axis is a separating axis

            }

            // Test axis a10
            p0 = v0.dot(a10);
            p1 = v1.dot(a10);
            p2 = v2.dot(a10);
            r = extents.x * Math.abs(f0.z) + extents.z * Math.abs(f0.x);
            if (Math.max(-Math.max(p0, p1, p2), Math.min(p0, p1, p2)) > r) {

                return false; // Axis is a separating axis

            }

            // Test axis a11
            p0 = v0.dot(a11);
            p1 = v1.dot(a11);
            p2 = v2.dot(a11);
            r = extents.x * Math.abs(f1.z) + extents.z * Math.abs(f1.x);

            if (Math.max(-Math.max(p0, p1, p2), Math.min(p0, p1, p2)) > r) {

                return false; // Axis is a separating axis

            }

            // Test axis a12
            p0 = v0.dot(a12);
            p1 = v1.dot(a12);
            p2 = v2.dot(a12);
            r = extents.x * Math.abs(f2.z) + extents.z * Math.abs(f2.x);

            if (Math.max(-Math.max(p0, p1, p2), Math.min(p0, p1, p2)) > r) {

                return false; // Axis is a separating axis

            }

            // Test axis a20
            p0 = v0.dot(a20);
            p1 = v1.dot(a20);
            p2 = v2.dot(a20);
            r = extents.x * Math.abs(f0.y) + extents.y * Math.abs(f0.x);

            if (Math.max(-Math.max(p0, p1, p2), Math.min(p0, p1, p2)) > r) {

                return false; // Axis is a separating axis

            }

            // Test axis a21
            p0 = v0.dot(a21);
            p1 = v1.dot(a21);
            p2 = v2.dot(a21);
            r = extents.x * Math.abs(f1.y) + extents.y * Math.abs(f1.x);

            if (Math.max(-Math.max(p0, p1, p2), Math.min(p0, p1, p2)) > r) {

                return false; // Axis is a separating axis

            }

            // Test axis a22
            p0 = v0.dot(a22);
            p1 = v1.dot(a22);
            p2 = v2.dot(a22);
            r = extents.x * Math.abs(f2.y) + extents.y * Math.abs(f2.x);

            if (Math.max(-Math.max(p0, p1, p2), Math.min(p0, p1, p2)) > r) {

                return false; // Axis is a separating axis

            }

            // Test the three axes corresponding to the face normals of AABB b (category 1).
            // Exit if...
            // ... [-extents.x, extents.x] and [min(v0.x,v1.x,v2.x), max(v0.x,v1.x,v2.x)] do not overlap
            if (Math.max(v0.x, v1.x, v2.x) < -extents.x || Math.min(v0.x, v1.x, v2.x) > extents.x) {

                return false;

            }
            // ... [-extents.y, extents.y] and [min(v0.y,v1.y,v2.y), max(v0.y,v1.y,v2.y)] do not overlap
            if (Math.max(v0.y, v1.y, v2.y) < -extents.y || Math.min(v0.y, v1.y, v2.y) > extents.y) {

                return false;

            }
            // ... [-extents.z, extents.z] and [min(v0.z,v1.z,v2.z), max(v0.z,v1.z,v2.z)] do not overlap
            if (Math.max(v0.z, v1.z, v2.z) < -extents.z || Math.min(v0.z, v1.z, v2.z) > extents.z) {

                return false;

            }

            return aabb.intersectsPlane(plane.setFromCoplanarPoints(a, b, c));

        };
    })();

    export function RotateCC<k extends IVertex2>(target: k, angleRad: number, origin?: IVertex2) {
        var ox = 0,
            oy = 0,
            cos = Math.cos(angleRad),
            sin = Math.sin(angleRad);

        if (origin) {
            ox = origin.x;
            oy = origin.y;
        }

        var dx = target.x - ox,
            dy = target.y - oy,
            x = ox + dx * cos - dy * sin,
            y = oy + dx * sin + dy * cos;

        target.x = x;
        target.y = y;

        return target;
    }

    export function ProjectVectorToClosestAxis<k extends IVertex2>(v: k, minDistance = 0) {
        var dx: number, dy: number;

        if (Math.abs(v.x) > Math.abs(v.y)) {
            dx = v.x > 0 ? 1 : -1;
            dy = 0;
        } else {
            dx = 0;
            dy = v.y > 0 ? 1 : -1;
        }

        var dot = Math.max(minDistance, v.x * dx + v.y * dy);

        v.x = dx * dot;
        v.y = dy * dot;

        return v;
    }

    export function ToClosestAxis<k extends IVertex2>(v: k) {
        if (Math.abs(v.x) > Math.abs(v.y)) {
            v.x = v.x > 0 ? 1 : -1;
            v.y = 0;
        } else {
            v.x = 0;
            v.y = v.y > 0 ? 1 : -1;
        }
        return v;
    }

    export function CrossProd(a: IVertex2, b: IVertex2) {
        return a.x * b.y - a.y * b.x;
    }

    export function DotProd(a: IVertex2, b: IVertex2) {
        return a.x * b.x + a.y * b.y;
    }

    //Angle in radians from v1 to v2 in counter clockwise direction [-pi .. +pi]
    export function GetCCAngleRad(a: IVertex2, b: IVertex2) {
        // |A·B| = |A| |B| COS(θ)
        // |A×B| = |A| |B| SIN(θ)

        return Math.atan2(CrossProd(a, b), DotProd(a, b));

    }

    //converts degree angle to [0 .. +360]
    export function DegreeToPositive(deg: number) {
        return (deg % 360 + 360) % 360;
    }

    //converts degree angle to [-180 .. +180]
    export function ClampDegree(deg: number) {
        deg = DegreeToPositive(deg);
        return deg > 180 ? deg - 360 : deg;
    }

    export function PointsToPath(points: { x: number, y: number, z?: number }[], precision = 100) {
        var path = new ClipperLib.Path();

        if (points && points.length) {
            for (var i = 0, l = points.length; i < l; i++) {
                var p = points[i];
                path.push(new ClipperLib.IntPoint(Math.round(p.x * precision), Math.round(p.y * precision), Math.round((p.z || 0) * precision)));
            }
        }

        return path;
    }

    export function PointsToPaths(points: { x: number, y: number, z?: number }[][], precision = 100) {
        var paths = new ClipperLib.Paths();

        if (points && points.length) {
            for (var i = 0, l = points.length; i < l; i++) {
                paths.push(PointsToPath(points[i], precision));
            }
        }

        return paths;
    }

    export function PathToPoints(path: ClipperLib.Path, precision = 100) {
        var pts: THREE.Vector3[] = [];

        if (path && path.length) {
            for (var i = 0, l = path.length; i < l; i++) {
                var p = path[i];
                pts.push(new THREE.Vector3(p.X / precision, p.Y / precision, p.Z / precision));
            }
        }

        return pts;
    }

    export function PathsToPoints(paths: ClipperLib.Paths, precision = 100) {
        return paths.map(path => PathToPoints(path, precision));
    }

    export function extractPolygons(node: ClipperLib.PolyNode, collection?: ClipperLib.Path[][]) {
        if (!node)
            return [];

        if (!collection)
            collection = [];

        var childs = node.Childs();

        if (node.IsHole || node.Parent == null) {
            if (childs && childs.length) {
                for (var i = childs.length; i--;) {
                    extractPolygons(childs[i], collection);
                }
            }
        } else {
            var contour = node.Contour();

            if (!node.IsOpen && contour && contour.length) {

                var isClockwise = !ClipperLib.Clipper.Orientation(contour);
                if (isClockwise)
                    contour.reverse();

                var currentPolygons = [contour];

                if (childs && childs.length) {
                    for (var i = childs.length; i--;) {
                        var nodeChild = childs[i],
                            childContour = nodeChild.Contour();
                        if (nodeChild.IsHole()) {
                            var childIsClockwise = !ClipperLib.Clipper.Orientation(childContour);
                            if (!childIsClockwise)
                                childContour.reverse();

                            currentPolygons.push(childContour);
                            var childChilds = nodeChild.Childs();

                            if (childChilds && childChilds.length) {
                                for (var j = childChilds.length; j--;)
                                    extractPolygons(childChilds[j], collection);
                            }
                        } else
                            extractPolygons(nodeChild, collection);
                    }
                }

                collection.push(currentPolygons);
            }
        }

        return collection;
    }

    export function calculateTriangulatedPolygons(node: ClipperLib.PolyNode, collection?: ClipperLib.Path[][]) {

    }

    export function DebugPolygons(closedPolys: THREE.Vector3[][], openPolys: THREE.Vector3[][], points: THREE.Vector4[], key?: string) {

        var datas: SolarProTool.DebugPolygonData[] = [];

        if (closedPolys && closedPolys.length) {
            closedPolys.forEach(poly => {
                datas.push({
                    Color: null,
                    Vertices: poly,
                    Closed: true,
                    Radius: 0
                });
            });
        }

        if (openPolys && openPolys.length) {
            openPolys.forEach(poly => {
                datas.push({
                    Color: null,
                    Vertices: poly,
                    Closed: false,
                    Radius: 0
                });
            });
        }

        if (points && points.length) {
            var rd: { [r: number]: THREE.Vector3[] } = {};
            points.forEach(p => {
                if (!rd[p.w])
                    rd[p.w] = [];
                rd[p.w].push(new THREE.Vector3(p.x, p.y, p.z));
            });

            Object.keys(rd).forEach(r => {
                var radius = +r;
                if (radius > 0) {
                    datas.push({
                        Color: null,
                        Vertices: rd[radius],
                        Closed: false,
                        Radius: radius
                    });
                }
            });
        }

        SolarProTool.Ajax("WebServices/Anordnung3DService.asmx").Call("SaveDebugPolygon").Data({ datas: datas }).CallBack(s => {
            if (key)
                console.log(key);
            console.log(s);
        });
    }

    export function GetTriangles(mesh: THREE.Mesh): THREE.Vector3[][] {
        if (!mesh || !mesh.geometry)
            return [];

        var vertices: THREE.Vector3[][] = [];

        traverseMeshTriangles(mesh, (p1, p2, p3) => {
            vertices.push([p1.clone(), p2.clone(), p3.clone()]);
            return true;
        });

        return vertices;

        //var faces: number[][] = [],
        //    vertices: THREE.Vector3[] = [];

        //if ((<THREE.BufferGeometry>mesh.geometry).isBufferGeometry) {
        //    var geometry = <THREE.BufferGeometry>mesh.geometry;
        //    var indices = (geometry.index !== null ? geometry.index.array : undefined) as number[];
        //    var attributes = geometry.attributes;
        //    var positions = attributes.position.array as number[];

        //    for (var i = 0; i < positions.length; i += 3)
        //        vertices.push(new THREE.Vector3().fromArray(positions, i));

        //    var groups = geometry.groups;

        //    if (groups.length > 0) {
        //        for (var i = 0; i < groups.length; i++) {
        //            var group = groups[i];

        //            var start = group.start;
        //            var count = group.count;

        //            for (var j = start, jl = start + count; j < jl; j += 3) {

        //                if (indices !== undefined) {
        //                    faces.push([indices[j], indices[j + 1], indices[j + 2]]);
        //                } else {
        //                    faces.push([j, j + 1, j + 2]);
        //                }
        //            }
        //        }
        //    } else {
        //        if (indices !== undefined) {
        //            for (var i = 0; i < indices.length; i += 3) {
        //                faces.push([indices[i], indices[i + 1], indices[i + 2]]);
        //            }

        //        } else {
        //            for (var i = 0; i < positions.length / 3; i += 3) {
        //                faces.push([i, i + 1, i + 2]);
        //            }
        //        }
        //    }
        //} else {
        //    var geo = <THREE.Geometry>mesh.geometry;
        //    vertices = geo.vertices;
        //    for (var i = 0, l = geo.faces.length; i < l; i++) {
        //        var face = geo.faces[i];
        //        faces.push([face.a, face.b, face.c]);
        //    }
        //}

        //return faces.map(face => {
        //    return [vertices[face[0]], vertices[face[1]], vertices[face[2]]];
        //});
    }

    export function TrianglesToSurfacePolygons(triangles: THREE.Vector3[][]) {
        var pk: { [k: string]: { normal: THREE.Vector4, triangles: THREE.Vector3[][] } } = {},
            n = new THREE.Vector3();

        for (var i = triangles.length; i--;) {
            var triangle = triangles[i];
            getNormal(triangle[0], triangle[1], triangle[2], n);
            if (n.z === 0)
                n.set(0, 0, 1);

            var d = n.dot(triangle[0]),
                //k = `${n.x.toFixed(1)}x${n.y.toFixed(1)}x${n.z.toFixed(1)}`;
                k = `${n.x.toFixed(2)}x${n.y.toFixed(2)}x${n.z.toFixed(2)}x${Math.round(d / 10) * 10}`;

            if (!pk[k])
                pk[k] = { normal: new THREE.Vector4(n.x, n.y, n.z, d), triangles: [] };

            pk[k].triangles.push(triangle);
        }

        var result: { plane: THREE.Vector4, polygons: ClipperLib.Paths }[] = [];

        for (var k in pk) {
            var trianglePaths = new ClipperLib.Paths(),
                trs = pk[k].triangles;

            for (var i = trs.length; i--;) {
                var tr = trs[i],
                    trianglePath = new ClipperLib.Path();
                for (var j = 0, l = tr.length; j < l; j++) {
                    var p = tr[j];
                    trianglePath.push(new ClipperLib.IntPoint(Math.round(p.x), Math.round(p.y), Math.round(p.z)));
                }
                trianglePaths.push(trianglePath);
            }

            var c = new ClipperLib.Clipper();
            c.AddPaths(trianglePaths, ClipperLib.PolyType.ptSubject, true);
            var union = new ClipperLib.Paths();
            c.Execute(ClipperLib.ClipType.ctUnion, union, ClipperLib.PolyFillType.pftNonZero, ClipperLib.PolyFillType.pftNonZero);

            result.push({ plane: pk[k].normal, polygons: union });
        }

        return result;
    }

    export function ProjectPolygonsToSurface(polygon: THREE.Vector3[], surfacePolygons: { plane: THREE.Vector4, polygons: ClipperLib.Paths }[], targetBounds?: THREE.Box3) {
        var result: { normal: THREE.Vector3, points: THREE.Vector3[] }[] = [];

        if (!polygon)
            return result;

        if (!targetBounds)
            targetBounds = new THREE.Box3();

        var borderPath = new ClipperLib.Path();
        polygon.forEach(p => {
            borderPath.push(new ClipperLib.IntPoint(Math.round(p.x), Math.round(p.y), Math.round(p.z)));
        });

        var n = new THREE.Vector3();

        for (var i = surfacePolygons.length; i--;) {
            var surface = surfacePolygons[i];

            n.set(surface.plane.x, surface.plane.y, surface.plane.z);

            var d = surface.plane.w;

            var c = new ClipperLib.Clipper();
            c.AddPath(borderPath, ClipperLib.PolyType.ptSubject, true);
            c.AddPaths(surface.polygons, ClipperLib.PolyType.ptClip, true);
            var intersected = new ClipperLib.Paths();
            c.Execute(ClipperLib.ClipType.ctIntersection, intersected, ClipperLib.PolyFillType.pftNonZero, ClipperLib.PolyFillType.pftNonZero);

            if (intersected && intersected.length) {
                intersected.forEach(inter => {
                    if (!ClipperLib.Clipper.Orientation(inter))
                        inter.reverse();
                    result.push({
                        normal: n.clone(), points: inter.map(pt => {
                            var rp = new THREE.Vector3(pt.X, pt.Y, (-n.x * pt.X - n.y * pt.Y + d) / n.z);
                            targetBounds.expandByPoint(rp);
                            return rp;
                        })
                    });
                });
            }
        }

        //var r = result.slice(),
        //    sp = surfacePolygons.map(s => s.polygons.map(ply => ply.map(
        //    p => new THREE.Vector3(p.X, p.Y, (-s.plane.x * p.X - s.plane.y * p.Y + s.plane.w) / s.plane.z))));
        //sp.forEach(spp => { r = r.concat(spp); });
        //DebugPolygons(r, null, null, "GetPolygonsByBorder");

        return result;
    }

    export function TextureFromImageData(imagedata: ImageData): THREE.Texture {

        var canvas = document.createElement('canvas'),
            ctx = canvas.getContext('2d');
        canvas.width = imagedata.width;
        canvas.height = imagedata.height;
        ctx.putImageData(imagedata, 0, 0);

        var image = new Image();
        image.src = canvas.toDataURL();

        //document.body.appendChild(image);

        var texture = new THREE.Texture(image);
        texture.format = THREE.RGBAFormat;
        texture.needsUpdate = true;

        return texture;
    }

    export function TextureFromBytes(buffer: ArrayBuffer, isJPEG: boolean): THREE.Texture {
        var imageBlob = new Blob([buffer], { type: isJPEG ? "image/jpeg" : "image/png" }),
            image = new Image(),
            texture = new THREE.Texture(image);

        image.src = URL.createObjectURL(imageBlob);
        image.onload = () => {
            image.onload = null;
            texture.needsUpdate = true;
        };

        //document.body.appendChild(image);

        // JPEGs can't have an alpha channel, so memory can be saved by storing them as RGB.
        texture.format = isJPEG ? THREE.RGBFormat : THREE.RGBAFormat;

        return texture;
    }

    export function Dxt1TextureFromBytes(bytes: Uint8Array, width: number, height: number): THREE.Texture {
        var texture: THREE.Texture;

        if (Detector.webglParameters.compressedTexture) {
            texture = new THREE.CompressedTexture();
            texture.image = [];
            texture.image.width = width;
            texture.image.height = height;
            texture.mipmaps = [
                {
                    data: bytes as any,
                    width: width,
                    height: height
                }
            ];
            texture.magFilter = texture.minFilter = THREE.LinearFilter;
            texture.generateMipmaps = false;
            //texture.minFilter = THREE.LinearMipMapLinearFilter;
            texture.format = THREE.RGBA_S3TC_DXT1_Format as any;
            texture.needsUpdate = true;
        } else {
            var imageDataView = new DataView(bytes.buffer, 0, bytes.length);
            var rgbaData = GMapsUtils.decodeDXT(imageDataView, width, height, 'dxt1') as Uint8Array;
            texture = new THREE.DataTexture(rgbaData,
                width,
                height,
                THREE.RGBAFormat,
                THREE.UnsignedByteType,
                THREE.UVMapping,
                THREE.ClampToEdgeWrapping,
                THREE.ClampToEdgeWrapping,
                THREE.LinearFilter,
                THREE.LinearFilter);
            texture.generateMipmaps = false;
            texture.needsUpdate = true;
        }

        //var imageDataView = new DataView(meshData.texture.bytes.buffer, 0, meshData.texture.bytes.length);
        //var rgbaData = dxtDecoder(imageDataView, meshData.texture.width, meshData.texture.height, 'dxt1') as Uint8Array;
        //var imageData = new ImageData(new Uint8ClampedArray(rgbaData.buffer), meshData.texture.width, meshData.texture.height);
        //texture = spt.ThreeJs.utils.TextureFromImageData(imageData);
        //texture.magFilter = texture.minFilter = THREE.LinearFilter;
        //texture.generateMipmaps = false;
        //flipY = true;

        return texture;

        //var texDatas = THREE.DDSLoader.parse(buffer, true),
        //    compressedTexture = new THREE.CompressedTexture(texDatas.mipmaps, texDatas.width, texDatas.height);

        //if (texDatas.mipmapCount === 1)
        //    compressedTexture.minFilter = THREE.LinearFilter;

        //compressedTexture.format = texDatas.format as any;
        //compressedTexture.needsUpdate = true;

        //return compressedTexture;
    }

    export var traverseMeshTriangles = (() => {
        var vA = new THREE.Vector3();
        var vB = new THREE.Vector3();
        var vC = new THREE.Vector3();

        var tempA = new THREE.Vector3();
        var tempB = new THREE.Vector3();
        var tempC = new THREE.Vector3();

        return (mesh: THREE.Mesh, trFn: (p1: THREE.Vector3, p2: THREE.Vector3, p3: THREE.Vector3) => boolean) => {
            if (!mesh || !trFn || !mesh.geometry)
                return;

            let { geometry, material, matrixWorld } = mesh,
                drawMode = THREE.TrianglesDrawMode; //mesh.drawMode

            if ((geometry as THREE.BufferGeometry).isBufferGeometry) {
                var bufferGeometry = <THREE.BufferGeometry>geometry;
                var a: number, b: number, c: number;
                var index = bufferGeometry.index;
                var position = bufferGeometry.attributes.position as THREE.BufferAttribute;
                var groups = bufferGeometry.groups;
                var drawRange = bufferGeometry.drawRange;
                var i: number, j: number, il: number, jl: number;
                var group: { start: number, count: number, materialIndex?: number };
                var start: number, end: number;

                if (index !== null) {
                    // indexed buffer geometry

                    if (mesh.userData && mesh.userData.octantsToExclude && mesh.userData.octantsToExclude.length) {
                        //GM Mesh

                        var octantsToExclude = mesh.userData.octantsToExclude as number[];

                        start = Math.max(0, drawRange.start);
                        end = Math.min(index.count, (drawRange.start + drawRange.count));

                        for (j = start, jl = end; j < jl; j++) {

                            if (i & 1) {
                                a = index.getX(j);
                                b = index.getX(j + 2);
                                c = index.getX(j + 1);
                            } else {
                                a = index.getX(j);
                                b = index.getX(j + 1);
                                c = index.getX(j + 2);
                            }

                            if (a === b || a === c || b === c)
                                continue;

                            var w = position.getW(a);

                            if (isNaN(w) || octantsToExclude.indexOf(w) >= 0)
                                continue;

                            if (!trFn(vA.fromBufferAttribute(position, a).applyMatrix4(matrixWorld), vB.fromBufferAttribute(position, b).applyMatrix4(matrixWorld), vC.fromBufferAttribute(position, c).applyMatrix4(matrixWorld)))
                                return;

                        }
                    }
                    else if (Array.isArray(material)) {

                        for (i = 0, il = groups.length; i < il; i++) {

                            group = groups[i];

                            start = Math.max(group.start, drawRange.start);
                            end = Math.min((group.start + group.count), (drawRange.start + drawRange.count));

                            if (drawMode === THREE.TriangleStripDrawMode) {
                                for (j = start, jl = end; j < jl; j++) {

                                    if (i & 1) {
                                        a = index.getX(j);
                                        b = index.getX(j + 2);
                                        c = index.getX(j + 1);
                                    } else {
                                        a = index.getX(j);
                                        b = index.getX(j + 1);
                                        c = index.getX(j + 2);
                                    }

                                    if (!trFn(vA.fromBufferAttribute(position, a).applyMatrix4(matrixWorld), vB.fromBufferAttribute(position, b).applyMatrix4(matrixWorld), vC.fromBufferAttribute(position, c).applyMatrix4(matrixWorld)))
                                        return;

                                }
                            } else {
                                for (j = start, jl = end; j < jl; j += 3) {

                                    a = index.getX(j);
                                    b = index.getX(j + 1);
                                    c = index.getX(j + 2);

                                    if (!trFn(vA.fromBufferAttribute(position, a).applyMatrix4(matrixWorld), vB.fromBufferAttribute(position, b).applyMatrix4(matrixWorld), vC.fromBufferAttribute(position, c).applyMatrix4(matrixWorld)))
                                        return;

                                }
                            }
                        }

                    }
                    else {

                        start = Math.max(0, drawRange.start);
                        end = Math.min(index.count, (drawRange.start + drawRange.count));

                        if (drawMode === THREE.TriangleStripDrawMode) {
                            for (i = start, il = end; i < il; i++) {
                                if (i & 1) {
                                    a = index.getX(i);
                                    b = index.getX(i + 2);
                                    c = index.getX(i + 1);
                                } else {
                                    a = index.getX(i);
                                    b = index.getX(i + 1);
                                    c = index.getX(i + 2);
                                }

                                if (!trFn(vA.fromBufferAttribute(position, a).applyMatrix4(matrixWorld), vB.fromBufferAttribute(position, b).applyMatrix4(matrixWorld), vC.fromBufferAttribute(position, c).applyMatrix4(matrixWorld)))
                                    return;

                            }
                        } else {
                            for (i = start, il = end; i < il; i += 3) {

                                a = index.getX(i);
                                b = index.getX(i + 1);
                                c = index.getX(i + 2);

                                if (!trFn(vA.fromBufferAttribute(position, a).applyMatrix4(matrixWorld), vB.fromBufferAttribute(position, b).applyMatrix4(matrixWorld), vC.fromBufferAttribute(position, c).applyMatrix4(matrixWorld)))
                                    return;

                            }
                        }
                    }

                }
                else if (position !== undefined) {

                    // non-indexed buffer geometry

                    if (Array.isArray(material)) {

                        for (i = 0, il = groups.length; i < il; i++) {

                            group = groups[i];

                            start = Math.max(group.start, drawRange.start);
                            end = Math.min((group.start + group.count), (drawRange.start + drawRange.count));

                            if (drawMode === THREE.TriangleStripDrawMode) {
                                for (j = start, jl = end; j < jl; j++) {

                                    if (i & 1) {
                                        a = j;
                                        b = j + 2;
                                        c = j + 1;
                                    } else {
                                        a = j;
                                        b = j + 1;
                                        c = j + 2;
                                    }

                                    if (!trFn(vA.fromBufferAttribute(position, a).applyMatrix4(matrixWorld), vB.fromBufferAttribute(position, b).applyMatrix4(matrixWorld), vC.fromBufferAttribute(position, c).applyMatrix4(matrixWorld)))
                                        return;

                                }
                            } else {
                                for (j = start, jl = end; j < jl; j += 3) {

                                    a = j;
                                    b = j + 1;
                                    c = j + 2;

                                    if (!trFn(vA.fromBufferAttribute(position, a).applyMatrix4(matrixWorld), vB.fromBufferAttribute(position, b).applyMatrix4(matrixWorld), vC.fromBufferAttribute(position, c).applyMatrix4(matrixWorld)))
                                        return;

                                }
                            }
                        }

                    } else {

                        start = Math.max(0, drawRange.start);
                        end = Math.min(position.count, (drawRange.start + drawRange.count));

                        if (drawMode === THREE.TriangleStripDrawMode) {
                            for (i = start, il = end; i < il; i++) {

                                if (i & 1) {
                                    a = i;
                                    b = i + 2;
                                    c = i + 1;
                                } else {
                                    a = i;
                                    b = i + 1;
                                    c = i + 2;
                                }

                                if (!trFn(vA.fromBufferAttribute(position, a).applyMatrix4(matrixWorld), vB.fromBufferAttribute(position, b).applyMatrix4(matrixWorld), vC.fromBufferAttribute(position, c).applyMatrix4(matrixWorld)))
                                    return;

                            }
                        } else {
                            for (i = start, il = end; i < il; i += 3) {

                                a = i;
                                b = i + 1;
                                c = i + 2;

                                if (!trFn(vA.fromBufferAttribute(position, a).applyMatrix4(matrixWorld), vB.fromBufferAttribute(position, b).applyMatrix4(matrixWorld), vC.fromBufferAttribute(position, c).applyMatrix4(matrixWorld)))
                                    return;

                            }
                        }
                    }
                }
            }
            else if ((geometry as THREE.Geometry).isGeometry) {
                var geo = <THREE.Geometry>geometry;
                var fvA: THREE.Vector3, fvB: THREE.Vector3, fvC: THREE.Vector3;
                var isMultiMaterial = Array.isArray(material);

                var vertices = geo.vertices;
                var faces = geo.faces;

                for (var f = 0, fl = faces.length; f < fl; f++) {

                    var face = faces[f];
                    var faceMaterial = isMultiMaterial ? material[face.materialIndex] : material;

                    if (faceMaterial === undefined) continue;

                    fvA = vertices[face.a];
                    fvB = vertices[face.b];
                    fvC = vertices[face.c];

                    if (faceMaterial.morphTargets === true) {

                        var morphTargets = geo.morphTargets;
                        var morphInfluences = mesh.morphTargetInfluences;

                        vA.set(0, 0, 0);
                        vB.set(0, 0, 0);
                        vC.set(0, 0, 0);

                        for (var t = 0, tl = morphTargets.length; t < tl; t++) {

                            var influence = morphInfluences[t];

                            if (influence === 0) continue;

                            var targets = morphTargets[t].vertices;

                            vA.addScaledVector(tempA.subVectors(targets[face.a], fvA), influence);
                            vB.addScaledVector(tempB.subVectors(targets[face.b], fvB), influence);
                            vC.addScaledVector(tempC.subVectors(targets[face.c], fvC), influence);

                        }

                        vA.add(fvA);
                        vB.add(fvB);
                        vC.add(fvC);
                    } else {
                        vA.copy(fvA);
                        vB.copy(fvB);
                        vC.copy(fvC);
                    }

                    if (!trFn(vA.applyMatrix4(matrixWorld), vB.applyMatrix4(matrixWorld), vC.applyMatrix4(matrixWorld)))
                        return;
                }

            }

        };
    })();

    export var cutTriangleByPlane = (() => {
        var i1 = new THREE.Vector3(),
            i2 = new THREE.Vector3(),
            line = new THREE.Line3();

        return (plane: THREE.Plane, trianglePoints: THREE.Vector3[]) => {
            var da = plane.distanceToPoint(trianglePoints[0]),
                db = plane.distanceToPoint(trianglePoints[1]),
                dc = plane.distanceToPoint(trianglePoints[2]),
                pos0 = Math.sign(da),
                pos1 = Math.sign(db),
                pos2 = Math.sign(dc),
                hasZero = pos0 * pos1 * pos2 === 0,
                sumPos = pos0 + pos1 + pos2,
                lpIdx: number;

            if ((pos0 === pos1 && pos1 === pos2) || (hasZero && sumPos !== 0))
                return [trianglePoints];

            var cutThrough = sumPos === 0;

            if (cutThrough) {
                //cut through point index
                lpIdx = pos0 === 0 ? 0 : pos1 === 0 ? 1 : 2;
            } else {
                // Find lonely point index
                lpIdx = pos0 === pos1 ? 2 : pos0 === pos2 ? 1 : 0;
            }

            // Set previous point in relation to front face order
            var prevPoint = lpIdx - 1;
            if (prevPoint === -1) prevPoint = 2;
            // Set next point in relation to front face order
            var nextPoint = lpIdx + 1;
            if (nextPoint === 3) nextPoint = 0;

            if (cutThrough) {
                /*                  |
                 *                  |P0
                 *       |         /|\
                 *       |        / | \       
                 *       |       /  |  \      
                 *       |      /   |   \
                 *       |     /    |    \
                 *      y|    /     |     \
                 *       | P2/__ ___|_____ \P1
                 *       |          |I1
                 *       |          |
                 *       |___________________
                 */

                // Get the 1 intersection point
                var newPoint = plane.intersectLine(line.set(trianglePoints[prevPoint], trianglePoints[nextPoint]), i1);
                if (newPoint) {
                    return [
                        [trianglePoints[lpIdx], trianglePoints[nextPoint], newPoint],
                        [trianglePoints[lpIdx], newPoint, trianglePoints[prevPoint]]
                    ];
                }
            } else {
                /*
                 *       |      |  /|
                 *       |      | / |P1       
                 *       |      |/  |         
                 *       |    I1|   |
                 *       |     /|   |
                 *      y|    / |   |
                 *       | P0/__|___|P2
                 *       |      |I2
                 *       |      |
                 *       |___________________
                 */

                // Get the 2 intersection points
                var newPointPrev = plane.intersectLine(line.set(trianglePoints[lpIdx], trianglePoints[prevPoint]), i1);
                var newPointNext = plane.intersectLine(line.set(trianglePoints[lpIdx], trianglePoints[nextPoint]), i2);

                if (newPointPrev && newPointNext) {
                    return [
                        [trianglePoints[lpIdx], newPointNext, newPointPrev],
                        [trianglePoints[prevPoint], newPointPrev, trianglePoints[nextPoint]],
                        [trianglePoints[nextPoint], newPointPrev, newPointNext]
                    ];
                }
            }

            return [trianglePoints];
        };
    })();

    export var projectedVolumeFromTriangle = (() => {
        var projTr = new THREE.Triangle(),
            i1 = new THREE.Vector3(),
            i2 = new THREE.Vector3(),
            line = new THREE.Line3();

        //var getProjectedArea = (pl: THREE.Plane, tp: THREE.Vector3[], d0: number, d1: number, d2: number) => {
        //    projTr.a.copy(pl.normal).multiplyScalar(-d0).add(tp[0]);
        //    projTr.b.copy(pl.normal).multiplyScalar(-d1).add(tp[1]);
        //    projTr.c.copy(pl.normal).multiplyScalar(-d2).add(tp[2]);
        //    return projTr.getArea();
        //};

        var projVolume = (pl: THREE.Plane, tp: THREE.Vector3[], maxDist: number) => {
            var d0 = pl.distanceToPoint(tp[0]),
                d1 = pl.distanceToPoint(tp[1]),
                d2 = pl.distanceToPoint(tp[2]);

            projTr.a.copy(pl.normal).multiplyScalar(-d0).add(tp[0]);
            projTr.b.copy(pl.normal).multiplyScalar(-d1).add(tp[1]);
            projTr.c.copy(pl.normal).multiplyScalar(-d2).add(tp[2]);

            return Math.min(maxDist, (Math.abs(d0) + Math.abs(d1) + Math.abs(d2)) / 3) * projTr.getArea();
        };
        var projVolumeFlat = (pl: THREE.Plane, tp: THREE.Vector3[], maxDist: number) => {
            var d0 = pl.distanceToPoint(tp[0]),
                d1 = pl.distanceToPoint(tp[1]),
                d2 = pl.distanceToPoint(tp[2]);

            projTr.a.copy(tp[0]);
            projTr.b.copy(tp[1]);
            projTr.c.copy(tp[2]);

            return Math.min(maxDist, (Math.abs(d0) + Math.abs(d1) + Math.abs(d2)) / 3) * projTr.getArea();
        };

        return (plane: THREE.Plane, trianglePoints: THREE.Vector3[], maxDistance: number, flat?: boolean) => {
            var da = plane.distanceToPoint(trianglePoints[0]),
                db = plane.distanceToPoint(trianglePoints[1]),
                dc = plane.distanceToPoint(trianglePoints[2]),
                pos0 = Math.sign(da),
                pos1 = Math.sign(db),
                pos2 = Math.sign(dc),
                hasZero = pos0 * pos1 * pos2 === 0,
                sumPos = pos0 + pos1 + pos2,
                lpIdx: number,
                getProjectedVolume = flat ? projVolumeFlat : projVolume;

            projTr.a.copy(plane.normal).multiplyScalar(-da).add(trianglePoints[0]);
            projTr.b.copy(plane.normal).multiplyScalar(-db).add(trianglePoints[1]);
            projTr.c.copy(plane.normal).multiplyScalar(-dc).add(trianglePoints[2]);

            if ((pos0 === pos1 && pos1 === pos2) || (hasZero && sumPos !== 0))
                return getProjectedVolume(plane, trianglePoints, maxDistance);//Math.min(maxDistance, (Math.abs(da) + Math.abs(db) + Math.abs(dc)) / 3) * getProjectedArea(plane, trianglePoints, da, db, dc);

            var cutThrough = sumPos === 0;

            if (cutThrough) {
                //cut through point index
                lpIdx = pos0 === 0 ? 0 : pos1 === 0 ? 1 : 2;
            } else {
                // Find lonely point index
                lpIdx = pos0 === pos1 ? 2 : pos0 === pos2 ? 1 : 0;
            }

            // Set previous point in relation to front face order
            var prevPoint = lpIdx - 1;
            if (prevPoint === -1) prevPoint = 2;
            // Set next point in relation to front face order
            var nextPoint = lpIdx + 1;
            if (nextPoint === 3) nextPoint = 0;

            if (cutThrough) {
                /*                  |
                 *                  |P0
                 *       |         /|\
                 *       |        / | \       
                 *       |       /  |  \      
                 *       |      /   |   \
                 *       |     /    |    \
                 *      y|    /     |     \
                 *       | P2/__ ___|_____ \P1
                 *       |          |I1
                 *       |          |
                 *       |___________________
                 */

                // Get the 1 intersection point
                var newPoint = plane.intersectLine(line.set(trianglePoints[prevPoint], trianglePoints[nextPoint]), i1);
                if (newPoint) {
                    return getProjectedVolume(plane, [trianglePoints[lpIdx], trianglePoints[nextPoint], newPoint], maxDistance) +
                        getProjectedVolume(plane, [trianglePoints[lpIdx], newPoint, trianglePoints[prevPoint]], maxDistance);
                    //return [
                    //    [trianglePoints[lpIdx], trianglePoints[nextPoint], newPoint],
                    //    [trianglePoints[lpIdx], newPoint, trianglePoints[prevPoint]]
                    //];
                }
            } else {
                /*
                 *       |      |  /|
                 *       |      | / |P1       
                 *       |      |/  |         
                 *       |    I1|   |
                 *       |     /|   |
                 *      y|    / |   |
                 *       | P0/__|___|P2
                 *       |      |I2
                 *       |      |
                 *       |___________________
                 */

                // Get the 2 intersection points
                var newPointPrev = plane.intersectLine(line.set(trianglePoints[lpIdx], trianglePoints[prevPoint]), i1);
                var newPointNext = plane.intersectLine(line.set(trianglePoints[lpIdx], trianglePoints[nextPoint]), i2);

                if (newPointPrev && newPointNext) {
                    return getProjectedVolume(plane, [trianglePoints[lpIdx], newPointNext, newPointPrev], maxDistance) +
                        getProjectedVolume(plane, [trianglePoints[prevPoint], newPointPrev, trianglePoints[nextPoint]], maxDistance) +
                        getProjectedVolume(plane, [trianglePoints[nextPoint], newPointPrev, newPointNext], maxDistance);
                    //return [
                    //    [trianglePoints[lpIdx], newPointNext, newPointPrev],
                    //    [trianglePoints[prevPoint], newPointPrev, trianglePoints[nextPoint]],
                    //    [trianglePoints[nextPoint], newPointPrev, newPointNext]
                    //];
                }
            }

            return getProjectedVolume(plane, trianglePoints, maxDistance);
        };
    })();

    //approximate using RANSAC
    export var approximatePlaneFromPositionArray = (() => {
        var v = new THREE.Vector3(),
            vA = new THREE.Vector3(),
            vB = new THREE.Vector3(),
            vC = new THREE.Vector3(),
            pl = new THREE.Plane(),
            plbest = new THREE.Plane();

        var calcScore = (pl: THREE.Plane, pos: ArrayLike<number>, maxDist: number) => {
            var score = 0;
            for (var j = 0, count = pos.length; j < count; j += 3) {
                score += Math.min(maxDist, Math.abs(pl.distanceToPoint(v.fromArray(pos, j))));
            }
            return score;
        };

        return (position: ArrayLike<number>, minZ?: number, maxDistance = 0.25) => {
            pl.constant = -minZ || 0;
            pl.normal.set(0, 0, 1);
            plbest.copy(pl);

            if (position.length < 9)
                return plbest;

            var bestScore = calcScore(pl, position, maxDistance),
                iterations = 100,
                posCount = position.length / 3,
                i = 0,
                a: number, b: number, c: number;

            if (posCount <= 7) {
                //brute force
                for (a = 0; a < posCount; a++) {
                    for (b = a + 1; b < posCount; b++) {
                        for (c = b + 1; c < posCount; c++) {
                            pl.setFromCoplanarPoints(vA.fromArray(position, a * 3), vB.fromArray(position, b * 3), vC.fromArray(position, c * 3));
                            if (pl.normal.z < 0) {
                                pl.normal.negate();
                                pl.constant = -pl.constant;
                            }
                            let s = calcScore(pl, position, maxDistance);
                            if (s < bestScore) {
                                bestScore = s;
                                plbest.copy(pl);
                            }
                        }
                    }
                }
            } else {
                while (i < iterations) {

                    a = Math.floor(Math.random() * posCount);
                    b = Math.floor(Math.random() * posCount);
                    c = Math.floor(Math.random() * posCount);

                    if (a === b || a === c || b === c)
                        continue;

                    i++;

                    pl.setFromCoplanarPoints(vA.fromArray(position, a * 3), vB.fromArray(position, b * 3), vC.fromArray(position, c * 3));
                    if (pl.normal.z < 0) {
                        pl.normal.negate();
                        pl.constant = -pl.constant;
                    }

                    let s = calcScore(pl, position, maxDistance);
                    if (s < bestScore) {
                        bestScore = s;
                        plbest.copy(pl);
                    }
                }
            }

            //console.log(`bestScore = ${bestScore}`);

            return plbest;

        };
    })();

    export function colorToGrayscale(color: THREE.Color) {
        var grayScale = Math.round((color.r * 0.3) + (color.g * 0.59) + (color.b * 0.11));
        return new THREE.Color(grayScale, grayScale, grayScale);
    }

    var _d1 = new THREE.Vector3(),
        _d2 = new THREE.Vector3(),
        _n1 = new THREE.Vector3(),
        _n2 = new THREE.Vector3(),
        _n3 = new THREE.Vector3(),
        _p1 = new THREE.Vector3(),
        _p2 = new THREE.Vector3(),
        _p3 = new THREE.Vector3();

    export function generateOffsetLinePoints(start: THREE.Vector3, end: THREE.Vector3, w: number, next?: THREE.Vector3) {
        var len1 = start.distanceTo(end),
            len2 = next ? end.distanceTo(next) : 0,
            dir1 = _d1.copy(end).sub(start).multiplyScalar(1 / len1),
            dir2 = len2 > 0 ? _d2.copy(next).sub(end).multiplyScalar(1 / len2) : dir1,
            n1 = _n1.set(-dir1.y, dir1.x, 0),
            n2 = _n2.set(-dir2.y, dir2.x, 0),
            n = _n3.copy(n1).add(n2),
            nl = n.length(),
            dirdot = dir1.dot(n2);

        if (len2 > 0 && nl > 0)
            n.multiplyScalar(1 / nl);
        else
            dirdot = 0;

        if (dirdot == 0) {
            //straight
            return [_p1.copy(n1).multiplyScalar(-w), _p2.copy(n1).multiplyScalar(w)];
        }

        var ang = n1.angleTo(n2),
            ta = Math.min(len1, len2, Math.tan(ang / 2) * w),
            tw = Math.sqrt(w * w + ta * ta);

        if (dirdot > 0) {
            //bend right
            return [_p1.copy(n).multiplyScalar(-tw), _p2.copy(n).multiplyScalar(w), _p3.copy(n1).multiplyScalar(w)];
        }

        //bend left
        return [_p1.copy(n1).multiplyScalar(-w), _p2.copy(n).multiplyScalar(-w), _p3.copy(n).multiplyScalar(tw)];
    }

    var _s = new THREE.Vector3(),
        _e = new THREE.Vector3(),
        _dir = new THREE.Vector3(),
        _n = new THREE.Vector3();

    export function generateOffsetLinePolygons(pts: { x: number, y: number, z: number }[], lineWidth: number, h = 50) {
        var len = pts.length - 1,
            result: THREE.Vector3[][] = [];

        for (var i = 0, j = 1; j <= len; ++i, ++j) {
            _s.set(pts[i].x, pts[i].y, 0)
            _e.set(pts[j].x, pts[j].y, 0)

            if (_s.distanceToSquared(_e) <= 0)
                continue;

            _dir.copy(_e).sub(_s).setZ(0).normalize();

            var ptsStart = spt.ThreeJs.utils.generateOffsetLinePoints(_e, _s, lineWidth, i > 0 ? _n.set(pts[i - 1].x, pts[i - 1].y, pts[i - 1].z) : null).map(p => _s.clone().add(p).setZ(h)),
                ptsEnd = spt.ThreeJs.utils.generateOffsetLinePoints(_s, _e, lineWidth, j < len ? _n.set(pts[j + 1].x, pts[j + 1].y, pts[j + 1].z) : null).map(p => _e.clone().add(p).setZ(h));

            var maxts = ArrayHelper.Max(ptsStart, (p) => _dir.dot(p)),
                minte = ArrayHelper.Min(ptsEnd, (p) => _dir.dot(p));

            if (maxts > minte) {
                //fix overlapping edges
                var t = (maxts + minte) * 0.5,
                    dt = new THREE.Vector3();
                ptsStart.forEach(p => {
                    var dot = _dir.dot(p);
                    if (dot > t)
                        p.add(dt.copy(_dir).multiplyScalar(t - dot));
                });

                ptsEnd.forEach(p => {
                    var dot = _dir.dot(p);
                    if (dot < t)
                        p.add(dt.copy(_dir).multiplyScalar(t - dot));
                });
            }

            var resPts = ptsStart.concat(ptsEnd),
                resultPoly: THREE.Vector3[] = [],
                l = resPts.length;

            for (var i1 = l - 1, i2 = 0; i2 < l; ++i2) {
                //fix duplicate points
                if (resPts[i1].distanceToSquared(resPts[i2]) <= 0)
                    continue;

                resultPoly.push(resPts[i2]);

                i1 = i2;
            }

            result.push(resultPoly);
        }

        return result;
    }

    export function setMatrix4(obj: THREE.Object3D, m: THREE.Matrix4) {
        m.decompose(obj.position, obj.quaternion, obj.scale);
        obj.updateMatrix();
    }
}

(() => {
    THREE.CircleLineBufferGeometry = spt.ThreeJs.utils.CircleLineBufferGeometry;
    THREE.CircleLineBufferGeometryCollection = spt.ThreeJs.utils.CircleLineBufferGeometryCollection;
    var texDispose = THREE.Texture.prototype.dispose;
    THREE.Texture.prototype.dispose = function () {
        texDispose.call(this);
        this._isDisposed = true;
    };
    var cubetexDispose = THREE.CubeTexture.prototype.dispose;
    THREE.CubeTexture.prototype.dispose = function () {
        cubetexDispose.call(this);
        this._isDisposed = true;
    };
})();

if (THREE) {
    //check and correct crossorigin policies for safari
    ["Loader", "ImageLoader", "TextureLoader", "CubeTextureLoader", "ObjectLoader"].forEach(l => {
        if (THREE[l] && THREE[l].prototype && THREE[l].prototype.crossOrigin) {
            THREE[l].prototype.crossOrigin = null;
        }
    });

    if (THREE["Octree"]) {
        THREE["Octree"].prototype.rebuild = function () {

            var i,
                l,
                node,
                objectData,
                indexOctant,
                indexOctantLast,
                objectsUpdate = [];

            // check all object data for changes in position
            // assumes all object matrices are up to date

            for (i = 0, l = this.objectsData.length; i < l; i++) {

                objectData = this.objectsData[i];

                node = objectData.node;

                // update object

                objectData.update();

                // if position has changed since last organization of object in tree

                if (node instanceof spt.ThreeJs.utils.OctreeNode && !objectData.positionLast.equals(objectData.position)) {

                    // get octant index of object within current node

                    indexOctantLast = objectData.indexOctant;

                    indexOctant = (<any>node).getOctantIndex(objectData);

                    // if object octant index has changed

                    if (indexOctant !== indexOctantLast) {

                        // add to update list

                        objectsUpdate.push(objectData);

                    }

                }

            }

            // update changed objects

            for (i = 0, l = objectsUpdate.length; i < l; i++) {

                objectData = objectsUpdate[i];

                // remove object from current node

                //objectData.node.removeObject( objectData );
                this.remove(objectData);

                // add object to tree root

                //this.root.addObject( objectData );
                this.add(objectData);

            }

        };
    }
}

//module THREE {
//    export var CircleLineBufferGeometry: typeof spt.ThreeJs.utils.CircleLineBufferGeometry = spt.ThreeJs.utils.CircleLineBufferGeometry;
//    export var CircleLineBufferGeometryCollection: typeof spt.ThreeJs.utils.CircleLineBufferGeometryCollection = spt.ThreeJs.utils.CircleLineBufferGeometryCollection;
//    var texDispose = THREE.Texture.prototype.dispose;
//    THREE.Texture.prototype.dispose = function () {
//        texDispose.call(this);
//        this._isDisposed = true;
//    };
//    var cubetexDispose = THREE.CubeTexture.prototype.dispose;
//    THREE.CubeTexture.prototype.dispose = function () {
//        cubetexDispose.call(this);
//        this._isDisposed = true;
//    };
//}