interface KnockoutExtenders {
    IntMinMax: (target: any, options: any) => any;
    UIntMinMax: (target: any, options: any) => any;
    ValueManipulate: (target: any, options: any) => any;
    UfloatPrecision: (target: any, options: any) => any;
    floatPrecision: (target: any, options: any) => any;
    trimString: (target: any) => any;
    onUpdate: (target: any, func: (val: any) => void) => any;
}

ko.extenders.IntMinMax = function (target, options) {
    //create a writeable computed observable to intercept writes to our observable
    var result = ko.computed({
        read: target,
        write: function (newValue) {
            var current = target();
            if (newValue && typeof newValue == "string")
                newValue = newValue.replace(",", ".");
            var valueToWrite = !isNumber(newValue) ? 0 : parseInt(newValue as any);
            if (typeof options.min !== "undefined" && valueToWrite < options.min) {
                valueToWrite = options.min;
            }

            if (typeof options.max !== "undefined" && valueToWrite > options.max) {
                valueToWrite = options.max;
            }

            //only write if it changed
            if (valueToWrite !== current) {
                target(valueToWrite);
            } else {
                if (newValue !== current) {
                    target.notifySubscribers(valueToWrite);
                }
            }
        }
    });

    result(target());

    //return the new computed observable
    return result;
};

ko.extenders.UIntMinMax = function (target, options) {
    //create a writeable computed observable to intercept writes to our observable
    var result = ko.computed({
        read: target,  //always return the original observables value
        write: function (newValue) {
            var current = target();
            var min = null;
            var max = null;
            if (UseImperialSystem) {
                //mm to inches
                if (typeof options.min !== "undefined")
                    min = options.min * 0.0393700787;
                if (typeof options.max !== "undefined")
                    max = options.max * 0.0393700787;
                newValue = ImperialLengthToValue(newValue as any, options.isFeet === true);
            } else {
                if (newValue && typeof newValue == "string")
                    newValue = newValue.replace(",", ".");
                if (typeof options.min !== "undefined")
                    min = options.min;
                if (typeof options.max !== "undefined")
                    max = options.max;
            }
            var valueToWrite = !isNumber(newValue) ? 0 : (UseImperialSystem ? parseFloat(newValue as any) : parseInt(newValue as any));
            if (min !== null && valueToWrite < min) {
                valueToWrite = min;
                if (UseImperialSystem)
                    valueToWrite = Math.round(valueToWrite * 1000) / 1000;
            }

            if (max !== null && valueToWrite > max) {
                valueToWrite = max;
                if (UseImperialSystem)
                    valueToWrite = Math.round(valueToWrite * 1000) / 1000;
            }

            //only write if it changed
            if (valueToWrite !== current) {
                target(valueToWrite);
            } else {
                if (newValue !== current) {
                    target.notifySubscribers(valueToWrite);
                }
            }
        }
    });

    result(target());

    //return the new computed observable
    return result;
};

ko.extenders.ValueManipulate = function (target, options) {
    var readFunc = typeof options.read === "function" ? function () {
        return options.read(target(), options);
    } : function () {
        return target();
    };

    var writeFunc = typeof options.write === "function" ? function (newValue) {
        var current = target();
        var valueToWrite = options.write(newValue, current, options);
        if (valueToWrite !== current) {
            target(valueToWrite);
        } else {
            if (newValue !== current) {
                target.notifySubscribers(valueToWrite);
            }
        }
    } : function (newValue) {
        var current = target();
        if (newValue !== current) {
            target(newValue);
        }
    };

    var result = ko.computed({
        read: readFunc,
        write: writeFunc
    });

    //initialize with current value
    result(target());

    //return the new computed observable
    return result;
};

ko.extenders.UfloatPrecision = function (target, options) {
    //create a writeable computed observable to intercept writes to our observable
    var result = ko.computed({
        read: options.replaceSeperator ? function () {
            if ((<any>$).global.culture)
                return ('' + target()).replace(".", (<any>$).global.culture.numberFormat["."]);
            return ('' + target());
        } : target,
        write: function (newValue) {
            var current = target();
            var roundingMultiplier = Math.pow(10, options.precision);
            var min = null;
            var max = null;
            newValue = ('' + newValue).replace(",", ".");
            if (UseImperialSystem) {
                //mm to inches
                if (typeof options.min !== "undefined")
                    min = options.min * 0.0393700787;
                if (typeof options.max !== "undefined")
                    max = options.max * 0.0393700787;
                newValue = ImperialLengthToValue(newValue as any, options.isFeet === true);
            } else {
                if (typeof options.min !== "undefined")
                    min = options.min;
                if (typeof options.max !== "undefined")
                    max = options.max;
            }
            var newValueAsNum = isNaN(newValue as any) ? 0 : parseFloat(+newValue as any);
            if (min !== null && newValueAsNum < min) {
                newValueAsNum = min;
                if (UseImperialSystem)
                    newValueAsNum = Math.round(newValueAsNum * 1000) / 1000;
            }

            if (max !== null && newValueAsNum > max) {
                newValueAsNum = max;
                if (UseImperialSystem)
                    newValueAsNum = Math.round(newValueAsNum * 1000) / 1000;
            }
            var valueToWrite = Math.round(newValueAsNum * roundingMultiplier) / roundingMultiplier;
            //if ((<any>$).global.culture.numberFormat["."] == ',')
            //    valueToWrite = ('' + valueToWrite).replace(".", ",");
            //only write if it changed
            if (valueToWrite !== current) {
                target(valueToWrite);
            } else {
                //if the rounded value is the same, but a different value was written, force a notification for the current field
                if (newValue !== current) {
                    target.notifySubscribers(valueToWrite);
                }
            }
        }
    });

    //initialize with current value to make sure it is rounded appropriately
    result(target());

    //return the new computed observable
    return result;
};

ko.extenders.floatPrecision = function (target, options) {
    //create a writeable computed observable to intercept writes to our observable
    var result = ko.computed({
        read: options.replaceSeperator ? function () {
            if ((<any>$).global.culture)
                return ('' + target()).replace(".", (<any>$).global.culture.numberFormat["."]);
            return ('' + target());
        } : target,
        write: function (newValue) {
            var current = target();
            var roundingMultiplier = Math.pow(10, options.precision);
            newValue = ('' + newValue).replace(",", ".");
            var newValueAsNum = isNaN(newValue as any) ? 0 : parseFloat(+newValue as any);
            if (typeof options.min !== "undefined" && newValueAsNum < options.min) {
                newValueAsNum = options.min;
            }

            if (typeof options.max !== "undefined" && newValueAsNum > options.max) {
                newValueAsNum = options.max;
            }
            var valueToWrite = Math.round(newValueAsNum * roundingMultiplier) / roundingMultiplier;
            //if ((<any>$).global.culture.numberFormat["."] == ',')
            //    valueToWrite = ('' + valueToWrite).replace(".", ",");

            //only write if it changed
            if (valueToWrite !== current) {
                target(valueToWrite);
            } else {
                //if the rounded value is the same, but a different value was written, force a notification for the current field
                if (newValue !== current) {
                    target.notifySubscribers(valueToWrite);
                }
            }
        }
    });

    //initialize with current value to make sure it is rounded appropriately
    result(target());

    //return the new computed observable
    return result;
};

ko.extenders.trimString = function (target) {
    var result = ko.computed({
        read: target,  //always return the original observables value
        write: function (newValue) {
            var current = target();
            var valueToWrite = newValue as any;
            if (typeof newValue == "string")
                valueToWrite = valueToWrite.replace(/^\s+|\s+$/g, '');
            //only write if it changed
            if (valueToWrite !== current) {
                target(valueToWrite);
            } else {
                if (newValue !== current) {
                    target.notifySubscribers(valueToWrite);
                }
            }
        }
    });

    //initialize with current value to make sure it is rounded appropriately
    result(target());

    //return the new computed observable
    return result;
};

ko.extenders.onUpdate = function (target, func) {
    if (typeof func === 'function') {
        target.subscribe(function (newValue) {
            func(newValue as any);
        });
    }
    return target;
};

ko.bindingHandlers.blurOnEnter = {
    init: function (element, valueAccessor) {
        $(element).bind('keyup', function (e) {
            if (e.keyCode === 13)
                $(this).blur();
        });
    }
};

ko.bindingHandlers.NotifyOnEnter = {
    init: function (element, valueAccessor) {
        $(element).bind('keyup', function (e) {
            if (e.keyCode === 13) {
                var observable = valueAccessor();
                if (ko.isObservable(observable)) {
                    var v1 = observable();
                    $(this).blur();
                    var v2 = observable();
                    if (v1 === v2)
                        observable(v1);
                } else
                    $(this).blur();
            }
        });
    }
};

ko.bindingHandlers.actionOnEnter = {
    init: function (element, valueAccessor) {
        var fn = ko.utils.unwrapObservable(valueAccessor());
        $(element).bind('keyup', function (e) {
            if (fn && e.keyCode === 13)
                fn(this);
        });
    }
};

ko.bindingHandlers.checkBoxGetterSetter =
{
    init: function (element, valueAccessor) {
        var options = ko.unwrap(valueAccessor());
        $(element).prop('checked', !!options.get()).change(function () {
            options.set(!!this.checked);
        });
    },
    update: function (element, valueAccessor) {
        var options = ko.unwrap(valueAccessor());
        $(element).prop('checked', !!options.get());
    }
};

var jqColorPickerActionCallbacks = [];

var jqColorPickerActionCallback = function (e, action) {
    var actions = jqColorPickerActionCallbacks.slice();
    for (var i = 0, j = actions.length; i < j; i++) {
        actions[i](e, action);
    }
};

function jqColorPickerRgbToHex(rgb) {

    if (typeof rgb !== "string" || !rgb.length)
        return null;
    rgb = rgb.toLowerCase();
    if (/^#[0-9a-f]{6}$/i.test(rgb))
        return rgb;
    else if (rgb.indexOf("rgb") !== -1) {
        rgb = rgb.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+))?\)$/);
        return "#" + ("0" + parseInt(rgb[1]).toString(16)).slice(-2) + ("0" + parseInt(rgb[2]).toString(16)).slice(-2) + ("0" + parseInt(rgb[3]).toString(16)).slice(-2);
    }

    return null;
}

ko.bindingHandlers.jqColorPicker = {
    init: function (element, valueAccessor, allBindingsAccessor) {
        var options = allBindingsAccessor().colorPickerOptions || {};
        var defaultMemColors = "'rgba(0,0,0,1)','rgba(20,20,20,1)','rgba(64,64,64,1)','rgba(117,117,117,1)','rgba(156,156,156,1)','rgba(181,181,181,1)','rgba(229,229,229,1)','rgba(11,27,60,1)'";
        // set default value
        var value = ko.utils.unwrapObservable(valueAccessor());
        if (value && value.length && value[0] === "#")
            value = value.substr(1);
        if (value)
            $(element).val(value.toLowerCase());

        var actionCallback = function (e, action) {
            if (element.style && element.style.backgroundColor) {
                var col = jqColorPickerRgbToHex(element.style.backgroundColor);
                if (col !== null) {
                    var observable = valueAccessor();
                    if (col !== observable())
                        observable(col);
                }
            }
        };

        jqColorPickerActionCallbacks.push(actionCallback);

        //"000000", "141414", "404040", "757575", "9C9C9C", "B5B5B5", "E5E5E5", "0B1B3C"
        // colorpicker
        ($(element) as any).colorPicker({
            noAlpha: true,
            memoryColors: options.memoryColors === undefined ? defaultMemColors : options.memoryColors,//"'rgba(0,0,0,1)','rgba(20,20,20,1)','rgba(64,64,64,1)','rgba(117,117,117,1)','rgba(156,156,156,1)','rgba(181,181,181,1)','rgba(229,229,229,1)','rgba(11,27,60,1)'",
            appendTo: options.appendTo !== undefined ? options.appendTo : document.body,
            readOnly: true,
            noResize: true,
            size: options.size === undefined || options.size > 3 ? 2 : options.size, //2,
            init: function (elm, colors) {
                elm.style.backgroundColor = '#' + elm.value;
                //elm.style.color = colors.rgbaMixCustom.luminance > 0.22 ? '#222' : '#ddd';
            },
            actionCallback: jqColorPickerActionCallback
        });

        //handle the field changing
        ko.utils.registerEventHandler(element, "change", function () {
            var col = jqColorPickerRgbToHex(element.style.backgroundColor);
            if (col !== null && col.length) {
                var observable = valueAccessor();
                col = col.toLowerCase();
                if (col.charAt(0) !== '#')
                    col = '#' + col;
                var col2 = observable().toLowerCase();
                if (col2.charAt(0) !== '#')
                    col2 = '#' + col2;
                if (col !== col2) {
                    element.style.backgroundColor = col2;
                }
            }
        });

        //handle disposal (if KO removes by the template binding)
        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
            var index = jqColorPickerActionCallbacks.indexOf(actionCallback);
            if (index > -1) {
                jqColorPickerActionCallbacks.splice(index, 1);
            }
            try {
                ($(element) as any).colorPicker("destroy");
                if (typeof ($(element) as any).colorPicker.destroy === "function")
                    ($(element) as any).colorPicker.destroy();
            } catch (e) { }
        });
    },
    update: function (element, valueAccessor) {
        var value = ko.utils.unwrapObservable(valueAccessor());
        if (value && value.length && value[0] === "#")
            value = value.substr(1);
        if (value)
            value = value.toLowerCase();
        if ($(element).val() !== value) {
            $(element).val(value);
            $(element).change();
        }
    }
};

ko.bindingHandlers.jqSpinner = {
    init: function (element, valueAccessor, allBindingsAccessor) {
        //initialize datepicker with some optional options
        var options = allBindingsAccessor().spinnerOptions || {};
        ($(element) as any).spinner(options);

        //handle the field changing
        ko.utils.registerEventHandler(element, "spinchange", function () {
            var observable = valueAccessor();
            observable(($(element) as any).spinner("value"));
        });

        //handle disposal (if KO removes by the template binding)
        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
            ($(element) as any).spinner("destroy");
        });

    },
    update: function (element, valueAccessor) {
        var value = ko.utils.unwrapObservable(valueAccessor()),
            current = ($(element) as any).spinner("value");

        if (value !== current) {
            ($(element) as any).spinner("value", value);
        }
    }
};

//dynamic jquery ui tabs with knockout
ko.bindingHandlers.tabify = {
    init: function (element, valueAccessor) {
        var options = ko.unwrap(valueAccessor());
        var $target = (options.useThis ? $(element) : $(element).parent()) as any;
        var tabOptions = options.tabOptions || { active: 0 };
        if ($target.data('hasTabs') !== true) {
            $target.data('hasTabs', true);
            setTimeout(function () {
                if ($target.tabs) {
                    var $uiTabs = $target.tabs(tabOptions);
                    $uiTabs.find('.ui-widget-header').removeClass('ui-widget-header');
                }
            }, 0);
        }
        else if ($target.data('recreateTabs') !== true) {
            $target.data('recreateTabs', true);
            setTimeout(function () {
                $target.data('recreateTabs', false);
                if ($target.tabs) {
                    $target.tabs('destroy');
                    var $uiTabs = $target.tabs(tabOptions);
                    $uiTabs.find('ul.ui-widget-header').removeClass('ui-widget-header');
                }
            }, 0);
        }
    }
};

ko.bindingHandlers.qToolTip = {
    update: function (element, valueAccessor) {
        var options = valueAccessor(),
            $elem = $(element) as any;
        var txt = options.text ? ko.unwrap(options.text) : null;
        var title = options.title ? ko.unwrap(options.title) : null;

        var standardPosition = { corner: { target: 'rightMiddle', tooltip: 'bottomLeft' } };
        var corner = options.position != null ? options.position : standardPosition;
        if ($elem.data('hasQTip') !== true) {
            $elem.data('hasQTip', true);
            var qTipOption = {
                // position: { corner: { target: 'rightMiddle', tooltip: 'bottomLeft' } },
                position: corner,
                style: {
                    name: 'light',
                    tip: true,
                    border: { width: 2, radius: 1, color: '#' + ThemeManager.sptThemeMainColor },
                    classes: { tooltip: 'jqtip_tooltip', content: 'jqtip_content' },
                    padding: 10,
                },
                show: { solo: true, delay: 500 },
                hide: { when: { event: 'mouseout' }, delay: 100 }
            } as any;
            if (txt && txt.length) {
                qTipOption.content = { text: "" + txt };
                if (title && title.length)
                    qTipOption.content.title = { text: "" + title };
            }
            if ($elem.qtip)
                $elem.qtip(qTipOption);
        } else if (txt && txt.length) {
            if ($elem.qtip) {
                $elem.qtip('api').updateContent("" + txt);
                if (title && title.length)
                    $elem.qtip('api').updateTitle("" + title);
            }
        } else if (title && title.length) {
            if ($elem.qtip)
                $elem.qtip('api').updateTitle("" + title);
        }
    }
};

ko.bindingHandlers.selectable = { //Bindings: selectable, selectableValue, selectableActiveClass, selectableDefaultClass
    init: function (element, valueAccessor, allBindingsAccessor) {
        var value = valueAccessor();
        //var valueUnwrapped = ko.unwrap(value);
        var allBindings = allBindingsAccessor();
        var $elem = $(element);
        var ownValue = allBindings.selectableValue === 0 ? 0 : (allBindings.selectableValue || ($elem.val() || null));
        if (typeof value === 'function') {
            $elem.click(function () {
                if (value() != ownValue) {
                    value(ownValue);
                }
            });
        }
    },
    update: function (element, valueAccessor, allBindingsAccessor) {
        var value = valueAccessor();
        var valueUnwrapped = ko.unwrap(value);
        var allBindings = allBindingsAccessor();
        var $elem = $(element);
        var ownValue = allBindings.selectableValue === 0 ? 0 : (allBindings.selectableValue || ($elem.val() || null));
        var selectedClass = allBindings.selectableActiveClass || null;
        var unselectedClass = allBindings.selectableDefaultClass || null;
        if (selectedClass && selectedClass != unselectedClass) {
            if (ownValue === valueUnwrapped) {
                if (unselectedClass)
                    $elem.removeClass(unselectedClass);
                $elem.addClass(selectedClass);
            } else {
                if (unselectedClass)
                    $elem.addClass(unselectedClass);
                $elem.removeClass(selectedClass);
            }
        }
    }
};

ko.bindingHandlers.inputReadonly = {
    init: function (element, valueAccessor) {
        var value = valueAccessor();
        var valueUnwrapped = ko.unwrap(value);
        if (valueUnwrapped) {
            $(element).prop('disabled', true);
        } else {
            $(element).prop('disabled', false);
        }
    },
    update: function (element, valueAccessor) {
        var value = valueAccessor();
        var valueUnwrapped = ko.unwrap(value);
        if (valueUnwrapped) {
            $(element).prop('disabled', true);
        } else {
            $(element).prop('disabled', false);
        }
    }
};

ko.bindingHandlers.fadeVisible = {
    init: function (element, valueAccessor) {
        // Initially set the element to be instantly visible/hidden depending on the value
        var value = valueAccessor();
        $(element).toggle(ko.unwrap(value)); // Use "unwrapObservable" so we can handle values that may or may not be observable
    },
    update: function (element, valueAccessor) {
        // Whenever the value subsequently changes, slowly fade the element in or out
        var value = valueAccessor();
        ko.unwrap(value) ? $(element).fadeIn('fast') : $(element).fadeOut('fast');
    }
};

ko.bindingHandlers.slideVisible = {
    init: function (element, valueAccessor) {
        // Initially set the element to be instantly visible/hidden depending on the value
        var value = valueAccessor();
        $(element).toggle(ko.unwrap(value)); // Use "unwrapObservable" so we can handle values that may or may not be observable
    },
    update: function (element, valueAccessor, allBindings) {
        // First get the latest data that we're bound to
        var value = valueAccessor();

        // Next, whether or not the supplied model property is observable, get its current value
        var valueUnwrapped = ko.unwrap(value);

        // Grab some more data from another binding property
        var duration = allBindings.get('slideDuration') || 200; // 200 ms is default duration unless otherwise specified

        var direction = allBindings.get('slideDirection') || 'up';

        setTimeout(function () {
            if (element) {
                if (valueUnwrapped)
                    DManager.showWithEffect($(element), 'slide', { direction: direction }, duration); // Make the element visible
                else
                    DManager.hideWithEffect($(element), 'slide', { direction: direction }, duration); // Make the element invisible
            }
        }, 0);

    }
};

ko.bindingHandlers.valueAdjusted = {
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        var val = ko.unwrap(valueAccessor());

        var opts = allBindings.get('valueAdjustedOptions') || null;

        val = spt.Utils.GetFloatFromInputValue("" + val, opts);

        if ((<any>$).global.culture)
            val = ('' + val).replace(".", (<any>$).global.culture.numberFormat["."]);

        $(element).val(val);

        var $elem = $(element).on('keyup',
            function (e) {
                if (e.keyCode === 13) //esc
                    $(this).blur();
            });

        var onChanged = allBindings.get('valueAdjustedChanged') || null;
        if (typeof onChanged === "function") {
            $elem.on("change",
                function () {
                    onChanged($(element).val());
                });
        }

        var onInput = allBindings.get('valueAdjustedOnInput') || null;
        if (typeof onInput === "function") {
            $elem.on("input",
                function () {
                    onInput($(element).val());
                });
        }
    },
    update: function (element, valueAccessor, allBindings) {
        var val = ko.unwrap(valueAccessor());

        var opts = allBindings.get('valueAdjustedOptions') || null;

        val = spt.Utils.GetFloatFromInputValue("" + val, opts);

        if ((<any>$).global.culture)
            val = ('' + val).replace(".", (<any>$).global.culture.numberFormat["."]);

        $(element).val(val);
    }
};

ko.bindingHandlers.stopBinding = {
    init: function () {
        return { controlsDescendantBindings: true };
    }
};

ko.bindingHandlers.tooltip = {
    defaultTooltipOptions: {
        style: {
            name: 'light', tip: true, border: { width: 2, radius: 1, color: '#' + ThemeManager.sptThemeMainColor },
            classes: { tooltip: 'jqtip_tooltip', content: 'jqtip_content' },
            padding: 10
        },
        show: { solo: true, delay: 500 },
        hide: { when: { event: 'mouseout' }, delay: 100 },
        position: { corner: { target: 'topMiddle', tooltip: 'topMiddle' } }
    },
    getBehaviour: function (bindings) {
        var behaviour = {} as any;
        if (typeof bindings === "string")
            behaviour.content = bindings;
        else if (typeof bindings === "object")
            behaviour = $.extend(behaviour, bindings);
        return $.extend({}, ko.bindingHandlers.tooltip.defaultTooltipOptions, behaviour);
    },
    init: function (element, valueAccessor, allBindingsAccessor, viewModel) {
        var allBindings = allBindingsAccessor();
        var tooltipBindings = allBindings.tooltip;
        var behaviour = ko.bindingHandlers.tooltip.getBehaviour(tooltipBindings);
        var $elem = $(element) as any;
        if($elem.qtip)
            $elem.qtip(behaviour);
    }
};

ko.virtualElements.allowedBindings.stopBinding = true;

var koDeferUpdates = ko.options.deferUpdates = true;

//ko.observable -> ko.notDefferedObservable
//ko.observableArray -> ko.notDefferedObservableArray
//ko.computed -> ko.notDefferedComputed
//ko.pureComputed -> ko.notDefferedPureComputed
['observable', 'observableArray', 'pureComputed', 'computed'].forEach(function (k) {
    var nk = 'notDeffered' + k.charAt(0).toUpperCase() + k.slice(1);
    ko[nk] = function () {
        ko.options.deferUpdates = false;
        var o = ko[k].apply(this, arguments);
        ko.options.deferUpdates = koDeferUpdates;
        return o;
    };
});

