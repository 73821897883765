module spt.ThreeJs.utils {
    export class OBJExporter {
        constructor(numberOfDigits = 6, objectsAsObjObjects = true, objectsAsObjGroups = false, materialGroups = false, exportLines = true) {
            this.numberOfDigits = numberOfDigits;
            this.materialGroups = materialGroups;
            this.objectsAsObjObjects = objectsAsObjObjects;
            this.objectsAsObjGroups = objectsAsObjGroups;
            this.exportLines = exportLines;
        }

        numberOfDigits: number;
        materialGroups: boolean;
        objectsAsObjObjects: boolean;
        objectsAsObjGroups: boolean;
        exportLines: boolean;

        outputLines: string[] = [];
        indexCount: number = 0;
        vertexCount: number = 0;
        normalCount: number = 0;
        uvCount: number = 0;

        vertex = new THREE.Vector3();
        normal = new THREE.Vector3();
        uv = new THREE.Vector2();

        normalMatrixWorld = new THREE.Matrix3();

        parse(object: THREE.Object3D) {
            object.traverse((child) => {
                if ((child as THREE.Points).isPoints) {
                    //ignore
                }
                else if ((child as THREE.Line).isLine) {
                    this.parseLine(child as THREE.Line);
                }
                else if ((child as THREE.Mesh).isMesh) {
                    this.parseMesh(child as THREE.Mesh);
                }
            });

            return this;
        }

        private parseMesh(mesh: THREE.Mesh) {

            var nbVertex = 0,
                nbNormals = 0,
                nbVertexUvs = 0,
                vertex = this.vertex,
                normal = this.normal,
                uv = this.uv,
                indexVertex = this.vertexCount,
                indexVertexUvs = this.uvCount,
                indexNormals = this.normalCount,
                outputLines = this.outputLines,
                materials = mesh.material,
                material: THREE.Material,
                materialName: string,
                i: number, j: number, m: number, l: number, il: number, jl: number, a: number, b: number, c: number,
                geometry = mesh.geometry as THREE.BufferGeometry,
                normalMatrixWorld = this.normalMatrixWorld,
                matrixWorld = mesh.matrixWorld,
                drawMode = THREE.TrianglesDrawMode, //mesh.drawMode,
                name = mesh.name || `Mesh.${("0000" + mesh.id).slice(-4)}`,
                numberOfDigits = this.numberOfDigits,
                formatNumber = (num: number) => {
                    return +num.toFixed(numberOfDigits);
                };

            if (((<any>geometry) as THREE.Geometry).isGeometry)
                geometry = new THREE.BufferGeometry().setFromObject(mesh);

            if (geometry.isBufferGeometry) {

                // shortcuts
                var groups = geometry.groups,
                    drawRange = geometry.drawRange,
                    group: { start: number, count: number, materialIndex?: number },
                    start: number, end: number,
                    vertices = geometry.getAttribute('position') as THREE.BufferAttribute,
                    normals = geometry.getAttribute('normal') as THREE.BufferAttribute,
                    uvs = geometry.getAttribute('uv') as THREE.BufferAttribute,
                    indices = geometry.getIndex();

                // name of the mesh object
                if (this.objectsAsObjObjects)
                    outputLines.push(`\no ${name}\n`);
                else if (this.objectsAsObjGroups)
                    outputLines.push(`\ng ${name}\n`);
                else
                    outputLines.push('\n');

                // vertices

                if (vertices !== undefined) {

                    for (i = 0, l = vertices.count; i < l; i++ , nbVertex++) {

                        vertex.fromBufferAttribute(vertices, i);

                        // transfrom the vertex to world space
                        vertex.applyMatrix4(matrixWorld);

                        // transform the vertex to export format
                        outputLines.push(`v ${formatNumber(vertex.x)} ${formatNumber(vertex.y)} ${formatNumber(vertex.z)}\n`);

                    }

                }

                // uvs

                if (uvs !== undefined) {

                    for (i = 0, l = uvs.count; i < l; i++ , nbVertexUvs++) {

                        uv.fromBufferAttribute(uvs, i);

                        // transform the uv to export format
                        outputLines.push(`vt ${formatNumber(uv.x)} ${formatNumber(uv.y)}\n`);

                    }

                }

                // normals

                if (normals !== undefined) {

                    normalMatrixWorld.getNormalMatrix(matrixWorld);

                    for (i = 0, l = normals.count; i < l; i++ , nbNormals++) {

                        normal.fromBufferAttribute(normals, i);

                        // transfrom the normal to world space
                        normal.applyMatrix3(normalMatrixWorld);

                        // transform the normal to export format
                        outputLines.push(`vn ${formatNumber(normal.x)} ${formatNumber(normal.y)} ${formatNumber(normal.z)}\n`);

                    }

                }

                // faces

                var writeSingleFace = (i1: number, i2: number, i3: number) => {
                    outputLines.push('f '
                        + (indexVertex + i1) + (normals || uvs ? '/' + (uvs ? (indexVertexUvs + i1) : '') + (normals ? '/' + (indexNormals + i1) : '') : '') + ' '
                        + (indexVertex + i2) + (normals || uvs ? '/' + (uvs ? (indexVertexUvs + i2) : '') + (normals ? '/' + (indexNormals + i2) : '') : '') + ' '
                        + (indexVertex + i3) + (normals || uvs ? '/' + (uvs ? (indexVertexUvs + i3) : '') + (normals ? '/' + (indexNormals + i3) : '') : '')
                        + "\n");
                };

                var writeFaces = (from: number, to: number) => {
                    if (indices !== null) {
                        switch (drawMode) {
                            case THREE.TriangleStripDrawMode:
                                for (j = from, jl = to - 2; j < jl; j++) {
                                    if (j & 1) {
                                        a = 1 + indices.getX(j);
                                        b = 1 + indices.getX(j + 2);
                                        c = 1 + indices.getX(j + 1);
                                    } else {
                                        a = 1 + indices.getX(j);
                                        b = 1 + indices.getX(j + 1);
                                        c = 1 + indices.getX(j + 2);
                                    }

                                    writeSingleFace(a, b, c);

                                }
                                break;
                            case THREE.TriangleFanDrawMode:
                                for (j = from, jl = to - 2; j < jl; j++) {

                                    a = 1 + indices.getX(from);
                                    b = 1 + indices.getX(j + 1);
                                    c = 1 + indices.getX(j + 2);

                                    writeSingleFace(a, b, c);

                                }
                                break;
                            default:
                                for (j = from, jl = to; j < jl; j += 3) {

                                    a = 1 + indices.getX(j);
                                    b = 1 + indices.getX(j + 1);
                                    c = 1 + indices.getX(j + 2);

                                    writeSingleFace(a, b, c);

                                }
                                break;
                        }
                    } else {
                        switch (drawMode) {
                            case THREE.TriangleStripDrawMode:
                                for (j = from, jl = to - 2; j < jl; j++) {
                                    if (j & 1) {
                                        a = j + 1;
                                        b = j + 3;
                                        c = j + 2;
                                    } else {
                                        a = j + 1;
                                        b = j + 2;
                                        c = j + 3;
                                    }

                                    writeSingleFace(a, b, c);

                                }
                                break;
                            case THREE.TriangleFanDrawMode:
                                for (j = from, jl = to - 2; j < jl; j++) {

                                    a = from + 1;
                                    b = j + 2;
                                    c = j + 3;

                                    writeSingleFace(a, b, c);

                                }
                                break;
                            default:
                                for (j = from, jl = to; j < jl; j += 3) {

                                    a = j + 1;
                                    b = j + 2;
                                    c = j + 3;

                                    writeSingleFace(a, b, c);

                                }
                                break;
                        }
                    }
                };

                var writeMaterial = (mat: THREE.Material) => {
                    if (mat) {
                        // name of the mesh material
                        materialName = mat.name || `Material.${("0000" + mat.id).slice(-4)}`;

                        if (this.materialGroups) {
                            outputLines.push(`g ${name}${geometry.name ? '_' + geometry.name : ''}.${("0000" + i).slice(-4)}_${materialName}\n`);
                        }

                        outputLines.push('usemtl ' + materialName + '\n');

                    }
                };

                if (indices !== null && mesh.userData && mesh.userData.octantsToExclude && mesh.userData.octantsToExclude.length) {
                    //GM Mesh

                    var octantsToExclude = mesh.userData.octantsToExclude as number[];
                    
                    material = materials as THREE.Material;

                    start = Math.max(0, drawRange.start);
                    end = Math.min(indices.count, (drawRange.start + drawRange.count));

                    writeMaterial(material);

                    for (j = start, jl = end - 2; j < jl; j++) {
                        if (j & 1) {
                            a = indices.getX(j);
                            b = indices.getX(j + 2);
                            c = indices.getX(j + 1);
                        } else {
                            a = indices.getX(j);
                            b = indices.getX(j + 1);
                            c = indices.getX(j + 2);
                        }

                        if (a === b || a === c || b === c)
                            continue;

                        var w = vertices.getW( a );

                        if (isNaN(w) || octantsToExclude.indexOf(w) >= 0)
                            continue;

                        writeSingleFace(1 + a, 1 + b, 1 + c);

                    }

                } else {
                    if (Array.isArray(materials)) {
                        for (i = 0, il = groups.length; i < il; i++) {
                            group = groups[i];
                            material = materials[group.materialIndex];

                            start = Math.max(0, group.start, drawRange.start);
                            end = Math.min(indices !== null ? indices.count : vertices.count, (group.start + group.count), (drawRange.start + drawRange.count));

                            writeMaterial(material);

                            writeFaces(start, end);
                        }
                    }
                    else {
                        material = materials;

                        start = Math.max(0, drawRange.start);
                        end = Math.min(indices !== null ? indices.count : vertices.count, (drawRange.start + drawRange.count));

                        writeMaterial(material);

                        writeFaces(start, end);
                    }
                }

            } else {

                console.warn('THREE.OBJExporter.parseMesh(): geometry type unsupported', geometry);

            }

            // update index
            this.vertexCount += nbVertex;
            this.uvCount += nbVertexUvs;
            this.normalCount += nbNormals;

            return this;
        }

        private parseLine(line: THREE.Line | THREE.LineSegments) {
            if (!this.exportLines)
                return this;

            var nbVertex = 0,
                outputLines = this.outputLines,
                geometry = line.geometry,
                type = line.type as string,
                vertex = this.vertex,
                indexVertex = this.vertexCount,
                i: number, j: number, k: number, l: number, o: string,
                numberOfDigits = this.numberOfDigits,
                formatNumber = (num: number) => {
                    return +num.toFixed(numberOfDigits);
                };

            if ((geometry as THREE.Geometry).isGeometry)
                geometry = new THREE.BufferGeometry().setFromObject(line);

            if ((geometry as THREE.BufferGeometry).isBufferGeometry) {

                // shortcuts
                var vertices = (<THREE.BufferGeometry>geometry).getAttribute('position');

                // name of the line object
                if (this.objectsAsObjObjects)
                    outputLines.push(`\no ${line.name}\n`);
                else if (this.objectsAsObjGroups)
                    outputLines.push(`\ng ${line.name}\n`);
                else
                    outputLines.push('\n');

                if (vertices !== undefined) {

                    for (i = 0, l = vertices.count; i < l; i++ , nbVertex++) {

                        vertex.x = vertices.getX(i);
                        vertex.y = vertices.getY(i);
                        vertex.z = vertices.getZ(i);

                        // transfrom the vertex to world space
                        vertex.applyMatrix4(line.matrixWorld);

                        // transform the vertex to export format
                        outputLines.push(`v ${formatNumber(vertex.x)} ${formatNumber(vertex.y)} ${formatNumber(vertex.z)}\n`);

                    }

                }

                if (type === 'Line') {

                    o = 'l ';

                    for (j = 1, l = vertices.count; j <= l; j++) {

                        o += `${indexVertex + j} `;

                    }

                    outputLines.push(`${o}\n`);

                }

                if (type === 'LineSegments') {

                    for (j = 1, k = j + 1, l = vertices.count; j < l; j += 2, k = j + 1) {

                        outputLines.push(`l ${indexVertex + j} ${indexVertex + k}\n`);

                    }

                }

            } else {

                console.warn('THREE.OBJExporter.parseLine(): geometry type unsupported', geometry);

            }

            // update index
            this.vertexCount += nbVertex;

            return this;
        }

        getOutput() {
            return this.outputLines.join('');
        }

        getBlob() {
            return new Blob([this.getOutput()], { type: "text/plain" });
        }

        download(fileName = "Scene.obj") {
            spt.Utils.saveBlobAsFile(fileName, this.getBlob());
        }
    }
}