module spt.ThreeJs.utils {
    export interface ICharDefinition {
        id: number;
        x: number;
        y: number;
        width: number;
        height: number;
        xoffset: number;
        yoffset: number;
        xadvance: number;
        page: number;
        chnl: number;
    }

    export interface ISdfFontMeta {
        name: string;
        imageSize: number;
        fontSize: number;
        chars: { [id: number]: ICharDefinition }
    }

    export class SDFFontMaterial extends THREE.RawShaderMaterial {

        public static imagePath: string = "";
        public static precision: string = "mediump";
        public static imageExtension: string = ".png";
        public static textureAnisotropy: number = 1;
        public static FontMetaDatas: { [name: string]: ISdfFontMeta } = {};
        private static instances: { [name: string]: SDFFontMaterial } = {};
        private static loadingQueue: { [name: string]: ((metaData: ISdfFontMeta) => void)[] } = {};
        public fontName: string;
        public instanced: boolean;

        get map() {
            return this.uniforms["map"].value as THREE.Texture;
        }

        set map(value: THREE.Texture) {
            this.uniforms["map"].value = value;
        }

        public static GetMetaData(fontName: string, fn: (metaData: ISdfFontMeta) => void) {
            if (SDFFontMaterial.FontMetaDatas[fontName] === undefined) {
                if (SDFFontMaterial.loadingQueue[fontName] !== undefined) {
                    SDFFontMaterial.loadingQueue[fontName].push(fn);
                    return;
                }
                SDFFontMaterial.loadingQueue[fontName] = [fn];
                var loader = new THREE.FileLoader(THREE.DefaultLoadingManager);
                loader.responseType = "json";
                loader.load(SDFFontMaterial.imagePath + fontName + ".json", (data) => {
                    if (data) {
                        //console.log(data);
                        var json = typeof data === "string" ? JSON.parse(data) : data;
                        SDFFontMaterial.FontMetaDatas[fontName] = json;
                        var lqueue = SDFFontMaterial.loadingQueue[fontName];
                        for (var i = 0, j = lqueue.length; i < j; i++) {
                            lqueue[i](json);
                        }
                    } else
                        SDFFontMaterial.FontMetaDatas[fontName] = null;

                    if (SDFFontMaterial.loadingQueue[fontName] !== undefined)
                        delete SDFFontMaterial.loadingQueue[fontName];

                    LoaderManager.notifyResourceLoaded();
                });
            } else
                fn(SDFFontMaterial.FontMetaDatas[fontName]);
        }

        constructor(fontName: string, instanced?: boolean, depthTest?: boolean, depthWrite?: boolean, notTransparent?: boolean) {
            super();

            this.instanced = !!instanced;
            this.fontName = fontName;
            this.transparent = !notTransparent;
            this.side = THREE.DoubleSide;
            this.depthTest = !!depthTest;
            this.depthWrite = !!depthWrite;

            var tex = (new THREE.TextureLoader()).load(
                SDFFontMaterial.imagePath + fontName + SDFFontMaterial.imageExtension,
                () => {
                    LoaderManager.notifyResourceLoaded();
                });
            tex.anisotropy = SDFFontMaterial.textureAnisotropy > 0 ? SDFFontMaterial.textureAnisotropy : 1;
            tex.generateMipmaps = false;
            tex.minFilter = tex.magFilter = THREE.LinearFilter;

            this.uniforms = {
                map: <THREE.IUniform>{ type: "t", value: tex }
            };

            this.vertexColors = instanced ? false : true;

            this.vertexShader = [
                "precision " + SDFFontMaterial.precision + " float;",

                "uniform mat4 modelViewMatrix;",
                "uniform mat4 projectionMatrix;",

                instanced ? "attribute vec2 sizei;" : "",
                instanced ? "attribute vec3 offseti;" : "",
                instanced ? "attribute vec4 uvMati;" : "", //x,y = offset; z,w = size

                "attribute vec3 position;",
                "attribute vec2 uv;",
                instanced ? "attribute vec3 colori;" : "attribute vec3 color;",

                "varying vec2 vUv;",
                "varying vec3 vColor;",

                "void main() {",
                instanced ? "vUv = uv * uvMati.zw + uvMati.xy;" : "vUv = uv;",
                instanced ? "vColor = colori;" : "vColor = color;",
                "gl_Position = projectionMatrix * modelViewMatrix * vec4( " + (instanced ? "position * vec3(sizei, 1.0) + offseti" : "position") + ", 1.0 );",
                "}"
            ].join("\n");

            this.fragmentShader = [
                "precision " + SDFFontMaterial.precision + " float;",

                "uniform sampler2D map;",

                "varying vec2 vUv;",
                "varying vec3 vColor;",

                "const float smoothing = 1.0/16.0;",

                "void main() {",
                "float distance = texture2D(map, vUv).a;",
                "float alpha = smoothstep(0.5 - smoothing, 0.5 + smoothing, distance);",
                "if(alpha <= 0.0) { discard; }",
                "gl_FragColor = vec4( vColor, alpha );",
                "}"
            ].join("\n");
        }

        public static getInstance(fontName: string, instanced?: boolean, depthTest?: boolean, depthWrite?: boolean, notTransparent?: boolean) {
            var k = `${fontName}${instanced ? "_i" : ""}${depthTest ? "_t" : ""}${depthWrite ? "_w" : ""}${notTransparent ? "_o" : ""}`;
            if (!SDFFontMaterial.instances[k]) {
                SDFFontMaterial.instances[k] = new SDFFontMaterial(fontName, instanced, depthTest, depthWrite, notTransparent);
            }
            return SDFFontMaterial.instances[k];
        }
    }

    export class SDFTextObject extends THREE.Object3D {

        private _text: string;
        private _depthTest: boolean;
        private _depthWrite: boolean;
        private _notTransparent: boolean;

        private _fontMeta: ISdfFontMeta;

        private _insertPosition: THREE.Vector2 = new THREE.Vector2();

        private _alignmentPoint: THREE.Vector2;

        private _color: THREE.Color;
        private _isDisposed: boolean = false;
        private _isLoaded: boolean = false;

        private _onLoad: ((to: SDFTextObject) => void)[] = null;

        private isDynamic: boolean;

        zOffset = 0.2;
        size: number;

        get color() {
            return this._color;
        }

        set color(value: THREE.Color) {
            if (value.getHex() !== this._color.getHex()) {
                this._color.copy(value);
                this.onColorChanged();
            }
        }

        onColorChanged() {
            var color = this.color;
            if (this._fontMeta) {
                this.traverse((mesh) => {
                    if (mesh instanceof THREE.Mesh) {
                        var geometry = mesh.geometry;
                        if (geometry instanceof THREE.BufferGeometry) {
                            var colorAttribute = geometry.attributes['color'] as THREE.BufferAttribute;
                            var colorArray = colorAttribute.array as Array<number>;
                            for (var i = 0, j = colorArray.length; i < j; i += 3) {
                                colorArray[i] = color.r;
                                colorArray[i + 1] = color.g;
                                colorArray[i + 2] = color.b;
                            }
                            colorAttribute.needsUpdate = true;
                        } else if (geometry instanceof THREE.Geometry) {
                            for (var k = 0, l = geometry.faces.length; k < l; k++) {
                                var f = geometry.faces[k];
                                f.vertexColors[0] = color;
                                f.vertexColors[1] = color;
                                f.vertexColors[2] = color;
                            }
                            geometry.colorsNeedUpdate = true;
                        }
                    }
                });
            }
        }

        get text() {
            return this._text;
        }

        set text(value: string) {
            if (this._text !== value) {
                this.ReGenerate(value);
            }
        }

        getWidth() {
            if (this._fontMeta)
                return this._insertPosition.x * this.size / this._fontMeta.fontSize;
            return 0;
        }

        constructor(txt: string, size: number, color?: THREE.Color, font?: string, isDynamic?: boolean, alignmentPoint?: THREE.Vector2, depthTest?: boolean, depthWrite?: boolean, notTransparent?: boolean) {
            super();
            this.type = "SDFTextObject";
            this._text = txt;
            this.size = size || 1;
            this._color = color || new THREE.Color(0x000000);
            this.isDynamic = !!isDynamic;
            this._alignmentPoint = alignmentPoint || new THREE.Vector2(0.5, 0);
            this._depthTest = !!depthTest;
            this._depthWrite = !!depthWrite;
            this._notTransparent = !!notTransparent;
            this.SetFont(font);
        }

        setParameter(txt: string, size: number, color?: THREE.Color, font?: string, alignmentPoint?: THREE.Vector2) {
            this._text = txt;
            this.size = size || 1;
            this._color = color || new THREE.Color(0x000000);
            if (alignmentPoint)
                this._alignmentPoint = alignmentPoint;
            this.SetFont(font);
        }

        private updateAlignmentScale() {
            if (this._fontMeta) {
                var scale = this.size / this._fontMeta.fontSize;
                var ax = -this._alignmentPoint.x * this._insertPosition.x * scale,
                    ay = -this._alignmentPoint.y * this._fontMeta.fontSize * scale;
                this.traverse((obj) => {
                    obj.position.set(ax, ay, 0);
                    obj.scale.set(scale, scale, scale);
                });
            }
        }

        SetAlignment(point: THREE.Vector2) {
            this._alignmentPoint.copy(point);
            this.updateAlignmentScale();
        }

        GetAlignment() {
            return this._alignmentPoint;
        }

        SetSize(size: number) {
            this.size = size;
            this.updateAlignmentScale();
        }

        SetFont(font?: string) {
            if (this._isDisposed)
                return;
            this._isLoaded = false;
            SDFFontMaterial.GetMetaData(font || "Arial", this.SetFontMeta.bind(this));
        }

        SetFontMeta(fontMeta: ISdfFontMeta) {
            if (this._isDisposed)
                return;
            this._fontMeta = fontMeta;
            if (fontMeta)
                this.ReGenerate();
        }

        ReGenerate(txt?: string) {
            if (this._isDisposed)
                return;
            if (!txt)
                txt = this._text;
            else
                this._text = txt;

            if (!this._fontMeta)
                return;

            var scale = this.size / this._fontMeta.fontSize;

            var insertPosition = this._insertPosition.set(0, 0);
            var fontMeta = this._fontMeta;
            var zOff = this.zOffset;

            if (this.isDynamic) {

                var addCharDynamic = (id: number, color: THREE.Color, geometry: THREE.Geometry) => {
                    var char = fontMeta.chars[id];
                    if (!char)
                        char = fontMeta.chars[32]; //empty space

                    //skip whitespace
                    if (char.id == 32) {
                        insertPosition.x += char.xadvance;
                        return;
                    }

                    var size = fontMeta.imageSize,
                        pos = insertPosition,
                        xOff = pos.x + char.xoffset,
                        yOff = pos.y - char.height + char.yoffset;

                    geometry.vertices[0].set(xOff, yOff, zOff);
                    geometry.vertices[1].set(xOff + char.width, yOff, zOff);
                    geometry.vertices[2].set(xOff + char.width, yOff + char.height, zOff);
                    geometry.vertices[3].set(xOff, yOff + char.height, zOff);

                    var w = (char.width / size),
                        h = (char.height / size),
                        x = (char.x / size),
                        y = 1 - ((char.y + char.height) / size);

                    geometry.faceVertexUvs[0][0][0].set(x, y);
                    geometry.faceVertexUvs[0][0][1].set(x + w, y);
                    geometry.faceVertexUvs[0][0][2].set(x + w, y + h);

                    geometry.faceVertexUvs[0][1][0].set(x, y);
                    geometry.faceVertexUvs[0][1][1].set(x + w, y + h);
                    geometry.faceVertexUvs[0][1][2].set(x, y + h);

                    geometry.faces[0].vertexColors[0] = color;
                    geometry.faces[0].vertexColors[1] = color;
                    geometry.faces[0].vertexColors[2] = color;

                    geometry.faces[1].vertexColors[0] = color;
                    geometry.faces[1].vertexColors[1] = color;
                    geometry.faces[1].vertexColors[2] = color;

                    insertPosition.x += char.xadvance;
                }

                var geometry: THREE.Geometry;
                var mesh: THREE.Mesh;

                var k = 0;

                for (var l = txt.length; k < l; k++) {

                    if (this.children.length > k) {

                        mesh = this.children[k] as THREE.Mesh;
                        geometry = mesh.geometry as THREE.Geometry;
                        mesh.material = SDFFontMaterial.getInstance(this._fontMeta.name, false, this._depthTest, this._depthWrite, this._notTransparent);
                        mesh.visible = true;

                    } else {

                        geometry = new THREE.Geometry();
                        geometry.vertices.push(new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3());
                        geometry.faces.push(new THREE.Face3(0, 1, 2), new THREE.Face3(0, 2, 3));
                        geometry.faceVertexUvs[0].push([new THREE.Vector2(), new THREE.Vector2(), new THREE.Vector2()], [new THREE.Vector2(), new THREE.Vector2(), new THREE.Vector2()]);

                        mesh = new THREE.Mesh(geometry, SDFFontMaterial.getInstance(this._fontMeta.name, false, this._depthTest, this._depthWrite, this._notTransparent));
                        this.add(mesh);
                    }

                    addCharDynamic(txt.charCodeAt(k), this.color, geometry);

                    geometry.verticesNeedUpdate = true;
                    geometry.elementsNeedUpdate = true;
                    geometry.uvsNeedUpdate = true;
                    geometry.colorsNeedUpdate = true;

                    mesh.scale.set(scale, scale, 1);
                }

                for (var l = this.children.length; k < l; k++) {
                    this.children[k].visible = false;
                }

                var ax = -this._alignmentPoint.x * this._insertPosition.x * scale,
                    ay = -this._alignmentPoint.y * this._fontMeta.fontSize * scale;

                for (var m = 0, n = this.children.length; m < n; m++) {
                    var child = this.children[m] as THREE.Mesh;
                    if (!child.visible)
                        continue;
                    child.position.set(ax, ay, 0);
                    child.geometry.computeBoundingSphere();
                }

            } else {

                spt.ThreeJs.utils.emptyObject3D(this, true, true);

                var vertices: number[] = [];
                var uvs: number[] = [];
                var indices: number[] = [];
                var colors: number[] = [];

                var addChar = (id: number, color: THREE.Color) => {
                    var char = fontMeta.chars[id];
                    if (!char)
                        char = fontMeta.chars[32]; //empty space

                    //skip whitespace
                    if (char.id == 32) {
                        insertPosition.x += char.xadvance;
                        return;
                    }

                    var size = fontMeta.imageSize,
                        pos = insertPosition,
                        indexOffset = vertices.length / 3,
                        xOff = pos.x + char.xoffset,
                        yOff = pos.y - char.height + char.yoffset;

                    vertices.push(
                        xOff, yOff, zOff,
                        xOff + char.width, yOff, zOff,
                        xOff + char.width, yOff + char.height, zOff,
                        xOff, yOff + char.height, zOff
                    );

                    indices.push(
                        indexOffset, indexOffset + 1, indexOffset + 2,
                        indexOffset, indexOffset + 2, indexOffset + 3
                    );

                    var w = (char.width / size),
                        h = (char.height / size),
                        x = (char.x / size),
                        y = 1 - ((char.y + char.height) / size);

                    uvs.push(
                        x, y,
                        x + w, y,
                        x + w, y + h,
                        x, y + h
                    );

                    colors.push(
                        color.r, color.g, color.b,
                        color.r, color.g, color.b,
                        color.r, color.g, color.b,
                        color.r, color.g, color.b
                    );

                    insertPosition.x += char.xadvance;
                };

                for (var i = 0, j = txt.length; i < j; i++) {
                    addChar(txt.charCodeAt(i), this.color);
                }

                var bgeometry = new THREE.BufferGeometry();

                bgeometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(vertices), 3));
                bgeometry.setAttribute('color', new THREE.BufferAttribute(new Float32Array(colors), 3));
                bgeometry.setAttribute('uv', new THREE.BufferAttribute(new Float32Array(uvs), 2));
                bgeometry.setIndex(new THREE.BufferAttribute(new Uint16Array(indices), 1));

                var bmesh = new THREE.Mesh(bgeometry, SDFFontMaterial.getInstance(this._fontMeta.name, false, this._depthTest, this._depthWrite, this._notTransparent));

                bmesh.scale.set(scale, scale, 1);

                var ax = -this._alignmentPoint.x * this._insertPosition.x * scale,
                    ay = -this._alignmentPoint.y * this._fontMeta.fontSize * scale;

                bmesh.position.set(ax, ay, 0);

                this.add(bmesh);
            }

            this._isLoaded = true;
            if (this._onLoad) {
                var onloads = this._onLoad.slice();
                var self = this;
                onloads.forEach((fn) => { fn(self); });
                this._onLoad = null;
            }

        }

        dispose() {
            spt.ThreeJs.utils.emptyObject3D(this, true, true);
            delete this._fontMeta;
            this._isDisposed = true;
        }

        onLoad(fn: (to: SDFTextObject) => void) {
            if (this._isDisposed)
                return;
            if (this._isLoaded)
                fn(this);
            else {
                if (!this._onLoad)
                    this._onLoad = [];
                this._onLoad.push(fn);
            }
        }
    }

    export class SDFTextObjectCollection extends THREE.Object3D {

        private _depthTest: boolean;
        private _depthWrite: boolean;
        private _notTransparent: boolean;

        constructor(input: any[] | SDFTextObject[], fontName?: string, depthTest?: boolean, depthWrite?: boolean, notTransparent?: boolean) {
            super();

            if (!input || !input.length)
                return;

            this._depthTest = !!depthTest;
            this._depthWrite = !!depthWrite;
            this._notTransparent = !!notTransparent;

            var textObjects: SDFTextObject[];

            if (typeof input[0] === "object" && input[0] instanceof THREE.SDFTextObject) {
                textObjects = (<SDFTextObject[]>input).map(txtObj => {
                    var newTxtObj = new THREE.SDFTextObject(
                        txtObj.text,
                        txtObj.size,
                        txtObj.color,
                        fontName,
                        false,
                        txtObj.GetAlignment(),
                        depthTest,
                        depthWrite,
                        notTransparent
                    );
                    txtObj.updateMatrix();
                    newTxtObj.matrix.copy(txtObj.matrix);
                    return newTxtObj;
                });
            } else {
                var data = input as any[];
                textObjects = [];

                var i = 0;

                while (i < data.length) {
                    var txtObj = new THREE.SDFTextObject(
                        data[i++] as string,
                        data[i++] as number,
                        new THREE.Color(data[i++], data[i++], data[i++]),
                        fontName,
                        false,
                        new THREE.Vector2(data[i++], data[i++]),
                        depthTest,
                        depthWrite,
                        notTransparent
                    );
                    
                    txtObj.matrix.fromArray(data[i++] as number[]);

                    textObjects.push(txtObj);
                }
            }

            if (!textObjects || !textObjects.length)
                return;

            setTimeout(() => {
                SDFFontMaterial.GetMetaData(fontName || "Arial", (metaData) => {

                    var geomtries = textObjects.map(txtObj => (<THREE.Mesh>txtObj.children[0]).geometry as THREE.BufferGeometry);

                    var transforms = textObjects.map(txtObj => {
                        var mesh = txtObj.children[0] as THREE.Mesh;
                        mesh.updateMatrix();
                        return txtObj.matrix.clone().multiply(mesh.matrix).toArray();
                    });

                    var newGeometry = spt.ThreeJs.utils.MergeBufferGeometries(geomtries, transforms);

                    this.add(new THREE.Mesh(newGeometry, SDFFontMaterial.getInstance(metaData.name, false, this._depthTest, this._depthWrite, this._notTransparent)));
                    textObjects.forEach(txtObj => {
                        txtObj.dispose();
                    });
                });
            }, 0);
        }
    }

    export class SDFTextObjectInstances extends THREE.Object3D {

        private _instances: { pos: THREE.Vector3, text: string, col: THREE.Color }[] = [];
        
        private _depthTest: boolean;
        private _depthWrite: boolean;
        private _notTransparent: boolean;

        private _fontMeta: ISdfFontMeta;
        private _bounds = new THREE.Box3();

        private _alignmentPoint: THREE.Vector2;
        private _insertPosition: THREE.Vector2 = new THREE.Vector2();
        private _color: THREE.Color;

        private _isDisposed: boolean = false;
        private _needsUpdate: boolean = true;
        private _bufferSize: number = 0;

        private _sizeAttribute: THREE.InstancedBufferAttribute/* | LS.Client3DEditor.FakeInstancedBufferAttribute*/;
        private _offsetAttribute: THREE.InstancedBufferAttribute/* | LS.Client3DEditor.FakeInstancedBufferAttribute*/;
        private _uvMatAttribute: THREE.InstancedBufferAttribute/* | LS.Client3DEditor.FakeInstancedBufferAttribute*/;
        private _colorAttribute: THREE.InstancedBufferAttribute/* | LS.Client3DEditor.FakeInstancedBufferAttribute*/;

        get instanceCount() {
            return this._instances.length;
        }

        removeInstances(count: number) {
            if (count > 0 && this._instances.length)
                this._instances.splice(0, Math.min(count, this._instances.length));
            this._needsUpdate = true;
        }

        setTextXYZ(index: number, text: string, x: number, y: number, z: number, col?: THREE.Color): void {
            var instances = this._instances;

            if (index >= instances.length) {
                this.addTextXYZ(text, x, y, z, col);
                return;
            }

            var inst = this._instances[index];

            if (text && inst.text != text)
                inst.text = text || "";

            inst.pos.set(x, y, z);

            if (col)
                inst.col.copy(col);

            this._needsUpdate = true;
        }

        setTextAt(index: number, text?: string, pos?: THREE.Vector3, col?: THREE.Color): void {
            var instances = this._instances;

            if (index >= instances.length) {
                this.addTextAt(text, pos, col);
                return;
            }

            var inst = this._instances[index];

            if (text)
                inst.text = text || "";

            if (pos)
                inst.pos.copy(pos);

            if (col)
                inst.col.copy(col);

            this._needsUpdate = true;
        }

        addTextAt(text: string, pos: THREE.Vector3, col?: THREE.Color): number {
            this._instances.push({ pos: pos ? pos.clone() : new THREE.Vector3(0, 0, 0), text: text || "", col: col ? col.clone() : this._color });
            this._needsUpdate = true;
            return this._instances.length - 1;
        }

        addTextXYZ(text: string, x: number, y: number, z: number, col?: THREE.Color): number {
            this._instances.push({ pos: new THREE.Vector3(x, y, z), text: text || "", col: col ? col.clone() : this._color });
            this._needsUpdate = true;
            return this._instances.length - 1;
        }

        update(): void {
            if (this._needsUpdate)
                this.ReGenerate();
        }

        zOffset = 0.2;
        private size: number;

        constructor(size: number, color?: THREE.Color, font?: string, alignmentPoint?: THREE.Vector2, depthTest?: boolean, depthWrite?: boolean, notTransparent?: boolean) {
            super();
            this.type = "SDFTextObjectInstances";
            this.size = size || 1;
            this._color = color || new THREE.Color(0x000000);
            this._alignmentPoint = alignmentPoint || new THREE.Vector2(0.5, 0);
            this._depthTest = !!depthTest;
            this._depthWrite = !!depthWrite;
            this._notTransparent = !!notTransparent;
            this.SetFont(font);
        }

        setParameter(size: number, font?: string, alignmentPoint?: THREE.Vector2) {
            this.size = size || 1;
            if (alignmentPoint)
                this._alignmentPoint = alignmentPoint;
            this.SetFont(font);
        }

        SetFont(font?: string) {
            if (this._isDisposed)
                return;
            SDFFontMaterial.GetMetaData(font || "Arial", this.SetFontMeta.bind(this));
        }

        SetFontMeta(fontMeta: ISdfFontMeta) {
            if (this._isDisposed)
                return;
            this._fontMeta = fontMeta;
            if (fontMeta)
                this.ReGenerate();
        }

        ReGenerate() {
            if (this._isDisposed || !this._fontMeta)
                return;

            var instances = this._instances,
                fontMeta = this._fontMeta,
                maxInstancedCount = instances.map(inst => inst.text && inst.text.length ? inst.text.split("").map(c => fontMeta.chars[c.charCodeAt(0)] || fontMeta.chars[32]).filter(char => char && char.id != 32).length : 0).reduce((pv, cv) => pv + cv, 0),
                insertPosition = this._insertPosition.set(0, 0),
                zOff = this.zOffset,
                scale = this.size / fontMeta.fontSize,
                alignmentPoint = this._alignmentPoint,
                bounds = this._bounds;

            bounds.makeEmpty();

            if (maxInstancedCount <= 0) {
                this.visible = false;
                return;
            } else if (!this.visible)
                this.visible = true;

            if (this._bufferSize < maxInstancedCount) {
                spt.ThreeJs.utils.emptyObject3D(this, true, true, false);

                if (this._sizeAttribute && (<any>this._sizeAttribute).dispose)
                    (<any>this._sizeAttribute).dispose();
                this._sizeAttribute = null;

                if (this._offsetAttribute && (<any>this._offsetAttribute).dispose)
                    (<any>this._offsetAttribute).dispose();
                this._offsetAttribute = null;

                if (this._uvMatAttribute && (<any>this._uvMatAttribute).dispose)
                    (<any>this._uvMatAttribute).dispose();
                this._uvMatAttribute = null;

                if (this._colorAttribute && (<any>this._colorAttribute).dispose)
                    (<any>this._colorAttribute).dispose();
                this._colorAttribute = null;

                //if (Detector.webglParameters.supportsInstancedArrays) {
                    this._sizeAttribute = new THREE.InstancedBufferAttribute(new Float32Array(maxInstancedCount * 2), 2, false, 1);
                    this._offsetAttribute = new THREE.InstancedBufferAttribute(new Float32Array(maxInstancedCount * 3), 3, false, 1);
                    this._uvMatAttribute = new THREE.InstancedBufferAttribute(new Float32Array(maxInstancedCount * 4), 4, false, 1);
                    this._colorAttribute = new THREE.InstancedBufferAttribute(new Float32Array(maxInstancedCount * 3), 3, false, 1);

                    this._sizeAttribute.setUsage(THREE.DynamicDrawUsage);
                    this._offsetAttribute.setUsage(THREE.DynamicDrawUsage);
                    this._uvMatAttribute.setUsage(THREE.DynamicDrawUsage);
                    this._colorAttribute.setUsage(THREE.DynamicDrawUsage);
                //} else {
                //    //instancing not supported
                //    this._sizeAttribute = new LS.Client3DEditor.FakeInstancedBufferAttribute(maxInstancedCount, 2);
                //    this._offsetAttribute = new LS.Client3DEditor.FakeInstancedBufferAttribute(maxInstancedCount, 3);
                //    this._uvMatAttribute = new LS.Client3DEditor.FakeInstancedBufferAttribute(maxInstancedCount, 4);
                //    this._colorAttribute = new LS.Client3DEditor.FakeInstancedBufferAttribute(maxInstancedCount, 3);
                //}

                this._bufferSize = maxInstancedCount;
            }

            var sizeAttr = this._sizeAttribute,
                offsetAttr = this._offsetAttribute,
                uvMatAttr = this._uvMatAttribute,
                colorAttr = this._colorAttribute,
                instanceIndex = 0;

            sizeAttr.needsUpdate = true;
            offsetAttr.needsUpdate = true;
            uvMatAttr.needsUpdate = true;
            colorAttr.needsUpdate = true;

            instances.forEach(instance => {
                var txt = instance.text,
                    pos = instance.pos,
                    color = instance.col,
                    chars = txt.split("").map(c => fontMeta.chars[c.charCodeAt(0)] || fontMeta.chars[32]),
                    txtWidth = chars.map(c => c.xadvance).reduce((pv, cv) => pv + cv, 0),
                    ax = -alignmentPoint.x * txtWidth * scale,
                    ay = -alignmentPoint.y * fontMeta.fontSize * scale;

                bounds.expandByPoint(new THREE.Vector3(pos.x + ax, pos.y + ay, pos.z - zOff));
                bounds.expandByPoint(new THREE.Vector3(pos.x + ax + txtWidth, pos.y + ay + fontMeta.fontSize, pos.z + zOff));

                chars.forEach(char => {
                    //skip whitespace
                    if (char.id == 32) {
                        insertPosition.x += char.xadvance;
                        return;
                    }

                    var size = fontMeta.imageSize,
                        xOff = pos.x + (insertPosition.x + char.xoffset) * scale + ax,
                        yOff = pos.y + (insertPosition.y - char.height + char.yoffset) * scale + ay,
                        w = (char.width / size),
                        h = (char.height / size),
                        x = (char.x / size),
                        y = 1 - ((char.y + char.height) / size);

                    sizeAttr.setXY(instanceIndex, char.width * scale, char.height * scale);
                    offsetAttr.setXYZ(instanceIndex, xOff, yOff, pos.z + zOff);
                    uvMatAttr.setXYZW(instanceIndex, x, y, w, h);
                    colorAttr.setXYZ(instanceIndex, color.r, color.g, color.b);

                    insertPosition.x += char.xadvance;
                    ++instanceIndex;
                });

                insertPosition.set(0, 0);
            });

            if (!this.children.length) {
                var buildGeometry: THREE.InstancedBufferGeometry/* | THREE.BufferGeometry*/ = /*Detector.webglParameters.supportsInstancedArrays ? */new THREE.InstancedBufferGeometry()/* : new THREE.BufferGeometry()*/;

                buildGeometry.boundingSphere = bounds.getBoundingSphere(new THREE.Sphere());
                buildGeometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array([0, 0, 0, 1, 0, 0, 1, 1, 0, 0, 0, 0, 1, 1, 0, 0, 1, 0]), 3));
                buildGeometry.setAttribute('uv', new THREE.BufferAttribute(new Float32Array([0, 0, 1, 0, 1, 1, 0, 0, 1, 1, 0, 1]), 2));

                //if (Detector.webglParameters.supportsInstancedArrays) {
                    (buildGeometry as THREE.InstancedBufferGeometry).maxInstancedCount = maxInstancedCount;
                    buildGeometry.setAttribute('sizei', sizeAttr as any);
                    buildGeometry.setAttribute('offseti', offsetAttr as any);
                    buildGeometry.setAttribute('uvMati', uvMatAttr as any);
                    buildGeometry.setAttribute('colori', colorAttr as any);
                //} else {
                //    //fallback method (slowdown expected)

                //    var vLength = (buildGeometry.attributes as any).position.array.length / 3;

                //    //duplicate indices
                //    //if (buildGeometry.getIndex()) {
                //    //    var attribute = buildGeometry.getIndex(),
                //    //        array = attribute.array,
                //    //        arrayLength = array.length,
                //    //        newLength = arrayLength * maxInstancedCount,
                //    //        newArray = new (array as any).constructor(newLength);

                //    //    for (var iIdx = maxInstancedCount; iIdx--;) {
                //    //        var off = iIdx * arrayLength,
                //    //            iOff = iIdx * vLength;
                //    //        for (var i = arrayLength; i--;) {
                //    //            newArray[i + off] = array[i] + iOff;
                //    //        }
                //    //    }

                //    //    attribute.array = newArray;
                //    //}

                //    //duplicate attributes
                //    var attributes = Object.keys(buildGeometry.attributes);
                //    for (var k = attributes.length; k--;) {
                //        var name = attributes[k],
                //            attrib = buildGeometry.attributes[name] as THREE.BufferAttribute,
                //            arr = attrib.array,
                //            arrLength = arr.length,
                //            nLength = arrLength * maxInstancedCount,
                //            nArray = new (arr as any).constructor(nLength);
                //        ArrayHelper.iterateTimes(nLength, (i) => {
                //            nArray[i] = arr[i % arrLength];
                //        });
                //        attrib.array = nArray;
                //    }
                //    (sizeAttr as LS.Client3DEditor.FakeInstancedBufferAttribute).addToBufferGeometry(buildGeometry, 'sizei', vLength);
                //    (offsetAttr as LS.Client3DEditor.FakeInstancedBufferAttribute).addToBufferGeometry(buildGeometry, 'offseti', vLength);
                //    (uvMatAttr as LS.Client3DEditor.FakeInstancedBufferAttribute).addToBufferGeometry(buildGeometry, 'uvMati', vLength);
                //    (colorAttr as LS.Client3DEditor.FakeInstancedBufferAttribute).addToBufferGeometry(buildGeometry, 'colori', vLength);
                //}

                this.add(new THREE.Mesh(buildGeometry, SDFFontMaterial.getInstance(this._fontMeta.name, true, this._depthTest, this._depthWrite, this._notTransparent)));
            } else {
                this.children.forEach((child: THREE.Mesh) => {
                    (child.geometry as THREE.InstancedBufferGeometry).maxInstancedCount = maxInstancedCount;
                });
            }

            this._needsUpdate = false;
        }

        clear() {
            if (this._isDisposed)
                return;
            this._instances = [];
            spt.ThreeJs.utils.emptyObject3D(this, true, true);
        }

        dispose() {
            this.clear();
            delete this._fontMeta;
            this._isDisposed = true;
        }
    }

    export class DimensionAligned extends THREE.Object3D implements LS.Client3DEditor.ISelectableUi {
        private _text: string = null;
        private _start: THREE.Vector3;
        private _end: THREE.Vector3;
        private _start2: THREE.Vector3;
        private _end2: THREE.Vector3;
        private _dimensionPosition: THREE.Vector3;
        private _textSize: number;
        private _color: THREE.Color;
        private _fontName: string;
        private _arrowLength: number = 100;
        private _arrowSlope: number = 1 / 6;
        private _linear = false;
        private _rotation = 0; //Math.PI * 0.5;
        private _textVerticalAlignment = 0;

        static materialInstances: { [name: string]: THREE.Material } = {};
        public static lineMaterial: THREE.Material = null;
        public static arrowMaterial: THREE.Material = null;

        zOffset = 0.2;

        private _activeColor = new THREE.Color();

        get activeColor() {
            if (this.isSelected || this.isHovered)
                return this._activeColor.set(this.isSelected ? 0xD52CE8 : 0xffff00);
            return this._activeColor.copy(this.color);
        }

        _isDisposed = false;

        private isDynamic: boolean;
        private vertexColors: boolean;

        isSelected: boolean;
        isHovered: boolean;

        applyObjectPosition() {
            //this.position.set(0, 0, 0);
        }

        OnChanged(viewModel?: LS.Client3DEditor.ViewModel) {
            var childs = this.children;
            if (!childs || !childs.length || this.vertexColors) {
                return;
            }

            var color = this.activeColor;

            for (var i = childs.length; i--;) {
                var child = childs[i];
                if (child instanceof SDFTextObject) {
                    //child.color = color;
                } else if (child instanceof THREE.Line) {
                    child.material = this.getLineMaterial(color);
                } else if (child instanceof THREE.Mesh) {
                    child.material = this.getArrowMaterial(color);
                }
            }
        }

        public getArrowMaterial(color: THREE.Color) {
            if (!this.vertexColors) {
                var arrowMaterial: THREE.Material;
                var meshMatKey = "Mesh:" + color.getHexString();

                if (!DimensionAligned.materialInstances[meshMatKey]) {
                    arrowMaterial = new THREE.MeshBasicMaterial({
                        color: color as any,
                        fog: false,
                        side: THREE.DoubleSide
                    });
                    DimensionAligned.materialInstances[meshMatKey] = arrowMaterial;
                } else {
                    arrowMaterial = DimensionAligned.materialInstances[meshMatKey];
                }

                return arrowMaterial as THREE.MeshBasicMaterial;
            }
            if (!DimensionAligned.arrowMaterial) {
                DimensionAligned.arrowMaterial = new THREE.MeshBasicMaterial({
                    vertexColors: true, //THREE.VertexColors,
                    fog: false,
                    side: THREE.DoubleSide
                });
            }
            return DimensionAligned.arrowMaterial as THREE.MeshBasicMaterial;
        }

        public getLineMaterial(color: THREE.Color) {
            if (!this.vertexColors) {
                var material: THREE.Material;
                var lineMatKey = "Line:" + color.getHexString();

                if (!DimensionAligned.materialInstances[lineMatKey]) {
                    material = new THREE.LineBasicMaterial({
                        color: color as any,
                        fog: false
                    });
                    DimensionAligned.materialInstances[lineMatKey] = material;
                } else {
                    material = DimensionAligned.materialInstances[lineMatKey];
                }

                return material as THREE.LineBasicMaterial;
            }
            if (!DimensionAligned.lineMaterial) {
                DimensionAligned.lineMaterial = new THREE.LineBasicMaterial({
                    vertexColors: true, //THREE.VertexColors,
                    fog: false
                });
            }
            return DimensionAligned.lineMaterial as THREE.LineBasicMaterial;
        }

        removeInstance() {
            var parent = this.parent;
            if (parent && parent instanceof LS.Client3DEditor.MeasureObjectHolder) {
                parent.removeDimensionLine(this);
            }
        }

        moveBy(v: THREE.Vector3) {

        }

        GetPositionProperty(k: string) {
            return this._start2[k] || null;
        }

        GetSizeProperty(k: string): number {
            return 0;
        }
        
        get fontName() {
            return this._fontName;
        }

        set fontName(value: string) {
            if (value && value.length) {
                this._fontName = value;
                this.rebuild();
            }
        }

        get textSize() {
            return this._textSize;
        }

        set textSize(value: number) {
            if (value > 0) {
                this._textSize = value;
                this.rebuild();
            }
        }

        get color() {
            return this._color;
        }

        set color(value: THREE.Color) {
            this._color.copy(value);
            this.OnChanged();
        }

        get text() {
            return this._text;
        }

        set text(value) {
            this._text = value;
            this.rebuild();
        }

        get isLinear() {
            return this._linear;
        }

        set isLinear(value) {
            this._linear = value;
            this.rebuild();
        }

        get linearRotation() {
            return this._rotation;
        }

        set linearRotation(value) {
            this._rotation = value;
            this.rebuild();
        }

        get start() {
            return this._start;
        }

        set start(value: THREE.Vector3) {
            this._start.copy(value);
            this.rebuild();
        }

        get end() {
            return this._end;
        }

        set end(value: THREE.Vector3) {
            this._end.copy(value);
            this.rebuild();
        }

        setEndAutoPosition(end: THREE.Vector3) {
            this._end.copy(end);
            var se = this._end2.copy(end).sub(this._start);
            se.normalize();
            if (Math.abs(se.y) <= 0.0001 && Math.abs(se.x) <= 0.0001)
                se.set(0, 1, 0);
            this.dimensionPosition.set(se.y, -se.x, se.z).multiplyScalar(50).add(this._start);
            this.rebuild();
        }

        get dimensionPosition() {
            return this._dimensionPosition;
        }

        set dimensionPosition(value: THREE.Vector3) {
            this._dimensionPosition.copy(value);
            this.rebuild();
        }

        get mlineStart() {
            return this._start2;
        }

        get mlineEnd() {
            return this._end2;
        }

        setPositions(s: THREE.Vector3, e: THREE.Vector3, p: THREE.Vector3) {
            this._start.copy(s);
            this._end.copy(e);
            this._dimensionPosition.copy(p);
            this.rebuild();
        }

        setParameter(startx?: number, starty?: number, startz?: number, endx?: number, endy?: number, endz?: number, positionx?: number, positiony?: number, positionz?: number, colorHex?: number, textSize?: number, fontName?: string, text?: string) {
            if (startx !== undefined && starty !== undefined && startz !== undefined)
                this._start.set(startx, starty, startz);
            if (endx !== undefined && endy !== undefined && endz !== undefined)
                this._end.set(endx, endy, endz);
            if (positionx !== undefined && positiony !== undefined && positionz !== undefined)
                this._dimensionPosition.set(positionx, positiony, positionz);
            if (colorHex !== undefined)
                this._color.setHex(colorHex);
            if (textSize !== undefined)
                this._textSize = textSize;
            if (fontName !== undefined)
                this._fontName = fontName;
            if (text !== undefined)
                this._text = text;
            this.rebuild();
        }

        constructor(start?: THREE.Vector3, end?: THREE.Vector3, position?: THREE.Vector3, color?: THREE.Color, textSize?: number, fontName?: string, isDynamic?: boolean, vertexColors?: boolean, text?: string, isLinear?: boolean, rotation?: number, textVerticalAlignment?: number) {
            super();

            this.type = "DimensionAligned";
            this._start = start || new THREE.Vector3();
            this._end = end || new THREE.Vector3();
            this._start2 = new THREE.Vector3();
            this._end2 = new THREE.Vector3();
            this._dimensionPosition = position || new THREE.Vector3();
            this._color = color || new THREE.Color(0x888888);
            this._textSize = textSize || 100;
            this._text = text || null;
            this._fontName = fontName || "Arial";
            this.isSelected = false;
            this.isDynamic = !!isDynamic;
            this.vertexColors = !isDynamic && vertexColors;
            this._linear = !!isLinear;
            this._rotation = rotation || 0;
            this._textVerticalAlignment = textVerticalAlignment || 0;

            this.rebuild();
        }
        
        reset() {
            this.start.set(0, 0, 0);
            this.end.set(0, 0, 0);
            this.position.set(0, 0, 0);
            this.rebuild();
        }

        dispose() {
            spt.ThreeJs.utils.emptyObject3D(this, true, true);
            this._isDisposed = true;
        }

        rebuild() {
            var start = this._start,
                end = this._end,
                se = end.clone().sub(start),
                p = this._dimensionPosition,
                sp = p.clone().sub(start),
                ep = p.clone().sub(end),
                seLengthSq = se.lengthSq(),
                color = this.activeColor,
                textSize = this._textSize,
                fontName = this._fontName,
                EPS = 0.0001,
                isLinear = this._linear,
                rotation = this._rotation,
                textVerticalAlignment = this._textVerticalAlignment;
            
            if (this.isDynamic) {
                if (seLengthSq <= EPS) {
                    this.visible = false;
                    return;
                }
                this.visible = true;
            } else {
                spt.ThreeJs.utils.emptyObject3D(this, true, true);

                if (seLengthSq <= EPS)
                    return;
            }

            var seLength = Math.sqrt(seLengthSq),
                seDir = se.clone().divideScalar(seLength);

            var dimensionOffsetDir: THREE.Vector3;
            var start2: THREE.Vector3;
            var end2: THREE.Vector3;

            if (isLinear) {
                dimensionOffsetDir = new THREE.Vector3(0, 1, 0);
                if (rotation != 0)
                    dimensionOffsetDir.applyMatrix4(new THREE.Matrix4().makeRotationZ(rotation));

                var up = new THREE.Vector3(0, 0, 1);
                var localUp = sp.clone().cross(ep).normalize();
                if (localUp.lengthSq() <= 0.001)
                    localUp.copy(up);
                if (localUp.z < 0)
                    localUp.negate();
                if (localUp.z < 1 && up.angleTo(localUp) > 0) {
                    var xAxis = up.clone().cross(localUp).normalize();
                    var yAxis = xAxis.clone().cross(localUp).normalize();
                    xAxis.multiplyScalar(dimensionOffsetDir.x);
                    yAxis.multiplyScalar(dimensionOffsetDir.y);
                    dimensionOffsetDir.copy(xAxis).add(yAxis).normalize();
                }

                start2 = this._start2.copy(start).add(dimensionOffsetDir.clone().multiplyScalar(dimensionOffsetDir.dot(sp)));
                end2 = this._end2.copy(end).add(dimensionOffsetDir.clone().multiplyScalar(dimensionOffsetDir.dot(ep)));
                seDir.copy(end2).sub(start2);
                seLengthSq = seDir.lengthSq();
                seLength = Math.sqrt(seLengthSq);
                seDir = seDir.divideScalar(seLength);
            } else {
                var dimensionOffset = se.clone().multiplyScalar(sp.dot(se) / seLengthSq).add(start).sub(p).negate();

                if (dimensionOffset.lengthSq() <= EPS) {
                    dimensionOffset.copy(se);
                    dimensionOffset.normalize();
                    if (Math.abs(dimensionOffset.y) <= EPS && Math.abs(dimensionOffset.x) <= EPS)
                        dimensionOffset.set(0, 1, 0);
                    dimensionOffset.set(dimensionOffset.y, -dimensionOffset.x, dimensionOffset.z);
                    dimensionOffsetDir = dimensionOffset;
                    start2 = this._start2.copy(start);
                    end2 = this._end2.copy(end);
                } else {
                    dimensionOffsetDir = dimensionOffset.clone().normalize();
                    start2 = this._start2.copy(start).add(dimensionOffset);
                    end2 = this._end2.copy(end).add(dimensionOffset);
                }
            }

            var alignRight = seDir.clone();
            var alignUp = dimensionOffsetDir.clone();

            if (alignRight.x < -0.01) {
                alignRight.negate();
            }
            if (alignRight.x < 0.01 && alignRight.y < 0) {
                alignRight.negate();
            }

            if (alignUp.y < -0.01) {
                alignUp.negate();
            }
            if (alignUp.y < 0.01 && alignUp.x > 0) {
                alignUp.negate();
            }

            var textText: string = this._text;

            if (!textText || !textText.length || typeof textText !== "string") {
                if (UseImperialSystem) {
                    let textVal = convertLengthUnit(seLength, { from: "mm", to: "in" });

                    textText = textVal.toFixed(1) + "\"";
                }
                else
                    textText = Math.round(seLength).toString(); //seLength.toFixed(2);
            }

            if (this.isDynamic) {
                var txtObj: SDFTextObject;
                var lines: THREE.LineSegments;
                var linesGeo: THREE.Geometry;
                var arrows: THREE.Mesh;
                var arrowsGeo: THREE.Geometry;
                var childs = this.children;
                if (childs.length) {
                    for (var i = childs.length; i--;) {
                        var child = childs[i];
                        if (child instanceof SDFTextObject) {
                            txtObj = child as SDFTextObject;
                        } else if (child instanceof THREE.LineSegments) {
                            lines = child as THREE.LineSegments;
                            linesGeo = lines.geometry as THREE.Geometry;
                            lines.material = this.getLineMaterial(color);
                        } else if (child instanceof THREE.Mesh) {
                            arrows = child as THREE.Mesh;
                            arrowsGeo = arrows.geometry as THREE.Geometry;
                            arrows.material = this.getArrowMaterial(color);
                        }
                    }
                    txtObj.setParameter(textText, textSize, color, fontName);
                }
                else
                {
                    linesGeo = new THREE.Geometry();
                    linesGeo.vertices.push(new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3());
                    lines = new THREE.LineSegments(linesGeo, this.getLineMaterial(color));
                    arrowsGeo = new THREE.Geometry();
                    arrowsGeo.faces.push(new THREE.Face3(0, 1, 2), new THREE.Face3(3, 4, 5));
                    arrowsGeo.vertices.push(new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3());
                    arrows = new THREE.Mesh(arrowsGeo, this.getArrowMaterial(color));
                    txtObj = new SDFTextObject(textText, textSize, color, fontName, this.isDynamic, new THREE.Vector2(0.5, textVerticalAlignment));
                    this.add(txtObj);
                    this.add(arrows);
                    this.add(lines);
                }

                var dtextRotation = new THREE.Quaternion().setFromUnitVectors(new THREE.Vector3(1, 0, 0), alignRight);

                dtextRotation.copy(new THREE.Quaternion().setFromUnitVectors(new THREE.Vector3(0, 1, 0).applyQuaternion(dtextRotation), alignUp).multiply(dtextRotation));
                txtObj.setRotationFromQuaternion(dtextRotation);

                txtObj.position.copy(start2).add(end2).multiplyScalar(0.5);

                txtObj.onLoad((txObj) => {
                    var textWidth = txObj.getWidth();

                    if (textWidth > (seLength - (this._arrowLength * 3))) {
                        var txOff = alignUp.clone().multiplyScalar(this._arrowLength * 0.5);
                        if (textVerticalAlignment > 0.5)
                            txOff.negate();
                        txObj.position.add(txOff);

                        var mp = start2.clone().add(end2).multiplyScalar(0.5);

                        linesGeo.vertices[0].set(start.x, start.y, start.z);
                        linesGeo.vertices[1].set(start2.x, start2.y, start2.z);
                        linesGeo.vertices[2].set(start2.x, start2.y, start2.z);
                        linesGeo.vertices[3].set(mp.x, mp.y, mp.z);
                        linesGeo.vertices[4].set(mp.x, mp.y, mp.z);
                        linesGeo.vertices[5].set(end2.x, end2.y, end2.z);
                        linesGeo.vertices[6].set(end2.x, end2.y, end2.z);
                        linesGeo.vertices[7].set(end.x, end.y, end.z);
                    } else {

                        var tl = (seLength - textWidth) / 2;
                        var start3 = seDir.clone().multiplyScalar(tl).add(start2);
                        var end3 = seDir.clone().multiplyScalar(tl + textWidth).add(start2);

                        linesGeo.vertices[0].set(start.x, start.y, start.z);
                        linesGeo.vertices[1].set(start2.x, start2.y, start2.z);
                        linesGeo.vertices[2].set(start2.x, start2.y, start2.z);
                        linesGeo.vertices[3].set(start3.x, start3.y, start3.z);
                        linesGeo.vertices[4].set(end3.x, end3.y, end3.z);
                        linesGeo.vertices[5].set(end2.x, end2.y, end2.z);
                        linesGeo.vertices[6].set(end2.x, end2.y, end2.z);
                        linesGeo.vertices[7].set(end.x, end.y, end.z);
                    }

                    linesGeo.verticesNeedUpdate = true;
                    linesGeo.computeBoundingSphere();

                    var aor = dimensionOffsetDir.clone().multiplyScalar(this._arrowLength * this._arrowSlope);

                    var as2 = seDir.clone().multiplyScalar(this._arrowLength).add(start2).sub(aor);
                    var as3 = as2.clone().add(aor).add(aor);

                    var es2 = seDir.clone().multiplyScalar(-this._arrowLength).add(end2).add(aor);;
                    var es3 = es2.clone().sub(aor).sub(aor);

                    arrowsGeo.vertices[0].set(start2.x, start2.y, start2.z);
                    arrowsGeo.vertices[1].set(as2.x, as2.y, as2.z);
                    arrowsGeo.vertices[2].set(as3.x, as3.y, as3.z);

                    arrowsGeo.vertices[3].set(end2.x, end2.y, end2.z);
                    arrowsGeo.vertices[4].set(es2.x, es2.y, es2.z);
                    arrowsGeo.vertices[5].set(es3.x, es3.y, es3.z);

                    arrowsGeo.verticesNeedUpdate = true;
                    arrowsGeo.computeBoundingSphere();
                });

            } else {

                var textObject = new SDFTextObject(textText, textSize, color, fontName, this.isDynamic, new THREE.Vector2(0.5, textVerticalAlignment));

                var textRotation = new THREE.Quaternion().setFromUnitVectors(new THREE.Vector3(1, 0, 0), alignRight);

                textRotation.copy(new THREE.Quaternion().setFromUnitVectors(new THREE.Vector3(0, 1, 0).applyQuaternion(textRotation), alignUp).multiply(textRotation));
                textObject.setRotationFromQuaternion(textRotation);

                textObject.position.copy(start2).add(end2).multiplyScalar(0.5);

                textObject.onLoad((txObj) => {
                    this.add(txObj);

                    var textWidth = txObj.getWidth();
                    var zOff = this.zOffset;
                    var geometry = new THREE.BufferGeometry();

                    if (textWidth > (seLength - (this._arrowLength * 3))) {
                        var txOff = alignUp.clone().multiplyScalar(this._arrowLength * 0.5);
                        if (textVerticalAlignment > 0.5)
                            txOff.negate();
                        txObj.position.add(txOff);
                        if (start.distanceToSquared(start2) <= EPS) {
                            geometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array([
                                start2.x, start2.y, start2.z + zOff,
                                end2.x, end2.y, end2.z + zOff
                            ]), 3));

                            if (this.vertexColors) {
                                geometry.setAttribute('color', new THREE.BufferAttribute(new Float32Array([
                                    color.r, color.g, color.b,
                                    color.r, color.g, color.b
                                ]), 3));
                            }
                        } else {
                            geometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array([
                                start.x, start.y, start.z + zOff,
                                start2.x, start2.y, start2.z + zOff,

                                start2.x, start2.y, start2.z + zOff,
                                end2.x, end2.y, end2.z + zOff,

                                end2.x, end2.y, end2.z + zOff,
                                end.x, end.y, end.z + zOff
                            ]), 3));
                            if (this.vertexColors) {
                                geometry.setAttribute('color', new THREE.BufferAttribute(new Float32Array([
                                    color.r, color.g, color.b,
                                    color.r, color.g, color.b,

                                    color.r, color.g, color.b,
                                    color.r, color.g, color.b,

                                    color.r, color.g, color.b,
                                    color.r, color.g, color.b
                                ]), 3));
                            }
                        }
                    } else {

                        var tl = (seLength - textWidth) / 2;
                        var start3 = seDir.clone().multiplyScalar(tl).add(start2);
                        var end3 = seDir.clone().multiplyScalar(tl + textWidth).add(start2);

                        if (start.distanceToSquared(start2) <= EPS) {
                            geometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array([
                                start2.x, start2.y, start2.z + zOff,
                                start3.x, start3.y, start3.z + zOff,

                                end3.x, end3.y, end3.z + zOff,
                                end2.x, end2.y, end2.z + zOff
                            ]), 3));
                            if (this.vertexColors) {
                                geometry.setAttribute('color', new THREE.BufferAttribute(new Float32Array([
                                    color.r, color.g, color.b,
                                    color.r, color.g, color.b,

                                    color.r, color.g, color.b,
                                    color.r, color.g, color.b
                                ]), 3));
                            }
                        } else {
                            geometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array([
                                start.x, start.y, start.z + zOff,
                                start2.x, start2.y, start2.z + zOff,

                                start2.x, start2.y, start2.z + zOff,
                                start3.x, start3.y, start3.z + zOff,

                                end3.x, end3.y, end3.z + zOff,
                                end2.x, end2.y, end2.z + zOff,

                                end2.x, end2.y, end2.z + zOff,
                                end.x, end.y, end.z + zOff
                            ]), 3));
                            if (this.vertexColors) {
                                geometry.setAttribute('color', new THREE.BufferAttribute(new Float32Array([
                                    color.r, color.g, color.b,
                                    color.r, color.g, color.b,

                                    color.r, color.g, color.b,
                                    color.r, color.g, color.b,

                                    color.r, color.g, color.b,
                                    color.r, color.g, color.b,

                                    color.r, color.g, color.b,
                                    color.r, color.g, color.b
                                ]), 3));
                            }
                        }
                    }

                    var mesh = new THREE.LineSegments(geometry, this.getLineMaterial(color));

                    this.add(mesh);

                    //Arrows

                    var arrowGeometry = new THREE.BufferGeometry();

                    var aor = dimensionOffsetDir.clone().multiplyScalar(this._arrowLength * this._arrowSlope);

                    var as2 = seDir.clone().multiplyScalar(this._arrowLength).add(start2).sub(aor);
                    var as3 = as2.clone().add(aor).add(aor);

                    var es2 = seDir.clone().multiplyScalar(-this._arrowLength).add(end2).add(aor);;
                    var es3 = es2.clone().sub(aor).sub(aor);

                    arrowGeometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array([
                        start2.x, start2.y, start2.z + zOff,
                        as2.x, as2.y, as2.z + zOff,
                        as3.x, as3.y, as3.z + zOff,

                        end2.x, end2.y, end2.z + zOff,
                        es2.x, es2.y, es2.z + zOff,
                        es3.x, es3.y, es3.z + zOff
                    ]), 3));

                    if (this.vertexColors) {
                        arrowGeometry.setAttribute('color', new THREE.BufferAttribute(new Float32Array([
                            color.r, color.g, color.b,
                            color.r, color.g, color.b,
                            color.r, color.g, color.b,

                            color.r, color.g, color.b,
                            color.r, color.g, color.b,
                            color.r, color.g, color.b
                        ]), 3));
                    }

                    var arrowMesh = new THREE.Mesh(arrowGeometry, this.getArrowMaterial(color));

                    this.add(arrowMesh);
                });
            }
        }

        duplicate() {

        }

        rotateByPivot(pivot: THREE.Vector3, angleRad: number) { }

        getPivot(): THREE.Vector3 { return null; }

    }

    export class DimensionAlignedCollection extends THREE.Object3D {
        constructor(input: any[] | DimensionAligned[], fontName?: string) {
            super();

            if (!fontName)
                fontName = "Arial";

            if (!input || !input.length)
                return;

            var dimensions: DimensionAligned[];

            if (typeof input[0] === "object" && input[0] instanceof THREE.DimensionAligned) {
                dimensions = (<DimensionAligned[]>input).map(dim =>
                    new THREE.DimensionAligned(
                        dim.start,
                        dim.end,
                        dim.dimensionPosition,
                        dim.color,
                        dim.textSize,
                        fontName,
                        false,
                        true,
                        dim.text,
                        dim.isLinear,
                        dim.linearRotation)
                );
            } else {
                var data = input as any[];
                dimensions = [];

                var i = 0;
                if (data.length && data.length % 16 === 0 && typeof data[14] === "boolean") {
                    while (i < data.length) {
                        dimensions.push(new THREE.DimensionAligned(
                            new THREE.Vector3(data[i++], data[i++], data[i++]),
                            new THREE.Vector3(data[i++], data[i++], data[i++]),
                            new THREE.Vector3(data[i++], data[i++], data[i++]),
                            new THREE.Color(data[i++], data[i++], data[i++]),
                            data[i++],
                            fontName,
                            false,
                            true,
                            data[i++],
                            data[i++],
                            data[i++]));
                    }
                }
                else if (data.length % 14 === 0) {
                    while (i < data.length) {
                        dimensions.push(new THREE.DimensionAligned(
                            new THREE.Vector3(data[i++], data[i++], data[i++]),
                            new THREE.Vector3(data[i++], data[i++], data[i++]),
                            new THREE.Vector3(data[i++], data[i++], data[i++]),
                            new THREE.Color(data[i++], data[i++], data[i++]),
                            data[i++],
                            fontName,
                            false,
                            true,
                            data[i++]));
                    }
                }
            }

            if (!dimensions || !dimensions.length)
                return;

            setTimeout(() => {
                SDFFontMaterial.GetMetaData(fontName, (metaData) => {

                    var textobjects: SDFTextObject[] = [];
                    var lines: THREE.Line[] = [];
                    var meshes: THREE.Mesh[] = [];

                    dimensions.filter(dim => dim.visible).forEach(dim => {
                        var childs = dim.children;

                        for (var i = childs.length; i--;) {
                            var child = childs[i];
                            if (child instanceof SDFTextObject) {
                                textobjects.push(child);
                            } else if (child instanceof THREE.Line) {
                                lines.push(child);
                            } else if (child instanceof THREE.Mesh) {
                                meshes.push(child);
                            }
                        }
                    });

                    if (textobjects.length) {
                        this.add(new THREE.SDFTextObjectCollection(textobjects, fontName));
                    }

                    if (lines.length) {
                        var newLinesGeometry = spt.ThreeJs.utils.MergeBufferGeometries(lines.map(line => line.geometry as THREE.BufferGeometry));
                        this.add(new THREE.LineSegments(newLinesGeometry, DimensionAligned.lineMaterial as THREE.LineBasicMaterial));
                    }

                    if (meshes.length) {
                        var newMeshGeometry = spt.ThreeJs.utils.MergeBufferGeometries(meshes.map(mesh => mesh.geometry as THREE.BufferGeometry));
                        this.add(new THREE.Mesh(newMeshGeometry, DimensionAligned.arrowMaterial));
                    }

                    dimensions.forEach(d => d.dispose());

                });
            }, 0);
        }
    }

    export class DirectedAreaDefinition extends THREE.Object3D implements LS.Client3DEditor.ISelectableUi, SolarProTool.IDirectedAreaDefinition {

        static EdgeBottom = 0;
        static EdgeRight = 1;
        static EdgeTop = 2;
        static EdgeLeft = 3;

        IdString: string = null;

        private _box: THREE.Box3 = new THREE.Box3();
        private _origPosition = new THREE.Vector3();
        private _start = new THREE.Vector3();
        private _end = new THREE.Vector3();
        private _start2 = new THREE.Vector3();
        private _end2 = new THREE.Vector3();
        private _dimensionPosition = new THREE.Vector3();
        private _offsetVector = new THREE.Vector3();
        private _directionVector = new THREE.Vector3();
        private _lineColor = new THREE.Color(0xCC4700);
        private _areaColor = new THREE.Color(0xF77834);
        private _areaColorAlternate = new THREE.Color(0xD44A00);
        private _arrowColor = new THREE.Color(0xF7AB05);
        private _activeLineColor = new THREE.Color();
        private _activeAreaColor = new THREE.Color();
        private _activeAreaColorAlternate = new THREE.Color();
        private _activeArrowColor = new THREE.Color();
        private _userData: any = {};
        private _edges: THREE.Vector3[][];
        private _height = 0;
        private _length = 0;
        private _generated: THREE.Object3D[] = [];
        private _constructed: THREE.Object3D = null;
        private _alpha: number = 1;
        private _rasterYCounts: number[] = [1];
        private _rasterXLengths: number[] = [0];
        private _subRanges: number[] = [0];
        private _skipRebuild: boolean;

        private _disabledSubAreas: number[];
        private _disabledSubAreasNeedsUpdate;

        private _excludeInterval: number[][];
        private _includeInterval: number[][];

        flip: boolean = false;

        _isDisposed: boolean = false;

        isDynamic: boolean = false;
        private _isSelected: boolean = false;
        isHovered: boolean = false;
        withArrow: boolean = true;

        constructor(areaDefinition?: SolarProTool.IDirectedAreaDefinition) {
            super();

            this.copyFrom(areaDefinition);
        }

        addInterval(from: number, to: number, include?: boolean) {
            var v1 = Math.round(from),
                v2 = Math.round(to),
                start = Math.min(v1, v2),
                end = Math.max(v1, v2);

            if (include) {
                if (!this._includeInterval)
                    this._includeInterval = [];

                this._includeInterval.push([start, end]);
            } else {
                if (!this._excludeInterval)
                    this._excludeInterval = [];

                this._excludeInterval.push([start, end]);
            }
        }

        setIntervals(inters: number[][], include?: boolean) {
            if (include)
                this._includeInterval = inters || [];
            else
                this._excludeInterval = inters || [];

            this.rebuild();
        }

        setDisabledSubAreas(indices?: number[]) {
            if (indices && indices.length)
                this._disabledSubAreas = indices.slice();
            else
                this._disabledSubAreas = null;
            this._disabledSubAreasNeedsUpdate = true;

            this.rebuild();
        }

        addToDisabledSubAreas(index: number, toggle?: boolean) {
            if (!this._disabledSubAreas)
                this._disabledSubAreas = [];
            if (this._disabledSubAreas.some(idx => idx === index)) {
                if (toggle)
                    this._disabledSubAreas = this._disabledSubAreas.filter(idx => idx !== index);
            } else
                this._disabledSubAreas.push(index);
            this._disabledSubAreasNeedsUpdate = true;
        }

        appendSubRange(range?: number) {
            this._subRanges.push(range || 0);

            this.setSubrangesCount(this._subRanges.length);

            this.rebuild();
        }

        prependSubRange(range?: number) {
            this._subRanges.unshift(range || 0);

            this._rasterXLengths.unshift(0);

            this._rasterYCounts.unshift(1);

            this.rebuild();
        }

        removeEmptySubranges() {
            if (this._subRanges && this._subRanges.length) {

                this.setSubrangesCount(this._subRanges.length);

                var nSubranges: number[] = [];
                var nRasterXLengths: number[] = [];
                var nRasterYCounts: number[] = [];

                for (var i = 0, j = this._subRanges.length; i < j; i++) {
                    if (this._subRanges[i] > 0) {
                        nSubranges.push(this._subRanges[i]);
                        nRasterXLengths.push(this._rasterXLengths[i]);
                        nRasterYCounts.push(this._rasterYCounts[i]);
                    }
                }

                if (nSubranges.length !== this._subRanges.length) {
                    if (!nSubranges.length) {
                        this._subRanges = [0];
                        this._rasterXLengths = [0];
                        this._rasterYCounts = [1];


                    } else {
                        this._subRanges = nSubranges;
                        this._rasterXLengths = nRasterXLengths;
                        this._rasterYCounts = nRasterYCounts;
                    }

                    this.rebuild();
                }
            }
        }

        GetSimple(id?: string, offset?: THREE.Vector3): SolarProTool.IDirectedAreaDefinition {
            var da = this,
                s = da.start,
                e = da.end,
                p = da.start2;

            if (offset) {
                s = s.clone().add(offset);
                e = e.clone().add(offset);
                p = p.clone().add(offset);
            }

            return {
                IdString: id || da.IdString,
                //lineColor?: Color; // SolarCalcObjects.Gfx3D.THREE.Color
                //arrowColor?: Color; // SolarCalcObjects.Gfx3D.THREE.Color
                //areaColor?: Color; // SolarCalcObjects.Gfx3D.THREE.Color
                start: s,
                end: e,
                dimensionPosition: p,
                withArrow: false,
                flip: false,
                isDynamic: false,
                userData: null
            };
        }

        copyFrom(areaDefinition?: SolarProTool.IDirectedAreaDefinition) {

            this._skipRebuild = true;

            if (areaDefinition)
                Object.keys(areaDefinition).filter(k => this[k] !== undefined).forEach(k => {
                    this[k] = areaDefinition[k];
                });

            if (!this._userData._myDirectedArea) {
                Object.defineProperty(this._userData, '_myDirectedArea', { enumerable: false, configurable: true, writable: false, value: this });
                Object.defineProperty(this._userData, 'setParameter', { enumerable: false, configurable: true, writable: false, value: this.setParameter.bind(this) });
                Object.defineProperty(this._userData, 'getParameter', { enumerable: false, configurable: true, writable: false, value: this.getParameter.bind(this) });
            }

            if (!this.IdString)
                this.IdString = spt.Utils.GenerateGuid();

            this._skipRebuild = false;

            if (areaDefinition.ConstructedObject) {
                this.clearConstructed();
                var obj = spt.ThreeJs.utils.ConvertThreeJsObject(areaDefinition.ConstructedObject) as THREE.Object3D;
                if (obj) {
                    var opacity = this.alpha;
                    this.add(obj);
                    var ro = this.renderOrder + 1;
                    obj.traverse((o: any) => {
                        o.renderOrder = ro;
                        if (o.material) {
                            o.material.transparent = true;
                            o.material.opacity = opacity;
                        }
                    });
                    this._constructed = obj;
                }
            }

            this.rebuild();
        }

        genericUserParams: { [name: string]: { get: () => any, set: (val: any) => void } } = {
            "SubAreas": {
                get: () => {
                    if (this.subRanges && this.subRanges.length)
                        return this.subRanges.map(s => Math.round(s));
                    return [0];
                }, set: (size: number[]) => {
                    if (!ArrayHelper.arraysEqual(this.subRanges, size))
                        this.subRanges = size;
                }
            },
            "ParkingAreaCount": {
                get: () => {
                    if (this.rasterXCounts && this.rasterXCounts.length)
                        return this.rasterXCounts.map(xc => Math.round(xc * 10) / 10);
                    return [1];
                }, set: (count: number[]) => {
                    if (!ArrayHelper.arraysEqual(this.rasterXCounts, count))
                        this.rasterXCounts = count;
                }
            },
            "ParkingAreaSize": {
                get: () => {
                    if (this.rasterXLengths && this.rasterXLengths.length) {
                        return this.rasterXLengths.map(xl => Math.round(xl));
                    }
                    return [0];
                }, set: (size: number[]) => {
                    if (!ArrayHelper.arraysEqual(this.rasterXLengths, size))
                        this.rasterXLengths = size;
                }
            },
            "ParkingAreaRows": {
                get: () => {
                    if (this.rasterYCounts && this.rasterYCounts.length)
                        return this.rasterYCounts.map(yc => Math.round(yc));
                    return [1];
                }, set: (count: number[]) => {
                    if (!ArrayHelper.arraysEqual(this.rasterYCounts, count))
                        this.rasterYCounts = count;
                }
            },
            "DisabledSubAreas": {
                get: () => {
                    if (this._disabledSubAreasNeedsUpdate) {
                        this._disabledSubAreas.sort((a, b) => a - b);
                        this._disabledSubAreasNeedsUpdate = false;
                    }
                    return this._disabledSubAreas || [];
                }, set: (indices: number[]) => {
                    if (!this._disabledSubAreas || !ArrayHelper.arraysEqual(this._disabledSubAreas, indices))
                        this.setDisabledSubAreas(indices);
                }
            },
            "BaseStandInterferences": {
                get: () => {
                    if (this._excludeInterval && this._excludeInterval.length)
                        return this._excludeInterval.map(inter => `${inter[0]} - ${inter[1]}`);
                    return [];
                }, set: (intervals: string[]) => {
                    this.setIntervals(ArrayHelper.intervalsFromStringList(intervals), false);
                }
            },
            "BaseStandTargets": {
                get: () => {
                    if (this._includeInterval && this._includeInterval.length)
                        return this._includeInterval.map(inter => `${inter[0]} - ${inter[1]}`);
                    return [];
                }, set: (intervals: string[]) => {
                    this.setIntervals(ArrayHelper.intervalsFromStringList(intervals), true);
                }
            }
        };

        setGenericUserParam(k: string, val: any) {
            if (this.genericUserParams[k])
                this.genericUserParams[k].set(val);
        }

        getGenericUserParam(k: string, target?: any) {
            if (this.genericUserParams[k]) {
                var v = this.genericUserParams[k].get();
                if (target)
                    target[k] = v;
                return v;
            }
            return null;
        }

        get alpha() {
            return this._alpha;
        }

        set alpha(val: number) {
            if (this._alpha != val) {
                var obj = this._constructed;
                if (obj) {
                    obj.traverse((o: any) => {
                        if (o.material)
                            o.material.opacity = val;
                    });
                }

                this._alpha = val;
            }
        }

        get userData() {
            for (let k in this._userData)
                this.getGenericUserParam(k, this._userData);
            return this._userData;
        }

        set userData(value) {
            if (!this._userData) {
                this._userData = value;
            } else {
                var revertSkip = !this._skipRebuild;
                if (revertSkip)
                    this._skipRebuild = true;
                var usrData = this._userData;
                if (value && typeof value === "object") {
                    Object.keys(usrData).filter(k => value[k] === undefined).forEach(k => {
                        delete usrData[k];
                    });
                    Object.keys(value).forEach(k => {
                        usrData[k] = value[k];
                    });
                }
                for (let k in usrData) {
                    this.setGenericUserParam(k, usrData[k]);
                }
                if (revertSkip)
                    this._skipRebuild = false;
                this.rebuild();
            }
        }

        get subRanges() {
            return this._subRanges;
        }

        set subRanges(values: number[]) {
            if (values && values.length) {
                this._subRanges = values.map(value => {
                    var l = +value;
                    if (l > 0 && isFinite(l))
                        return l;
                    return 0;
                });
            } else
                this._subRanges = [0];

            if (!this._subRanges.length)
                this._subRanges = [0];

            this.setSubrangesCount(this._subRanges.length);

            this.rebuild();
        }

        getSubAreaIndexByPoint(p: THREE.Vector3): number {
            var tp = p.clone().sub(this.position),
                axis = new THREE.Vector3(1, 0, 0).applyEuler(this.rotation),
                xAxis = Math.max(0, Math.min(this.length, axis.dot(tp))),
                subRanges = this.subRanges,
                xOff = 0,
                indexOffset = 0;

            if (!subRanges || !subRanges.length)
                return -1;

            for (var i = 0, j = subRanges.length; i < j; i++) {
                var subRange = subRanges[i];

                var x = xAxis - xOff,
                    rasterXLength = this.rasterXLengths[i] > 0 ? this.rasterXLengths[i] : subRange,
                    rasterXCount = Math.floor(Math.max(1, subRange / rasterXLength)),
                    rasterYCount = Math.floor(Math.max(1, this.rasterYCounts[i]));

                if (x > subRange) {
                    xOff += subRange;
                    indexOffset += rasterXCount * rasterYCount;
                    continue;
                }

                axis.set(-axis.y, axis.x, 0);

                var height = this.height,
                    y = Math.max(0, Math.min(height, axis.dot(tp)));

                return indexOffset + Math.min(Math.floor(y * rasterYCount / height), rasterYCount - 1) * rasterXCount + Math.min(Math.floor(x / rasterXLength), rasterXCount - 1);
            }

            return -1;

            //var xCount = Math.floor(Math.max(1, area.rasterXCount)),
            //    lenStepX = area.rasterXLength > 0 ? area.rasterXLength : area.length,
            //    x = Math.max(area.rasterXOffset, Math.min(xCount * lenStepX + area.rasterXOffset, axis.dot(tp))) - area.rasterXOffset;

            //axis.set(-axis.y, axis.x, 0);

            //var y = Math.max(0, Math.min(area.height, axis.dot(tp))),
            //    yCount = area.rasterYCount > 1 ? area.rasterYCount : 1;

            //return Math.min(Math.floor(y * yCount / area.height), yCount - 1) * xCount + Math.min(Math.floor(x / lenStepX), xCount - 1);
        }

        setParameter(name: string, oldValue: any, newValue: any) {
            switch (name) {
                case "X":
                    {
                        let t = (+newValue) - (+oldValue);
                        if (t != 0)
                            this.moveBy(new THREE.Vector3(t, 0, 0));
                        return;
                    }
                case "Y":
                    {
                        let t = (+newValue) - (+oldValue);
                        if (t != 0)
                            this.moveBy(new THREE.Vector3(0, t, 0));
                        return;
                    }
                case "Z":
                    {
                        let t = (+newValue) - (+oldValue);
                        if (t != 0)
                            this.moveBy(new THREE.Vector3(0, 0, t));
                        return;
                    }
                case "Rotation":
                    {
                        this.setRotation(newValue);
                        return;
                    }
                case "Height":
                    {
                        this.setHeight(newValue);
                        return;
                    }
                case "Width":
                    {
                        this.setWidth(newValue);
                        return;
                    }
            }
            this.setGenericUserParam(name, newValue);
        }

        setRotation(newValue: number) {
            this.angle = +newValue;
        }

        setHeight(v: number) {
            var h = +v;
            if (h > 0)
                this.dimensionPosition = this.start2.clone().sub(this.start).normalize().multiplyScalar(h).add(this.start);
        }

        setWidth(v: number) {
            var w = +v;
            if (w > 0)
                this.end = this.end.clone().sub(this.start).normalize().multiplyScalar(w).add(this.start);
        }

        getParameter(name: string): any {
            switch (name) {
                case "X":
                    return Math.round(this.start.x);
                case "Y":
                    return Math.round(this.start.y);
                case "Z":
                    return Math.round(this.start.z);
                case "Width":
                    return Math.round(this.length);
                case "Height":
                    return Math.round(this.height);
                case "Rotation":
                    return Math.round(this.angle * 100) / 100;
                default:
                    if (this[name] !== undefined)
                        return this[name];
            }
            return null;
        }

        applyFlip() {
            if (!this.flip)
                return;
            this._start.add(this.offsetVector);
            this._end.add(this.offsetVector);
            this._dimensionPosition.sub(this.offsetVector);
            this.flip = false;
            this.rebuild();
        }

        setLeft(p: THREE.Vector3, setSubRange: boolean) {
            if (this.length > 0 && this.height > 0) {

                var dir = this.directionVector.clone().normalize();

                if (setSubRange && this.subRanges && this.subRanges.length > 1) {
                    let e = new THREE.Vector3().copy(dir).multiplyScalar(this.subRanges[0]).add(this.start),
                        ep = new THREE.Vector3().copy(p).sub(e);
                    dir.negate();
                    let newLength = Math.round(dir.dot(ep));

                    if (newLength >= 10) {
                        this.subRanges[0] = newLength;
                        this._start.copy(e).add(dir.multiplyScalar(newLength));
                        this.rebuild();
                    }
                } else {
                    dir.negate();
                    let ep = new THREE.Vector3().copy(p).sub(this.end),
                        newLength = Math.round(dir.dot(ep));

                    if (newLength >= 10) {
                        this._start.copy(this.end).add(dir.multiplyScalar(newLength));
                        this.rebuild();
                    }
                }
            }
        }

        setRight(p: THREE.Vector3, setSubRange: boolean) {
            if (this.length > 0 && this.height > 0) {

                var dir = this.directionVector.clone().normalize();

                if (setSubRange && this.subRanges && this.subRanges.length > 1) {
                    let ls = this.subRanges.reduce((pv, cv) => pv + cv) - this.subRanges[this.subRanges.length - 1];
                    let s = new THREE.Vector3().copy(dir).multiplyScalar(ls).add(this.start),
                        sp = new THREE.Vector3().copy(p).sub(s),
                        newLength = Math.round(dir.dot(sp));

                    if (newLength >= 10) {
                        this.subRanges[this.subRanges.length - 1] = newLength;
                        this._end.copy(s).add(dir.multiplyScalar(newLength));
                        this.rebuild();
                    }
                } else {
                    let sp = new THREE.Vector3().copy(p).sub(this.start),
                        newLength = dir.dot(sp);

                    if (newLength >= 10) {
                        this._end.copy(this.start).add(dir.multiplyScalar(newLength));
                        this.rebuild();
                    }
                }
            }
        }

        setBottom(p: THREE.Vector3) {
            if (this.length > 0 && this.height > 0) {
                var d = new THREE.Vector3().copy(p).sub(this.start2);
                var dir = this.offsetVector.clone().negate().normalize();
                var newLength = dir.dot(d);
                if (newLength >= 10) {
                    dir.multiplyScalar(newLength);
                    this._start.copy(this.start2).add(dir);
                    this._end.copy(this.end2).add(dir);
                    this.rebuild();
                }
            }
        }

        setTop(p: THREE.Vector3) {
            this._dimensionPosition.copy(p);
            this.rebuild();
        }

        setPositions(start?: THREE.Vector3, end?: THREE.Vector3, pos?: THREE.Vector3) {
            if (start)
                this._start.copy(start);
            if (end)
                this._end.copy(end);
            if (pos)
                this._dimensionPosition.copy(pos);

            this.rebuild();
        }

        get isSelected(): boolean {
            return this._isSelected;
        }

        set isSelected(value: boolean) {
            var b = !!value;
            if (this._isSelected !== b) {
                this._isSelected = b;
                if (b)
                    LS.Client3DEditor.Controller.Current.viewModel.genericParameters.bindObject(this.userData);
                else
                    LS.Client3DEditor.Controller.Current.viewModel.genericParameters.unbindObject(this.userData);
            }
        }

        public static materialInstances: { [name: string]: THREE.Material } = {};

        get BoundingBox() {
            return this._box;
        }

        get rasterYCounts() {
            return this._rasterYCounts;
        }

        set rasterYCounts(values: number[]) {
            if (values && values.length) {
                this._rasterYCounts = values.map(value => {
                    var l = +value;
                    if (l > 1 && isFinite(l))
                        return l;
                    return 1;
                });
            } else
                this._rasterYCounts = [1];

            this.setSubrangesCount(this._rasterYCounts.length);

            this.rebuild();
        }

        get rasterXCounts() {
            if (this.subRanges && this.subRanges.length)
                return this.subRanges.map((len, i) => {
                    var rxlen = this._rasterXLengths[i];
                    if (rxlen > 0)
                        return Math.round(100 * len / rxlen) / 100;
                    return 0;
                });
            return [0];
        }

        set rasterXCounts(values: number[]) {
            if (this.subRanges && this.subRanges.length && values && values.length === this.subRanges.length) {
                this.rasterXLengths = values.map((value, i) => {
                    var c = +value;
                    if (c > 0)
                        return Math.round(this.subRanges[i] / c);
                    else
                        return 0;
                });
            } else
                this.rasterXLengths = [0];
        }

        get rasterXLengths() {
            return this._rasterXLengths;
        }

        set rasterXLengths(values: number[]) {
            if (values && values.length) {
                this._rasterXLengths = values.map(value => {
                    var l = +value;
                    if (l > 0 && isFinite(l))
                        return l;
                    return 0;
                });
            } else
                this._rasterXLengths = [0];

            if (!this._rasterXLengths.length)
                this._rasterXLengths = [0];

            this.setSubrangesCount(this._rasterXLengths.length);

            this.rebuild();
        }

        setSubrangesCount(count: number) {
            if (count <= 0)
                count = 1;

            if (this._subRanges.length !== count) {
                let l = this._subRanges.length,
                    del = Math.max(0, l - count),
                    add = Math.max(count - l);
                this._subRanges.splice.apply(this._subRanges, ([l - del, del] as any[]).concat(ArrayHelper.fillArray(0, add)));
            }

            if (this._rasterXLengths.length !== count) {
                let l = this._rasterXLengths.length,
                    del = Math.max(0, l - count),
                    add = Math.max(count - l);
                this._rasterXLengths.splice.apply(this._rasterXLengths, ([l - del, del] as any[]).concat(ArrayHelper.fillArray(0, add)));
            }

            if (this._rasterYCounts.length !== count) {
                let l = this._rasterYCounts.length,
                    del = Math.max(0, l - count),
                    add = Math.max(count - l);
                this._rasterYCounts.splice.apply(this._rasterYCounts, ([l - del, del] as any[]).concat(ArrayHelper.fillArray(1, add)));
            }
        }

        public static getLineMaterial(color: THREE.Color, useVertexColors?: boolean) {
            if (!useVertexColors) {
                var material: THREE.Material;
                var lineMatKey = "Line_" + color.getHexString();

                if (!DimensionAligned.materialInstances[lineMatKey]) {
                    material = new THREE.LineBasicMaterial({
                        color: color as any,
                        fog: false,
                        depthWrite: false,
                        depthTest: false
                    });
                    DimensionAligned.materialInstances[lineMatKey] = material;
                } else {
                    material = DimensionAligned.materialInstances[lineMatKey];
                }

                return material as THREE.LineBasicMaterial;
            }
            if (!DimensionAligned.lineMaterial) {
                DimensionAligned.lineMaterial = new THREE.LineBasicMaterial({
                    vertexColors: true, //THREE.VertexColors,
                    fog: false
                });
            }
            return DimensionAligned.lineMaterial as THREE.LineBasicMaterial;
        }

        public static getMeshMaterial(color: THREE.Color, opacity: number) {
            var material: THREE.Material;
            var matKey = "C_" + color.getHexString() + "_O" + opacity;

            if (!DirectedAreaDefinition.materialInstances[matKey]) {
                material = new THREE.MeshBasicMaterial({
                    color: color as any,
                    fog: false,
                    transparent: opacity < 1,
                    opacity: opacity,
                    depthTest: false,
                    depthWrite: false,
                    side: THREE.DoubleSide,

                });
                DirectedAreaDefinition.materialInstances[matKey] = material;
            } else {
                material = DirectedAreaDefinition.materialInstances[matKey];
            }

            return material as THREE.LineBasicMaterial;
        }

        get start() {
            return this._start;
        }

        set start(value: THREE.Vector3) {
            this._start.copy(value);
            this.rebuild();
        }

        get end2() {
            return this._end2;
        }

        get start2() {
            return this._start2;
        }

        get end() {
            return this._end;
        }

        set end(value: THREE.Vector3) {
            this._end.copy(value);
            this.rebuild();
        }

        get dimensionPosition() {
            return this._dimensionPosition;
        }

        set dimensionPosition(value: THREE.Vector3) {
            this._dimensionPosition.copy(value);
            this.rebuild();
        }

        get direction() {
            return this._end.clone().sub(this._start);
        }

        set direction(v: THREE.Vector3) {
            if (v && (v.x != 0 || v.y != 0)) {
                this.angle = Math.atan2(v.y, v.x) / Math.PI * 180;
            }
        }

        get angle() {
            //var dir = this.direction;
            //return Math.atan2(dir.y, dir.x) / Math.PI * 180;
            return this.rotation.z / Math.PI * 180;
        }

        set angle(angleGrad: number) {
            //var dir = this.direction;
            this.rotateBy((angleGrad / 180 * Math.PI) - this.rotation.z);
        }

        rotateBy(angleRad: number) {
            this.rotateByPivot(this.getPivot(), angleRad);
        }

        rotateByPivot(pivot: THREE.Vector3, angleRad: number) {
            if (angleRad != 0) {
                var m = new THREE.Matrix4().makeTranslation(pivot.x, pivot.y, pivot.z).multiply(new THREE.Matrix4().makeRotationZ(angleRad).multiply(new THREE.Matrix4().makeTranslation(-pivot.x, -pivot.y, -pivot.z)));
                this._start.applyMatrix4(m);
                this._end.applyMatrix4(m);
                this._dimensionPosition.applyMatrix4(m);
                this.rebuild();
            }
        }

        getPivot(): THREE.Vector3 {
            return this._start.clone().add(this._end).add(this._end2).add(this._start2).divideScalar(4);
        }

        get length() {
            if (!this._length)
                this._length = this._start.distanceTo(this._end);
            return this._length;
        }

        get height() {
            return this._height;
        }

        get hasHeight() {
            return this._height > 0;
        }

        get activeOpacity() {
            var op = 0.5;

            if (this.isHovered)
                op *= 1.2;
            if (this.isSelected)
                op *= 1.2;

            return Math.min(op, 1);
        }

        get activeArrowColor() {
            this._activeArrowColor.copy(this.arrowColor);
            if (this.isHovered)
                this._activeArrowColor.multiplyScalar(1.2);
            if (this.isSelected)
                this._activeArrowColor.multiplyScalar(1.4);
            return this._activeArrowColor;
        }

        get activeLineColor() {
            this._activeLineColor.copy(this.lineColor);
            if (this.isHovered)
                this._activeLineColor.multiplyScalar(1.2);
            if (this.isSelected)
                this._activeLineColor.multiplyScalar(1.4);
            return this._activeLineColor;
        }

        get activeAreaColor() {
            this._activeAreaColor.copy(this.areaColor);
            if (this.isHovered)
                this._activeAreaColor.multiplyScalar(1.2);
            if (this.isSelected)
                this._activeAreaColor.multiplyScalar(1.4);
            return this._activeAreaColor;
        }

        get activeAreaColorAlternate() {
            this._activeAreaColorAlternate.copy(this.areaColorAlternate);
            if (this.isHovered)
                this._activeAreaColorAlternate.multiplyScalar(1.2);
            if (this.isSelected)
                this._activeAreaColorAlternate.multiplyScalar(1.4);
            return this._activeAreaColorAlternate;
        }

        get arrowColor() {
            return this._arrowColor;
        }

        set arrowColor(value: THREE.Color) {
            this._arrowColor.copy(value);
            this.OnChanged();
        }

        get lineColor() {
            return this._lineColor;
        }

        set lineColor(value: THREE.Color) {
            this._lineColor.copy(value);
            this.OnChanged();
        }

        get areaColor() {
            return this._areaColor;
        }

        set areaColor(value: THREE.Color) {
            this._areaColor.copy(value);
            this.OnChanged();
        }

        get areaColorAlternate() {
            return this._areaColorAlternate;
        }

        set areaColorAlternate(value: THREE.Color) {
            this._areaColorAlternate.copy(value);
            this.OnChanged();
        }

        get edges() {
            return this._edges;
        }

        get offsetVector() {
            return this._offsetVector;
        }

        get directionVector() {
            return this._directionVector;
        }

        intersecsBox(b: THREE.Box3) {
            return this.BoundingBox.intersectsBox(b) && (
                b.containsBox(this.BoundingBox) ||
                this.edges.some(e => utils.LineIntersecsBox(e[0], e[1], b)) ||
                this.edges.every(e => utils.pointOnLeftSideOfLine(e[0], e[1], b.min) >= 0));
        }

        intersecsTestVolume(testVolume: spt.ThreeJs.utils.TestVolume) {
            return !testVolume.testBoxForSeparationAxis(this.BoundingBox) && !testVolume.testEdgesForSeparationAxis(this.edges);
        }

        applyObjectPosition() {
            this.moveBy(this.position.sub(this._origPosition));
        }

        OnChanged(viewModel?: LS.Client3DEditor.ViewModel) {
            var controller = LS.Client3DEditor.Controller.Current;
            if (controller.viewModel.isDragging) {
                this.position.copy(controller.viewModel.diffSnapPosition).add(this._origPosition);
            } else {
                //color changed. No rebuild needed
                var lineColor = this.activeLineColor,
                    areaColor = this.activeAreaColor,
                    areaColorAlternate = this.activeAreaColorAlternate,
                    arrowColor = this.activeArrowColor,
                    opacity = this.activeOpacity,
                    childs = this._generated;

                if (childs.length) {
                    for (var i = childs.length; i--;) {
                        var child = childs[i];
                        if (child instanceof THREE.Mesh) {
                            switch (child.name) {
                                case "lines":
                                    child.material = DirectedAreaDefinition.getLineMaterial(lineColor, false);
                                    break;
                                case "arrow":
                                    child.material = DirectedAreaDefinition.getMeshMaterial(arrowColor, opacity * 0.7);
                                    break;
                                case "arrowLine":
                                    child.material = DirectedAreaDefinition.getLineMaterial(arrowColor, false);
                                    break;
                                case "alternate":
                                    child.material = DirectedAreaDefinition.getMeshMaterial(areaColorAlternate, opacity);
                                    break;
                                case "area":
                                    child.material = DirectedAreaDefinition.getMeshMaterial(areaColor, opacity);
                                    break;
                                default:
                                    break;
                            }
                        }
                    }
                }
            }
        }

        addGenerated(obj: THREE.Object3D) {
            this._generated.push(obj);
            this.add(obj);
        }

        clearGenerated() {
            if (!this._generated.length)
                return;

            for (var k = this._generated.length; k--;) {
                var obj = this._generated[k];
                this.remove(obj);
                spt.ThreeJs.utils.disposeObject3D(obj, true, true);
            }

            this._generated = [];
        }

        clearConstructed() {

            if (!this._constructed)
                return;

            this.remove(this._constructed);

            spt.ThreeJs.utils.disposeObject3D(this._constructed, false, true);

            this._constructed = null;
        }

        getSubAreaByIndex(index: number, local?: boolean, includeDisabled?: boolean): THREE.Vector3[] {
            if (index === undefined || index === null || index < 0)
                return null;

            var subRanges = this.subRanges,
                disabledSubAreas = this._disabledSubAreas,
                height = this.height,
                xOff = 0,
                curIndex = 0;

            if (height <= 0 || (disabledSubAreas && disabledSubAreas.length && !includeDisabled && disabledSubAreas.some(idx => idx === index)))
                return null;

            if (subRanges && subRanges.length) {
                for (var idx = 0, k = subRanges.length; idx < k; idx++) {
                    let width = subRanges[idx];

                    if (width <= 0) {
                        xOff += Math.max(0, width);
                        continue;
                    }

                    let lenStepX = this.rasterXLengths[idx] || width,
                        yCount = Math.max(1, this.rasterYCounts[idx]),
                        xCount = Math.floor(width / lenStepX),
                        localCount = xCount * yCount;

                    if (curIndex + localCount <= index) {
                        curIndex += localCount;
                        xOff += width;
                        continue;
                    }

                    let lenStepY = height / yCount,
                        localIndex = index - curIndex,
                        row = Math.floor(localIndex / xCount),
                        column = localIndex % xCount,
                        y1 = row * lenStepY,
                        y2 = y1 + lenStepY,
                        x1 = column * lenStepX,
                        x2 = x1 + lenStepX,
                        vs = [
                            new THREE.Vector3(xOff + x1, y1, 0),
                            new THREE.Vector3(xOff + x2, y1, 0),
                            new THREE.Vector3(xOff + x2, y2, 0),
                            new THREE.Vector3(xOff + x1, y2, 0)
                        ];

                    return local ? vs : vs.map(v => v.applyMatrix4(this.matrixWorld));
                }
            }

            return null;
        }

        countAllSubareas(includeDisabled?: boolean): number {

            var subRanges = this.subRanges,
                height = this.height,
                count = 0;

            if (height <= 0)
                return 0;

            if (subRanges && subRanges.length) {
                for (var idx = 0, k = subRanges.length; idx < k; idx++) {
                    let width = subRanges[idx];

                    if (width <= 0)
                        continue;

                    let lenStepX = this.rasterXLengths[idx] || width,
                        yCount = Math.max(1, this.rasterYCounts[idx]),
                        xCount = Math.floor(width / lenStepX);

                    count += xCount * yCount;
                }
            }

            if (this._disabledSubAreas && this._disabledSubAreas.length && !includeDisabled)
                count -= this._disabledSubAreas.filter(d => d < count).length;

            return count;
        }

        getAllSubareas(includeDisabled?: boolean): THREE.Vector3[][][] {
            var result: THREE.Vector3[][][] = [],
                subRanges = this.subRanges,
                disabledSubAreas = this._disabledSubAreas,
                height = this.height,
                curSubIndex = 0,
                curDisabledIndex = 0,
                nextDisabledIndex = disabledSubAreas && disabledSubAreas.length && !includeDisabled ? disabledSubAreas[curDisabledIndex++] : -1,
                xOff = 0;

            if (subRanges && subRanges.length) {
                subRanges.forEach((range, idx) => {

                    var width = range,
                        rasterXLength = this.rasterXLengths[idx],
                        rasterYCount = this.rasterYCounts[idx],
                        res: THREE.Vector3[][] = [];

                    result.push(res);

                    if (height <= 0 || width <= 0) {
                        xOff += Math.max(0, width);
                        return;
                    }

                    let lenStepX = rasterXLength > 0 ? rasterXLength : width,
                        yCount = Math.max(1, rasterYCount),
                        lenStepY = height / yCount,
                        xCount = Math.floor(Math.round(100 * width / lenStepX) / 100);

                    for (let i = 0; i < yCount; i++) {
                        let y1 = i * lenStepY,
                            y2 = y1 + lenStepY;

                        for (var j = 0; j < xCount; j++) {
                            if (curSubIndex !== nextDisabledIndex) {
                                let x1 = j * lenStepX,
                                    x2 = x1 + lenStepX;

                                res.push([
                                    new THREE.Vector3(xOff + x1, y1, 0),
                                    new THREE.Vector3(xOff + x2, y1, 0),
                                    new THREE.Vector3(xOff + x2, y2, 0),
                                    new THREE.Vector3(xOff + x1, y2, 0)
                                ]);

                            } else {
                                res.push(null);

                                if (curDisabledIndex < disabledSubAreas.length)
                                    nextDisabledIndex = disabledSubAreas[curDisabledIndex++];
                            }

                            curSubIndex++;
                        }
                    }

                    xOff += width;
                });
            }

            return result;
        }

        rebuild() {
            if (this._skipRebuild)
                return;

            if (utils.pointOnLeftSideOfLine(this._start, this._end, this._dimensionPosition) < 0) {
                let tmp = this._start.clone();
                this._start.copy(this._end);
                this._end.copy(tmp);
            }

            var start = this._start,
                end = this._end,
                start2 = this._start2.copy(start),
                end2 = this._end2.copy(end),
                se = this._directionVector.copy(end).sub(start).setZ(0),
                p = this._dimensionPosition,
                sp = p.clone().sub(start),
                seLengthSq = se.lengthSq(),
                lineColor = this.activeLineColor,
                areaColor = this.activeAreaColor,
                areaColorAlternate = this.activeAreaColorAlternate,
                arrowColor = this.activeArrowColor,
                opacity = this.activeOpacity,
                arrowLength = 0,
                edges = this._edges = [],
                subRanges = this._subRanges;

            this._offsetVector.set(0, 0, 0);

            if (!subRanges || !subRanges.length)
                subRanges = this._subRanges = [0];

            this._height = 0;
            this._length = 0;

            this.clearGenerated();

            this.position.copy(start);
            this._origPosition.copy(start);

            var indicatorSize = 500;
            var indicatorSize2 = 700;

            var linesPoints: THREE.Vector3[] = [
                new THREE.Vector3(-indicatorSize2, 0, 0), new THREE.Vector3(0, 0, 0),
                new THREE.Vector3(0, -indicatorSize2, 0), new THREE.Vector3(0, 0, 0),
                new THREE.Vector3(indicatorSize, 0, 0), new THREE.Vector3(0, indicatorSize, 0),
                new THREE.Vector3(0, indicatorSize, 0), new THREE.Vector3(-indicatorSize, 0, 0),
                new THREE.Vector3(-indicatorSize, 0, 0), new THREE.Vector3(0, -indicatorSize, 0),
                new THREE.Vector3(0, -indicatorSize, 0), new THREE.Vector3(indicatorSize, 0, 0)
            ];

            if (seLengthSq < 1) {
                this.rotation.z = 0;

                edges.push(
                    [start, end],
                    [end, end2],
                    [end2, start2],
                    [start2, start]
                );

                return;
            }

            var seLength = Math.sqrt(seLengthSq),
                seDir = se.clone().divideScalar(seLength),
                seLengthRounded = Math.round(seLength);

            if (subRanges.length > 1) {
                var sum = subRanges.reduce((pv, cv) => pv + cv);
                if (sum !== seLengthRounded) {
                    var newRanges = [] as number[],
                        curLen = 0;
                    for (var o = 0, q = subRanges.length; o < q; o++) {
                        var range = subRanges[o];
                        if (range > 0 && (curLen + range) > seLengthRounded)
                            range = seLengthRounded - curLen;
                        newRanges.push(Math.max(0, range));
                        curLen += range;
                    }
                    subRanges = this._subRanges = newRanges;
                }
            } else
                subRanges = this._subRanges = [seLengthRounded];

            if (seLength !== seLengthRounded) {
                se.multiplyScalar(seLengthRounded / seLength);
                end.copy(start).add(se);
                end2.copy(end);
                seLength = seLengthRounded;
                seLengthSq = seLength * seLength;
            }

            this._length = seLength;

            this._box.makeEmpty().expandByPoint(start).expandByPoint(end);

            var dimensionOffset = this._offsetVector.copy(se).multiplyScalar(sp.dot(se) / seLengthSq).add(start).sub(p).negate(),
                dimensionOffsetLengthSq = dimensionOffset.lengthSq(),
                dimensionOffsetLength = 0;

            //var linesPoints: THREE.Vector3[] = [start, end];
            linesPoints.push(new THREE.Vector3(0, 0, 0), new THREE.Vector3(seLength, 0, 0));
            var meshPoints: THREE.Vector3[][] = null;

            var dimensionOffsetDir: THREE.Vector3 = new THREE.Vector3(-seDir.y, seDir.x, 0);

            if (dimensionOffsetLengthSq >= 1) {

                dimensionOffsetLength = Math.sqrt(dimensionOffsetLengthSq);
                var dimensionOffsetLengthRounded = Math.round(dimensionOffsetLength);

                if (dimensionOffsetLengthRounded !== dimensionOffsetLength) {
                    dimensionOffset.multiplyScalar(dimensionOffsetLengthRounded / dimensionOffsetLength);
                    dimensionOffsetLength = dimensionOffsetLengthRounded;
                }

                if (dimensionOffsetDir.dot(dimensionOffset) < 0) {
                    dimensionOffsetDir.negate();
                    this.position.copy(end);
                    this._origPosition.copy(end);

                    //edges.push(
                    //    [end, start],
                    //    [start, start2],
                    //    [start2, end2],
                    //    [end2, end]
                    //);
                } else {
                    //edges.push(
                    //    [start, end],
                    //    [end, end2],
                    //    [end2, start2],
                    //    [start2, start]
                    //);
                }

                edges.push(
                    [start, end],
                    [end, end2],
                    [end2, start2],
                    [start2, start]
                );

                arrowLength = Math.min(dimensionOffsetLength, seLength);

                //dimensionOffsetDir = dimensionOffset.clone().divideScalar(dimensionOffsetLength);

                start2.add(dimensionOffset);
                end2.add(dimensionOffset);

                this._box.expandByPoint(start2);
                this._box.expandByPoint(end2);

                this._height = dimensionOffsetLength;

                var s = new THREE.Vector3(0, 0, 0),
                    e = new THREE.Vector3(seLength, 0, 0),
                    s2 = new THREE.Vector3(0, dimensionOffsetLength, 0),
                    e2 = new THREE.Vector3(seLength, dimensionOffsetLength, 0);

                //linesPoints.push(end, end2, end2, start2, start2, start);
                linesPoints.push(e, e2, e2, s2, s2, s);

                if (this._disabledSubAreasNeedsUpdate) {
                    if (this._disabledSubAreas)
                        this._disabledSubAreas.sort((a, b) => a - b);
                    this._disabledSubAreasNeedsUpdate = false;
                }

                //var disabledSubAreas = this._disabledSubAreas;

                //var lastSubRangeIndex = subRanges.length - 1;
                //var lastSubRange = subRanges[lastSubRangeIndex];
                //var lastLenStepX = this.rasterXLengths && this.rasterXLengths.length > lastSubRangeIndex && this.rasterXLengths[lastSubRangeIndex];
                //var tx1 = 0;
                //var tx2 = lastLenStepX > 0 ? lastSubRange + (Math.floor(lastSubRange / lastLenStepX) * lastLenStepX) : seLength;
                var xoff = 0;

                for (var i = 0, j = subRanges.length; i < j; i++) {
                    var subRange = subRanges[i];

                    if (subRange <= 0)
                        continue;

                    let lenStepX = this.rasterXLengths[i];
                    let rasterYCount = this.rasterYCounts[i];
                    let lenStepY = dimensionOffsetLength / rasterYCount;

                    if (i > 0)
                        linesPoints.push(new THREE.Vector3(xoff, 0, 0), new THREE.Vector3(xoff, dimensionOffsetLength, 0));

                    //add raster lines
                    if (lenStepX > 0) {
                        for (var lx = lenStepX; lx < subRange; lx += lenStepX) {
                            let tx = xoff + lx;
                            linesPoints.push(new THREE.Vector3(tx, 0, 0), new THREE.Vector3(tx, dimensionOffsetLength, 0));
                        }
                    }

                    if (rasterYCount > 1) {
                        for (var ly = lenStepY; ly < dimensionOffsetLength; ly += lenStepY) {
                            linesPoints.push(new THREE.Vector3(xoff, ly, 0), new THREE.Vector3(xoff + subRange, ly, 0));
                        }
                    }

                    xoff += subRange;
                }
                if (xoff > 0 && xoff < seLength) {
                    linesPoints.push(new THREE.Vector3(xoff, 0, 0), new THREE.Vector3(xoff, dimensionOffsetLength, 0));
                }

                meshPoints = [[], []];

                var subAreaPoints = this.getAllSubareas().filter(subAreaPts => subAreaPts && subAreaPts.length > 0);

                for (var r = 0, t = subAreaPoints.length; r < t; r++) {
                    var subArea = subAreaPoints[r],
                        mps = meshPoints[r % 2];

                    for (var u = 0, v = subArea.length; u < v; u++) {
                        var pts = subArea[u];
                        if (pts)
                            mps.push(
                                pts[0],
                                pts[1],
                                pts[2],
                                pts[0],
                                pts[2],
                                pts[3]
                            );
                    }
                }
            } else {
                edges.push(
                    [start, end],
                    [end, end2],
                    [end2, start2],
                    [start2, start]
                );
            }

            this.rotation.z = -Math.atan2(dimensionOffsetDir.x, dimensionOffsetDir.y);

            if (this.isDynamic) {
                var linesGeo = new THREE.Geometry();

                linesGeo.vertices = linesPoints;
                let ls = new THREE.LineSegments(linesGeo, DirectedAreaDefinition.getLineMaterial(lineColor, false));
                ls.name = "lines";
                this.addGenerated(ls);
            } else {
                var linesBufferGeo = new THREE.BufferGeometry();
                var lpositions = new Float32Array(linesPoints.length * 3);

                for (var k = 0, l = linesPoints.length; k < l; k++) {
                    var pt = linesPoints[k];
                    var li = k * 3;
                    lpositions[li + 0] = pt.x;
                    lpositions[li + 1] = pt.y;
                    lpositions[li + 2] = pt.z;
                }

                linesBufferGeo.setAttribute('position', new THREE.BufferAttribute(lpositions, 3));
                let ls = new THREE.LineSegments(linesBufferGeo, DirectedAreaDefinition.getLineMaterial(lineColor, false));
                ls.name = "lines";
                this.addGenerated(ls);
            }

            if (meshPoints) {
                meshPoints.forEach((mps, i) => {
                    let meshBufferGeo = new THREE.BufferGeometry();

                    let mpositions = new Float32Array(mps.length * 3);

                    for (let m = 0, n = mps.length; m < n; m++) {
                        var mp = mps[m];
                        var mpi = m * 3;

                        mpositions[mpi + 0] = mp.x;
                        mpositions[mpi + 1] = mp.y;
                        mpositions[mpi + 2] = mp.z;
                    }

                    meshBufferGeo.setAttribute('position', new THREE.BufferAttribute(mpositions, 3));

                    let ms = new THREE.Mesh(this.isDynamic ? new THREE.Geometry().fromBufferGeometry(meshBufferGeo) : meshBufferGeo, DirectedAreaDefinition.getMeshMaterial(i > 0 ? areaColorAlternate : areaColor, opacity));
                    ms.name = i > 0 ? "alternate" : "area";
                    this.addGenerated(ms);
                });
            }

            var excludeInterval = this._excludeInterval,
                includeInterval = this._includeInterval;

            if (excludeInterval && excludeInterval.length && dimensionOffsetLength > 0 && seLength > 0) {

                let mpositions = new Float32Array(excludeInterval.length * 6 * 3),
                    lpositions = new Float32Array(excludeInterval.length * 4 * 3),
                    mpi = 0,
                    lpi = 0;

                excludeInterval.forEach(inter => {
                    var sx = inter[0],
                        ex = inter[1];

                    lpositions[lpi++] = sx;
                    lpositions[lpi++] = 0;
                    lpositions[lpi++] = 0;

                    lpositions[lpi++] = sx;
                    lpositions[lpi++] = dimensionOffsetLength;
                    lpositions[lpi++] = 0;

                    lpositions[lpi++] = ex;
                    lpositions[lpi++] = 0;
                    lpositions[lpi++] = 0;

                    lpositions[lpi++] = ex;
                    lpositions[lpi++] = dimensionOffsetLength;
                    lpositions[lpi++] = 0;

                    mpositions[mpi++] = sx;
                    mpositions[mpi++] = 0;
                    mpositions[mpi++] = 0;

                    mpositions[mpi++] = ex;
                    mpositions[mpi++] = 0;
                    mpositions[mpi++] = 0;

                    mpositions[mpi++] = ex;
                    mpositions[mpi++] = dimensionOffsetLength;
                    mpositions[mpi++] = 0;

                    mpositions[mpi++] = sx;
                    mpositions[mpi++] = 0;
                    mpositions[mpi++] = 0;

                    mpositions[mpi++] = ex;
                    mpositions[mpi++] = dimensionOffsetLength;
                    mpositions[mpi++] = 0;

                    mpositions[mpi++] = sx;
                    mpositions[mpi++] = dimensionOffsetLength;
                    mpositions[mpi++] = 0;

                });

                //let hsl = this.areaColor.getHSL(),
                //    col = new THREE.Color().setHSL(((hsl.h * 360 + 350) % 360) / 360, hsl.s * 0.5, hsl.l);

                let col = new THREE.Color(0x454545);

                let meshBufferGeo = new THREE.BufferGeometry();
                meshBufferGeo.setAttribute('position', new THREE.BufferAttribute(mpositions, 3));
                let msMesh = new THREE.Mesh(this.isDynamic ? new THREE.Geometry().fromBufferGeometry(meshBufferGeo) : meshBufferGeo, DirectedAreaDefinition.getMeshMaterial(col, opacity));
                msMesh.name = "excluded";
                this.addGenerated(msMesh);

                meshBufferGeo = new THREE.BufferGeometry();
                meshBufferGeo.setAttribute('position', new THREE.BufferAttribute(lpositions, 3));
                var ms = new THREE.LineSegments(this.isDynamic ? new THREE.Geometry().fromBufferGeometry(meshBufferGeo) : meshBufferGeo, DirectedAreaDefinition.getLineMaterial(col, false));
                ms.name = "excluded";
                this.addGenerated(ms);
            }

            if (includeInterval && includeInterval.length && dimensionOffsetLength > 0 && seLength > 0) {

                let mpositions = new Float32Array(includeInterval.length * 6 * 3),
                    lpositions = new Float32Array(includeInterval.length * 4 * 3),
                    mpi = 0,
                    lpi = 0;

                includeInterval.forEach(inter => {
                    var sx = Math.min.apply(null, inter),
                        ex = Math.max.apply(null, inter);

                    lpositions[lpi++] = sx;
                    lpositions[lpi++] = 0;
                    lpositions[lpi++] = 0;

                    lpositions[lpi++] = sx;
                    lpositions[lpi++] = dimensionOffsetLength;
                    lpositions[lpi++] = 0;

                    lpositions[lpi++] = ex;
                    lpositions[lpi++] = 0;
                    lpositions[lpi++] = 0;

                    lpositions[lpi++] = ex;
                    lpositions[lpi++] = dimensionOffsetLength;
                    lpositions[lpi++] = 0;

                    mpositions[mpi++] = sx;
                    mpositions[mpi++] = 0;
                    mpositions[mpi++] = 0;

                    mpositions[mpi++] = ex;
                    mpositions[mpi++] = 0;
                    mpositions[mpi++] = 0;

                    mpositions[mpi++] = ex;
                    mpositions[mpi++] = dimensionOffsetLength;
                    mpositions[mpi++] = 0;

                    mpositions[mpi++] = sx;
                    mpositions[mpi++] = 0;
                    mpositions[mpi++] = 0;

                    mpositions[mpi++] = ex;
                    mpositions[mpi++] = dimensionOffsetLength;
                    mpositions[mpi++] = 0;

                    mpositions[mpi++] = sx;
                    mpositions[mpi++] = dimensionOffsetLength;
                    mpositions[mpi++] = 0;

                });

                let hsl = this.areaColor.getHSL({ h: 0, s: 0, l: 0 }),
                    col = new THREE.Color().setHSL(((hsl.h * 360 + 10) % 360) / 360, hsl.s, hsl.l);

                let meshBufferGeo = new THREE.BufferGeometry()
                meshBufferGeo.setAttribute('position', new THREE.BufferAttribute(mpositions, 3));
                let msMesh = new THREE.Mesh(this.isDynamic ? new THREE.Geometry().fromBufferGeometry(meshBufferGeo) : meshBufferGeo, DirectedAreaDefinition.getMeshMaterial(col, opacity));
                msMesh.name = "included";
                this.addGenerated(msMesh);

                meshBufferGeo = new THREE.BufferGeometry()
                meshBufferGeo.setAttribute('position', new THREE.BufferAttribute(lpositions, 3));
                var ms = new THREE.LineSegments(this.isDynamic ? new THREE.Geometry().fromBufferGeometry(meshBufferGeo) : meshBufferGeo, DirectedAreaDefinition.getLineMaterial(col, false));
                ms.name = "included";
                this.addGenerated(ms);
            }

            if (arrowLength > 0 && this.withArrow && dimensionOffsetDir) {
                //var midPoint = start.clone().add(end).multiplyScalar(0.5);
                var midPoint = new THREE.Vector3(seLength * 0.5, 0, 0);
                var lenHalf = arrowLength * 0.5;
                var lenQuarter = arrowLength * 0.25;

                //var dirRight = seDir;
                //var dirUp = dimensionOffsetDir;
                var dirRight = new THREE.Vector3(1, 0, 0);
                var dirUp = new THREE.Vector3(0, 1, 0);

                if (this.flip) {
                    //midPoint.add(dimensionOffset);
                    //dimensionOffsetDir.negate();

                    midPoint.setY(dimensionOffsetLength);
                    dirUp.negate();
                }

                var p1 = dirRight.clone().multiplyScalar(lenQuarter).add(midPoint);
                var p2 = dirUp.clone().multiplyScalar(lenHalf).add(p1);
                var p3 = dirRight.clone().multiplyScalar(-lenHalf).add(p2);
                var p4 = dirRight.clone().multiplyScalar(-lenHalf).add(p1);

                var pt1 = dirRight.clone().multiplyScalar(lenQuarter).add(p2);
                var pt2 = dirUp.clone().multiplyScalar(arrowLength).add(midPoint);
                var pt3 = dirRight.clone().multiplyScalar(-lenQuarter).add(p3);

                if (this.isDynamic) {
                    let verts = [p1, p2, p3, p4, pt1, pt2, pt3];

                    let arrowGeo = new THREE.Geometry();
                    arrowGeo.vertices = [0, 1, 4, 5, 6, 2, 3, 0].map(idx => verts[idx]);
                    let arrow = new THREE.Line(arrowGeo, DirectedAreaDefinition.getLineMaterial(arrowColor, false));
                    arrow.name = "arrowLine";
                    this.addGenerated(arrow);

                    arrowGeo = new THREE.Geometry();
                    arrowGeo.vertices = verts;
                    arrowGeo.faces = [new THREE.Face3(0, 1, 2), new THREE.Face3(0, 2, 3), new THREE.Face3(4, 5, 6)];
                    arrowGeo.computeBoundingSphere();
                    var arrowMesh = new THREE.Mesh(arrowGeo, DirectedAreaDefinition.getMeshMaterial(arrowColor, opacity * 0.7));
                    arrowMesh.name = "arrow";
                    this.addGenerated(arrowMesh);
                } else {
                    let arr = [
                        p1.x, p1.y, p1.z,
                        p2.x, p2.y, p2.z,
                        p3.x, p3.y, p3.z,
                        p4.x, p4.y, p4.z,
                        pt1.x, pt1.y, pt1.z,
                        pt2.x, pt2.y, pt2.z,
                        pt3.x, pt3.y, pt3.z
                    ];
                    let arrowBufferGeo = new THREE.BufferGeometry();

                    arrowBufferGeo.setAttribute('position', new THREE.BufferAttribute(new Float32Array(arr), 3));
                    arrowBufferGeo.setIndex(new THREE.BufferAttribute(new Uint16Array([0, 1, 4, 5, 6, 2, 3, 0]), 1));
                    let arrow = new THREE.Line(arrowBufferGeo, DirectedAreaDefinition.getLineMaterial(arrowColor, false));
                    arrow.name = "arrowLine";
                    this.addGenerated(arrow);

                    arrowBufferGeo = new THREE.BufferGeometry();

                    arrowBufferGeo.setAttribute('position', new THREE.BufferAttribute(new Float32Array(arr), 3));
                    arrowBufferGeo.setIndex(new THREE.BufferAttribute(new Uint16Array([0, 1, 2, 0, 2, 3, 4, 5, 6]), 1));
                    var arrowMesh = new THREE.Mesh(arrowBufferGeo, DirectedAreaDefinition.getMeshMaterial(arrowColor, opacity * 0.7));
                    arrowMesh.name = "arrow";
                    this.addGenerated(arrowMesh);
                }
            }
        }

        moveBy(v: THREE.Vector3) {
            if (!v)
                return;

            this._start.add(v);
            this._end.add(v);
            this._dimensionPosition.add(v);

            this.rebuild();
        }

        GetPositionProperty(k: string) {
            return this.start[k] || null;
        }

        GetSizeProperty(k: string): number {
            return 0;
        }

        removeInstance() {
            var parent = this.parent;
            if (parent && parent instanceof LS.Client3DEditor.DirectedAreaHolder) {
                parent.removeArea(this);
            }
        }

        reset() {
            this.start.set(0, 0, 0);
            this.end.set(0, 0, 0);
            this.dimensionPosition.set(0, 0, 0);
            this.rebuild();
        }

        dispose() {
            spt.ThreeJs.utils.emptyObject3D(this, true, true);
            this._isDisposed = true;
        }

        duplicate(count?: number, distance?: number, direction?: THREE.Vector3) {
            var parent = this.parent;
            if (parent && parent instanceof LS.Client3DEditor.DirectedAreaHolder) {
                parent.addDuplicate(this, count, distance, direction);
            }
        }
    }

    export class LineMaterialHelper {
        private static _mats: THREE.LineMaterial[] = [];

        static GetLineMaterial(opts?: THREE.LineMaterialParameters): THREE.LineMaterial {
            var mat = new THREE.LineMaterial(opts);
            LineMaterialHelper._mats.push(mat);
            var dp = mat.dispose;
            if (dp) {
                mat.dispose = function () {
                    ArrayHelper.removeItem(LineMaterialHelper._mats, mat);
                    dp.apply(mat, arguments);
                };
            }

            if (LS && LS.Client3DEditor && LS.Client3DEditor.Controller.Current && LS.Client3DEditor.Controller.Current.viewModel) {
                var viewModel = LS.Client3DEditor.Controller.Current.viewModel;
                mat.resolution.set(viewModel.ContainerWidth, viewModel.ContainerHeight);
            }

            return mat;
        }

        static updateViewSize(containerWidth: number, containerHeight: number) {
            LineMaterialHelper._mats.forEach(mat => {
                mat.resolution.set(containerWidth, containerHeight);
            });
        }
    }

    export class Line2SegmentsCollection extends THREE.Object3D {
        constructor(geoData: number[], color: number, linewidth: number, dashed = false, dashScale = 1, dashSize = 1, gapSize = 1, depthTest = true, depthWrite = true, transparent = false) {
            super();
            
            var lineMaterial = LineMaterialHelper.GetLineMaterial({
                color: color,
                linewidth: linewidth, // in pixels
                vertexColors: false, //THREE.NoColors,
                transparent: transparent,
                //resolution:  // to be set by renderer, eventually
                dashed: dashed,
                dashScale: dashScale,
                dashSize: dashSize,
                gapSize: gapSize,
                depthTest: depthTest,
                depthWrite: depthWrite
            });

            if (dashed) {
                lineMaterial.dashed = true;
                lineMaterial.defines.USE_DASH = "";
            }

            lineMaterial.needsUpdate = true;

            //[ x1, y1, z1,  x2, y2, z2, ... ]
            var array = new Float32Array(geoData);
            var geo = new THREE.LineSegmentsGeometry();
            if (!geo.boundingSphere)
                geo.boundingSphere = new THREE.Sphere();
            geo.setPositions(array);

            var line = new THREE.Line2(geo, lineMaterial);
            line.computeLineDistances();

            line.renderOrder = LS.Client3DEditor.DefaultRenderOrder;
            this.add(line);
        }
    }
}

THREE.SDFTextObject = spt.ThreeJs.utils.SDFTextObject;
THREE.SDFTextObjectCollection = spt.ThreeJs.utils.SDFTextObjectCollection;
THREE.SDFFontMaterial = spt.ThreeJs.utils.SDFFontMaterial;
THREE.DimensionAligned = spt.ThreeJs.utils.DimensionAligned;
THREE.DimensionAlignedCollection = spt.ThreeJs.utils.DimensionAlignedCollection;
THREE.DirectedAreaDefinition = spt.ThreeJs.utils.DirectedAreaDefinition;
THREE.Line2SegmentsCollection = spt.ThreeJs.utils.Line2SegmentsCollection;

//module THREE {
//    export var SDFTextObject: typeof spt.ThreeJs.utils.SDFTextObject = spt.ThreeJs.utils.SDFTextObject;
//    export var SDFTextObjectCollection: typeof spt.ThreeJs.utils.SDFTextObjectCollection = spt.ThreeJs.utils.SDFTextObjectCollection;
//    export var SDFFontMaterial: typeof spt.ThreeJs.utils.SDFFontMaterial = spt.ThreeJs.utils.SDFFontMaterial;
//    export var DimensionAligned: typeof spt.ThreeJs.utils.DimensionAligned = spt.ThreeJs.utils.DimensionAligned;
//    export var DimensionAlignedCollection: typeof spt.ThreeJs.utils.DimensionAlignedCollection = spt.ThreeJs.utils.DimensionAlignedCollection;
//    export var DirectedAreaDefinition: typeof spt.ThreeJs.utils.DirectedAreaDefinition = spt.ThreeJs.utils.DirectedAreaDefinition;
//}