class LoadingJob {
    constructor(timeoutFunc: (j: LoadingJob) => void, timeoutTime: number) {
        this.TimeOutFunc = timeoutFunc;
        this.TimeoutTime = timeoutTime;
        this.TimeOut = null;
        this.waiting = false;
    }

    start() {
        if (!this.TimeOut) {
            this.TimeOut = setTimeout(this.TimeOutEvent, this.TimeoutTime) as any;
        }
        this.waiting = true;
        return this;
    }

    stop() {
        if (this.TimeOut) {
            window.clearTimeout(this.TimeOut);
            this.TimeOut = null;
        }
        this.waiting = false;
        return this;
    }

    TimeOutEvent() {
        this.TimeOut = null;
        if (this.TimeOutFunc) {
            this.TimeOutFunc(this);
        }
    }

    finish() {
        LoaderManager.finishLoadingJob(this);
    }

    TimeOutFunc: (j: LoadingJob) => void;
    TimeoutTime: number;
    TimeOut: number;
    waiting: boolean;
}

module spt.LoadingHelper {
    var finishedEvent = { type: 'finished' };
    var resourceLoadedEvent = { type: 'resourceLoaded' };

    export class LoadingManager implements IEventManager {
        private _loadingQueue: LoadingJob[] = [];
        private _maxLoadingTimeOut = 1000 * 60 * 2; //2 minutes
        private _loadingStarted = false;

        private checkLoadingQueue(): void {
            if (this._loadingQueue.length === 0) {
                if (this._loadingStarted)
                    this.dispatchEvent(finishedEvent);
                this._loadingStarted = false;

                this.hideLoadingAnim();
            }
        }

        private removeFromQueue(j: LoadingJob): void {
            var l = this._loadingQueue.length;
            if (l) {
                var index = this._loadingQueue.indexOf(j);
                if (index != -1) {
                    this._loadingQueue.splice(index, 1);
                    this.checkLoadingQueue();
                } else {
                    this.finishLoadingJob();
                }
            }
        }
        
        private showLoadingAnim() {
            //TODO lade animation hinzufügen
            let mapViewLoader = $('#clientMapViewLoader');
            mapViewLoader.removeClass();
            mapViewLoader.addClass('ui active inverted dimmer');
        }

        private hideLoadingAnim() {
            //TODO lade animation hinzufügen
            let mapViewLoader = $('#clientMapViewLoader');
            mapViewLoader.removeClass();
            mapViewLoader.addClass('ui disabled');
        }

        isLoading(): boolean {
            return this._loadingQueue.length > 0;
        }

        addLoadingJob(): LoadingJob {
            
            if (this._loadingQueue.length === 0) {
                
                this._loadingStarted = true;
                this.showLoadingAnim();
                
            }
            var lj = new LoadingJob(this.removeFromQueue, this._maxLoadingTimeOut).start();
            this._loadingQueue.push(lj);
            return lj;
        }

        finishLoadingJob(j?: LoadingJob): void {
            
            if (!j) {
                j = this._loadingQueue.pop();
                if (j)
                    j.stop();
                this.checkLoadingQueue();
            }
            else {
                j.stop();
                this.removeFromQueue(j);
            }
        }

        //reset(): void;
        //testloadingAnimation(): void;
        //loadingAnimation(percent: number, display: boolean, showCount?: boolean): void;
        //hasLoadingAnimation(): boolean;
        notifyResourceLoaded() {
            this.dispatchEvent(resourceLoadedEvent);
        }

        addListener(type: string, listener: (event: IEventManagerEvent) => void): EventManager { return this; }

        hasListener(type: string, listener: (event: IEventManagerEvent) => void): boolean { return false; }

        removeListener(type?: string, listener?: (event: IEventManagerEvent) => void): void {}

        dispatchEvent(event: IEventManagerEvent): void {}
    }

    EventManager.apply(LoadingManager.prototype);
}

var LoaderManager = new spt.LoadingHelper.LoadingManager();