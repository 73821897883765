interface ArrayConstructor {
    from(arg: ArrayLike<any>): Array<any>;
}

module spt.ThreeJs {
    import Stopwatch = spt.Utils.Stopwatch;

    export interface IExtendableBufferAttributes {
        [name: string]: {
            array: number[];
            itemSize: number;
            type: string;
        }
    }

    export interface ILibraryEntry {
        RefKeyName: string;
    }

    export interface IReferenceLibrary {
        Entries: ILibraryEntry[];
    }

    export class JsonConverter {

        Library: { [key: string]: any };

        Layer: { [key: string]: THREE.Object3D[] };

        Container: HTMLElement = null;

        Camera: THREE.Camera = null;

        TextureLoader: THREE.TextureLoader = null;
        CubeTextureLoader: THREE.CubeTextureLoader = null;

        constructor(camera?: THREE.Camera, container?: HTMLElement) {
            if (container)
                this.Container = container;
            if (camera)
                this.Camera = camera;
            this.reset();
        }

        reset() {
            this.Library = {};
            this.Layer = {};
        }

        dispose() {
            delete this.Library;
            delete this.Layer;
        }

        addToLibrary(library: IReferenceLibrary) {
            var entries = library.Entries;
            for (var i = 0, l = entries.length; i < l; i++) {
                var entry = entries[i];
                this.Library[entry.RefKeyName] = this.convertThreeJsObject(entry);
            }
        }

        convertThreeJsObject(obj: any) {
            if (typeof obj == "undefined" || obj === null) {
                return obj;
            } else if (obj instanceof Array !== false || Array.isArray(obj)) {
                var arr = [];
                for (var i = 0, l = obj.length; i < l; i++) {
                    var object = obj[i] as any;
                    if (object !== null && typeof object === "object" && (<any>object).ThreeJSType === "ReferenceLibrary") {
                        this.addToLibrary(object as any);
                        continue;
                    }
                    arr.push(this.convertThreeJsObject(object));
                }
                return arr;
            } else if (typeof obj !== "object") {
                return obj;
            } else if (obj.ThreeJSType) {
                return this.convertThreeJsType(obj, obj.ThreeJSType);
            } else if (obj.ThreeJSConstructor) {
                var parameters = obj.Parameters;
                if (obj.ParametersCompressed)
                    parameters = JSON.parse(LZString.decompressFromEncodedURIComponent(obj.ParametersCompressed));
                var genericObj = constructObject(THREE[obj.ThreeJSConstructor], this.convertThreeJsObject(parameters || [])),
                    isObject3D = genericObj instanceof THREE.Object3D;
                if (obj.Attributes) {
                    for (var key in obj.Attributes) {
                        if (isObject3D) {
                            switch (key) {
                                case "matrix":
                                    genericObj.applyMatrix4(this.convertThreeJsObject(obj.Attributes[key]));
                                    break;
                                case "children":
                                    for (var i = 0, l = obj.Attributes[key].length; i < l; i++) {
                                        var child = obj.Attributes[key][i];
                                        if (child === null)
                                            continue;
                                        if (typeof child === "object" && (<any>child).ThreeJSType === "ReferenceLibrary") {
                                            this.addToLibrary(child as any);
                                            continue;
                                        }
                                        genericObj.add(this.convertThreeJsObject(child));
                                    }
                                    break;
                                default:
                                    this.assignThreeJsProperty(genericObj, key, obj.Attributes[key]);
                                    break;
                            }
                        } else
                            this.assignThreeJsProperty(genericObj, key, obj.Attributes[key]);
                    }
                }
                return genericObj;
            }
            return obj;
        }

        convertThreeJsType(obj: any, type: string): any {
            if (this.TypedArrayGenerator[type]) {
                var compressedArray: spt.Utils.FastIntegerCompression.CompressionResult = obj.CompressedArray;
                if (compressedArray && compressedArray.Data) {
                    var vArray = spt.Utils.FastIntegerCompression.uncompressFromBase64(compressedArray.Data, compressedArray.Bias, compressedArray.Scale);
                    if (obj.IsRGBArray) {
                        var cArray = this.TypedArrayGenerator[type](vArray.length * 3) as Array<number>,
                            idx = 0;
                        vArray.forEach(hex => {
                            var r = (hex >> 16 & 255) / 255,
                                g = (hex >> 8 & 255) / 255,
                                b = (hex & 255) / 255;

                            cArray[idx++] = r;
                            cArray[idx++] = g;
                            cArray[idx++] = b;
                        });

                        return cArray;
                    }

                    return this.TypedArrayGenerator[type](vArray);
                }
                else
                    return this.TypedArrayGenerator[type]((obj.Array && obj.Scale && obj.Scale > 0) ? obj.Array.map(v => v * obj.Scale) : (obj.Array || []));
            }
            switch (type) {
                case "ReferenceLibrary":
                    this.addToLibrary(obj);
                    return null;
                case "ReferenceEntry":
                    return obj.RefKeyName ? this.Library[obj.RefKeyName] : null;
                case "Texture":
                    //var lj = LoaderManager.addLoadingJob();
                    //var texture = this.loadTextureFromUrl(obj.image, () => { LoaderManager.finishLoadingJob(lj); }, () => { LoaderManager.finishLoadingJob(lj); });
                    var texture = this.loadTextureFromUrl(obj.image);
                    for (var key in texture) {
                        if (key != "image" && key != "images" && typeof obj[key] != "undefined" && obj[key] != null) {
                            this.assignThreeJsProperty(texture, key, (obj[key]));
                        }
                    }
                    if (texture.format === 1022 || texture.format === 1020)
                        texture.format = THREE.RGBFormat;
                    else if (texture.format === 1021 || texture.format === 1023)
                        texture.format = THREE.RGBAFormat;
                    if (obj.variationName) {
                        (<any>texture).variationName = obj.variationName;
                        (<any>texture).TextureVariations = obj.TextureVariations;
                    }
                    return texture;
                case "CubeTexture":
                    //var lj = LoaderManager.addLoadingJob();
                    var cubeTexLoader = this.CubeTextureLoader;
                    if (!cubeTexLoader)
                        cubeTexLoader = this.CubeTextureLoader = new THREE.CubeTextureLoader();
                    //var cubeTex = cubeTexLoader.load(obj.images, () => { LoaderManager.finishLoadingJob(lj); }, undefined, () => { LoaderManager.finishLoadingJob(lj); }) as THREE.CubeTexture;
                    var cubeTex = cubeTexLoader.load(obj.images, () => { LoaderManager.notifyResourceLoaded(); }) as THREE.CubeTexture;
                    for (var key in cubeTex as any) {
                        if (key != "image" && key != "images" && typeof obj[key] != "undefined" && obj[key] != null) {
                            this.assignThreeJsProperty(cubeTex, key, (obj[key]));
                        }
                    }
                    if (cubeTex.format === 1022 || cubeTex.format === 1020)
                        cubeTex.format = THREE.RGBFormat;
                    else if (cubeTex.format === 1021 || cubeTex.format === 1023)
                        cubeTex.format = THREE.RGBAFormat;
                    return cubeTex;
                case "OrbitControls":
                    var controls = new THREE.OrbitControls(this.Camera, this.Container);
                    for (var key in controls) {
                        if (typeof obj[key] != "undefined" && obj[key] != null) {
                            this.assignThreeJsProperty(controls, key, (obj[key]));
                        }
                    }
                    return controls;
                case "BufferGeometry":
                case "MultipleBufferGeometry":
                    var geometry = new THREE.BufferGeometry(),
                        objAttribs = obj.attributes,
                        objIndex = obj.index;

                    for (var name in objAttribs) {
                        if (name && objAttribs.hasOwnProperty(name) && objAttribs[name] && name[0] !== "_") {
                            geometry.setAttribute(name, this.convertThreeJsObject(objAttribs[name]));
                        }
                    }
                    if (objIndex && objIndex.array)
                        geometry.setIndex(this.convertThreeJsObject(objIndex));
                    if (obj.appendGeometries && obj.appendGeometries.length) {
                        var extendable = this.BufferGeometryToExtendable(geometry),
                            tMat = new THREE.Matrix4(),
                            tCol = new THREE.Color(),
                            colorArray = this.convertThreeJsObject(obj.appendGeometryColors) as Float32Array,
                            trsArray: Array<number>;

                        if (obj.appendGeometryTransforms) {
                            if (obj.appendGeometryTransforms instanceof Array !== false || Array.isArray(obj.appendGeometryTransforms))
                                trsArray = obj.appendGeometryTransforms;
                            else
                                trsArray = this.convertThreeJsObject(obj.appendGeometryTransforms) as Array<number>;
                        }

                        var transformArray = new Float32Array(trsArray);

                        for (var j = 0, m = obj.appendGeometries.length; j < m; j++) {
                            var tIdx = j * 16,
                                cIdx = j * 3;
                            this.AppendBufferGeometryToExtendable(extendable, this.convertThreeJsObject(obj.appendGeometries[j]), tMat.fromArray(<any>transformArray.subarray(tIdx, tIdx + 16)), colorArray ? tCol.fromArray(<any>colorArray.subarray(cIdx, cIdx + 3)) : null);
                        }
                        geometry = this.BufferGeometryFromExtendable(extendable);
                    }
                    if(obj.doComputeFaceNormals)
                        geometry.computeFaceNormals();
                    if(obj.doComputeVertexNormals)
                        geometry.computeVertexNormals();
                    if (obj.multipleMatricesCount && obj.multipleMatrixData) {
                        var multipleMatrixData = JSON.parse(LZString.decompressFromEncodedURIComponent(obj.multipleMatrixData)) as Array<number>,
                            mergedGeometry = new THREE.BufferGeometry(),
                            sourceAttributes = geometry.attributes,
                            mat = new THREE.Matrix4(),
                            nMat = new THREE.Matrix3(),
                            matricesLength = obj.multipleMatricesCount,
                            sourceVertexCount = 0;

                        for (var sourceName in sourceAttributes) {

                            var sourceAttribute = sourceAttributes[sourceName] as THREE.BufferAttribute;

                            if (sourceAttribute && sourceAttribute.array)
                                mergedGeometry.setAttribute(sourceName, new THREE.BufferAttribute(new (<any>sourceAttribute.array.constructor)(sourceAttribute.array.length * matricesLength) as any, sourceAttribute.itemSize, sourceAttribute.normalized));

                            if (sourceName === "position")
                                sourceVertexCount = sourceAttribute.count;
                        }

                        if (geometry.index && geometry.index.array) {
                            var maxIndex = sourceVertexCount * matricesLength;
                            if (maxIndex >= 65535)
                                mergedGeometry.setIndex(new THREE.BufferAttribute(new Uint32Array(geometry.index.array.length * matricesLength), geometry.index.itemSize, geometry.index.normalized));
                            else
                                mergedGeometry.setIndex(new THREE.BufferAttribute(new Uint16Array(geometry.index.array.length * matricesLength), geometry.index.itemSize, geometry.index.normalized));
                        }

                        var targetAttributes = Object.keys(mergedGeometry.attributes).map(name => {
                            return { name: name, attribute: mergedGeometry.attributes[name] as THREE.BufferAttribute };
                        });

                        if (mergedGeometry.index && mergedGeometry.index.array) {
                            targetAttributes.push({ name: "index", attribute: mergedGeometry.index as THREE.BufferAttribute });
                        }

                        targetAttributes.forEach(attr => {
                            var name = attr.name,
                                targetAttribute = attr.attribute,
                                targetArray = targetAttribute.array as number[],
                                //targetCount = targetAttribute.count,
                                sourceAttribute = name === "index" ? geometry.index : sourceAttributes[name] as THREE.BufferAttribute,
                                sourceArray = sourceAttribute.array as number[],
                                sourceArrayLength = sourceArray.length,
                                sourceCount = sourceAttribute.count;

                            if (name === "position" || name === "normal") {
                                var v1 = new THREE.Vector3(),
                                    isNormal = name === "normal";

                                for (var midx = 0; midx < matricesLength; midx++) {

                                    mat.fromArray(multipleMatrixData, midx * 16);

                                    if (isNormal) {
                                        nMat.getNormalMatrix(mat);

                                        for (var sci = 0; sci < sourceCount; sci++) {

                                            v1.set(sourceAttribute.getX(sci),
                                                sourceAttribute.getY(sci),
                                                sourceAttribute.getZ(sci)).applyMatrix3(nMat);//.normalize();

                                            targetAttribute.setXYZ(midx * sourceCount + sci, v1.x, v1.y, v1.z);

                                        }
                                    } else {
                                        for (var sci = 0; sci < sourceCount; sci++) {

                                            v1.set(sourceAttribute.getX(sci),
                                                sourceAttribute.getY(sci),
                                                sourceAttribute.getZ(sci)).applyMatrix4(mat);

                                            targetAttribute.setXYZ(midx * sourceCount + sci, v1.x, v1.y, v1.z);

                                        }
                                    }
                                }
                            }
                            else if (name === "index") {
                                for (var midx = 0; midx < matricesLength; midx++) {
                                    for (var sidx = 0; sidx < sourceArrayLength; sidx++) {
                                        targetArray[midx * sourceArrayLength + sidx] = sourceArray[sidx] + sourceVertexCount * midx;
                                    }
                                }
                            } else {
                                for (var midx = 0; midx < matricesLength; midx++) {
                                    for (var sidx = 0; sidx < sourceArrayLength; sidx++) {
                                        targetArray[midx * sourceArrayLength + sidx] = sourceArray[sidx];
                                    }
                                }
                            }

                            targetAttribute.needsUpdate = true;
                        });

                        geometry = mergedGeometry;
                    }
                    for (var key in geometry) {
                        if (key !== "attributes" && key !== "index" && typeof obj[key] !== "undefined" && obj[key] !== null) {
                            this.assignThreeJsProperty(geometry, key, (obj[key]));
                        }
                    }
                    geometry.computeBoundingSphere();
                    return geometry;
                case "BufferAttribute":
                    return new THREE.BufferAttribute(this.convertThreeJsObject(obj.array), obj.itemSize);
                case "DynamicBufferAttribute":
                    return new THREE.BufferAttribute(this.convertThreeJsObject(obj.array), obj.itemSize).setUsage(THREE.DynamicDrawUsage);
                case "BBlockGeometry":
                    return this.getBufferGeometryFromBBlockGeometry(obj);
                case "Geometry":
                case "MultipleGeometry":
                    var resGeometry = new THREE.Geometry();
                    this.assignProperties(obj, resGeometry, false, ["multipleMatricesCount", "multipleMatrixData"]);

                    if(obj.doComputeFaceNormals)
                        resGeometry.computeFaceNormals();
                    if(obj.doComputeVertexNormals)
                        resGeometry.computeVertexNormals();

                    var mGeometry = resGeometry.clone();

                    if (obj.multipleMatricesCount && obj.multipleMatrixData) {
                        var multipleMatrixData = JSON.parse(LZString.decompressFromEncodedURIComponent(obj.multipleMatrixData)) as Array<number>,
                            mat = new THREE.Matrix4(),
                            matricesLength = obj.multipleMatricesCount;

                        for (var midx = 0; midx < matricesLength; midx++) {
                            mat.fromArray(multipleMatrixData, midx * 16);
                            if(midx === 0)
                                resGeometry.applyMatrix4(mat);
                            else
                                resGeometry.merge(mGeometry, mat, 0);
                        }
                    }

                    return resGeometry;
                case "InstancedMesh":
                    var instanceCount = obj.count || 0,
                        geo = this.convertThreeJsObject(obj.geometry) as THREE.BufferGeometry;
                    if((<any>geo as THREE.Geometry).isGeometry)
                        geo = new THREE.BufferGeometry().fromGeometry(geo as any);
                    else if(obj.geometry.ThreeJSType === "ReferenceEntry")
                        geo = new THREE.BufferGeometry().copy(geo);

                    var instancedMesh = new THREE.InstancedMesh(geo, this.convertThreeJsObject(obj.material), instanceCount);

                    if (instanceCount && obj.instanceMatrixData) {
                        var instanceMatrixData = JSON.parse(LZString.decompressFromEncodedURIComponent(obj.instanceMatrixData)) as Array<number>,
                            mat = new THREE.Matrix4();

                        for (var i = 0; i < instanceCount; ++i) {
                            instancedMesh.setMatrixAt(i, mat.fromArray(instanceMatrixData, i * 16));
                        }
                    }
                    this.assignProperties(obj, instancedMesh, true, ["count", "instanceMatrixData", "instanceMatrix", "geometry", "material"]);
                    return instancedMesh;
                case "LOD":
                    var lod = new THREE.LOD();
                    if (obj.levels) {
                        var levels = obj.levels as { obj: any, dist: number }[];
                        for (var i = 0; i < levels.length; ++i) {
                            lod.addLevel(this.convertThreeJsObject(levels[i].obj), levels[i].dist);
                        }
                    }
                    this.assignProperties(obj, lod, true, ["levels"]);
                    return lod;
                default: //generic
                    var threejsObj = constructObject(THREE[type], []);
                    var isObject3D = threejsObj instanceof THREE.Object3D;
                    this.assignProperties(obj, threejsObj, isObject3D);
                    return threejsObj;
            }
        }

        assignProperties(obj: any, threejsObj: any, isObject3D: boolean, except?: string[]) {
            if (!except) except = [];
            var doUpdateMatrix = false;
            for (var key in obj) {
                if (key === "ThreeJSType" || except.indexOf(key) !== -1)
                    continue;
                if (typeof obj[key] !== "undefined" && obj[key] !== null) {
                    if (isObject3D) {
                        switch (key) {
                            case "matrix":
                                spt.ThreeJs.utils.setMatrix4(threejsObj, this.convertThreeJsObject(obj[key]));
                                doUpdateMatrix = false;
                                break;
                            case "position":
                            case "scale":
                            case "quaternion":
                            case "rotation":
                                threejsObj[key].copy(this.convertThreeJsObject(obj[key]));
                                doUpdateMatrix = true;
                                break;
                            case "children":
                                for (var i = 0, l = obj[key].length; i < l; i++) {
                                    var child = obj[key][i];
                                    if (child === null)
                                        continue;
                                    if (typeof child === "object" && (<any>child).ThreeJSType === "ReferenceLibrary") {
                                        this.addToLibrary(child as any);
                                        continue;
                                    }
                                    threejsObj.add(this.convertThreeJsObject(child));
                                }
                                break;
                            default:
                                this.assignThreeJsProperty(threejsObj, key, (obj[key]));
                                break;
                        }
                    } else
                        this.assignThreeJsProperty(threejsObj, key, (obj[key]));
                }
            }

            if(isObject3D && doUpdateMatrix)
                threejsObj.updateMatrix();

            if (threejsObj instanceof THREE.Camera)
                (threejsObj as any).updateProjectionMatrix();

            if (isObject3D && obj.LayerType && typeof obj.LayerType == "string" && obj.LayerType.length) {
                if (this.Layer[obj.LayerType])
                    this.Layer[obj.LayerType].push(threejsObj as THREE.Object3D);
                else
                    this.Layer[obj.LayerType] = [threejsObj as THREE.Object3D];
            }
        }

        assignThreeJsProperty(threejsObj: any, key: string, nprop: any) {

            if (nprop !== null && typeof nprop === "object" && (<any>nprop).ThreeJSType === "ReferenceLibrary") {
                this.addToLibrary(nprop as any);
                return;
            }
            var newObj = this.convertThreeJsObject(nprop);
            //if (threejsObj[key] === undefined && !Object.keys(threejsObj).some(k => k === key)) {
            //    console.warn(Object.prototype.toString.call(threejsObj) + " has no attribute with name: " + key + ". Possible attributes are [" + Object.keys(threejsObj).join(",") + "]");
            //}
            //if (!newObj && nprop) {
            //    console.warn(Object.prototype.toString.call(threejsObj) + "[" + key + "] : property is " + newObj + ". From " + nprop);
            //}
            if (!threejsObj[key] || !newObj)
                threejsObj[key] = newObj;
            else if (threejsObj[key] instanceof THREE.Color)
                threejsObj[key].set(newObj);
            else if (typeof threejsObj[key].copy == "function" && threejsObj[key].type === newObj.type)
                threejsObj[key].copy(newObj);
            else
                threejsObj[key] = newObj;
        }

        loadTextureFromUrl(url: string, onLoad?: (t?: THREE.Texture) => void, onError?: (e?: ErrorEvent) => void): THREE.Texture {
            return JsonConverter.loadTextureFromUrlStatic(url, this.TextureLoader || (this.TextureLoader = new THREE.TextureLoader()), onLoad, onError);
        }

        static loadTextureFromUrlStatic(url: string, loader?: THREE.TextureLoader, onLoad?: (t?: THREE.Texture) => void, onError?: (e?: ErrorEvent) => void): THREE.Texture {
            if (!loader)
                loader = new THREE.TextureLoader();

            var texture = loader.load(url, (t?: THREE.Texture) => {
                if (onLoad)
                    onLoad(t);
                LoaderManager.notifyResourceLoaded();
            }, undefined, onError);
            texture.anisotropy = Detector.webglParameters.maxAnisotropy || 1;
            return texture;
        }

        TypedArrayGenerator = {
            'Int8Array': (array?: number[]) => new Int8Array(array),
            'Uint8Array': (array?: number[]) => new Uint8Array(array),
            'Uint8ClampedArray': (array?: number[]) => new Uint8ClampedArray(array),
            'Int16Array': (array?: number[]) => new Int16Array(array),
            'Uint16Array': (array?: number[]) => new Uint16Array(array),
            'Int32Array': (array?: number[]) => new Int32Array(array),
            'Uint32Array': (array?: number[]) => new Uint32Array(array),
            'Float32Array': (array?: number[]) => new Float32Array(array),
            'Float64Array': (array?: number[]) => new Float64Array(array),
            'UndefinedArray': (array?: number[]) => array ? (Array.isArray(array) ? array : new Array(array)) : []
        };

        BufferGeometryToExtendable(geometry: THREE.BufferGeometry): IExtendableBufferAttributes {
            var geo = geometry.index ? geometry.toNonIndexed() : geometry;

            var extAttributes: IExtendableBufferAttributes = {};

            var attributes = geo.attributes;

            for (var name in attributes) {
                var attribute = attributes[name] as any;
                var array = attribute.array as any[];
                var newArray: any[] = spt.Utils.arrayFrom(array);

                extAttributes[name] = {
                    array: newArray,
                    itemSize: attribute.itemSize,
                    type: attribute.array.constructor.name
                };
            }

            return extAttributes;
        }

        BufferGeometryFromExtendable(attributes: IExtendableBufferAttributes): THREE.BufferGeometry {
            var geometry = new THREE.BufferGeometry();

            for (var key in attributes) {

                var attribute = attributes[key];
                var typedArray = this.TypedArrayGenerator[attribute.type](attribute.array);

                geometry.setAttribute(key, new THREE.BufferAttribute(typedArray, attribute.itemSize));

            }

            return geometry;
        }

        AppendBufferGeometryToExtendable(attributes: IExtendableBufferAttributes, geometry: THREE.BufferGeometry | THREE.Geometry, transform?: THREE.Matrix4, color?: THREE.Color) {
            if (!transform)
                transform = new THREE.Matrix4();

            if (!geometry || !attributes || typeof attributes !== "object")
                return;

            var newGeo: THREE.BufferGeometry;

            if (geometry instanceof THREE.Geometry) {
                newGeo = (new THREE.BufferGeometry()).fromGeometry(geometry);
                if (newGeo.index)
                    newGeo = newGeo.toNonIndexed();
            } else if (geometry instanceof THREE.BufferGeometry) {
                newGeo = geometry.index ? geometry.toNonIndexed() : (new THREE.BufferGeometry()).copy(geometry);
            }
            else
                return;

            if (!newGeo.attributes)
                return;

            if (!attributes['position']) {

                attributes['position'] = { array: [], itemSize: 3, type: 'Float32Array' };

                if (newGeo.attributes['normal'])
                    attributes['normal'] = { array: [], itemSize: 3, type: 'Float32Array' };
                if (newGeo.attributes['color'] || color)
                    attributes['color'] = { array: [], itemSize: 3, type: 'Float32Array' };
                if (newGeo.attributes['uv'])
                    attributes['uv'] = { array: [], itemSize: 2, type: 'Float32Array' };

            }

            if (!color)
                color = new THREE.Color(0, 0, 0);

            if (attributes['normal'] && !newGeo.attributes['normal'])
                newGeo.computeVertexNormals();

            var newAttributes = newGeo.attributes;

            var newPositionAttribute = newAttributes['position'] as any;
            var newPositionArray = newPositionAttribute.array as number[];
            var newPosItemSize = newPositionAttribute.itemSize;
            var pointCount = newPositionArray.length / newPosItemSize;

            var tvec = new THREE.Vector3();

            for (var k = 0, m = newPositionArray.length; k < m; k += newPosItemSize) {
                var x = newPositionArray[k],
                    y = newPositionArray[k + 1],
                    z = newPositionArray[k + 2];

                tvec.set(x, y, z).applyMatrix4(transform);
                newPositionArray[k] = tvec.x;
                newPositionArray[k + 1] = tvec.y;
                newPositionArray[k + 2] = tvec.z;
            }

            if (newAttributes['normal'] && attributes['normal']) {

                var normalTransform = (new THREE.Matrix3()).getNormalMatrix(transform);

                var newNormalAttribute = newAttributes['normal'] as any;
                var newNormalArray = newNormalAttribute.array as number[];
                var newNormalItemSize = newNormalAttribute.itemSize;

                for (var k = 0, m = newNormalArray.length; k < m; k += newNormalItemSize) {
                    var x = newNormalArray[k],
                        y = newNormalArray[k + 1],
                        z = newNormalArray[k + 2];

                    tvec.set(x, y, z).applyMatrix3(normalTransform);

                    newNormalArray[k] = tvec.x;
                    newNormalArray[k + 1] = tvec.y;
                    newNormalArray[k + 2] = tvec.z;
                }
            }

            for (var name in attributes) {

                var attribute = attributes[name];
                var newAttribute = newAttributes[name] as any;

                var array = attribute.array as number[];

                var itemSize = attribute.itemSize;

                if (!newAttribute) {
                    switch (name) {
                        case 'color':
                            for (var i = 0; i < pointCount; i++) {
                                array.push(color.r, color.g, color.b);
                            }
                            break;
                        case 'uv':
                            for (var i = 0; i < pointCount; i++) {
                                array.push(0, 0);
                            }
                            break;
                        default:
                            for (var i = 0; i < pointCount; i++) {
                                for (var j = 0; j < itemSize; j++) {
                                    array.push(0);
                                }
                            }
                            break;
                    }
                } else {
                    var newArray = newAttribute.array as number[];

                    for (var i = 0, l = newArray.length; i < l; i++) {
                        array.push(newArray[i]);
                    }
                }
            }
        }

        getBufferGeometryFromBBlockGeometry(bBlockGeometry: any) {
            var stopwatch = new Stopwatch().start();
            var resGeometry = new THREE.Geometry();

            var index = 0;
            if (bBlockGeometry.verticeDataCompressed)
                bBlockGeometry.verticeData = JSON.parse(LZString.decompressFromEncodedURIComponent(bBlockGeometry.verticeDataCompressed));
            if (!bBlockGeometry.verticeData || !bBlockGeometry.verticeData.length)
                return new THREE.BufferGeometry();
            for (var i = 0, l = bBlockGeometry.verticeData.length; i < l; i++) {
                var poly = bBlockGeometry.verticeData[i];

                var verts2D: THREE.Vector2[] = [];
                var j: number, k: number, m: number, n: number, l2: number;

                for (j = 0, l2 = poly.length - 2; j < l2; j += 2) {
                    var vx = poly[j];
                    var vy = poly[j + 1];

                    verts2D.push(new THREE.Vector2(vx, vy));
                }

                var vertsLength = verts2D.length;

                if (!vertsLength)
                    continue;

                var h = poly[poly.length - 2];
                var zOffset = poly[poly.length - 1];

                for (k = 0, l2 = vertsLength; k < l2; k++) {
                    var v = verts2D[k];
                    resGeometry.vertices.push(new THREE.Vector3(v.x, v.y, h + zOffset));
                }

                for (k = 0, l2 = vertsLength; k < l2; k++) {
                    var v = verts2D[k];
                    resGeometry.vertices.push(new THREE.Vector3(v.x, v.y, zOffset));
                }

                var vIndizes = THREE.ShapeUtils.triangulateShape(verts2D, []);
                //var vIndizes = THREE.ShapeUtils.triangulate(verts2D, true) as number[][];

                for (m = 0, l2 = vIndizes.length; m < l2; m++) {
                    var ind = vIndizes[m];
                    resGeometry.faces.push(new THREE.Face3(ind[0] + index, ind[1] + index, ind[2] + index, new THREE.Vector3(0, 0, 1)));
                }

                for (n = 0, l2 = vertsLength; n < l2; n++) {
                    var n2 = n + 1;
                    if (n2 == l2)
                        n2 = 0;

                    var v1 = verts2D[n];
                    var v2 = verts2D[n2];

                    var d = (new THREE.Vector3(v2.x, v2.y, 0)).sub(new THREE.Vector3(v1.x, v1.y, 0));
                    var dLengthSq = d.lengthSq();
                    if (dLengthSq <= 0)
                        continue;
                    var dn = (new THREE.Vector3(d.y, -d.x, d.z)).divideScalar(Math.sqrt(dLengthSq));

                    resGeometry.faces.push(new THREE.Face3(n + vertsLength + index, n2 + vertsLength + index, n2 + index, dn));
                    resGeometry.faces.push(new THREE.Face3(n + vertsLength + index, n2 + index, n + index, dn.clone()));
                }

                index += vertsLength * 2;
            }

            if (bBlockGeometry.initializerMatrix)
                resGeometry.applyMatrix4(this.convertThreeJsObject(bBlockGeometry.initializerMatrix) as THREE.Matrix4);

            var bufferGeometry = (new THREE.BufferGeometry()).fromGeometry(resGeometry);

            stopwatch.log("BBlockgeometry generated");

            return bufferGeometry;
        }
    }
}