//Functions for the intercompatibility of Solar.Pro.Tool and SPT.Eco

var hostPath = window.location.host;

if (hostPath.indexOf("http") !== 0)
    hostPath = (window.location.protocol.indexOf("https") != -1 ? "https://" : "http://") + hostPath;

var culture = "de-DE";
var currentTheme = "SPTv2";
var currLogin = "unknown";
var currentLogicUnit = "Levasoft";
var isDebugMode = false;
var cdnPath = "";

var UseImperialSystem = false;
var isMultiRoofBuilding = false;
var usemeteronroof = false;
var isElevation = false;
var isClosed = false;
var EditorViewMode = "Anordnung";
var SkipAO3DSetup = true;
var keepDefaultConsole = true;

//TODO correct texture and font paths
var SDFFontPath = hostPath + "/content/images/SdfFonts/";
var pathToTextures = hostPath + "/content/images/Textures";

//set culture helper in jquery
//$.global.culture.numberFormat["."]
((jquery: any) => {
    if (!jquery.global)
        jquery.global = {};
    if (!jquery.global.culture)
        jquery.global.culture = {};
    if (!jquery.global.culture.numberFormat)
        jquery.global.culture.numberFormat = [];
    if (!jquery.global.culture.numberFormat["."])
        jquery.global.culture.numberFormat["."] = culture == "de-DE" ? "," : ".";
})($ as any);

//see SPTSettingInterface.Client3DObectOptions
var Client3DObectOptions = {
    None: 0,
    CanSelectInstances: 1,
    CanMoveInstances: 2,
    CanDeleteInstances: 4,
    CanInsertInstances: 8,
    CanDeactivateInstances: 16,
    CanRotate90: 32,
    StaticAppearance: 64,
    NoSimpleGeometry: 128,
    NoRectInsert: 256,
    ShowMetaData: 512,
    ScaleX: 1024,
    ScaleY: 2048,
    CanInsertScaled: 4096,
    CanBeScaledX: 8192,
    CanBeScaledY: 16384,
    All: 32767
};

var ErrorLevel = {
    NoError: 0,
    Fail: 1,
    Severe: 2,
    Warning: 3,
    Info: 4
};

var UI3DImages: IUI3DImages = {
    top: pathToTextures + "/top_cube_ui_3d.gif",
    bottom: pathToTextures + "/bottom_cube_ui_3d.gif",
    front: pathToTextures + "/front_cube_ui_3d.gif",
    back: pathToTextures + "/back_cube_ui_3d.gif",
    left: pathToTextures + "/left_cube_ui_3d.gif",
    right: pathToTextures + "/right_cube_ui_3d.gif",
    compassComposed: pathToTextures + "/compassComp_rgb.png",
    compassComposedNEWS: pathToTextures + "/compassNEWS_rgb.png",
    compassComposedHover: pathToTextures + "/compassCompHover_rgb.png",
    compassComposed_a: pathToTextures + "/compassComp_a.png",
    compassComposedNEWS_a: pathToTextures + "/compassNEWS_a.png",
    compassComposedHover_a: pathToTextures + "/compassCompHover_a.png",
    compassSidesHover: pathToTextures + "/sides_hover.gif",
    ModuleCellShape_Monocrystalline: pathToTextures + "/ModuleCellShape_Monocrystalline.png",
    ModuleCellShape_Polycrystalline: pathToTextures + "/ModuleCellShape_Polycrystalline.png",
    ModuleCellShape_Thinfilm: pathToTextures + "/ModuleCellShape_Thinfilm.png",
    ModuleCellShape_Flat: pathToTextures + "/ModuleCellShape_Flat.png",
    ModuleCellShape_Custom: hostPath + "/handler/StaticImagesHandler.ashx?ID=ModuleCellShape",
    ModuleReflection: pathToTextures + "/ModuleCellShape_Flat.png",
    InterCircle: pathToTextures + "/circle_green_16.png"
};

var AO3DStrings = {
    BrowserWebGLErrorMessage: "Warnung: Leider unterstützt ihr Browser ein nötiges WebGL Feature nicht. Dies kann zu Fehlern oder erheblichen Verlangsamungen führen.",
    CouldntInsertObject: "Das Objekt konnte hier nicht eingefügt werden.",
    PleaseTryAgain: "Bitte versuchen sie es erneut.",
    ActionCausedError: "Die Aktion hat leider einen Fehler verursacht.",
    ActionNotExecuted: "Die Aktion konnte leider nicht ausgeführt werden.",
    CouldntMoveObject: "Die Auswahl konnte nicht verschoben werden.",
    Interference: "Störfläche",
    Dormer: "Gaube"
};

var EditorViewLayers = {
    "Electric": [
        { Name: "Stringing", DisplayName: "Verstringung", IconX: 1, IconY: 8 },
        { Name: "Grounding", DisplayName: "Erdung", IconX: 2, IconY: 8 }
    ]
};

var StrCustomDimensioning = "CustomDimensioning";

var initialStepsWidth = 0;

var initialStepsHeight = 0;

var electricTranslation = window.electricTranslation = {
    PolyDes_ElectricSelectInput: "Bitte wählen Sie den zu verstringenden Eingang aus.",
    PolyDes_ElectricSelectModules: "Bitte wählen Sie Module zum Verstringen aus.",
    PolyDes_ElectricInputAlreadyConnected: "Dieser Eingang ist schon verstringt!",
    PolyDes_ElectricInputModuleInfo: "Sie müssen für diesen Eingang  {0} Module verstringen!",
    numberOfModules: "Anzahl Module"
};

var MDStrings = {
    ApproxNotEnoughDepthError: "Fehler: Keine Annäherung an Parameter möglich. Nicht genügend Tiefendaten für diese Region."
};

var MdScaleEdgeModes = [
    { name: 'Skalieren', val: 0 },
    { name: 'Nur entlang der Kante skalieren', val: 1 },
    { name: 'Lokal strecken', val: 2 },
    { name: 'Nach links Verlängern', val: 3 },
    { name: 'Nach rechts Verlängern', val: 4 }
];

var AreaTypeName = "Area";
var BuildingTypeName = "Building";
var RoofTypeName = "Roof";
var RoofAreaTypeName = "RoofArea";
var FreeAreaTypeName = "FreeArea";
var InterfernceTypeName = "Interference";

var AreaLayerName = "Area";
var BuildingLayerName = "Gebäude";
var RoofLayerName = "Dach";
var RoofAreaLayerName = "RoofArea";
var FreeAreaLayerName = "Freifläche";
var InterfernceLayerName = "Störfläche";
var editorMode = 0; //0 = Roof, 1 = Carport, 2 = FreeArea

var ConfirmDialogSaveOnlyClick: () => void;
var ConfirmDialogSaveAndReCreateClick: () => void;

function SetWidthDialogTitleBarWidth(event?: any) { }
function SetPaddingBack(event?: any) { }

function OnError(jqXHR: JQueryXHR, textStatus: string, errorThrown: string) {

}

//possible length units: mm, m, ft, in
function convertLengthUnit(val: number | string, options: { from?: string, to?: string }): number {

    var from = options.from || "mm",
        to = options.to || "mm";
    if (from === to || val === null || (typeof val !== "number" && typeof val !== "string"))
        return +val;

    var v = parseFloat(val as string);
    if (from === "ft") {
        v *= 12;
        from = "in";
    }
    if (from === "m") {
        v *= 1000;
        from = "mm";
    }

    var toReal = to;
    switch (to) {
        case "m":
            to = "mm";
            break;
        case "ft":
            to = "in";
            break;
    }

    if (from === "mm" && to === "in")
        v /= 25.4;
    else if (from === "in" && to === "mm")
        v *= 25.4;

    switch (toReal) {
        case "m":
            v /= 1000;
            break;
        case "ft":
            v /= 12;
            break;
    }

    return v;
}

function mmToImperial(val) {
    if (UseImperialSystem)
        return val / 25.4;
    return val;
}

//convert inches to mm if UseImperialSystem is true
function ImperialTomm(val) {
    if (UseImperialSystem)
        return val * 25.4;
    return val;
}

function isNumber(n: any) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

//check if the number is correct for the given culture
function CheckifNumber(value: string, culture: string) {
    if (value === '0')
        return true;

    if (culture.indexOf('de') != -1 && value.indexOf('.') != -1)
        return false;

    if (culture.indexOf('en') != -1 && value.indexOf(',') != -1)
        return false;

    return true;
}

function ImperialLengthToValue(input: number | string, isFeet?: boolean): string {
    if (!UseImperialSystem || !input || typeof input !== "string")
        return input as string;
    var isNegative = false;
    if (input.indexOf("-") === 0) {
        isNegative = true;
        input = input.substring(1);
    }
    if (input.indexOf("+") === 0)
        input = input.substring(1);
    var inches = input.match(/(\d+(\.|,)?\d{0,9}|(\.|,)\d{1,9})\s*(inches|in|''|”|"|’’|‘‘|´´)/);
    var inchesStr;
    if (Array.isArray(inches))
        inchesStr = inches.length > 0 ? "" + inches[0] : null;
    else
        inchesStr = inches ? "" + inches : null;

    var inchesVal = inchesStr ? inchesStr.indexOf('.') != -1 || inchesStr.indexOf(',') != -1 ? parseFloat(inchesStr.replace(',',
        '.')) : parseInt(inchesStr) : null;
    if (inchesVal && !isNumber(inchesVal))
        inchesVal = null;

    var feets = input.match(/(\d+(\.|,)?\d{0,9}|(\.|,)\d{1,9})\s*(feet|ft|\'|’|‘|´)/);
    var feetsStr = "";
    if (Array.isArray(feets))
        feetsStr = feets.length > 0 ? "" + feets[0] : null;
    else
        feetsStr = feets ? "" + feets : null;

    var feetsVal = feetsStr ? feetsStr.indexOf('.') != -1 || feetsStr.indexOf(',') != -1 ? parseFloat(feetsStr.replace(',',
        '.')) : parseInt(feetsStr) : null;
    if (feetsVal && !isNumber(feetsVal))
        feetsVal = null;

    if (!inchesVal && !feetsVal) {
        if (isFeet) {
            feetsVal = input.indexOf('.') != -1 || input.indexOf(',') != -1 ? parseFloat(input.replace(',',
                '.')) : parseInt(input);
            if (isNaN(feetsVal))
                return "0";
        } else {
            inchesVal = input.indexOf('.') != -1 || input.indexOf(',') != -1 ? parseFloat(input.replace(',',
                '.')) : parseInt(input);
            if (isNaN(inchesVal))
                return "0";
        }
    }

    if (!inchesVal || typeof inchesVal != "number")
        inchesVal = 0.0;
    if (!feetsVal || typeof feetsVal != "number")
        feetsVal = 0.0;
    var value = 0;
    if (isFeet)
        value = (feetsVal + (inchesVal * 0.0833333333)) * 1000;
    else
        value = (inchesVal + (feetsVal * 12)) * 1000;
    value = Math.round(value) / 1000;
    var val = "" + (isNegative ? (-value) : value);
    if (CheckifNumber(val, window.culture))
        return val;
    else
        return val.replace('.',
            ',');
}

function constructObject(constructor: (...args: any[]) => any, args?: any[]): any {
    return new (Function.prototype.bind.apply(constructor, [null].concat(args)));
}

function getSize() {
    var myWidth = 0, myHeight = 0;

    if (typeof (window.innerWidth) == 'number') {
        //Non-IE
        myWidth = parseInt(window.innerWidth as any);
        myHeight = parseInt(window.innerHeight as any);
    } else if (document.documentElement && (document.documentElement.clientWidth || document.documentElement.clientHeight)) {
        //IE 6+ in 'standards compliant mode'
        myWidth = parseInt(document.documentElement.clientWidth as any);
        myHeight = parseInt(document.documentElement.clientHeight as any);
    } else if (document.body && (document.body.clientWidth || document.body.clientHeight)) {
        //IE 4 compatible
        myWidth = parseInt(document.body.clientWidth as any);
        myHeight = parseInt(document.body.clientHeight as any);
    }
    return [myWidth, myHeight];
}

var articleModel = null;
var articleHolderViewModel = {
    showDialog: (ids: any[]) => { }
};
var ArticleHolderInitalCallback = () => { };

function CheckStatic() { }

module ThemeManager {
    export function ResetTheme(navTitle?: string) { }
    export var sptThemeMainColor = "fcb904";
}

function WriteTraceSimple(message: string, category: string) {
    console.log(`${category}: ${message}`);
}

function OnProjectInfoCallBack(projectInfo: SolarProTool.ProjectInfoData) {

}

function ShowProgressView() {

}

function HideProgressView() {

}

function asyncTimeout(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

//function setupButtonNextClickCallback(click: (callback: () => void) => void) {
//    console.log("setup button next click callback");

//    var nxtbtn = $('.button-next').get(0) as HTMLButtonElement,
//        btn: HTMLButtonElement;

//    if(!nxtbtn)
//        return;
    
//    if ($('#new-next-button').length) {
//        btn = document.getElementById('new-next-button') as HTMLButtonElement;
//    } else {
//        btn = document.createElement('button');
//        btn.className = nxtbtn.className;
//        btn.type = "button";
//        btn.id = "new-next-button";
//        btn.textContent = nxtbtn.textContent;
//        nxtbtn.parentElement.insertBefore(btn, nxtbtn);
//    }

//    nxtbtn.style.display = "none";
    
//    btn.onclick = () => {
//        if(nxtbtn.disabled)
//            return;

//        click(() => {
//            console.log("trigger next click");
//            $(nxtbtn).trigger("click");
//        });
//    };
//}