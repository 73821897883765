module MapDrawing {

    export interface IPoint2D {
        x?: number;
        y?: number;
        X: number;
        Y: number;
    }

    export interface IPoint3D extends IPoint2D {
        z?: number;
        Z: number;
    }

    export interface ILatLng {
        Latitude: number;
        Longitude: number;
    }

    export interface IBoundsLatLng {
        Min: ILatLng;
        Max: ILatLng;
    }

    export interface IBounds2D {
        Min: IPoint2D;
        Max: IPoint2D;
    }

    export interface IBounds3D {
        Min: IPoint3D;
        Max: IPoint3D;
    }

    export class Point2D implements IPoint2D {
        constructor(x?: number | IPoint2D, y?: number) {

            if (x && typeof x == "object") {
                var o = <IPoint2D>x;
                y = o.Y;
                x = o.X;
            }

            this.x = (<number>x) || 0;
            this.y = y || 0;
        }

        static computeCentroid(vertices: IPoint2D[]) {
            if (!vertices || !vertices.length)
                return null;
            var centroid = new Point2D(0, 0);
            var signedArea = 0.0;

            // For all vertices except last
            for (var i = 0, j = vertices.length; i < j; i++) {
                var v1 = vertices[i];
                var v2 = vertices[(i + 1) % j];
                var x0 = v1.x;
                var y0 = v1.y;
                var x1 = v2.x;
                var y1 = v2.y;
                var a = x0 * y1 - x1 * y0;
                signedArea += a;
                centroid.x += (x0 + x1) * a;
                centroid.y += (y0 + y1) * a;
            }

            signedArea *= 0.5;
            centroid.x /= (6.0 * signedArea);
            centroid.y /= (6.0 * signedArea);

            return centroid;
        }

        static computeCenter(vertices: IPoint2D[] | IPoint2D[][]) {
            if (!vertices || !vertices.length)
                return null;

            var center = new Point2D(0, 0);
            var len: number;

            if (Array.isArray(vertices[0])) {
                len = 0;
                (<Point2D[][]>vertices).forEach(vs => {
                    vs.forEach(v => {
                        center.x += v.x;
                        center.y += v.y;
                    });
                    len += vs.length;
                });
            } else {
                len = vertices.length;
                (<Point2D[]>vertices).forEach(v => {
                    center.x += v.x;
                    center.y += v.y;
                });
            }

            return center.mul(1 / len);
        }

        static computeArea(vertices: { x: number, y: number }[] | { x: number, y: number }[][]) {
            if (!vertices || !vertices.length)
                return 0;

            var area: number = 0;

            if (Array.isArray(vertices[0])) {
                (<IPoint2D[][]>vertices).forEach(verts => {
                    for (var i = 0, l = verts.length - 1; i <= l; i++) {
                        var j = i === l ? 0 : i + 1;
                        area += verts[i].x * verts[j].y;
                        area -= verts[j].x * verts[i].y;
                    }
                });
            } else {
                let verts = vertices as IPoint2D[];
                for (var i = 0, l = verts.length - 1; i <= l; i++) {
                    var j = i === l ? 0 : i + 1;
                    area += verts[i].x * verts[j].y;
                    area -= verts[j].x * verts[i].y;
                }
            }

            return area / 2;
        }

        static isInsidePolygon(p: { x: number, y: number }, pts: { x: number, y: number }[], eps: number = 0.01): boolean {
            return pointToPolygonDist(p.x, p.y, [pts.map(p => [p.x, p.y])]) >= -eps;
        }

        static isClockwise(vertices: { x: number, y: number }[] | { x: number, y: number }[][]) {
            return Point2D.computeArea(vertices) < 0;
        }

        static moveToZero(vertices: Point2D[]): Point2D[] {
            if (!vertices || vertices.length < 2)
                return vertices ? vertices.map(v => new Point2D(0, 0)) : vertices;

            var b = Bounds2D.FromPoints(vertices);

            if (!b.IsEmpty)
                return vertices.map(v => v.sub(b.Min));

            return vertices.map(v => new Point2D(0, 0));
        }

        x: number;
        y: number;

        get X(): number {
            return this.x;
        }

        set X(v: number) {
            this.x = v;
        }

        get Y(): number {
            return this.y;
        }

        set Y(v: number) {
            this.y = v;
        }

        static Zero: Point2D = new Point2D(0, 0);
        static XAxis: Point2D = new Point2D(1, 0);
        static YAxis: Point2D = new Point2D(0, 1);

        Equals(other: Point2D | Object): boolean {
            if (!other)
                return false;
            if (other instanceof Point2D)
                return this.x === other.X && this.y === other.Y;
            return false;
        }

        add(other: Point2D): Point2D {
            return new Point2D(this.x + other.X, this.y + other.Y);
        }

        sub(other: Point2D): Point2D {
            return new Point2D(this.x - other.X, this.y - other.Y);
        }

        mul(s: number): Point2D {
            return new Point2D(this.x * s, this.y * s);
        }

        multiply(v: Point2D): Point2D {
            return new Point2D(this.x * v.x, this.y * v.y);
        }

        round(): Point2D {
            return new Point2D(Math.round(this.x), Math.round(this.y));
        }

        divide(s: number): Point2D {
            return new Point2D(this.x / s, this.y / s);
        }

        set(x: number, y: number): Point2D {
            this.x = x;
            this.y = y;
            return this;
        }

        setX(v: number): Point2D {
            this.x = v;
            return this;
        }

        setY(v: number): Point2D {
            this.y = v;
            return this;
        }

        neg(): Point2D {
            return new Point2D(-this.x, -this.y);
        }

        static pointsOnLine(p1: Point2D, p2: Point2D, p3: Point2D): boolean {
            if (p1.IsEqualTo(p2) || p1.IsEqualTo(p3))
                return true;

            return (p2.X - p1.X) * (p3.Y - p1.Y) === (p3.X - p1.X) * (p2.Y - p1.Y);
        }

        DistanceTo(other: Point2D) {
            return other.sub(this).GetLength();
        }

        DistanceSquaredTo(other: Point2D) {
            return other.sub(this).GetLengthSquared();
        }

        Clone(): Point2D {
            return new Point2D(this);
        }

        rot90(): Point2D {
            return new Point2D(-this.y, this.x);
        }

        rotr90(): Point2D {
            return new Point2D(this.y, -this.x);
        }

        Copy(p: IPoint2D): Point2D {
            this.x = p.X;
            this.y = p.Y;
            return this;
        }

        GetLength(): number {
            return Math.sqrt(this.GetLengthSquared());
        }

        GetLengthSquared(): number {
            return this.x * this.x + this.y * this.y;
        }

        GetNormalized(): Point2D {
            var lensq = this.GetLengthSquared();
            return lensq > 0 ? this.divide(Math.sqrt(lensq)) : new Point2D(0, 0);
        }

        Project(v: Point2D) {
            return new Point2D(this.x * v.X + this.y * v.Y, this.y * v.X - this.x * v.Y);
        }

        Unproject(v: Point2D) {
            return new Point2D(this.x * v.X - this.y * v.y, this.y * v.x + this.x * v.Y);
        }

        angleTo(v: Point2D): number {
            var theta = this.dot(v) / (Math.sqrt(this.GetLengthSquared() * v.GetLengthSquared()));

            return Math.acos(Math.max(-1, Math.min(1, theta)));
        }

        cross(point: Point2D) {
            return this.x * point.y - this.y * point.x;
        }

        /**
         * Returns the angle between two vectors. The angle is directional and
         * signed, giving information about the rotational direction.
         * @return {Number} the angle between the two vectors
        */
        static AngleFromVectors(u: Point2D, v: Point2D) {
            // see https://github.com/paperjs/paper.js/blob/3ef3ca66d5a615df53f001331081396ae701c276/src/basic/Point.js#L422
            return Math.atan2(u.cross(v), u.dot(v)) * 180 / Math.PI;
        }

        ToGMPoint(): google.maps.Point {
            return new google.maps.Point(this.x, this.y);
        }

        static Min(a: IPoint2D, b: IPoint2D): Point2D {
            return new Point2D(Math.min(a.X, b.X), Math.min(a.Y, b.Y));
        }

        static Max(a: IPoint2D, b: IPoint2D): Point2D {
            return new Point2D(Math.max(a.X, b.X), Math.max(a.Y, b.Y));
        }

        RotateByPivot(rad: number, pivot: Point2D): Point2D {
            var s: number = Math.sin(rad),
                c: number = Math.cos(rad),
                x = this.x - pivot.X,
                y = this.y - pivot.Y,
                xnew: number = x * c - y * s,
                ynew: number = x * s + y * c;
            return new Point2D(pivot.X + xnew, pivot.Y + ynew);
        }

        Rotate(rad: number): Point2D {
            var ca = Math.cos(rad),
                sa = Math.sin(rad);
            return new Point2D(ca * this.X - sa * this.Y, sa * this.X + ca * this.Y);
        }

        static FromOrientation(rad: number): Point2D {
            return new Point2D(Math.cos(rad), Math.sin(rad));
        }

        getOrientation(): number {
            return Math.atan2(this.y, this.x) || 0;
        }

        static FromGMPoint(p: google.maps.Point): Point2D {
            return new Point2D(p.x, p.y);
        }

        dot(v: Point2D): number {
            return this.x * v.X + this.y * v.Y;
        }

        flipY() {
            return new Point2D(this.x, -this.y);
        }

        to3D(z: number = 0): Point3D {
            return new Point3D(this.x, this.y, z);
        }

        IsEqualTo(other: Point2D) {
            return this.X === other.X && this.Y === other.Y;
        }

        LeftHandNormal(): Point2D {
            return new Point2D(-this.Y, this.X);
        }

        RightHandNormal(): Point2D {
            return new Point2D(this.Y, -this.X);
        }

        toString(): string {
            return ' ' + Math.round(this.x) + ' ' + Math.round(-this.y);
        }
    }

    export class Point3D implements IPoint3D {
        x: number;
        y: number;
        z: number;

        constructor(x?: number | IPoint3D, y?: number, z?: number) {
            if (x && typeof x == "object") {
                var o = <IPoint3D>x;
                y = o.Y;
                z = o.Z;
                x = o.X;
            }

            this.x = (<number>x) || 0;
            this.y = y || 0;
            this.z = z || 0;
        }

        get X(): number {
            return this.x;
        }

        set X(v: number) {
            this.x = v;
        }

        get Y(): number {
            return this.y;
        }

        set Y(v: number) {
            this.y = v;
        }

        get Z(): number {
            return this.z;
        }

        set Z(v: number) {
            this.z = v;
        }

        setZ(v: number): Point3D {
            this.z = v;
            return this;
        }

        Equals(other: Point3D | Object): boolean {
            if (!other)
                return false;
            if (other instanceof Point3D)
                return this.x === other.X && this.y === other.Y && this.z === other.Z;
            return false;
        }

        static Zero: Point3D = new Point3D(0, 0, 0);
        static XAxis: Point3D = new Point3D(1, 0, 0);
        static YAxis: Point3D = new Point3D(0, 1, 0);
        static ZAxis: Point3D = new Point3D(0, 0, 1);

        static getNormalFromPoints(p1: Point3D, p2: Point3D, p3: Point3D): Point3D {
            var d = p2.sub(p1).cross(p3.sub(p1));
            var t = d.GetLengthSquared();
            return t > 0 ? d.mul(1 / Math.sqrt(t)) : new Point3D(0, 0, 1);
        }

        static getUpwardsNormalFromPoints(p1: Point3D, p2: Point3D, p3: Point3D): Point3D {
            var n = Point3D.getNormalFromPoints(p1, p2, p3);
            return n.z < 0 ? n.neg() : n;
        }

        static pointsOnLine(p1: Point3D, p2: Point3D, p3: Point3D): boolean {
            //var t = p2.sub(p1);
            //var lensq = t.GetLengthSquared();

            //if (lensq <= 0)
            //    return true;

            //var d = t.mul(1 / Math.sqrt(lensq));

            //return (p1.add(d.mul(d.dot(p3.sub(p1))))).DistanceSquaredTo(p3) <= 0;

            if (p1.IsEqualTo(p2) || p1.IsEqualTo(p3))
                return true;

            return (p2.X - p1.X) * (p3.Y - p1.Y) === (p3.X - p1.X) * (p2.Y - p1.Y) &&
                (p2.X - p1.X) * (p3.Z - p1.Z) === (p3.X - p1.X) * (p2.Z - p1.Z) &&
                (p2.Y - p1.Y) * (p3.Z - p1.Z) === (p3.Y - p1.Y) * (p2.Z - p1.Z);
        }

        GetNormalized(): Point3D {
            var lensq = this.GetLengthSquared();
            return lensq > 0 ? this.divide(Math.sqrt(lensq)) : new Point3D(0, 0, 0);
        }

        GetLengthSquared(): number {
            return this.x * this.x + this.y * this.y + this.z * this.z;
        }

        GetLength(): number {
            return Math.sqrt(this.GetLengthSquared());
        }

        add(other: Point3D): Point3D {
            return new Point3D(this.x + other.X, this.y + other.Y, this.z + other.Z);
        }

        sub(other: Point3D): Point3D {
            return new Point3D(this.x - other.X, this.y - other.Y, this.z - other.Z);
        }

        mul(s: number): Point3D {
            return new Point3D(this.x * s, this.y * s, this.z * s);
        }

        divide(s: number): Point3D {
            return new Point3D(this.x / s, this.y / s, this.z / s);
        }

        dot(v: Point3D): number {
            return this.x * v.X + this.y * v.Y + this.z * v.Z;
        }

        cross(other: Point3D): Point3D {
            return new Point3D(this.Y * other.Z - this.Z * other.Y, this.Z * other.X - this.X * other.Z, this.X * other.Y - this.Y * other.X);
        }

        neg(): Point3D {
            return new Point3D(-this.x, -this.y, -this.z);
        }

        angleTo(v: Point3D): number {

            var theta = this.dot(v) / (Math.sqrt(this.GetLengthSquared() * v.GetLengthSquared()));

            // clamp, to handle numerical problems

            return Math.acos(Math.max(-1, Math.min(1, theta)));

        }

        Copy(p: IPoint3D): Point3D {
            this.x = p.X;
            this.y = p.Y;
            this.z = p.Z;
            return this;
        }

        getXY(): Point2D {
            return new Point2D(this.x, this.y);
        }

        getNormalColor(): string {
            return `rgb(${Math.round((this.x + 1) * 127.5)},${Math.round((this.y + 1) * 127.5)},${Math.round((this.z + 1) * 127.5)})`;
        }

        LeftHandNormal(): Point3D {
            return new Point3D(-this.Y, this.X, this.Z);
        }

        RightHandNormal(): Point3D {
            return new Point3D(this.Y, -this.X, this.Z);
        }

        IsEqualTo(other: Point3D) {
            return this.X === other.X && this.Y === other.Y && this.Z === other.Z;
        }

        DistanceTo(other: Point3D) {
            return other.sub(this).GetLength();
        }

        DistanceSquaredTo(other: Point3D) {
            return other.sub(this).GetLengthSquared();
        }

        static Min(a: IPoint3D, b: IPoint3D): Point3D {
            return new Point3D(Math.min(a.X, b.X), Math.min(a.Y, b.Y), Math.min(a.Z, b.Z));
        }

        static Max(a: IPoint3D, b: IPoint3D): Point3D {
            return new Point3D(Math.max(a.X, b.X), Math.max(a.Y, b.Y), Math.max(a.Z, b.Z));
        }
    }

    export class Plane3D {

        constructor(p?: Point3D, d?: Point3D) {
            this.pos = p || new Point3D(0, 0, 0);
            this.dir = d || new Point3D(0, 0, 1);
        }

        static fromPoints(points: Point3D[]): Plane3D {
            let n = points.length;
            if (n < 3)
                return null;

            //if (n === 3)
            //    return Plane3D.fromThreePoints(points[0], points[1], points[2]);

            let sum = new Point3D(0, 0, 0);
            points.forEach(p => {
                sum = sum.add(p);
            });

            let centroid = sum.mul(1.0 / n);

            // Calc full 3x3 covariance matrix, excluding symmetries:
            let xx = 0, xy = 0, xz = 0, yy = 0, yz = 0, zz = 0;
            points.forEach(p => {
                let r = p.sub(centroid);
                xx += r.x * r.x;
                xy += r.x * r.y;
                xz += r.x * r.z;
                yy += r.y * r.y;
                yz += r.y * r.z;
                zz += r.z * r.z;
            });

            let det_x = yy * zz - yz * yz,
                det_y = xx * zz - xz * xz,
                det_z = xx * yy - xy * xy,
                det_max = Math.max(det_x, det_y, det_z);

            if (det_max <= 0)
                return null; //The points don't span a plane

            // Pick path with best conditioning:
            let dir: Point3D;

            if (det_max === det_x)
                dir = new Point3D(det_x, xz * yz - xy * zz, xy * yz - xz * yy);
            else if (det_max === det_y)
                dir = new Point3D(xz * yz - xy * zz, det_y, xy * xz - yz * xx);
            else
                dir = new Point3D(xy * yz - xz * yy, xy * xz - yz * xx, det_z);

            return new Plane3D(centroid, dir.GetNormalized());
        }

        static fromThreePoints(p1: Point3D, p2: Point3D, p3: Point3D): Plane3D {
            return new Plane3D(p1, p2.sub(p1).cross(p3.sub(p1)).GetNormalized());
        }

        pos: Point3D;
        dir: Point3D;
    }

    export class Segment2D {
        constructor(start?: Point2D, end?: Point2D, straight?: boolean) {
            this.straight = !!straight;
            this.setStartEnd(start, end);
        }

        private _Start: Point2D;
        private _End: Point2D;
        private _Delta: Point2D;
        private _Direction: Point2D;
        private _Normal: Point2D;
        private _len: number;

        straight: boolean;

        get Start() {
            return this._Start;
        }

        set Start(value) {
            this._Start = value;
            this.update();
        }

        get End() {
            return this._End;
        }

        set End(value) {
            this._End = value;
            this.update();
        }

        setStartEnd(start: Point2D, end: Point2D) {
            this._Start = start || new Point2D(0, 0);
            this._End = end || new Point2D(0, 0);

            this.update();
        }

        get Delta(): Point2D {
            return this._Delta;
        }

        get Direction(): Point2D {
            return this._Direction;
        }

        get Normal(): Point2D {
            return this._Normal;
        }

        get Length(): number {
            return this._len;
        }

        private update() {
            var delta = this._Delta = this._End.sub(this._Start);
            var lenSq = delta.GetLengthSquared();
            if (lenSq > 0) {
                var len = this._len = Math.sqrt(lenSq);
                var dir = this._Direction = delta.divide(len);
                this._Normal = dir.rot90();
            } else {
                this._len = 0;
                this._Normal = this._Direction = new Point2D(0, 0);
            }
        }

        isOnSameStraightLine(other: Segment2D, tolerance: number): boolean {
            var s = this.Start,
                n = this.Normal;
            return Math.abs(n.dot(other.Start.sub(s))) <= tolerance && Math.abs(n.dot(other.End.sub(s))) <= tolerance;
        }

        getClosestPoint(p: Point2D): Point2D {
            if (!p)
                return null;

            var s = this.Start,
                d = p.sub(s),
                dir = this.Direction,
                t = dir.dot(d);

            if (!this.straight)
                t = Math.min(this.Length, Math.max(0, t));

            return s.add(dir.mul(t));
        }

        distanceToPoint(p: Point2D): number {
            if (!p)
                return Number.POSITIVE_INFINITY;

            var len = this.Length;

            if (!len)
                return this.Start.DistanceTo(p);

            var s = this.Start,
                d = p.sub(s),
                n = this.Normal;

            if (this.straight)
                return Math.abs(n.dot(d));

            var dir = this.Direction,
                t = dir.dot(d);

            if (t <= 0)
                return this.Start.DistanceTo(p);

            if (t >= len)
                return this.End.DistanceTo(p);

            return Math.abs(n.dot(d));

        }

        getIntersectionParams(other: Segment2D): { t1: number; t2: number; p: Point2D } {
            var ax = (this.End.X - this.Start.X);
            var ay = (this.End.Y - this.Start.Y);
            var bx = (other.End.X - other.Start.X);
            var by = (other.End.Y - other.Start.Y);

            var denom = (ax * by) - (bx * ay);

            //  AB & CD are parallel 
            if (denom === 0)
                return { t1: 0, t2: 0, p: null };

            var cx = (this.Start.X - other.Start.X);
            var cy = (this.Start.Y - other.Start.Y);

            var r1 = (bx * cy - cx * by) / denom;
            var r2 = (ax * cy - cx * ay) / denom;

            if ((this.straight || (r1 >= 0 && r1 <= 1)) && (other.straight || (r2 >= 0 && r2 <= 1))) {
                var x = this.Start.X + r1 * ax;
                var y = this.Start.Y + r1 * ay;

                return { t1: r1, t2: r2, p: new Point2D(x, y) };
            }
            return { t1: 0, t2: 0, p: null };
        }

        getIntersection(other: Segment2D): Point2D {
            var ax = (this.End.X - this.Start.X);
            var ay = (this.End.Y - this.Start.Y);
            var bx = (other.End.X - other.Start.X);
            var by = (other.End.Y - other.Start.Y);

            var denom = (ax * by) - (bx * ay);

            //  AB & CD are parallel 
            if (denom === 0)
                return null;

            var cx = (this.Start.X - other.Start.X);
            var cy = (this.Start.Y - other.Start.Y);

            var r1 = (bx * cy - cx * by) / denom;
            var r2 = (ax * cy - cx * ay) / denom;

            if ((this.straight || (r1 >= 0 && r1 <= 1)) && (other.straight || (r2 >= 0 && r2 <= 1))) {
                var x = this.Start.X + r1 * ax;
                var y = this.Start.Y + r1 * ay;

                return new Point2D(x, y);
            }
            return null;
        }

        static GetIntersection(s1: IPoint2D, e1: IPoint2D, s2: IPoint2D, e2: IPoint2D): Point2D {
            var ax = (e1.X - s1.X);
            var ay = (e1.Y - s1.Y);
            var bx = (e2.X - s2.X);
            var by = (e2.Y - s2.Y);

            var denom = (ax * by) - (bx * ay);

            //  AB & CD are parallel 
            if (denom === 0)
                return null;

            var cx = (s1.X - s2.X);
            var cy = (s1.Y - s2.Y);

            var r1 = (bx * cy - cx * by) / denom;
            var r2 = (ax * cy - cx * ay) / denom;

            if ((r1 >= 0 && r1 <= 1) && (r2 >= 0 && r2 <= 1)) {
                var x = s1.X + r1 * ax;
                var y = s1.Y + r1 * ay;

                return new Point2D(x, y);
            }
            return null;
        }

        getOrientation(): number {
            var delta = this.Delta;
            return Math.atan2(delta.y, delta.x) || 0;
        }

        getCenter(): Point2D {
            return this.Start.add(this.End).mul(0.5);
        }
    }

    export class Transformation3D {

        static Identity = new Transformation3D();

        constructor(elements?: number[]) {
            this.elements = elements || [

                1, 0, 0,
                0, 1, 0,
                0, 0, 1

            ];
        }

        static Rotation(rad: number, pivot?: Point2D): Transformation3D {
            var c = Math.cos(rad), s = Math.sin(rad);
            var rot = new Transformation3D().set(

                c, - s, 0,
                s, c, 0,
                0, 0, 1

            );
            if (pivot) {
                var x = pivot.x;
                var y = pivot.y;
                return Transformation3D.Translation(-x, -y).multiply(rot).multiply(Transformation3D.Translation(x, y));
            }
            return rot;
        }

        static Translation(x: number | Point2D, y?: number): Transformation3D {

            if (x && x instanceof Point2D) {
                return new Transformation3D().set(

                    1, 0, x.x,
                    0, 1, x.y,
                    0, 0, 1

                );
            }

            return new Transformation3D().set(

                1, 0, x,
                0, 1, y,
                0, 0, 1

            );
        }

        transformCanvas(ctx: CanvasRenderingContext2D) {
            var te = this.elements;

            ctx.transform(te[0], te[1], te[3], te[4], te[6], te[7]);
        }

        setCanvasTransform(ctx: CanvasRenderingContext2D) {
            var te = this.elements;

            ctx.setTransform(te[0], te[1], te[3], te[4], te[6], te[7]);
        }

        static Scale(x: number, y: number): Transformation3D {
            return new Transformation3D().set(

                x, 0, 0,
                0, y, 0,
                0, 0, 1

            );
        }

        multiply(m: Transformation3D) {
            return this.multiplyMatrices(m, this);
        }

        multiplyMatrices(a: Transformation3D, b: Transformation3D) {

            var ae = a.elements;
            var be = b.elements;
            var te = this.elements;

            var a11 = ae[0], a12 = ae[3], a13 = ae[6];
            var a21 = ae[1], a22 = ae[4], a23 = ae[7];
            var a31 = ae[2], a32 = ae[5], a33 = ae[8];

            var b11 = be[0], b12 = be[3], b13 = be[6];
            var b21 = be[1], b22 = be[4], b23 = be[7];
            var b31 = be[2], b32 = be[5], b33 = be[8];

            te[0] = a11 * b11 + a12 * b21 + a13 * b31;
            te[3] = a11 * b12 + a12 * b22 + a13 * b32;
            te[6] = a11 * b13 + a12 * b23 + a13 * b33;

            te[1] = a21 * b11 + a22 * b21 + a23 * b31;
            te[4] = a21 * b12 + a22 * b22 + a23 * b32;
            te[7] = a21 * b13 + a22 * b23 + a23 * b33;

            te[2] = a31 * b11 + a32 * b21 + a33 * b31;
            te[5] = a31 * b12 + a32 * b22 + a33 * b32;
            te[8] = a31 * b13 + a32 * b23 + a33 * b33;

            return this;

        }

        transform(p: Point2D): Point2D {
            var x = p.x,
                y = p.y,
                e = this.elements;

            var xn = e[0] * x + e[3] * y + e[6];
            var yn = e[1] * x + e[4] * y + e[7];

            return new Point2D(xn, yn);
        }

        elements: number[];

        set(n11, n12, n13, n21, n22, n23, n31, n32, n33) {
            var te = this.elements;

            te[0] = n11; te[1] = n21; te[2] = n31;
            te[3] = n12; te[4] = n22; te[5] = n32;
            te[6] = n13; te[7] = n23; te[8] = n33;

            return this;
        }

        identity() {

            this.set(

                1, 0, 0,
                0, 1, 0,
                0, 0, 1

            );

            return this;

        }

        clone() {
            return new Transformation3D(this.elements.slice());
        }

        applyTo(ctx: CanvasRenderingContext2D) {
            var te = this.elements;

            ctx.transform(te[0], te[1], te[3], te[4], te[6], te[7]);
        }

        copy(m: Transformation3D) {
            this.elements = m.elements.slice();
            return this;
        }

        multiplyScalar(s: number) {

            var te = this.elements;

            te[0] *= s; te[3] *= s; te[6] *= s;
            te[1] *= s; te[4] *= s; te[7] *= s;
            te[2] *= s; te[5] *= s; te[8] *= s;

            return this;

        }
    }

    export class Bounds2D implements IBounds2D {
        constructor(min?: IPoint2D | IBounds2D, max?: IPoint2D) {
            if (min && (min as IBounds2D).Min) {
                max = (min as IBounds2D).Max;
                min = (min as IBounds2D).Min;
            } else {
                min = min || new Point2D();
                max = max || new Point2D();
            }

            this.Min = Point2D.Min(min as IPoint2D, max);
            this.Max = Point2D.Max(min as IPoint2D, max);
        }

        static FromCenterDimension(center: Point2D, dimension: Point2D): Bounds2D {
            let d = dimension.mul(0.5);
            return new Bounds2D(center.sub(d), center.add(d));
        }

        static FromCenterSize(center: Point2D, width: number, height: number): Bounds2D {
            let d = new Point2D(width * 0.5, height * 0.5);
            return new Bounds2D(center.sub(d), center.add(d));
        }

        static FromPoints(points: Point2D[] | Point2D[][] | Point3D[] | Point3D[][]): Bounds2D {
            if (!points || !points.length)
                return new Bounds2D();

            var minX = Number.MAX_VALUE,
                minY = Number.MAX_VALUE,
                maxX = -Number.MAX_VALUE,
                maxY = -Number.MAX_VALUE;

            if (Array.isArray(points[0])) {
                (<Point2D[][]>points).forEach(ps => {
                    ps.forEach(p => {
                        if (p.X < minX) minX = p.X;
                        if (p.Y < minY) minY = p.Y;
                        if (p.X > maxX) maxX = p.X;
                        if (p.Y > maxY) maxY = p.Y;
                    });
                });
            } else {
                (<Point2D[]>points).forEach(p => {
                    if (p.X < minX) minX = p.X;
                    if (p.Y < minY) minY = p.Y;
                    if (p.X > maxX) maxX = p.X;
                    if (p.Y > maxY) maxY = p.Y;
                });
            }

            if (minX < Number.MAX_VALUE)
                return new Bounds2D(new Point2D(minX, minY), new Point2D(maxX, maxY));

            return new Bounds2D();
        }

        get Center(): Point2D {
            return this.Min.add(this.Max).divide(2);
        }

        set Center(v: Point2D) {
            if (v) {
                var t = v.sub(this.Min.add(this.Max).divide(2));
                this.Min = this.Min.add(t);
                this.Max = this.Max.add(t);
            }
        }

        get Delta(): Point2D {
            return this.Max.sub(this.Min);
        }

        set Delta(v: Point2D) {
            if (v) {
                var c = this.Center;
                var t = v.divide(2);
                this.Min = c.sub(t);
                this.Max = c.add(t);
            }
        }

        get Initialized() {
            return !this.Min.Equals(Point2D.Zero) || !this.Max.Equals(Point2D.Zero);
        }

        get IsEmpty() {
            return this.Min.Equals(this.Max);
        }

        Min: Point2D;
        Max: Point2D;

        IsInside(p: Point2D | Bounds2D): boolean {
            if (!p)
                return false;
            if (p instanceof Point2D)
                return p.X >= this.Min.X && p.X <= this.Max.X && p.Y >= this.Min.Y && p.Y <= this.Max.Y;
            else if (p instanceof Bounds2D)
                return this.IsInside(p.Min) && this.IsInside(p.Max);
            return false;
        }

        Overlaps(other: Bounds2D): boolean {
            return this.Min.X < other.Max.X &&
                this.Max.X > other.Min.X &&
                this.Min.Y < other.Max.Y &&
                this.Max.Y > other.Min.Y;
        }

        Offset(v: Point2D) {
            this.Min = this.Min.add(v);
            this.Max = this.Max.add(v);
        }

        updateFromBounds(bd: Bounds2D) {
            this.Min = Point2D.Min(this.Min, bd.Min);
            this.Max = Point2D.Max(this.Max, bd.Max);

            return this;
        }

        updateFromPoint(p: Point2D) {
            this.Min = Point2D.Min(this.Min, p);
            this.Max = Point2D.Max(this.Max, p);

            return this;
        }

        expandByScalar(v: number) {
            this.Min = new Point2D(this.Min.X - v, this.Min.Y - v);
            this.Max = new Point2D(this.Max.X + v, this.Max.Y + v);

            return this;
        }
    }

    export class Bounds3D implements IBounds3D {
        constructor(min?: IPoint3D | IBounds3D, max?: IPoint3D) {
            if (min && (min as IBounds3D).Min) {
                max = (min as IBounds3D).Max;
                min = (min as IBounds3D).Min;
            } else {
                min = min || new Point3D();
                max = max || new Point3D();
            }

            this.Min = Point3D.Min(min as IPoint3D, max);
            this.Max = Point3D.Max(min as IPoint3D, max);
        }

        static FromPoints(points: Point3D[] | Point3D[][]): Bounds3D {
            if (!points || !points.length)
                return new Bounds3D();

            var minX = Number.MAX_VALUE,
                minY = Number.MAX_VALUE,
                minZ = Number.MAX_VALUE,
                maxX = -Number.MAX_VALUE,
                maxY = -Number.MAX_VALUE,
                maxZ = -Number.MAX_VALUE;

            if (Array.isArray(points[0])) {
                (<Point3D[][]>points).forEach(ps => {
                    ps.forEach(p => {
                        if (p.X < minX) minX = p.X;
                        if (p.Y < minY) minY = p.Y;
                        if (p.Z < minZ) minZ = p.Z;
                        if (p.X > maxX) maxX = p.X;
                        if (p.Y > maxY) maxY = p.Y;
                        if (p.Z > maxZ) maxZ = p.Z;
                    });
                });
            } else {
                (<Point3D[]>points).forEach(p => {
                    if (p.X < minX) minX = p.X;
                    if (p.Y < minY) minY = p.Y;
                    if (p.Z < minZ) minZ = p.Z;
                    if (p.X > maxX) maxX = p.X;
                    if (p.Y > maxY) maxY = p.Y;
                    if (p.Z > maxZ) maxZ = p.Z;
                });
            }

            if (minX < Number.MAX_VALUE)
                return new Bounds3D(new Point3D(minX, minY, minZ), new Point3D(maxX, maxY, maxZ));

            return new Bounds3D();
        }

        get Center(): Point3D {
            return this.Min.add(this.Max).divide(2);
        }

        set Center(v: Point3D) {
            if (v) {
                var t = v.sub(this.Min.add(this.Max).divide(2));
                this.Min = this.Min.add(t);
                this.Max = this.Max.add(t);
            }
        }

        get Delta(): Point3D {
            return this.Max.sub(this.Min);
        }

        set Delta(v: Point3D) {
            if (v) {
                var c = this.Center;
                var t = v.divide(2);
                this.Min = c.sub(t);
                this.Max = c.add(t);
            }
        }

        get Initialized() {
            return !this.Min.Equals(Point3D.Zero) || !this.Max.Equals(Point3D.Zero);
        }

        get IsEmpty() {
            return this.Min.Equals(this.Max);
        }

        Min: Point3D;
        Max: Point3D;

        IsInside(p: Point3D | Bounds3D): boolean {
            if (!p)
                return false;
            if (p instanceof Point3D)
                return p.X >= this.Min.X && p.X <= this.Max.X && p.Y >= this.Min.Y && p.Y <= this.Max.Y && p.Z >= this.Min.Z && p.Z <= this.Max.Z;
            else if (p instanceof Bounds3D)
                return this.IsInside(p.Min) && this.IsInside(p.Max);
            return false;
        }

        Overlaps(other: Bounds3D): boolean {
            return this.Min.X < other.Max.X &&
                this.Max.X > other.Min.X &&
                this.Min.Y < other.Max.Y &&
                this.Max.Y > other.Min.Y &&
                this.Min.Z < other.Max.Z &&
                this.Max.Z > other.Min.Z;
        }

        Offset(v: Point3D) {
            this.Min = this.Min.add(v);
            this.Max = this.Max.add(v);
        }
    }

    export class LatLng implements ILatLng {
        constructor(latitude?: number, longitude?: number) {
            this.Latitude = latitude || 0;
            this.Longitude = longitude || 0;
        }

        static Zero: LatLng = new LatLng(0, 0);

        static PiHalf: number = Math.PI * 0.5;
        static Pi2: number = Math.PI * 2;
        static D_Pi_180: number = Math.PI / 180;
        static D_180_Pi: number = 180 / Math.PI;
        static EarthRadius: number = 6378137;
        static EarthDiameter: number = LatLng.EarthRadius * 2;
        static EarthCircumference: number = LatLng.EarthDiameter * Math.PI;
        static LatPerMeter: number = 360 / LatLng.EarthCircumference;
        static EPS: number = LatLng.LatPerMeter * 0.0005;
        //static sm_a: number = LatLng.EarthRadius;
        //static sm_b: number = 6356752.314;
        //static sm_EccSquared: number = 6.69437999013e-03;
        //static UTMScaleFactor: number = 0.9996;
        static TILE_SIZE: number = 256;
        static TILE_SIZE_HALF: number = LatLng.TILE_SIZE * 0.5;
        static PixelsPerLonDegree: number = LatLng.TILE_SIZE / 360;
        static PixelsPerLonRadian: number = LatLng.TILE_SIZE / (2 * Math.PI);
        static PixelsPerLonRadianHalf: number = LatLng.PixelsPerLonRadian * 0.5;
        static MetersPerPixel: number = LatLng.EarthCircumference / LatLng.TILE_SIZE;
        static Origin: Point2D = new Point2D(LatLng.TILE_SIZE_HALF, LatLng.TILE_SIZE_HALF);

        Latitude: number;
        Longitude: number;

        static Center(...latLngs: LatLng[]): LatLng {
            var latLng = new LatLng(0, 0),
                count = latLngs.length;

            if (count <= 0)
                return latLng;

            latLngs.forEach(ll => { latLng.add(ll); });

            return latLng.divide(count);
        }

        set(latitude: number, longitude: number) {
            this.Latitude = latitude || 0;
            this.Longitude = longitude || 0;
        }

        add(other: LatLng): LatLng {
            return new LatLng(this.Latitude + other.Latitude, this.Longitude + other.Longitude);
        }

        sub(other: LatLng): LatLng {
            return new LatLng(this.Latitude - other.Latitude, this.Longitude - other.Longitude);
        }

        mul(s: number): LatLng {
            return new LatLng(this.Latitude * s, this.Longitude * s);
        }

        divide(s: number): LatLng {
            return new LatLng(this.Latitude / s, this.Longitude / s);
        }

        Equals(other: LatLng | Object): boolean {
            if (!other)
                return false;
            if (other instanceof LatLng)
                return Math.abs(this.Latitude - other.Latitude) < LatLng.EPS && Math.abs(this.Longitude - other.Longitude) < LatLng.EPS;
            return false;
        }

        OffsetLatitude(meters: number): LatLng {
            return this.add(new LatLng(meters * LatLng.LatPerMeter, 0));
        }

        OffsetLongitude(meters: number): LatLng {
            return this.add(new LatLng(0, meters / Math.cos(this.Latitude * LatLng.D_Pi_180) * LatLng.LatPerMeter));
        }

        Offset(latMeters: number, lonMeters?: number): LatLng {
            return this.add(new LatLng(latMeters * LatLng.LatPerMeter, (lonMeters === undefined ? latMeters : lonMeters) / Math.cos(this.Latitude * LatLng.D_Pi_180) * LatLng.LatPerMeter));
        }

        OffsetVector(off: { x: number, y: number }): LatLng {
            return this.add(new LatLng(off.y * LatLng.LatPerMeter, off.x / Math.cos(this.Latitude * LatLng.D_Pi_180) * LatLng.LatPerMeter));
        }

        Clone(): LatLng {
            return new LatLng(this.Latitude, this.Longitude);
        }

        Copy(latLng: { Latitude: number, Longitude: number }): this {
            if (latLng) {
                this.Latitude = latLng.Latitude;
                this.Longitude = latLng.Longitude;
            }
            return this;
        }

        //World point in range [0 .. 256]
        ToPoint2D(): Point2D {
            return LatLng.LatLngToPoint2D(this.Latitude, this.Longitude);
        }

        IsSouthHemisphere(): boolean {
            return this.Latitude < 0;
        }

        IsValid() {
            return Math.abs(this.Latitude) <= 180 && Math.abs(this.Longitude) <= 360;
        }

        //Distance in meters
        DistanceTo(other: LatLng): number {
            var lat1 = this.Latitude;
            var lon1 = this.Longitude;
            var lat2 = other.Latitude;
            var lon2 = other.Longitude;
            var dLat = LatLng.Grad2Rad(lat2 - lat1);  // deg2rad below
            var dLon = LatLng.Grad2Rad(lon2 - lon1);
            var sdLat = Math.sin(dLat / 2);
            var sdLon = Math.sin(dLon / 2);
            var a = sdLat * sdLat +
                Math.cos(LatLng.Grad2Rad(lat1)) *
                Math.cos(LatLng.Grad2Rad(lat2)) *
                sdLon * sdLon;
            var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            var d = LatLng.EarthRadius * c; // Distance in m
            return d;
        }

        //Direction Vector in meters (x : meter in longitude direction, y : meter in latitude direction)
        DirectionTo(other: LatLng): Point2D {
            var lat1 = this.Latitude;
            var lat2 = other.Latitude;
            var lon1 = this.Longitude;
            var lon2 = other.Longitude;

            var latMid = (lat1 + lat2) * 0.5;
            var dLon = lon2 - lon1;
            var x = dLon / LatLng.LatPerMeter * Math.cos(LatLng.Grad2Rad(latMid));

            var dLat = LatLng.Grad2Rad(lat2 - lat1);
            var sdLat = Math.sin(dLat / 2);
            var a = sdLat * sdLat;
            var y = LatLng.EarthDiameter * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

            return new Point2D(x, lat1 > lat2 ? -y : y);
        }

        AngleTo(other: LatLng): number {
            var tx = LatLng.PixelsPerLonDegree * (other.Longitude - this.Longitude);

            var thisSiny = Math.min(Math.max(Math.sin(LatLng.Grad2Rad(this.Latitude)), -0.9999), 0.9999);
            var otherSiny = Math.min(Math.max(Math.sin(LatLng.Grad2Rad(other.Latitude)), -0.9999), 0.9999);

            var ty = LatLng.PixelsPerLonRadianHalf * (Math.log((1 + otherSiny) / (1 - otherSiny)) - Math.log((1 + thisSiny) / (1 - thisSiny)));

            return Math.atan2(ty, tx);
        }

        static Min(a: ILatLng, b: ILatLng): LatLng {
            return new LatLng(Math.min(a.Latitude, b.Latitude), Math.min(a.Longitude, b.Longitude));
        }

        static Max(a: ILatLng, b: ILatLng): LatLng {
            return new LatLng(Math.max(a.Latitude, b.Latitude), Math.max(a.Longitude, b.Longitude));
        }

        public static Grad2Rad(grad: number): number {
            return grad * LatLng.D_Pi_180;
        }

        public static Rad2Grad(rad: number): number {
            return rad * LatLng.D_180_Pi;
        }

        //World point in range [0 .. 256]
        public static LatLngToPoint2D(lat: number, lng: number): Point2D {
            var origin = LatLng.Origin;
            var x = origin.X + lng * LatLng.PixelsPerLonDegree;
            var siny = Math.min(Math.max(Math.sin(LatLng.Grad2Rad(lat)), -0.9999), 0.9999);
            var y = origin.Y + Math.log((1 + siny) / (1 - siny)) * LatLng.PixelsPerLonRadianHalf;
            return new Point2D(x, y);
        }

        //World point in range [0 .. 256]
        public static FromPoint2D(point: Point2D): LatLng {
            if (!point)
                return null;
            let origin = LatLng.Origin,
                lng = (point.X - origin.X) / LatLng.PixelsPerLonDegree,
                latRadians = (point.Y - origin.Y) / LatLng.PixelsPerLonRadian,
                lat = LatLng.Rad2Grad(2 * Math.atan(Math.exp(latRadians)) - Math.PI / 2);
            return new LatLng(lat, lng);
        }

        public static Point2DToGMLatLng(point: Point2D): google.maps.LatLng {
            var origin = LatLng.Origin;
            var lng = (point.X - origin.X) / LatLng.PixelsPerLonDegree;
            var latRadians = (point.Y - origin.Y) / LatLng.PixelsPerLonRadian;
            var lat = LatLng.Rad2Grad(2 * Math.atan(Math.exp(latRadians)) - Math.PI / 2);
            return new google.maps.LatLng(lat, lng);
        }

        public static GMLatLngToPoint2D(latLng: google.maps.LatLng): Point2D {
            return LatLng.LatLngToPoint2D(latLng.lat(), latLng.lng());
        }

        public static FromGMLatLng(latLng: google.maps.LatLng | google.maps.LatLngLiteral): LatLng {
            if (!latLng)
                return new LatLng();
            if (typeof latLng.lat === "function")
                return new LatLng((<google.maps.LatLng>latLng).lat(), (<google.maps.LatLng>latLng).lng());
            return new LatLng((<google.maps.LatLngLiteral>latLng).lat, (<google.maps.LatLngLiteral>latLng).lng);
        }

        public static FromGMLatLngArray(latLngs: google.maps.LatLng[] | google.maps.LatLngLiteral[] | google.maps.MVCArray<google.maps.LatLng>): LatLng[] {
            var result: LatLng[] = [];
            (<google.maps.LatLng[]>latLngs).forEach((ll) => {
                result.push(LatLng.FromGMLatLng(ll));
            });
            return result;
        }

        public static GMLatLngArrayToPoint2D(latLngs: google.maps.LatLng[] | google.maps.LatLngLiteral[] | google.maps.MVCArray<google.maps.LatLng>): Point2D[] {
            var result: Point2D[] = [];
            (<google.maps.LatLng[]>latLngs).forEach((ll) => {
                result.push(LatLng.GMLatLngToPoint2D(ll));
            });
            return result;
        }

        ToGMLatLng(): google.maps.LatLng {
            return new google.maps.LatLng(this.Latitude, this.Longitude);
        }

        ToLatLngLiteral(): google.maps.LatLngLiteral {
            return { lat: this.Latitude, lng: this.Longitude };
        }
    }

    export class BoundsLatLng implements IBoundsLatLng {
        constructor(corner1?: ILatLng | IBoundsLatLng, corner2?: ILatLng) {
            if (corner1 && (corner1 as IBoundsLatLng).Min) {
                corner2 = (corner1 as IBoundsLatLng).Max;
                corner1 = (corner1 as IBoundsLatLng).Min;
            } else {
                corner1 = corner1 || new LatLng();
                corner2 = corner2 || new LatLng();
            }

            this.Min = LatLng.Min(corner1 as ILatLng, corner2);
            this.Max = LatLng.Max(corner1 as ILatLng, corner2);
        }

        static fromValues(minLat: number, minLng: number, maxLat: number, maxLng: number) {
            return new BoundsLatLng(new LatLng(minLat, minLng), new LatLng(maxLat, maxLng));
        }

        static FromCenterAndSize(center: LatLng, sizeInMeters: number) {
            var s = sizeInMeters / 2;
            var corner1 = center.Offset(-s);
            var corner2 = center.Offset(s);
            return new BoundsLatLng(corner1, corner2);
        }

        static FromCenterWidthHeight(center: LatLng, widthInMeters: number, heightInMeters: number) {
            var lngOffset = widthInMeters / 2;
            var latOffset = heightInMeters / 2;

            var corner1 = center.Offset(-latOffset, -lngOffset);
            var corner2 = center.Offset(latOffset, lngOffset);
            return new BoundsLatLng(corner1, corner2);
        }

        static FromLatLngs(latLngs: LatLng[] | LatLng[][]) {

            if (!latLngs || !latLngs.length)
                return new BoundsLatLng();

            var minLat = Number.MAX_VALUE;
            var minLng = Number.MAX_VALUE;
            var maxLat = -Number.MAX_VALUE;
            var maxLng = -Number.MAX_VALUE;

            if (Array.isArray(latLngs[0])) {
                (<LatLng[][]>latLngs).forEach(lls => {
                    lls.forEach(latLng => {
                        if (latLng.Latitude < minLat) minLat = latLng.Latitude;
                        if (latLng.Longitude < minLng) minLng = latLng.Longitude;
                        if (latLng.Latitude > maxLat) maxLat = latLng.Latitude;
                        if (latLng.Longitude > maxLng) maxLng = latLng.Longitude;
                    });
                });
            } else {
                (<LatLng[]>latLngs).forEach(latLng => {
                    if (latLng.Latitude < minLat) minLat = latLng.Latitude;
                    if (latLng.Longitude < minLng) minLng = latLng.Longitude;
                    if (latLng.Latitude > maxLat) maxLat = latLng.Latitude;
                    if (latLng.Longitude > maxLng) maxLng = latLng.Longitude;
                });
            }

            if (minLat < Number.MAX_VALUE)
                return new BoundsLatLng(new LatLng(minLat, minLng), new LatLng(maxLat, maxLng));

            return new BoundsLatLng();
        }

        static FromGmLatLngs(latLngs: google.maps.MVCArray<google.maps.LatLng> | google.maps.MVCArray<google.maps.MVCArray<google.maps.LatLng>> | google.maps.LatLng[] | google.maps.LatLng[][]) {
            if (!latLngs)
                return new BoundsLatLng();

            var latLngsArray = (<google.maps.MVCArray<any>>latLngs).getArray ? (<google.maps.MVCArray<any>>latLngs).getArray() as any[] : latLngs as any[];

            if (latLngsArray.length) {
                if (latLngsArray[0].getArray)
                    latLngsArray = (<google.maps.MVCArray<google.maps.LatLng>[]>latLngsArray).map(lls => lls.getArray());

                if (latLngsArray[0].length)
                    return BoundsLatLng.FromLatLngs((latLngsArray as Array<Array<google.maps.LatLng>>).map<LatLng[]>(ma => ma.map<LatLng>(ll => LatLng.FromGMLatLng(ll))));

                return BoundsLatLng.FromLatLngs((latLngsArray as Array<google.maps.LatLng>).map<LatLng>(ll => LatLng.FromGMLatLng(ll)));
            }

            return new BoundsLatLng();
        }

        static FromCenterAndZoom(center: LatLng, zoomLevel: number, widthPx: number, heightPx: number) {
            var wHalf = widthPx / 2;
            var hHalf = heightPx / 2;

            var cp = center.ToPoint2D();
            var zoom = Math.pow(2, zoomLevel);
            var cpMin = new Point2D((cp.X * zoom - wHalf) / zoom, (cp.Y * zoom - hHalf) / zoom);
            var cpMax = new Point2D((cp.X * zoom + wHalf) / zoom, (cp.Y * zoom + hHalf) / zoom);

            var latlng1 = LatLng.FromPoint2D(cpMin);
            var latlng2 = LatLng.FromPoint2D(cpMax);

            var corner1 = LatLng.Min(latlng1, latlng2);
            var corner2 = LatLng.Max(latlng1, latlng2);

            return new BoundsLatLng(corner1, corner2);
        }

        static FromBoundsMeters(center: LatLng, bounds: Bounds2D): BoundsLatLng {
            return BoundsLatLng.FromCenterWidthHeight(center, bounds.Delta.X, bounds.Delta.Y);
        }

        static FromBoundsMillimeters(center: LatLng, bounds: Bounds2D): BoundsLatLng {
            return BoundsLatLng.FromCenterWidthHeight(center, bounds.Delta.X / 1000, bounds.Delta.Y / 1000);
        }

        Expand(latLng: LatLng | BoundsLatLng) {
            if (!latLng)
                return;
            if (latLng instanceof LatLng) {
                if (this.Initialized) {
                    this.Min = LatLng.Min(this.Min, latLng);
                    this.Max = LatLng.Max(this.Max, latLng);
                } else {
                    this.Min = latLng.Clone();
                    this.Max = latLng.Clone();
                }
            } else if (latLng instanceof BoundsLatLng) {
                this.Expand(latLng.Min);
                this.Expand(latLng.Max);
            }
        }

        ExpandByLatLngs(latLngs: LatLng[]) {
            if (!latLngs)
                return;
            latLngs.forEach(ll => {
                this.Expand(ll);
            });
        }

        IsInside(latLng: LatLng | BoundsLatLng): boolean {
            if (!latLng)
                return false;
            if (latLng instanceof LatLng)
                return latLng.Latitude >= this.Min.Latitude && latLng.Latitude <= this.Max.Latitude && latLng.Longitude >= this.Min.Longitude && latLng.Longitude <= this.Max.Longitude;
            else if (latLng instanceof BoundsLatLng)
                return this.IsInside(latLng.Min) && this.IsInside(latLng.Max);
            return false;
        }

        Overlaps(other: BoundsLatLng): boolean {
            return this.Min.Latitude < other.Max.Latitude &&
                this.Max.Latitude > other.Min.Latitude &&
                this.Min.Longitude < other.Max.Longitude &&
                this.Max.Longitude > other.Min.Longitude;
        }

        OffsetMeters(latMeters: number, lonMeters: number): BoundsLatLng {
            return BoundsLatLng.FromCenterWidthHeight(this.Center.Offset(latMeters, lonMeters), this.Width, this.Height);
        }

        OffsetKeepSize(offset: LatLng): BoundsLatLng {
            return BoundsLatLng.FromCenterWidthHeight(this.Center.add(offset), this.Width, this.Height);
        }

        Scale(s: number) {
            return BoundsLatLng.FromCenterWidthHeight(this.Center, this.Width * s, this.Height * s);
        }

        Extend(meters: number): BoundsLatLng {
            return new BoundsLatLng(this.Min.Offset(-meters), this.Max.Offset(meters));
        }

        Equals(other: BoundsLatLng | any): boolean {
            if (!other)
                return false;
            if (other instanceof BoundsLatLng)
                return this.Min.Equals(other.Min) && this.Max.Equals(other.Max);
            return false;
        }

        add(x: LatLng): BoundsLatLng {
            return new BoundsLatLng(this.Min.add(x), this.Max.add(x));
        }

        sub(x: LatLng): BoundsLatLng {
            return new BoundsLatLng(this.Min.sub(x), this.Max.sub(x));
        }

        mul(x: number): BoundsLatLng {
            return new BoundsLatLng(this.Min.mul(x), this.Max.mul(x));
        }

        divide(x: number): BoundsLatLng {
            return new BoundsLatLng(this.Min.divide(x), this.Max.divide(x));
        }

        Clone(): BoundsLatLng {
            return new BoundsLatLng(this.Min, this.Max);
        }

        get Center() {
            return this.Min.add(this.Max).divide(2);
        }

        get Delta() {
            return this.Max.sub(this.Min);
        }

        get Initialized() {
            return !this.IsEmpty || !this.Min.Equals(LatLng.Zero);
        }

        get IsEmpty() {
            return this.Min.Equals(this.Max);
        }

        isValid() {
            return this.Min.IsValid() && this.Max.IsValid();
        }

        get LowerLeft() {
            return this.Min;
        }

        get LowerRight() {
            return new LatLng(this.Min.Latitude, this.Max.Longitude);
        }

        get UpperRight() {
            return this.Max;
        }

        get UpperLeft() {
            return new LatLng(this.Max.Latitude, this.Min.Longitude);
        }

        get North() {
            return this.Max.Latitude;
        }

        get East() {
            return this.Max.Longitude;
        }

        get South() {
            return this.Min.Latitude;
        }

        get West() {
            return this.Min.Longitude;
        }

        get Corners() {
            return [this.LowerLeft, this.LowerRight, this.UpperRight, this.UpperLeft];
        }

        ///Size in longitude direction in meters
        get Width() {
            return this.LowerLeft.DistanceTo(this.LowerRight);
        }

        ///Size in latitude direction in meters
        get Height() {
            return this.LowerLeft.DistanceTo(this.UpperLeft);
        }

        ///Diagonal size in latitude direction in meters
        get DiagonalSize() {
            return this.Min.DistanceTo(this.Max);
        }

        ///Size vector X = longitude direction in meters, Y = latitude direction in meters
        get Size() {
            return new Point2D(this.Width, this.Height);
        }

        Min: LatLng;
        Max: LatLng;

        ToGMLatLngBounds(): google.maps.LatLngBounds {
            return new google.maps.LatLngBounds(this.Min.ToGMLatLng(), this.Max.ToGMLatLng());
        }

        ToBounds2D(factor: number = 1): Bounds2D {
            return new Bounds2D(this.Min.ToPoint2D().mul(factor), this.Max.ToPoint2D().mul(factor));
        }

        static FromGMLatLngBounds(b: google.maps.LatLngBounds) {
            if (b.isEmpty())
                return new BoundsLatLng();
            return new BoundsLatLng(LatLng.FromGMLatLng(b.getSouthWest()), LatLng.FromGMLatLng(b.getNorthEast()));
        }
    }

    export class Color {
        constructor(r?: number, g?: number, b?: number, a?: number) {
            this.r = r || 0;
            this.g = g || 0;
            this.b = b || 0;
            this.a = a !== undefined ? a : 1;
        }

        setStyle(style: string) {
            var m;

            if (m = /^((?:rgb|hsl)a?)\(\s*([^\)]*)\)/.exec(style)) {

                // rgb / hsl

                var color;
                var name = m[1];
                var components = m[2];

                switch (name) {

                    case 'rgb':
                    case 'rgba':

                        if (color = /^(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*(,\s*([0-9]*\.?[0-9]+)\s*)?$/.exec(components)) {

                            // rgb(255,0,0) rgba(255,0,0,0.5)
                            this.r = Math.min(255, parseInt(color[1], 10)) / 255;
                            this.g = Math.min(255, parseInt(color[2], 10)) / 255;
                            this.b = Math.min(255, parseInt(color[3], 10)) / 255;
                            this.a = color[5] !== undefined ? Math.min(1, Math.max(parseFloat(color[5]))) : 1;

                            return this;

                        }

                        if (color = /^(\d+)\%\s*,\s*(\d+)\%\s*,\s*(\d+)\%\s*(,\s*([0-9]*\.?[0-9]+)\s*)?$/.exec(components)) {

                            // rgb(100%,0%,0%) rgba(100%,0%,0%,0.5)
                            this.r = Math.min(100, parseInt(color[1], 10)) / 100;
                            this.g = Math.min(100, parseInt(color[2], 10)) / 100;
                            this.b = Math.min(100, parseInt(color[3], 10)) / 100;
                            this.a = color[5] !== undefined ? Math.min(1, Math.max(parseFloat(color[5]))) : 1;

                            return this;

                        }

                        break;

                    case 'hsl':
                    case 'hsla':

                        if (color = /^([0-9]*\.?[0-9]+)\s*,\s*(\d+)\%\s*,\s*(\d+)\%\s*(,\s*([0-9]*\.?[0-9]+)\s*)?$/.exec(components)) {

                            // hsl(120,50%,50%) hsla(120,50%,50%,0.5)
                            var h = parseFloat(color[1]) / 360;
                            var s = parseInt(color[2], 10) / 100;
                            var l = parseInt(color[3], 10) / 100;
                            var a = color[5] !== undefined ? Math.min(1, Math.max(parseFloat(color[5]))) : 1;

                            return this.setHSL(h, s, l, a);

                        }

                        break;

                }

            }
            else if (m = /^\#([A-Fa-f0-9]+)$/.exec(style)) {

                // hex color

                var hex = m[1];
                var size = hex.length;

                if (size === 3) {

                    // #ff0
                    this.r = parseInt(hex.charAt(0) + hex.charAt(0), 16) / 255;
                    this.g = parseInt(hex.charAt(1) + hex.charAt(1), 16) / 255;
                    this.b = parseInt(hex.charAt(2) + hex.charAt(2), 16) / 255;

                    return this;

                } else if (size === 6) {

                    // #ff0000
                    this.r = parseInt(hex.charAt(0) + hex.charAt(1), 16) / 255;
                    this.g = parseInt(hex.charAt(2) + hex.charAt(3), 16) / 255;
                    this.b = parseInt(hex.charAt(4) + hex.charAt(5), 16) / 255;

                    return this;

                }

            }

            // unknown color
            console.warn('Color: Unknown color ' + style);

            return this;
        }

        setHex(hex: number) {

            hex = Math.floor(hex);

            this.r = (hex >> 16 & 255) / 255;
            this.g = (hex >> 8 & 255) / 255;
            this.b = (hex & 255) / 255;

            return this;

        }

        getHex() {
            return (this.r * 255) << 16 ^ (this.g * 255) << 8 ^ (this.b * 255) << 0;
        }

        getHexString() {
            return ('000000' + this.getHex().toString(16)).slice(-6);
        }

        private hue2rgb(p: number, q: number, t: number) {

            if (t < 0) t += 1;
            if (t > 1) t -= 1;
            if (t < 1 / 6) return p + (q - p) * 6 * t;
            if (t < 1 / 2) return q;
            if (t < 2 / 3) return p + (q - p) * 6 * (2 / 3 - t);
            return p;

        }

        setHSL(h: number, s: number, l: number, a?: number) {

            // h,s,l ranges are in 0.0 - 1.0
            h = ((h % 1) + 1) % 1; // _Math.euclideanModulo(h, 1);
            s = Math.max(0, Math.min(1, s));
            l = Math.max(0, Math.min(1, l));

            if (s === 0) {

                this.r = this.g = this.b = l;

            } else {

                var p = l <= 0.5 ? l * (1 + s) : l + s - (l * s);
                var q = (2 * l) - p;

                this.r = this.hue2rgb(q, p, h + 1 / 3);
                this.g = this.hue2rgb(q, p, h);
                this.b = this.hue2rgb(q, p, h - 1 / 3);

            }

            if (a !== undefined)
                this.a = a;

            return this;

        }

        getHSL(): { h: number, s: number, l: number, a: number } {

            // h,s,l ranges are in 0.0 - 1.0

            var hsl = { h: 0, s: 0, l: 0, a: 1 };

            var r = this.r, g = this.g, b = this.b;

            var max = Math.max(r, g, b);
            var min = Math.min(r, g, b);

            var hue, saturation;
            var lightness = (min + max) / 2.0;

            if (min === max) {

                hue = 0;
                saturation = 0;

            } else {

                var delta = max - min;

                saturation = lightness <= 0.5 ? delta / (max + min) : delta / (2 - max - min);

                switch (max) {

                    case r: hue = (g - b) / delta + (g < b ? 6 : 0); break;
                    case g: hue = (b - r) / delta + 2; break;
                    case b: hue = (r - g) / delta + 4; break;

                }

                hue /= 6;

            }

            hsl.h = hue;
            hsl.s = saturation;
            hsl.l = lightness;
            hsl.a = this.a;

            return hsl;

        }

        adjustHSL(h = 0, hf = 1, s = 0, sf = 1, l = 0, lf = 1) {
            var hsl = this.getHSL();
            return this.setHSL(Math.min(1, Math.max(0, hsl.h * hf + h)), Math.min(1, Math.max(0, hsl.s * sf + s)), Math.min(1, Math.max(0, hsl.l * lf + l)));
        }

        premultiplyAlpha(a?: number, r = 1, g = 1, b = 1) {
            if (a === undefined)
                a = this.a;
            var ia = 1 - a;
            this.r = r * ia + this.r * a;
            this.g = g * ia + this.g * a;
            this.b = b * ia + this.b * a;
            this.a = 1;
            return this;
        }

        mul(v: number) {
            this.r *= v;
            this.g *= v;
            this.b *= v;
            return this;
        }

        divide(v: number) {
            return this.mul(1 / v);
        }

        getGrayscale() {
            var hsl = this.getHSL();
            return new Color().setHSL(hsl.h, 0, hsl.l);
        }

        //[0, 255]
        r: number = 0;
        //[0, 255]
        g: number = 0;
        //[0, 255]
        b: number = 0;
        //[0, 1]
        a: number = 1;

        toStringRgba(a?: number) {
            if (a === undefined)
                a = this.a;
            return `rgba(${Math.round(this.r * 255)},${Math.round(this.g * 255)},${Math.round(this.b * 255)},${a})`;
        }

        toString() {
            return `#${Math.round(this.r * 255).toString(16)}${Math.round(this.g * 255).toString(16)}${Math.round(this.b * 255).toString(16)}`;
        }
    }

    export class Circle2D {
        Center: Point2D;
        private _radius: number;
        private _radiusSquared: number;

        get Radius() {
            return this._radius;
        }

        set Radius(value: number) {
            this._radius = value;
            this._radiusSquared = value * value;
        }

        get RadiusSquared() {
            return this._radiusSquared;
        }

        set RadiusSquared(value: number) {
            this._radius = Math.sqrt(value);
            this._radiusSquared = value;
        }

        constructor(center?: Point2D, radius: number = 0) {
            this.Center = center || new Point2D(0, 0);
            this.Radius = +radius;
        }

        contains(p: Point2D) {
            return this.Center.DistanceSquaredTo(p) <= this.RadiusSquared;
        }
    }

    function compareMax(a: Cell, b: Cell): number {
        return b.max - a.max;
    }

    class Cell {
        x: number;
        y: number;
        h: number;
        d: number;
        max: number;

        constructor(x: number, y: number, h: number, polygon: number[][][]) {
            this.x = x; // cell center x
            this.y = y; // cell center y
            this.h = h; // half the cell size
            this.d = pointToPolygonDist(x, y, polygon); // distance from cell center to polygon
            this.max = this.d + this.h * Math.SQRT2; // max distance to polygon within a cell
        }
    }

    function pointToPolygonDist(x: number, y: number, polygon: number[][][]): number {
        var inside = false;
        var minDistSq = Infinity;

        for (var k = 0; k < polygon.length; k++) {
            var vs = polygon[k];

            for (var i = 0, len = vs.length, j = len - 1; i < len; j = i++) {
                var a = vs[i],
                    b = vs[j],
                    xi = a[0], yi = a[1],
                    xj = b[0], yj = b[1];

                if ((yi > y !== yj > y) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi))
                    inside = !inside;

                minDistSq = Math.min(minDistSq, getSegDistSq(x, y, a, b));
            }
        }

        return (inside ? 1 : -1) * Math.sqrt(minDistSq);
    }

    function getCentroidCell(polygon: number[][][]): Cell {
        var area = 0;
        var x = 0;
        var y = 0;
        var points = polygon[0];

        for (var i = 0, len = points.length, j = len - 1; i < len; j = i++) {
            var a = points[i];
            var b = points[j];
            var f = a[0] * b[1] - b[0] * a[1];
            x += (a[0] + b[0]) * f;
            y += (a[1] + b[1]) * f;
            area += f * 3;
        }
        if (area === 0) return new Cell(points[0][0], points[0][1], 0, polygon);
        return new Cell(x / area, y / area, 0, polygon);
    }

    function getSegDistSq(px: number, py: number, a: number[], b: number[]): number {

        var x = a[0];
        var y = a[1];
        var dx = b[0] - x;
        var dy = b[1] - y;

        if (dx !== 0 || dy !== 0) {

            var t = ((px - x) * dx + (py - y) * dy) / (dx * dx + dy * dy);

            if (t > 1) {
                x = b[0];
                y = b[1];

            } else if (t > 0) {
                x += dx * t;
                y += dy * t;
            }
        }

        dx = px - x;
        dy = py - y;

        return dx * dx + dy * dy;
    }

    export function biggestCircleInPolygon(poly: Point2D[], precision?: number, debug?: boolean): Circle2D {

        let polygon = [poly.map(p => [p.x, p.y])];

        precision = precision || 1.0;

        // find the bounding box of the outer ring
        var minX, minY, maxX, maxY;
        for (var i = 0; i < polygon[0].length; i++) {
            var p = polygon[0][i];
            if (!i || p[0] < minX) minX = p[0];
            if (!i || p[1] < minY) minY = p[1];
            if (!i || p[0] > maxX) maxX = p[0];
            if (!i || p[1] > maxY) maxY = p[1];
        }

        var width = maxX - minX;
        var height = maxY - minY;
        var cellSize = Math.min(width, height);
        var h = cellSize / 2;

        // a priority queue of cells in order of their "potential" (max distance to polygon)
        var cellQueue = new ArrayHelper.TinyQueue<Cell>(null, compareMax);

        if (cellSize === 0) return new Circle2D(new Point2D(minX, minY), 0);

        // cover polygon with initial cells
        for (var x = minX; x < maxX; x += cellSize) {
            for (var y = minY; y < maxY; y += cellSize) {
                cellQueue.push(new Cell(x + h, y + h, h, polygon));
            }
        }

        // take centroid as the first best guess
        var bestCell = getCentroidCell(polygon);

        // special case for rectangular polygons
        var bboxCell = new Cell(minX + width / 2, minY + height / 2, 0, polygon);
        if (bboxCell.d > bestCell.d) bestCell = bboxCell;

        var numProbes = cellQueue.length;

        while (cellQueue.length) {
            // pick the most promising cell from the queue
            var cell = cellQueue.pop();

            // update the best cell if we found a better one
            if (cell.d > bestCell.d) {
                bestCell = cell;
                if (debug) console.log('found best %d after %d probes', Math.round(1e4 * cell.d) / 1e4, numProbes);
            }

            // do not drill down further if there's no chance of a better solution
            if (cell.max - bestCell.d <= precision) continue;

            // split the cell into four cells
            h = cell.h / 2;
            cellQueue.push(new Cell(cell.x - h, cell.y - h, h, polygon));
            cellQueue.push(new Cell(cell.x + h, cell.y - h, h, polygon));
            cellQueue.push(new Cell(cell.x - h, cell.y + h, h, polygon));
            cellQueue.push(new Cell(cell.x + h, cell.y + h, h, polygon));
            numProbes += 4;
        }

        if (debug) {
            console.log('num probes: ' + numProbes);
            console.log('best distance: ' + bestCell.d);
        }

        return new Circle2D(new Point2D(bestCell.x, bestCell.y), bestCell.d);
    }

    export function biggestCircleInLatLngPolygon(poly: LatLng[], refLatLng?: LatLng, debug?: boolean): Circle2D {
        refLatLng = refLatLng || BoundsLatLng.FromLatLngs(poly).Min;
        var result = biggestCircleInPolygon(poly.map(ll => refLatLng.DirectionTo(ll).mul(1000)), 1, debug);

        return new Circle2D(refLatLng.OffsetVector(result.Center.mul(0.001)).ToPoint2D(), result.Radius * 0.001);
    }

    export function extractBounds(mapShape: SolarProTool.MapShape | SolarProTool.MapShape[]) {
        var minLat = Number.MAX_VALUE,
            minLng = Number.MAX_VALUE,
            maxLat = -Number.MAX_VALUE,
            maxLng = -Number.MAX_VALUE,
            minx = Number.MAX_VALUE,
            miny = Number.MAX_VALUE,
            maxx = -Number.MAX_VALUE,
            maxy = -Number.MAX_VALUE;

        if (Array.isArray(mapShape)) {
            mapShape.forEach(s => {
                s.borders.forEach(b => {
                    minLat = Math.min(minLat, b.minLat);
                    minLng = Math.min(minLng, b.minLng);
                    maxLat = Math.max(maxLat, b.maxLat);
                    maxLng = Math.max(maxLng, b.maxLng);
                    minx = Math.min(minx, b.minx);
                    miny = Math.min(miny, b.miny);
                    maxx = Math.max(maxx, b.maxx);
                    maxy = Math.max(maxy, b.maxy);
                });
            });
        } else {
            mapShape.borders.forEach(b => {
                minLat = Math.min(minLat, b.minLat);
                minLng = Math.min(minLng, b.minLng);
                maxLat = Math.max(maxLat, b.maxLat);
                maxLng = Math.max(maxLng, b.maxLng);
                minx = Math.min(minx, b.minx);
                miny = Math.min(miny, b.miny);
                maxx = Math.max(maxx, b.maxx);
                maxy = Math.max(maxy, b.maxy);
            });
        }

        return { boundsLatLng: new BoundsLatLng(new LatLng(minLat, minLng), new LatLng(maxLat, maxLng)), bounds: new Bounds2D(new Point2D(minx, miny), new Point2D(maxx, maxy)) };
    }

    export function calculateOrientationRad(points: SolarProTool.MapCoord[]) {
        if (!points || !points.length)
            return 0;

        var len = points.length,
            maxLen = 0,
            pair: { c1: SolarProTool.MapCoord, c2: SolarProTool.MapCoord, lenSq: number, d: Point2D } = null;

        for (var i = len - 1, j = 0; j < len; ++j) {
            var c1 = points[i],
                c2 = points[j],
                dx = c2.x - c1.x,
                dy = c2.y - c1.y,
                d = new Point2D(dx, dy),
                lenSq = d.GetLengthSquared();

            if (lenSq > maxLen) {
                maxLen = lenSq;
                pair = { c1, c2, lenSq, d };
            }

            i = j;
        }

        if (!pair || pair.lenSq <= 0)
            return 0;

        var localDir = pair.d.mul(1 / Math.sqrt(pair.lenSq)),
            ll1 = new LatLng(pair.c1.latitude, pair.c1.longitude),
            ll2 = new LatLng(pair.c2.latitude, pair.c2.longitude),
            g1 = ll1.ToPoint2D(),
            g2 = ll2.ToPoint2D(),
            gd = g2.sub(g1),
            gLenSq = gd.GetLengthSquared();

        if (gLenSq <= 0)
            return 0;

        var globalDir = gd.mul(1 / Math.sqrt(gLenSq));

        return globalDir.Rotate(-localDir.getOrientation()).getOrientation();
    }

    export function calculateWorldOrigin(orientationRad: number, customCoordinates: SolarProTool.MapCoord[]) {
        var rot = Transformation3D.Rotation(orientationRad),
            rotn = Transformation3D.Rotation(-orientationRad),
            bds = Bounds2D.FromPoints(customCoordinates.map(c => rotn.transform((new LatLng(c.latitude, c.longitude)).ToPoint2D())));
        return LatLng.FromPoint2D(rot.transform(bds.Min));
    }

    export function calculateGlobalCoordinates(points: {x: number, y: number}[], globalOrigin: LatLng, globalOrientationRad: number, slopeRad = 0, height = 0, altitude = 0) {
        var worldDirX = Point2D.FromOrientation(globalOrientationRad),
            worldDirY = worldDirX.LeftHandNormal(),
            slopeFactor = Math.cos(slopeRad);

        return points.map(point => {
            var latLng = globalOrigin.OffsetVector(worldDirX.mul(point.x * 0.001).add(worldDirY.mul(slopeFactor * point.y * 0.001))),
                c: SolarProTool.MapCoord = {
                    x: point.x,
                    y: point.y,
                    z: height,
                    latitude: latLng.Latitude,
                    longitude: latLng.Longitude,
                    altitude: altitude
                };
            return c;
        });
    }

    export class TiledMapCalculator {
        textureSize: number;
        zoom = 19;
        outerLatLngBounds: BoundsLatLng;
        latLngBounds: BoundsLatLng;
        min: Point2D;
        max: Point2D;
        delta: Point2D;

        constructor(latLngs: BoundsLatLng | LatLng[] | LatLng[][], startingZoomLevel = 19, textureSize = 2048) {
            var latLngBounds: BoundsLatLng = Array.isArray(latLngs) ? BoundsLatLng.FromLatLngs(latLngs) : latLngs;
            this.latLngBounds = latLngBounds;
            this.textureSize = textureSize;
            var zoomLevel = startingZoomLevel;

            var targetCenterLatLng = latLngBounds.Center;

            var outerLatLngBounds = BoundsLatLng.FromCenterAndZoom(targetCenterLatLng, zoomLevel, textureSize, textureSize);

            while (!outerLatLngBounds.IsInside(latLngBounds) && zoomLevel > 1) {
                --zoomLevel;
                outerLatLngBounds = BoundsLatLng.FromCenterAndZoom(targetCenterLatLng, zoomLevel, textureSize, textureSize);
            }

            this.outerLatLngBounds = outerLatLngBounds;
            this.zoom = zoomLevel;
            var min = this.min = outerLatLngBounds.Min.ToPoint2D();
            var max = this.max = outerLatLngBounds.Max.ToPoint2D();
            this.delta = new Point2D(max.x - min.x, max.y - min.y);
        }

        //call like "../../handler/StaticImagesHandler.ashx?" + GetMapsUrlParameter()
        GetMapsUrlParameter(mapId = "GoogleSatellite", scale = 1) {
            var center = this.outerLatLngBounds.Center,
                textureSize = this.textureSize,
                zoom = this.zoom;
            return `ID=${mapId}&center=${center.Latitude},${center.Longitude}&zoom=${zoom}&size=${textureSize}x${textureSize}&scale=${scale}`;
        }

        CalculateUvs(latLngs: LatLng[]) {
            var min = this.min,
                delta = this.delta;
            return latLngs.map(ll => {
                var p = ll.ToPoint2D();
                return new Point2D((p.X - min.X) / delta.X, (p.Y - min.Y) / delta.Y);
            });
        }
    }
}
