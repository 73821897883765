module DManager {
    function getJqueryElement(div: Element | JQuery | string) {
        //dynamically append # to div if its a string
        if (typeof div === "string") {
            if (div[0] !== "#" && div[0] !== ".")
                div = "#" + div;
        }

        return $(div as any) as JQuery<HTMLElement>;
    }

    export function init(div: Element | JQuery | string, width: number | string, height: number | string, openfunction?: (event: any) => void, closefunction?: (event: any) => void, options?: any, callBack?: () => void) {
        //TODO init a dialog
        var $div = getJqueryElement(div);
        $div.hide();
    }
    export function destroy(div: Element | JQuery | string) {
        //TODO destroy a dialog
        var $div = getJqueryElement(div);
        $div.remove();
    }
    export function show(div: Element | JQuery | string) {
        //TODO show a dialog
        var $div = getJqueryElement(div);
        $div.show();
    }
    //export function isVisible(div: Element | JQuery | string): boolean;
    export function hide(div: Element | JQuery | string) {
        //TODO hide a dialog
        var $div = getJqueryElement(div);
        $div.hide();
    }
    export function reload() {
        //TODO reload current site
    }
    //export function showMultiRoofConfirm(callback?: (result: boolean) => void): void;
    //export function showInfoWindowYesNo(message: string, callback?: (result: boolean) => void): void;
    export function showInfoWindow(message: string) {
        //TODO show info window
    }
    //export function HideInfoWindow(): void;
    //export function showprogress(message?: string): void;
    //export function showprogressWithOverlay(message?: string): void;
    export function showErrorMessage(message: string, showReload?: boolean) {
        //TODO error message
    }
    //export function errorMessageIsShown(): boolean;
    export function showDialogDynamic(url: string, dialogId: string, width: number | string, height: number | string, callBack?: () => void) {
        //TODO show dialog dynamic
    }
    export function loadDynamicDialog(url: string, dialogId: string, width: number | string, height: number | string, callBack?: (diaId: string, dialogGenerated: boolean) => void) {
        //TODO load dialog dynamic
    }
    //export function HideErrorWindow(): void;
    //export function HideProgress(): void;
    export function Navigate(path: string) {
        //TODO navigate
    }
    //export function initNoModal(div: Element | JQuery | string, width: number | string, height: number | string, openfunction?: (event: any) => void, closefunction?: (event: any) => void, options?: any, callBack?: () => void): void;
    //export function SetWidthDialogTitleBarWidth(event: Event): void;
    //export function SetPaddingBack(): void;
    //export function SetWizardTitleBar(): void;


    var smallInfoQueue: string[] = [];
    var smallInfoIsVisible: boolean = false;
    var smallInfoTimerId: number = null;

    function checkSmallInfoQueue() {
        if (!smallInfoIsVisible && smallInfoQueue.length) {
            smallInfoIsVisible = true;
            var message = smallInfoQueue.shift();
            $('#InfoMessageSmallText').html(message);
            showWithFade($('#InfoMessageSmall'));
            smallInfoTimerId = setTimeout(() => {
                hideWithFade($('#InfoMessageSmall'));
                smallInfoTimerId = null;
                smallInfoIsVisible = false;
                checkSmallInfoQueue();
            }, 3000) as any;
        }
    }

    export function SmallInfoWindowClear() {
        if (smallInfoIsVisible || smallInfoQueue.length) {
            if (smallInfoTimerId !== null) {
                clearTimeout(smallInfoTimerId);
                smallInfoTimerId = null;
            }
            if (smallInfoQueue.length)
                smallInfoQueue.splice(0, smallInfoQueue.length);
            if (smallInfoIsVisible)
                hideWithFade($('#InfoMessageSmall'));
            smallInfoIsVisible = false;
        }
    }

    export function ShowSmallInfo(message: string, clear?: boolean) {
        if (clear)
            SmallInfoWindowClear();
        smallInfoQueue.push(message);
        checkSmallInfoQueue();
    }

    export function SmallInfoWindowNext() {
        if (smallInfoIsVisible && smallInfoTimerId !== null) {
            clearTimeout(smallInfoTimerId);
            smallInfoTimerId = null;
            smallInfoIsVisible = false;
            hideWithFade($('#InfoMessageSmall'));
            checkSmallInfoQueue();
        }
    }

    export function hideWithFade(div: JQuery<HTMLElement>) {
        return div.addClass('fadeIn_hide').removeClass('fadeIn_show');
    }

    export function showWithFade(div: JQuery<HTMLElement>) {
        return div.show().addClass('fadeIn_show').removeClass('fadeIn_hide');
    }

    export function hideWithEffect(div: JQuery<HTMLElement>, effect: string, options?: any, duration?: number | string, complete?: Function) {
        return hideWithFade(div);
    }
    export function showWithEffect(div: JQuery<HTMLElement>, effect: string, options?: any, duration?: number | string, complete?: Function) {
        return showWithFade(div);
    }
    export function makeDraggable(div: JQuery<HTMLElement>) {
        //return div.draggable();
        return div;
    }
    export function makeAutoComplete(input: JQuery<HTMLElement>, options: AutocompleteOptions) {
        //TODO make autocomplete
        //return input.autocomplete(options as JQueryUI.AutocompleteOptions);
        return input;
    }
    export function removeAutoComplete(input: JQuery<HTMLElement>) {
        //input.autocomplete("destroy");
    }
    export function makeUnselectable($target: JQuery<HTMLElement>, applyToChildren?: boolean) {
        $target.addClass('unselectable') // All these attributes are inheritable
            .attr('unselectable', 'on') // For IE9 - This property is not inherited, needs to be placed onto everything
            .attr('draggable', 'false'); // For moz and webkit, although Firefox 16 ignores this when -moz-user-select: none; is set, it's like these properties are mutually exclusive, seems to be a bug.

        if (applyToChildren) {
            $target // Apply non-inheritable properties to the child elements
                .find('*')
                .attr('draggable', 'false')
                .attr('unselectable', 'on');
        }
    }
}

module DynToolTip {
    export function showHowToInfo(infoContainer: string, showForId: string, howToKey: string, openFunc?: () => void, always?: boolean, nextFunc?: () => void) {
        //TODO show dynamic tooltip
    }
}