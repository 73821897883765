module spt.ThreeJs.utils {

    THREE.ShaderLib['GmMeshShader'] = {
        uniforms: {
            map: <THREE.IUniform>{ type: "t", value: null },
            //uvOffsetAndScale: <THREE.IUniform>{ type: "vec4", value: uvOffsetAndScale },
            uvMatrix: <THREE.IUniform>{ type: "mat3", value: new THREE.Matrix3() },
            exc1: <THREE.IUniform>{ type: "vec4", value: new THREE.Vector4(-1, -1, -1, -1) },
            exc2: <THREE.IUniform>{ type: "vec4", value: new THREE.Vector4(-1, -1, -1, -1) },
        },
        vertexShader: [
            "precision mediump float;",

            "uniform mat4 modelViewMatrix;                                                                                                                                                                                                  ",
            "uniform mat4 projectionMatrix;",
            //"uniform mat4 modelMatrix;",
            //"uniform mat4 viewMatrix;",
            "uniform mat3 normalMatrix;",
            "uniform mat3 uvMatrix;",
            //"uniform vec3 cameraPosition;",

            "attribute vec4 position;",
            "attribute vec4 uv;",
            "attribute vec4 normal;",

            "varying vec2 vUv;",
            "varying vec3 vNormal;",
            "varying float vLayer;",

            "void main() {",

            "vec3 vt = uvMatrix * vec3(uv.y * 256.0 + uv.x, uv.w * 256.0 + uv.z, 1.0);",

            "vUv = vt.xy;",

            "vec3 objectNormal = vec3( normal.xyz * 2.0 - 1.0 );",
            "vec3 transformedNormal = normalMatrix * objectNormal;",
            "vNormal = normalize( transformedNormal );",
            "vLayer = position.w;",
            "gl_Position = projectionMatrix * modelViewMatrix * vec4( position.xyz, 1.0 );",
            "}"
        ].join("\n"),
        fragmentShader: [
            "precision mediump float;",

            "uniform sampler2D map;",

            "uniform vec4 exc1;",
            "uniform vec4 exc2;",

            "varying vec2 vUv;",
            "varying vec3 vNormal;",
            "varying float vLayer;",

            "vec4 is_eq(vec4 x, vec4 y) {",
            "return 1.0 - abs(sign(x - y));",
            "}",

            "void main() {",

            "vec4 vl = vec4(floor(vLayer + 0.5));",
            "vec4 ex = is_eq(vl, exc1) + is_eq(vl, exc2);",

            "if(dot(ex, vec4(1)) > 0.0) {",
            "discard;",
            "}",

            "vec3 color = texture2D(map, vUv).rgb;",
            "gl_FragColor = vec4( color, 1.0 );",
            "}"
        ].join("\n")
    };

    THREE.ShaderLib['GmMeshDepthShader'] = {
        uniforms: {
            //map: <THREE.IUniform>{ type: "t", value: null },
            //uvOffsetAndScale: <THREE.IUniform>{ type: "vec4", value: uvOffsetAndScale },
            //uvMatrix: <THREE.IUniform>{ type: "mat3", value: new THREE.Matrix3() },
            exc1: <THREE.IUniform>{ type: "vec4", value: new THREE.Vector4(-1, -1, -1, -1) },
            exc2: <THREE.IUniform>{ type: "vec4", value: new THREE.Vector4(-1, -1, -1, -1) },
        },
        vertexShader: [
            "precision highp float;",

            "uniform mat4 modelViewMatrix;",
            "uniform mat4 projectionMatrix;",
            "uniform mat4 modelMatrix;",
            //"uniform mat4 viewMatrix;",

            "attribute vec4 position;",

            "varying float vLayer;",
            "varying float vDepth;",

            "void main() {",
            "vLayer = position.w;",
            "vec4 mPos = modelMatrix * vec4( position.xyz, 1.0 );",
            "vDepth = mPos.z;",
            "gl_Position = projectionMatrix * modelViewMatrix * vec4( position.xyz, 1.0 );",
            "}"
        ].join("\n"),
        fragmentShader: [
            "precision highp float;",

            "uniform vec4 exc1;",
            "uniform vec4 exc2;",

            "varying float vLayer;",
            "varying float vDepth;",

            //"const vec3 PackFactors = vec3( 256. * 256. * 256., 256. * 256.,  256. );",
            //"const float PackUpscale = 256. / 255.;", // fraction -> 0..1 (including 1)
            //"const float ShiftRight8 = 1. / 256.;",

            //"vec4 packDepthToRGBA( const in float v ) {",
            //    "vec4 r = vec4( fract( v * PackFactors ), v );",
            //    "r.yzw -= r.xyz * ShiftRight8;", // tidy overflow
            //    "return r * PackUpscale;",
            //"}",

            "vec3 packDepthToRGB( const in float f ) {",
            "float b = floor(f / 256.);",
            "float g = floor((f - b * 256.));",
            "float r = floor((f - b * 256. - g) * 256.);",
            "return vec3(r, g, b) / 255.;",
            "",
            "}",

            "vec4 is_eq(vec4 x, vec4 y) {",
            "return 1.0 - abs(sign(x - y));",
            "}",

            "void main() {",
            "vec4 vl = vec4(floor(vLayer + 0.5));",
            "vec4 ex = is_eq(vl, exc1) + is_eq(vl, exc2);",

            "if(dot(ex, vec4(1)) > 0.0) {",
            "discard;",
            "}",

            "gl_FragColor = vec4(packDepthToRGB( vDepth ), 1.);",
            //"gl_FragColor = vec4(packDepthToRGB( gl_FragCoord.z ), 1.);",
            "}"
        ].join("\n")
    };

    export class GmMeshMaterial extends THREE.RawShaderMaterial {

        texFlipY: boolean;

        constructor(map: THREE.Texture, texFlipY: boolean, uvOffsetAndScale: Float32Array, octantsToExclude: number[], depth?: boolean) {
            super();

            if (!octantsToExclude || !octantsToExclude.length)
                octantsToExclude = [-1, -1, -1, -1, -1, -1, -1, -1];

            while (octantsToExclude.length < 9)
                octantsToExclude.push(-1);

            var uniforms = this.uniforms = THREE.UniformsUtils.clone(depth ? THREE.ShaderLib['GmMeshDepthShader'].uniforms : THREE.ShaderLib['GmMeshShader'].uniforms);

            (uniforms.exc1.value as THREE.Vector4).fromArray(octantsToExclude);
            (uniforms.exc2.value as THREE.Vector4).fromArray(octantsToExclude, 4);

            if (!depth) {
                var sx = uvOffsetAndScale[2];
                var sy = uvOffsetAndScale[3];

                var tx = uvOffsetAndScale[0];
                var ty = uvOffsetAndScale[1];

                uniforms.map.value = map;

                if (texFlipY) {
                    uniforms.uvMatrix.value.set(
                        sx, 0, tx * sx,
                        0, -sy, 1 - ty * sy,
                        0, 0, 1
                    );
                } else {
                    uniforms.uvMatrix.value.set(
                        sx, 0, tx * sx,
                        0, sy, ty * sy,
                        0, 0, 1
                    );
                }

                this.vertexShader = THREE.ShaderLib['GmMeshShader'].vertexShader;
                this.fragmentShader = THREE.ShaderLib['GmMeshShader'].fragmentShader;
            } else {
                this.vertexShader = THREE.ShaderLib['GmMeshDepthShader'].vertexShader;
                this.fragmentShader = THREE.ShaderLib['GmMeshDepthShader'].fragmentShader;
            }

            this.transparent = false;
            this.side = THREE.FrontSide;
            this.depthTest = true;
            this.depthWrite = true;
            this.vertexColors = false; //THREE.NoColors;
            this.texFlipY = texFlipY;
        }
    }

    export function generateGeometryFromMeshData(meshData: IGMMeshData, keepNodeData?: boolean) {

        if (meshData && meshData._cachedData && meshData._cachedData.geo && meshData._cachedData.geo.isBufferGeometry) {
            return meshData._cachedData.geo as THREE.BufferGeometry;
        }

        if (!meshData || !meshData.vertices)
            return null;

        var vertices = meshData.vertices;

        //var _v = new THREE.Vector3(),
        //    _b = new THREE.Box3();

        //var _w = [];

        //for (var j = 0; j < vertices.length; j += 8) {
        //    var x = vertices[j];
        //    var y = vertices[j + 1];
        //    var z = vertices[j + 2];
        //    var w = vertices[j + 3];
        //    _v.set(x, y, z);
        //    _b.expandByPoint(_v);

        //    _w[w] = (_w[w] as number || 0) + 1;
        //}

        //console.log("dims:");
        //console.log(JSON.stringify(octantsToExclude));
        //console.log(JSON.stringify(_b));
        //console.log(JSON.stringify(_w));
        //console.log("");

        var layerBounds = meshData.layerBounds,
            drawMaxRange = layerBounds[3];

        var dataIndices = meshData.indices,
            indexLen = Math.min(dataIndices.length - 2, drawMaxRange),
            a: number, b: number, c: number, idx: number,
            indices = new Uint16Array(indexLen * 3);

        //convert from (OpenGL drawmode) Triangle Strip to Triangle List for threejs

        for (let i = 0; i < indexLen; i += 2) {
            a = dataIndices[i];
            b = dataIndices[i + 1];
            c = dataIndices[i + 2];

            idx = i * 3;

            indices[idx] = a;
            indices[idx + 1] = b;
            indices[idx + 2] = c;
        }

        for (let i = 1; i < indexLen; i += 2) {
            a = dataIndices[i];
            b = dataIndices[i + 1];
            c = dataIndices[i + 2];
            
            idx = i * 3;

            indices[idx] = a;
            indices[idx + 1] = c;
            indices[idx + 2] = b;
        }

        if (!indices.length)
            return;

        var geometry = new THREE.BufferGeometry();
        var interleavedBuffer = new THREE.InterleavedBuffer(vertices, 8);

        geometry.setAttribute('position', new THREE.InterleavedBufferAttribute(interleavedBuffer, 4, 0, false));
        geometry.setAttribute('uv', new THREE.InterleavedBufferAttribute(interleavedBuffer, 4, 4, false));
        geometry.setAttribute('normal', new THREE.BufferAttribute(meshData.normals, 4, true));

        geometry.setIndex(new THREE.Uint16BufferAttribute(indices, 1));
        //geometry.setIndex(new THREE.Uint16BufferAttribute(meshData.indices, 1)); //THREE.TriangleStripDrawMode

        //if (drawMaxRange > 0 && drawMaxRange < meshData.indices.length)
        //    geometry.setDrawRange(0, drawMaxRange);

        if (geometry.boundingSphere === null)
            geometry.boundingSphere = new THREE.Sphere();

        if (geometry.boundingBox === null)
            geometry.boundingBox = new THREE.Box3();

        geometry.boundingBox.min.set(0, 0, 0);
        geometry.boundingBox.max.set(256, 256, 256);

        geometry.boundingBox.getBoundingSphere(geometry.boundingSphere);

        if (!meshData._cachedData)
            meshData._cachedData = {};

        meshData._cachedData.geo = geometry;

        if (!keepNodeData) {
            delete meshData.indices;
            delete meshData.layerBounds;
            delete meshData.normals;
            delete meshData.vertexAlphas;
            delete meshData.vertices;

            geometry.addEventListener("dispose", () => {
                meshData._isDisposed = true;
                console.log(`geometry disposed.`);
            });
        }

        //console.log(`geometry build: ${drawMaxRange} vertices.`);

        return geometry;
    }

    export function generateMaterialFromMeshData(meshData: IGMMeshData, octantsToExclude?: number[], keepNodeData?: boolean, depthMaterial?: boolean) {
        //if (!meshData || !meshData.octantCounts.some((oc, i) => oc > 0 && (!octantsToExclude || octantsToExclude.indexOf(i) === -1)))
        //    return null;

        if (!meshData || !meshData.octantCounts)
            return null;

        if (octantsToExclude && octantsToExclude.length) {
            var octantCounts = meshData.octantCounts,
                octantsVisible = false;

            for (var i = octantCounts.length; i--;) {
                if (octantCounts[i] > 0 && octantsToExclude.indexOf(i) === -1) {
                    octantsVisible = true;
                    break;
                }
            }
            if (!octantsVisible)
                return null;
        }

        var texture: THREE.Texture = null;
        var flipY: boolean = false;

        if (!depthMaterial) {
            if (meshData && meshData._cachedData && meshData._cachedData.tex && meshData._cachedData.tex.isTexture) {
                texture = meshData._cachedData.tex as THREE.Texture;
                flipY = !!meshData._cachedData.texFlip;
            } else {

                if (!meshData.texture)
                    return null;

                flipY = false;

                switch (meshData.texture.textureFormat) {
                    case 6: //dxt1
                        texture = spt.ThreeJs.utils.Dxt1TextureFromBytes(meshData.texture.bytes, meshData.texture.width, meshData.texture.height);
                        break;
                    default: //jpeg
                        texture = spt.ThreeJs.utils.TextureFromBytes(meshData.texture.bytes.buffer, true);
                        break;
                }

                if (!meshData._cachedData)
                    meshData._cachedData = {};

                meshData._cachedData.tex = texture;
                meshData._cachedData.texFlip = flipY;

                if (!keepNodeData) {
                    delete meshData.texture.bytes;
                    delete meshData.texture;

                    texture.addEventListener("dispose", () => {
                        meshData._isDisposed = true;
                    });
                }
            }
        }

        return new GmMeshMaterial(texture, flipY, meshData.uvOffsetAndScale, octantsToExclude, depthMaterial);
    }

    export interface IGmMeshUserData {
        north: number;
        east: number;
        south: number;
        west: number;
        path: string;
        octantsToExclude: number[];
    }

    export function generateMeshesFromNodeData(nodeData: IGMNodeData, octantBox: GMapsUtils.OctantBox, octantsToExclude?: number[], depthMaterial?: boolean): THREE.Mesh[] {
        var result: THREE.Mesh[] = [];

        if (!octantsToExclude)
            octantsToExclude = [];

        if (octantsToExclude.length >= 8)
            return result;

        let { North, East, South, West } = octantBox.box,
            meshMatrix = new THREE.Matrix4();

        if (nodeData.matrixGlobeFromMesh)
            meshMatrix.fromArray(nodeData.matrixGlobeFromMesh as any, 0);

        nodeData.meshes.forEach(meshData => {

            var mat = generateMaterialFromMeshData(meshData, octantsToExclude, false, depthMaterial);

            if (mat) {
                var geo = generateGeometryFromMeshData(meshData);

                if (geo) {

                    var mesh = new THREE.Mesh(geo, mat);

                    mesh.applyMatrix4(meshMatrix);

                    mesh.userData.north = North;
                    mesh.userData.east = East;
                    mesh.userData.south = South;
                    mesh.userData.west = West;
                    mesh.userData.path = octantBox.path;
                    mesh.userData.level = octantBox.level;
                    mesh.userData.octantsToExclude = octantsToExclude ? octantsToExclude.slice() : [];

                    result.push(mesh);

                }
            }
        });

        return result;
    }

    export var latLngToQuaternion = (() => {

        var rz = new THREE.Quaternion().setFromEuler(new THREE.Euler(0, 0, -Math.PI / 2)),
            ry = new THREE.Quaternion().setFromEuler(new THREE.Euler(0, -Math.PI / 2, 0)),
            rot = new THREE.Euler();

        return (lat: number, lng: number, target: THREE.Quaternion) => {
            //rz * (ry * rll)

            target.setFromEuler(rot.set(0, lat / 180 * Math.PI, -lng / 180 * Math.PI)).premultiply(ry).premultiply(rz);

            return target;
            //target.setFromEuler(new THREE.Euler(0, 0, Math.PI)).multiply(new THREE.Quaternion().setFromEuler(new THREE.Euler(0, -Math.PI / 2, 0)).multiply(new THREE.Quaternion().setFromEuler(new THREE.Euler(Math.PI / 2, lat / 180 * Math.PI, -lng / 180 * Math.PI, "XYZ"))));
        }
    })();

    export var earthRadiusByLatitude = (() => {
        const r1 = 6378137,//6376509, //radius at equator 
            r2 = 6356752,//6382941, //radius at pole
            r1_2 = r1 * r1,
            r2_2 = r2 * r2;

        return (lat: number) => {
            var s = Math.sin(lat / 180 * Math.PI),
                c = Math.cos(lat / 180 * Math.PI);

            return Math.sqrt((Math.pow(r1_2 * c, 2) + Math.pow(r2_2 * s, 2)) / (Math.pow(r1 * c, 2) + Math.pow(r2 * s, 2)));
        }
    })();

    export var latLngToMatrix4 = (() => {
        var q = new THREE.Quaternion(),
            m = new THREE.Matrix4();

        return (lat: number, lng: number, target: THREE.Matrix4) => {
            target.makeRotationFromQuaternion(latLngToQuaternion(lat, lng, q)).premultiply(m.makeTranslation(0, 0, -6371008.8/*-earthRadiusByLatitude(lat)*/));

            return target;
        }
    })();

    export var raycastGmMesh = (() => {
        var inverseMatrix = new THREE.Matrix4();
        var ray = new THREE.Ray();
        var sphere = new THREE.Sphere();

        var vA = new THREE.Vector3();
        var vB = new THREE.Vector3();
        var vC = new THREE.Vector3();

        //var tempA = new THREE.Vector3();
        //var tempB = new THREE.Vector3();
        //var tempC = new THREE.Vector3();

        //var uvA = new THREE.Vector2();
        //var uvB = new THREE.Vector2();
        //var uvC = new THREE.Vector2();

        var intersectionPoint = new THREE.Vector3();
        var intersectionPointWorld = new THREE.Vector3();

        function checkIntersection(object, material, raycaster, ray, pA, pB, pC, point) {

            var intersect;

            if (material.side === THREE.BackSide) {

                intersect = ray.intersectTriangle(pC, pB, pA, true, point);

            } else {

                intersect = ray.intersectTriangle(pA, pB, pC, material.side !== THREE.DoubleSide, point);

            }

            if (intersect === null) return null;

            intersectionPointWorld.copy(point);
            intersectionPointWorld.applyMatrix4(object.matrixWorld);

            var distance = raycaster.ray.origin.distanceTo(intersectionPointWorld);

            if (distance < raycaster.near || distance > raycaster.far) return null;

            return {
                distance: distance,
                point: intersectionPointWorld.clone(),
                object: object
            } as any;

        }

        function checkBufferGeometryIntersection(object, material, raycaster, ray, position, /*uv, */a, b, c) {

            vA.fromBufferAttribute(position, a);
            vB.fromBufferAttribute(position, b);
            vC.fromBufferAttribute(position, c);

            var intersection = checkIntersection(object, material, raycaster, ray, vA, vB, vC, intersectionPoint);

            //if ( intersection ) {

            //    if ( uv ) {

            //        uvA.fromBufferAttribute( uv, a );
            //        uvB.fromBufferAttribute( uv, b );
            //        uvC.fromBufferAttribute( uv, c );

            //        intersection.uv = (<any>THREE.Triangle).getUV( intersectionPoint, vA, vB, vC, uvA, uvB, uvC, new THREE.Vector2() );

            //    }

            //    var face = new THREE.Face3( a, b, c );
            //    THREE.Triangle.getNormal( vA, vB, vC, face.normal );

            //    intersection.face = face;

            //}

            return intersection;

        }

        return (raycaster: THREE.Raycaster, mesh: THREE.Mesh, intersects: THREE.Intersection[]) => {
            let geometry = (mesh && mesh.geometry) as THREE.BufferGeometry,
                material = mesh.material,
                matrixWorld = mesh.matrixWorld;

            if (!geometry || !geometry.boundingSphere || !geometry.boundingBox)
                return;

            sphere.copy(geometry.boundingSphere);
            sphere.applyMatrix4(matrixWorld);

            if (raycaster.ray.intersectsSphere(sphere) === false)
                return;

            inverseMatrix.getInverse(matrixWorld);
            ray.copy(raycaster.ray).applyMatrix4(inverseMatrix);

            // Check boundingBox before continuing

            if (ray.intersectsBox(geometry.boundingBox) === false)
                return;

            let index = geometry.index,
                drawRange = geometry.drawRange,
                position = geometry.attributes.position,
                //uv = geometry.attributes.uv,
                start = Math.max(0, drawRange.start),
                end = Math.min(index.count, (drawRange.start + drawRange.count)),
                a: number,
                b: number,
                c: number;

            for (var i = start, il = end; i < il; i += 1) {

                a = index.getX(i);
                b = index.getX(i + 1);
                c = index.getX(i + 2);

                var intersection = (i & 1)
                    ? checkBufferGeometryIntersection(mesh, material, raycaster, ray, position, /*uv,*/ a, c, b)
                    : checkBufferGeometryIntersection(mesh, material, raycaster, ray, position, /*uv,*/ a, b, c);

                if (intersection) {
                    //intersection.faceIndex = Math.floor( i / 3 ); // triangle number in indexed buffer semantics
                    intersects.push(intersection);
                }
            }
        };
    })();

    //export function TestAddMeshesToController(meshes: THREE.Mesh[]) {
    //    if (meshes && meshes.length && window["currentProjectLocation"]) {
    //        var controller = LS.Client3DEditor.Controller.Current,
    //            scene = controller.scene;

    //        //var translation = new THREE.Vector3(),
    //        //    rotation = new THREE.Quaternion(),
    //        //    scale = new THREE.Vector3();

    //        //meshes[0].matrix.decompose(translation, rotation, scale);

    //        var obj = new THREE.Object3D();

    //        var ll = window["currentProjectLocation"];
    //        //latLngToQuaternion(ll[0], ll[1], obj.quaternion);

    //        //var q = latLngToQuaternion(ll[0], ll[1], new THREE.Quaternion());
    //        var transform = latLngToMatrix4(ll[0], ll[1], new THREE.Matrix4());//.premultiply(new THREE.Matrix4().makeScale(100, 100, 100));

    //        //var refp = new THREE.Vector3().applyMatrix4(meshes[0].matrix).applyMatrix4(tr);

    //        //var tr = new THREE.Vector3(-translation.x, -translation.y, -translation.z);

    //        //var latLng = new MapDrawing.LatLng(ll[0], ll[1]);

    //        //var q = new THREE.Quaternion().setFromEuler(new THREE.Euler(0, latLng.Latitude / 180 * Math.PI, -latLng.Longitude / 180 * Math.PI, "ZYX"));
    //        //obj.quaternion.setFromEuler(new THREE.Euler(0, 0, Math.PI)).multiply(new THREE.Quaternion().setFromEuler(new THREE.Euler(0, -Math.PI / 2, 0)).multiply(new THREE.Quaternion().setFromEuler(new THREE.Euler(Math.PI / 2, latLng.Latitude / 180 * Math.PI, -latLng.Longitude / 180 * Math.PI, "XYZ"))));
    //        //obj.rotation.set(-latLng.Longitude / 180 * Math.PI, latLng.Latitude / 180 * Math.PI, 0);
    //        //obj.scale.set(100, 100, 100);
    //        //obj.position.set(0, 0, -refpt.z * 100 + 5000);
    //        //obj.position.set(-translation.x, -translation.y, -translation.z);
    //        //obj.matrix.multiplyMatrices(new THREE.Matrix4().makeScale(1000, 1000, 1000), new THREE.Matrix4().makeTranslation(-translation.x, -translation.y, -translation.z));
    //        //obj.updateMatrix();
    //        obj.applyMatrix4(transform);

    //        //console.log(`${ll[0]},${ll[1]} -> RX = ${obj.rotation.x / Math.PI * 180}}, RY = ${obj.rotation.y / Math.PI * 180}, RZ = ${obj.rotation.z / Math.PI * 180}`);

    //        var p = new THREE.Vector3(),
    //            bd = new THREE.Box3();

    //        meshes.forEach(m => {
    //            m.applyMatrix4(transform);

    //            bd.expandByPoint(p.set(0, 0, 0).applyMatrix4(m.matrix));
    //            //m.position.add(tr);
    //            //m.updateMatrix();
    //            obj.add(m);
    //        });

    //        var raycaster = new THREE.Raycaster();

    //        raycaster.ray.origin.set(0, 0, 10000);
    //        raycaster.ray.direction.set(0, 0, -1);

    //        //var interects = raycaster.intersectObjects(meshes, true);

    //        var container = new THREE.Object3D();

    //        container.add(obj);
    //        container.position.setZ(-bd.min.z);
    //        container.updateMatrix();

    //        scene.add(container);

    //        scene.add(new THREE.AxesHelper(1000));

    //    } else {
    //        console.log("no meshes...");
    //    }
    //}
}