module AManager {

    export interface IAjaxHandler {
        call: (param: any, callBack: (result: any) => void, errorfunc?: (msg: string) => void) => void
    }

    export interface IAjaxBindings {
        [url: string]: IAjaxBindingHandler | IAjaxBindingOptions;
    }

    export interface IAjaxBindingHandler {
        (data?: any): any;
    }

    export interface IAjaxBindingOptions {
        method: IAjaxBindingHandler;
        onFinished?: IAjaxBindingHandler;
    }

    var registeredHandlers: { [name: string]: (param: any, callBack: (result: any) => void, errorfunc?: (msg: string) => void) => void } = {};

    export function RegisterAjaxHandler(url: string, handler: (param: any, callBack: (result: any) => void, errorfunc?: (msg: string) => void) => void) {
        //var url = "/WebServices/" + handler.webservice + "/" + handler.urlToMethod;
        registeredHandlers[url] = handler;
    }

    function notImplemented(url: string) {
        console.log("Webservice call not implemented: " + url);
        console.trace();
        return {} as any;
    }

    export function RegisterAjaxBindings(bindings: IAjaxBindings) {
        Object.keys(bindings).forEach(url => {
            var binding = bindings[url],
                fn: IAjaxBindingHandler,
                onFinished: IAjaxBindingHandler;
            if (binding && typeof (binding) === "object" && binding.method) {
                fn = binding.method;
                onFinished = binding.onFinished;
            }
            else
                fn = binding || notImplemented.bind(this, url);
            RegisterAjaxHandler(url, (param, callBack, errfn) => {
                var res = fn(param);
                if (res && res.then) {
                    if (onFinished)
                        res.then(data => {
                            callBack(data);
                            onFinished();
                        });
                    else
                        res.then(callBack);
                }
                else {
                    callBack(res);
                    if (onFinished)
                        onFinished();
                }
            });
        });
    }

    export var sessionIsOnline = true;

    export function CheckSession() {

    }

    export function GetTranslation(key: string, callBack: (tranlated: string) => void) {
        //TODO callback with translation
        callBack(key);
    }

    //returns true if a handler was registered with this url
    function callRegisteredHandler(url: string, data: any, callBack: (result: any) => void, errorfunc?: (msg: string) => void, withLoading?: boolean) {

        if (withLoading) {
            if (registeredHandlers[url]) {
                var j = LoaderManager.addLoadingJob();
                registeredHandlers[url](data, (res) => {
                    LoaderManager.finishLoadingJob(j);
                    callBack(res);
                }, (err) => {
                    LoaderManager.finishLoadingJob(j);
                    if (errorfunc)
                        errorfunc(err);
                });
                return true;
            }
        } else {
            if (registeredHandlers[url]) {
                registeredHandlers[url](data, callBack, errorfunc);
                return true;
            }
        }

        console.log("No registered ajax handler found for: " + url);

        callBack(null);
        return true;

        //return false;
    }

    export function AjaxEx(webservice: string, urlToMethod: string, param: string | {}, callBack: (result: any) => void, errorfunc?: (msg: string) => void, root?: string, withLoading?: boolean) {
        var url = "/WebServices/" + webservice + "/" + urlToMethod;
        if (callRegisteredHandler(url, param, callBack, errorfunc, withLoading))
            return;

        //TODO ajax call
        return;
    }

    export function Ajax(webservice: string, urlToMethod: string, param: any, callBack: (result: any) => void, errorfunc?: (msg: string) => void) {

        var url = "/WebServices/" + webservice + "/" + urlToMethod;
        if (callRegisteredHandler(url, param, callBack, errorfunc))
            return;

        //TODO ajax call
        return;
    }

    export function AjaxWithLoading(webservice: string, urlToMethod: string, param: any, callBack: (result: any) => void, errorfunc?: (msg: string) => void) {
        var url = "/WebServices/" + webservice + "/" + urlToMethod;
        if (callRegisteredHandler(url, param, callBack, errorfunc, true))
            return;

        //TODO ajax call
        return;
    }

    export function UploadFile(inputTarget: HTMLInputElement | string, controller: string, method: string, options: { paramName?: string, acceptedExtensions?: string[], maxFileSize?: number, dataType?: string }, callBack: (result: any) => void, errorfunc?: (msg: string) => void) {

        if (!inputTarget)
            return DManager.showErrorMessage("Fileupload Error: input target is invalid");

        var maxFileSize = options && options.maxFileSize ? options.maxFileSize : 64 * 1048576; //64 mb

        var input: HTMLInputElement;

        if (inputTarget && typeof inputTarget === "string" && inputTarget.length) {
            if (inputTarget[0] === "#" || inputTarget[0] === ".") {
                input = $(inputTarget).get(0) as HTMLInputElement;
            } else
                input = document.getElementById(inputTarget) as HTMLInputElement;
        }
        else
            input = inputTarget as HTMLInputElement;

        if (!input || !(input instanceof HTMLInputElement) || input.type !== 'file')
            return DManager.showErrorMessage("Fileupload Error: input target is invalid");

        if (!input.value || !input.files || !input.files[0])
            return DManager.showErrorMessage("Fileupload Error: Uploaded file is invalid");

        var file = input.files[0];
        var url = input.value;
        var ext = url.substring(url.lastIndexOf('.') + 1).toLowerCase();

        if (!file.size)
            return DManager.showErrorMessage("Fileupload Error: Uploaded file has an invalid file size");

        if (file.size > maxFileSize)
            return DManager.showErrorMessage("Fileupload Error: Uploaded file is too big.");

        var acceptedExtensions = (options && options.acceptedExtensions && options.acceptedExtensions.length) ? options.acceptedExtensions : input.accept ? input.accept.split(',') : [];

        if (acceptedExtensions && acceptedExtensions.length)
            acceptedExtensions = acceptedExtensions.filter(s => s && s.length > 1).map(s => s[0] === "." ? s.substring(1).toLowerCase() : s.toLowerCase());

        if (!ext.length || (acceptedExtensions && acceptedExtensions.length && acceptedExtensions.every(ae => ae !== ext)))
            return DManager.showErrorMessage("Fileupload Error: File has an invalid extension.");

        //now begin file upload with ajax
        var param = options && options.paramName ? options.paramName : (input.name || "filename");

        var urlToMethod = `/${controller}/${method}`;

        var fd = new FormData();
        fd.append(param, file);

        var dataType = (options && options.dataType && options.dataType.length) ? options.dataType : "json";

        //LoaderManager.loadingAnimation(0, true);
        var j = LoaderManager.addLoadingJob();

        $.ajax({
            url: urlToMethod,
            data: fd,
            cache: false,
            contentType: false,
            processData: false,
            dataType: dataType,
            type: 'POST',
            success: (result: any, textStatus: string, jqXHR: JQueryXHR) => {
                if (callBack)
                    callBack(result);
            },
            error: (jqXHR: JQueryXHR, textStatus: string, errorThrown) => {
                //DManager.showErrorMessage(jqXHR.responseText);
                if (errorfunc)
                    errorfunc(textStatus);
                if (jqXHR.responseText && jqXHR.responseText.length > 3 && jqXHR.responseText.charCodeAt(0) == 31 && jqXHR.responseText.charCodeAt(1) == 65535 && jqXHR.responseText.charCodeAt(2) == 8) {
                    //error message with invalid characters. (maybe compressed)
                    (jqXHR as any).responseText = "Error";
                }
                if (window['OnError'])
                    window.OnError(jqXHR, textStatus + " on " + urlToMethod + " with " + param, errorThrown);
            },
            complete: (jqXHR: JQueryXHR, textStatus: string) => {
                //LoaderManager.loadingAnimation(0, false);
                LoaderManager.finishLoadingJob(j);
            },
            xhr: () => {
                var req = (<any>$.ajaxSettings).xhr();
                if (req) {
                    req.upload.addEventListener('progress', (ev) => {
                        //TODO Display progress Percentage
                        //var progress = Math.min(1, Math.max(0, +(ev.loaded / ev.total)));
                        //LoaderManager.loadingAnimation(progress, true, true);
                    }, false);
                }
                return req;
            }
        });

    }

    //#region AjaxRequest

    export class AjaxRequest {

        public withLoading(loading?: boolean): AjaxRequest {
            this.mLoading = !!loading;
            return this;
        }

        public Root(root: string): AjaxRequest {
            this.mRoot = root;
            return this;
        }

        public Url(url: string): AjaxRequest {
            this.mUrl = url;
            return this;
        }

        public Area(area: string): AjaxRequest {
            this.mArea = area;
            return this;
        }

        public Controller(controller: string): AjaxRequest {
            this.mRoot = "";
            this.mService = controller;
            return this;
        }

        public Service(service: string): AjaxRequest {
            this.mService = service;
            return this;
        }

        public Method(method: string): AjaxRequest {
            this.mMethod = method;
            return this;
        }

        public Type(type: string): AjaxRequest {
            this.mType = type;
            return this;
        }

        public Context(context: string): AjaxRequest {
            this.mContext = context;
            return this;
        }

        public Data(data: any): AjaxRequest {
            if (this.mResult || this.mError)
                throw "Ajax Error. Setting Data after request has been send.";
            this.mData = data;
            return this;
        }

        public ContentType(contentType: string): AjaxRequest {
            this.mContentType = contentType;
            return this;
        }

        public DataType(dataType: string): AjaxRequest {
            this.mDataType = dataType;
            return this;
        }

        public CallBack(callBack?: (result?: any) => void, skipSend?: boolean): AjaxRequest {
            this.mCallBack = callBack || null;

            if (this.mResult && this.mCallBack)
                this.mCallBack(this.mResult);
            else if (!skipSend)
                this.send();

            return this;
        }

        public OnError(errorFunc: (textStatus?: string, ajaxRequest?: AjaxRequest) => void): AjaxRequest {
            this.mOnError = errorFunc || null;
            if (this.mError && this.mOnError)
                this.mOnError(this.mError);
            return this;
        }

        private mLoading = true;
        private mRoot: string = null;
        private mArea: string = null;
        private mUrl: string = null;
        private mService = "";
        private mMethod = "";
        private mType = "POST";
        private mContext: any = document.body;
        private mData: any = null;
        private mContentType = "application/json";
        private mDataType = "json";
        private mCallBack: (result?: any) => void;
        private mOnError: (textStatus?: string, ajaxRequest?: AjaxRequest) => void;
        private mResult: any;
        private mError: string;

        public clear() {
            if (this.mError)
                delete this.mError;

            if (this.mResult)
                delete this.mResult;
        }

        //called automatically with the CallBack method
        public send() {

            this.clear();

            AManager.CheckSession();

            //var load = this.mLoading ? LoaderManager.addLoadingJob() : null;

            var url = this.mUrl ? this.mUrl : ((this.mArea ? "/Areas/" + this.mArea : "") + (this.mRoot !== "" ? (this.mRoot || "/WebServices") : "") + "/" + this.mService + "/" + this.mMethod);

            //var param = this.mData ? (typeof this.mData !== "string" ? JSON.stringify(this.mData) : this.mData) : null;

            if (callRegisteredHandler(url, this.mData, this.mCallBack, this.mOnError, this.mLoading))
                return;

            //if (AManager.sessionIsOnline) {
            //$.ajax({
            //    type: this.mType,
            //    url: url,
            //    context: this.mContext,
            //    data: param,
            //    contentType: this.mContentType,
            //    dataType: this.mDataType,
            //    success: (res) => {
            //        var result = res && res.d !== undefined ? res.d : res;
            //        this.mResult = result;
            //        if (this.mCallBack)
            //            this.mCallBack(result);
            //        if (load)
            //            LoaderManager.finishLoadingJob(load);
            //    },
            //    error: (jqXHR, textStatus, errorThrown) => {
            //        this.mError = textStatus;
            //        if (this.mOnError)
            //            this.mOnError(textStatus);
            //        if (load)
            //            LoaderManager.finishLoadingJob(load);
            //        //if (jqXHR.responseText && jqXHR.responseText.length > 3 && jqXHR.responseText.charCodeAt(0) == 31 && jqXHR.responseText.charCodeAt(1) == 65535 && jqXHR.responseText.charCodeAt(2) == 8) {
            //        //    //error message with invalid characters. (maybe compressed)
            //        //    jqXHR.responseText = "Error";
            //        //}
            //        if (window.OnError)
            //            window.OnError(jqXHR, textStatus + " on " + url + " with " + param, errorThrown);
            //    }
            //});
            //}
            //else
            //    window.location.href = '/Account/LogOff';
            return this;
        }
    }

    export function withLoading(loading?: boolean): AjaxRequest {
        return new AjaxRequest().withLoading(loading);
    }

    export function Root(root: string): AjaxRequest {
        return new AjaxRequest().Root(root);
    }

    export function Url(url: string): AjaxRequest {
        return new AjaxRequest().Url(url);
    }

    export function Controller(controller?: string): AjaxRequest {
        return new AjaxRequest().Controller(controller);
    }

    export function Area(area: string): AjaxRequest {
        return new AjaxRequest().Area(area);
    }

    export function Service(service: string): AjaxRequest {
        return new AjaxRequest().Service(service);
    }

    export function Method(method: string): AjaxRequest {
        return new AjaxRequest().Method(method);
    }

    export function Type(type: string): AjaxRequest {
        return new AjaxRequest().Type(type);
    }

    export function Context(context: string): AjaxRequest {
        return new AjaxRequest().Context(context);
    }

    export function Data(data: any): AjaxRequest {
        return new AjaxRequest().Data(data);
    }

    export function ContentType(contentType: string): AjaxRequest {
        return new AjaxRequest().ContentType(contentType);
    }

    export function DataType(dataType: string): AjaxRequest {
        return new AjaxRequest().DataType(dataType);
    }

    //#endregion
}

module SolarProTool {
    export var AjaxEx = AManager.AjaxEx;

    export class AjaxObject {

        constructor(ws: string) {
            this.ws = ws;
        }

        ws: string;
        method: string;
        data: any;

        Call(method: string) {
            this.method = method;
            return this;
        }

        Data(data?: any) {
            this.data = data;
            return this;
        }

        CallBack(callBack?: (result?: any) => void, errorFunc?: (textStatus?: string, ajaxRequest?: AManager.AjaxRequest) => void, withoutLoading?: boolean) {
            AManager.Url("/" + this.ws + "/" + this.method).Data(this.data).OnError(errorFunc).withLoading(!withoutLoading).CallBack(callBack);
            return this;
        }
    }
}

(SolarProTool as any).Ajax = (ws: string) => {
    return new SolarProTool.AjaxObject(ws);
};
