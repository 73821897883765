var EventManager;
var ListWithEvents;
var MapWithEvents;
(function() {
    "use strict";

    if (Array.iterateMany === undefined) {

        //iterate big array efficent
        Array.iterateMany = function (arr, fn) {
            var len = arr.length;
            var it = Math.floor(len / 8);
            var l = len % 8;
            var i = 0;

            if (l > 0) {
                do {
                    fn(arr[i++]);
                } while (--l > 0);
            }
            if (it <= 0) return;
            do {
                fn(arr[i++]);
                fn(arr[i++]);
                fn(arr[i++]);
                fn(arr[i++]);
                fn(arr[i++]);
                fn(arr[i++]);
                fn(arr[i++]);
                fn(arr[i++]);
            } while (--it > 0);
        };
    }

    if (Array.iterateHelper === undefined) {

        //call function multiple times
        Array.iterateHelper = function (times, fn) {
            var it = Math.floor(times / 8);
            var l = times % 8;
            var i = 0;

            if (l > 0) {
                do {
                    fn(i++);
                } while (--l > 0);
            }
            if (it <= 0) return;
            do {
                fn(i++);
                fn(i++);
                fn(i++);
                fn(i++);
                fn(i++);
                fn(i++);
                fn(i++);
                fn(i++);
            } while (--it > 0);
        };
    }

    if (Array.BinarySeach === undefined) {

        //returns index of element in ordered array. Negativ index indicating insert position if element was not found (eq. splice(idx >= 0 ? idx : -idx-1, 0, element))
        var defaultCompare = function(a, b) {
            return a - b;
        };

        Array.BinarySearchHelper = function(ar, el, compareFn) {
            var m = 0;
            var n = ar.length - 1;
            if (!compareFn) compareFn = defaultCompare;
            while (m <= n) {
                var k = (n + m) >> 1;
                var cmp = compareFn(el, ar[k]);
                if (cmp > 0) {
                    m = k + 1;
                } else if (cmp < 0) {
                    n = k - 1;
                } else {
                    return k;
                }
            }
            return -m - 1;
        }
    }

    if (!window.FillArray) {
        window.FillArray = function (value, count) {
            if (Array.prototype.fill)
                return new Array(count).fill(value);
            var res = [];
            for (var i = 0; i < count; i++)
                res.push(value);
            return res;
        };
    }

    EventManager = function () { }

    EventManager.prototype = {

        constructor: EventManager,

        addListener: function (type, listener) {
            if (!type) {
                if (this._allListeners === undefined) Object.defineProperty(this, '_allListeners', { enumerable: false, configurable: true, writable: true, value: [] });
                var allListeners = this._allListeners;
                if (allListeners.indexOf(listener) === -1)
                    allListeners.push(listener);
                return this;
            }

            if (this._listeners === undefined) Object.defineProperty(this, '_listeners', { enumerable: false, configurable: true, writable: true, value: {} });

            var listeners = this._listeners;

            if (listeners[type] === undefined)
                listeners[type] = [];

            if (listeners[type].indexOf(listener) === -1)
                listeners[type].push(listener);
            return this;
        },

        hasListener: function (type, listener) {

            if (!type) {
                if (this._allListeners === undefined) return false;
                return this._allListeners.indexOf(listener) !== -1;
            }

            if (this._listeners === undefined) return false;

            var listeners = this._listeners;

            if (listeners[type] !== undefined && listeners[type].indexOf(listener) !== -1) {

                return true;

            }

            return false;

        },

        removeListener: function (type, listener) {
            if (!type && !listener) {
                if (this._allListeners !== undefined)
                    delete this._allListeners;
                if (this._listeners !== undefined)
                    delete this._listeners;
                return;
            }
            if (!type) {
                if (this._allListeners === undefined) return;
                var allListeners = this._allListeners;
                var idx = allListeners.indexOf(listener);
                if (idx !== -1)
                    allListeners.splice(idx, 1);
                return;
            }
            
            if (this._listeners === undefined) return;

            var listeners = this._listeners;
            var listenerArray = listeners[type];

            if (listenerArray !== undefined) {

                if (!listener) {
                    listeners[type] = [];
                } else {
                    var index = listenerArray.indexOf(listener);

                    if (index !== -1) {

                        listenerArray.splice(index, 1);

                    }
                }
            }
        },

        dispatchEvent: function (event) {
            /*if (typeof event === "string")
                event = { type: event };*/
            event.object = this;

            if (this._allListeners !== undefined) {
                var allListeners = this._allListeners;
                var arr = [];
                var len = allListeners.length;

                for (var i = 0; i < len; i++) {
                    arr[i] = allListeners[i];
                }

                for (var i = 0; i < len; i++) {
                    arr[i].call(this, event);
                }
            };

            if (this._listeners === undefined) return;

            var listeners = this._listeners;
            var listenerArray = listeners[event.type];

            if (listenerArray !== undefined) {

                

                var array = [];
                var length = listenerArray.length;

                for (var i = 0; i < length; i++) {

                    array[i] = listenerArray[i];

                }

                for (var i = 0; i < length; i++) {

                    array[i].call(this, event);

                }
            }
        }
    };

    EventManager.apply = function (object) {

        object.addListener = EventManager.prototype.addListener;
        object.hasListener = EventManager.prototype.hasListener;
        object.removeListener = EventManager.prototype.removeListener;
        object.dispatchEvent = EventManager.prototype.dispatchEvent;

    };

    // List with events for splice and update type of changes
    ListWithEvents = function (array) {
        if (array && array.length) {
            for (var i = 0, l = array.length; i < l; i++) {
                this.push(array[i]);
            }
        }
        Object.defineProperty(this, 'push', {
            enumerable: false, configurable: false, writable: false, value: function (v) {
                Array.prototype.push.call(this, v);
                this.dispatchEvent({type: 'splice', index: this.length - 1, removed: [], addedCount: 1 });
            }
        });
        Object.defineProperty(this, 'unshift', {
            enumerable: false, configurable: false, writable: false, value: function (v) {
                Array.prototype.unshift.call(this, v);
                this.dispatchEvent({ type: 'splice', index: 0, removed: [], addedCount: 1 });
            }
        });
        Object.defineProperty(this, 'pop', {
            enumerable: false, configurable: false, writable: false, value: function () {
                var rem = Array.prototype.pop.call(this);
                this.dispatchEvent({ type: 'splice', index: this.length, removed: [rem], addedCount: 0 });
                return rem;
            }
        });
        Object.defineProperty(this, 'shift', {
            enumerable: false, configurable: false, writable: false, value: function () {
                var rem = Array.prototype.shift.call(this);
                this.dispatchEvent({ type: 'splice', index: 0, removed: [rem], addedCount: 0 });
                return rem;
            }
        });
        Object.defineProperty(this, 'splice', {
            enumerable: false, configurable: false, writable: false, value: function (index, howmany, item) {
                var rem;
                if (typeof item === "undefined") {
                    rem = Array.prototype.splice.call(this, index, howmany);
                    this.dispatchEvent({ type: 'splice', index: index, removed: rem, addedCount: 0 });
                } else {
                    rem = Array.prototype.splice.call(this, index, howmany, item);
                    this.dispatchEvent({ type: 'splice', index: index, removed: rem, addedCount: 1 });
                }
                return rem;
            }
        });
        Object.defineProperty(this, 'sort', {
            enumerable: false, configurable: false, writable: false, value: function (fn) {
                var copy = [];
                for (var i = 0, l = this.length; i < l; i++) {
                    copy.push(this[i]);
                }
                if (typeof fn === "function")
                    Array.prototype.sort.call(this, fn);
                else
                    Array.prototype.sort.call(this);
                for (var i = 0, l = this.length; i < l; i++) {
                    if(copy[i] !== this[i])
                        this.dispatchEvent({ type: 'update', name: i, oldValue: copy[i] });
                }
            }
        });
    };

    ListWithEvents.prototype = new Array();
    Object.defineProperty(ListWithEvents.prototype, 'constructor', { enumerable: false, configurable: false, writable: false, value: ListWithEvents });
    Object.defineProperty(ListWithEvents.prototype, 'toString', {
        enumerable: false, configurable: false, writable: false, value: function () {
            return "[object Array]";
        }
    });
    Object.defineProperty(ListWithEvents.prototype, 'toJSON', {
        enumerable: false, configurable: false, writable: false, value: function () {
            return this.toArray();
        }
    });
    Object.defineProperty(ListWithEvents.prototype, 'toArray', {
        enumerable: false, configurable: false, writable: false, value: function () {
            var arr = [];
            for (var i = 0, l = this.length; i < l; i++) {
                arr.push(this[i]);
            }
            return arr;
        }
    });
    Object.defineProperty(ListWithEvents.prototype, 'fromArray', {
        enumerable: false, configurable: false, writable: false, value: function (array) {
            this.splice(0, this.length);
            if (array && array.length) {
                for (var i = 0, l = array.length; i < l; i++) {
                    this.push(array[i]);
                }
            }
        }
    });
    Object.defineProperty(ListWithEvents.prototype, 'clear', {
        enumerable: false, configurable: false, writable: false, value: function () {
            this.splice(0, this.length);
        }
    });
    Object.defineProperty(ListWithEvents.prototype, 'getAt', {
        enumerable: false, configurable: false, writable: false, value: function (idx) {
            return this[idx];
        }
    });
    Object.defineProperty(ListWithEvents.prototype, 'setAt', {
        enumerable: false, configurable: false, writable: false, value: function (idx, v) {
            var old = this[idx];
            this[idx] = v;
            this.dispatchEvent({ type: 'update', name: idx, oldValue: old });
        }
    });
    Object.defineProperty(ListWithEvents.prototype, 'addListener', { enumerable: false, configurable: false, writable: false, value: EventManager.prototype.addListener });
    Object.defineProperty(ListWithEvents.prototype, 'hasListener', { enumerable: false, configurable: false, writable: false, value: EventManager.prototype.hasListener });
    Object.defineProperty(ListWithEvents.prototype, 'removeListener', { enumerable: false, configurable: false, writable: false, value: EventManager.prototype.removeListener });
    Object.defineProperty(ListWithEvents.prototype, 'dispatchEvent', { enumerable: false, configurable: false, writable: false, value: EventManager.prototype.dispatchEvent });


    MapWithEvents = function (initialObject) {
        if (typeof initialObject === "object") {
            var keys = Object.keys(initialObject);
            for (var i = 0, j = keys.length; i < j; i++) {
                var key = keys[i];
                this[key] = initialObject[key];
            }
        }
    };

    MapWithEvents.prototype = {
        constructor: MapWithEvents,
        get: function(key) {
            return this[key];
        },
        set: function(key, v) {
            if (this.hasOwnProperty(key)) {
                var old = this[key];
                if (old !== v) {
                    this[key] = v;
                    this.dispatchEvent({ type: 'update', name: key, oldValue: old, newValue: v });
                }
            } else {
                this[key] = v;
                this.dispatchEvent({ type: 'add', name: key, newValue: v });
            }
        },
        copy: function(obj) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    this.set(key, obj[key]);
                }
            }
        },
        update: function(key, v) {
            var old = this[key];
            if (old !== v) {
                this[key] = v;
                this.dispatchEvent({ type: 'update', name: key, oldValue: old, newValue: v });
            }
        },
        has: function(key) {
            return this.hasOwnProperty(key);
        },
        remove: function (key) {
            if (this.has(key)) {
                var old = this[key];
                delete this[key];
                this.dispatchEvent({ type: 'remove', name: key, oldValue: old });
            }
        },
        get keys() {
            return Object.keys(this);
        },
        set keys(v) {
            var t = v; //assignment to temp var so minify optimization dosn't remove the parameter
        },
        empty: function() {
            var keys = this.keys;
            var old = [];
            for (var i = 0, j = keys.length; i < j; i++) {
                var key = keys[i];
                old.push({name: key, value: this[key]});
                delete this[key];
            }
            this.dispatchEvent({ type: 'empty', removed: old });
        },
        clone: function () {
            var result = {};
            for (var key in this) {
                if (this.hasOwnProperty(key)) {
                    result[key] = this[key];
                }
            }
            return result;
        }
    };

    EventManager.apply(MapWithEvents.prototype);

})();