interface CanvasRenderingContext2D {
    fill(path: Path2D, fillRule?: CanvasFillRule): void;
    fill(path?: Path2D, fillRule?: CanvasFillRule): void;
}

module CanvasDrawing {
    import Point2D = MapDrawing.Point2D;

    export var path2DSupported: boolean = (() => {
        try {
            return !!window["Path2D"] && typeof (new Path2D()).moveTo === "function";
        } catch (e) {
        }
        return false;
    })();

    export interface IStyle {
        fillStyle: string | CanvasGradient | CanvasPattern | MapDrawing.Color;
        strokeStyle: string | CanvasGradient | CanvasPattern | MapDrawing.Color;
        lineWidth: number;

        apply(ctx: CanvasRenderingContext2D, vp: Viewport);
        applyToContext(ctx: CanvasRenderingContext2D, scaleFactor: number);
    }

    export class Style implements IStyle {
        //private static uuid = 1;

        //uid: number;

        fillStyle: string | CanvasGradient | CanvasPattern | MapDrawing.Color;
        strokeStyle: string | CanvasGradient | CanvasPattern | MapDrawing.Color;
        lineWidth: number;
        lineDash: number[];

        constructor(fillStyle?: string | CanvasGradient | CanvasPattern | MapDrawing.Color,
            strokeStyle?: string | CanvasGradient | CanvasPattern | MapDrawing.Color, lineWidth: number = 1, lineDash: number[] = []) {
            //this.uid = Style.uuid++;
            this.fillStyle = fillStyle || null;
            this.strokeStyle = strokeStyle || null;
            this.lineWidth = lineWidth;
            this.lineDash = lineDash;
        }

        apply(ctx: CanvasRenderingContext2D, vp: Viewport) {
            if (this.strokeStyle) {
                let scaleFactor = vp.scaleFactor;
                ctx.lineWidth = this.lineWidth / scaleFactor;
                if (ctx.setLineDash)
                    ctx.setLineDash(this.lineDash.map(v => v / scaleFactor));
                ctx.strokeStyle = Style.getCanvasStyle(this.strokeStyle);
                ctx.lineCap = "round";
                ctx.lineJoin = "round";
            }
            if (this.fillStyle)
                ctx.fillStyle = Style.getCanvasStyle(this.fillStyle);
        }

        applyToContext(ctx: CanvasRenderingContext2D, scaleFactor: number) {
            if (this.strokeStyle) {
                ctx.lineWidth = this.lineWidth / scaleFactor;
                if (ctx.setLineDash)
                    ctx.setLineDash(this.lineDash.map(v => v / scaleFactor));
                ctx.strokeStyle = Style.getCanvasStyle(this.strokeStyle);
            }
            if (this.fillStyle)
                ctx.fillStyle = Style.getCanvasStyle(this.fillStyle);
        }

        static getCanvasStyle(style: string | CanvasGradient | CanvasPattern | MapDrawing.Color):
            string | CanvasGradient | CanvasPattern {
            if (!style) {
                return null;
            } else if (style instanceof MapDrawing.Color) {
                return style.toStringRgba();
            } else {
                return style;
            }
        }

        static createHatchPattern(color: string | MapDrawing.Color = "#000000", angle: number = 45, size: number = 32, offset: number = 16, lineWidth: number = 2) : HTMLCanvasElement {
            
            var p = document.createElement("canvas") as HTMLCanvasElement;

            var s2 = size * 0.5;

            p.width = size;
            p.height = size;

            var ctx = p.getContext('2d');
            
            let len = Math.sqrt(size * size * 2),
                len2 = len * 0.5,
                min = s2 - len2,
                max = s2 + len2;

            if (color instanceof MapDrawing.Color)
                ctx.strokeStyle = color.toStringRgba();
            else
                ctx.strokeStyle = color;

            ctx.translate(s2, s2);
            ctx.rotate(angle * Math.PI / 180);
            ctx.translate(-s2, -s2);
            
            ctx.beginPath();

            for (let x = min; x <= max; x += offset) {
                ctx.moveTo(x, min);
                ctx.lineTo(x, max);
            }

            ctx.stroke();

            return p;
        }
    }

    export interface IShape {
        id?: string;
        style: IStyle;
        children: IShape[];
        parent: IShape;
        transform: MapDrawing.Transformation3D;
        visible: boolean;
        draw(ctx: CanvasRenderingContext2D, vp: Viewport);
        update();
        clearChildren();
        removeChild(child: IShape);
        removeFromParent();
        add(shape: IShape);
        traverse(fn: (shape: IShape) => boolean);
    }

    export class ShapeObject implements IShape {
        id: string;
        style: IStyle = null;
        private _children: IShape[] = [];
        parent: IShape = null;
        visible = true;
        private _bounds: MapDrawing.Bounds2D = null;
        transform: MapDrawing.Transformation3D = MapDrawing.Transformation3D.Identity;

        get fill() {
            return !!(this.style && this.style.fillStyle);
        }

        get stroke() {
            return !!(this.style && this.style.strokeStyle);
        }

        get bounds() {
            var bds = this._bounds;
            if (bds == null)
                bds = this._bounds = this.calculateBounds();
            return bds;
        }

        set bounds(v: MapDrawing.Bounds2D) {
            this._bounds = v;
        }

        update() {
            this._bounds = null;
        }

        calculateBounds(): MapDrawing.Bounds2D {
            //need to override
            return null;
        }

        get children() {
            return this._children;
        }

        set children(v: IShape[]) {
            this.clearChildren();
            if (v && v.length)
                v.forEach(this.add.bind(this));
        }

        private isVisible(vp: Viewport) {
            return this.visible && !!this.style && (this.bounds == null || this.bounds.Overlaps(vp.bounds));
        }

        prepareAndDraw(ctx: CanvasRenderingContext2D, vp: Viewport) {
            this.style.apply(ctx, vp);
        }

        draw(ctx: CanvasRenderingContext2D, vp: Viewport) {

            ctx.save();

            this.transform.transformCanvas(ctx);

            if (this.isVisible(vp))
                this.prepareAndDraw(ctx, vp);

            this.children.forEach(child => child.draw(ctx, vp));

            ctx.restore();
        }

        add(shape: IShape) {
            shape.parent = this;
            this.children.push(shape);
        }

        clearChildren(): void {
            if (this.children.length)
                this.children.splice(0, this.children.length).forEach(child => {
                    child.parent = null;
                });
        }

        removeChild(child: IShape): void {
            this.children = this.children.filter(c => c !== child);
        }

        removeFromParent(): void {
            if (this.parent)
                this.parent.removeChild(this);
            this.parent = null;
        }

        traverse(fn: (shape: IShape) => boolean): void {
            if (!fn(this))
                return;

            if (this.children)
                this.children.forEach(child => { child.traverse(fn); });
        }
    }

    export class ShapePolygon extends ShapeObject {

        closed: boolean = true;
        points: Point2D[] = [];
        private path: Path2D;

        constructor(pts?: Point2D[], style?: IStyle, closed: boolean = true) {
            super();
            this.closed = closed;
            if (pts && pts.length)
                this.points = pts;
            if (style)
                this.style = style;
        }
        
        prepareAndDraw(context: CanvasRenderingContext2D, vp: Viewport) {
            super.prepareAndDraw(context, vp);

            let ctx: CanvasRenderingContext2D | Path2D;

            context.beginPath();

            if (path2DSupported) {
                if (this.path) {
                    if (this.fill)
                        context.fill(this.path);
                    if (this.stroke)
                        context.stroke(this.path);
                    return;
                } else
                    ctx = this.path = new Path2D();
            } else {
                ctx = context;
            }

            let pts = this.points;
            if (pts && pts.length > 1) {

                let p0 = pts[0];

                ctx.moveTo(p0.x, p0.y);

                for (var i = 1, j = pts.length; i < j; i++) {
                    let p = pts[i];
                    ctx.lineTo(p.x, p.y);
                }

                if (this.closed)
                    ctx.closePath();
            }
            
            if (this.fill)
                context.fill(this.path);
            if (this.stroke)
                context.stroke(this.path);
        }

        calculateBounds(): MapDrawing.Bounds2D {
            return MapDrawing.Bounds2D.FromPoints(this.points);
        }

        update() {
            super.update();
            this.path = null;
        }
    }

    export class ShapeLine extends ShapePolygon {
        //start: Point2D = new Point2D();
        //end: Point2D = new Point2D();
        //width: number = 1;

        constructor(start: Point2D, end: Point2D, width?: number, style?: IStyle) {
            super(null, style);
            if (width > 0) {
                var dirRight = end.sub(start).GetNormalized().RightHandNormal().mul(width * 0.5);
                this.closed = true;
                this.points = [start.add(dirRight), end.add(dirRight), end.sub(dirRight), start.sub(dirRight)];
            } else {
                this.closed = false;
                this.points = [start, end];
            }
        }
    }
    
    export class ShapeCircle extends ShapeObject {
        origin: Point2D = new Point2D();
        radius: number = 1;

        constructor(origin: Point2D, radius: number, style?: IStyle) {
            super();
            this.origin = origin;
            this.radius = +radius;
            if (style)
                this.style = style;
        }

        prepareAndDraw(context: CanvasRenderingContext2D, vp: Viewport) {
            super.prepareAndDraw(context, vp);
            
            context.beginPath();

            context.arc(this.origin.x, this.origin.y, this.radius, 0, 2 * Math.PI);
            
            if (this.fill)
                context.fill();
            if (this.stroke)
                context.stroke();
        }

        calculateBounds(): MapDrawing.Bounds2D {
            var w = this.radius * 2;
            return MapDrawing.Bounds2D.FromCenterSize(this.origin, w, w);
        }

        update() {
            super.update();
        }
    }

    export class Viewport {
        bounds: MapDrawing.Bounds2D;
        canvasWidth: number;
        canvasHeight: number;

        constructor(canvasWidth: number, canvasHeight: number, bounds?: MapDrawing.Bounds2D) {
            this.bounds = bounds || new MapDrawing.Bounds2D(new Point2D(0, 0), new Point2D(+canvasWidth, +canvasHeight));
            this.updateCanvasSize(canvasWidth, canvasHeight);
        }

        updateCanvasSize(canvasWidth: number, canvasHeight: number) {
            this.canvasWidth = canvasWidth;
            this.canvasHeight = canvasHeight;
        }

        set(x: number, y: number, width: number, height: number) {
            this.bounds = MapDrawing.Bounds2D.FromCenterSize(new Point2D(x, y), width, height);
        }

        get x() {
            return this.bounds.Center.x;
        }

        set x(v: number) {
            this.translate(v - this.x, 0);
        }

        get y() {
            return this.bounds.Center.y;
        }

        set y(v: number) {
            this.translate(v - this.y, 0);
        }

        get width() {
            return this.bounds.Delta.x;
        }

        set width(v: number) {
            this.set(this.x, this.y, v, this.height);
        }

        get height() {
            return this.bounds.Delta.y;
        }

        set height(v: number) {
            this.set(this.x, this.y, this.width, v);
        }

        translate(x: number, y: number) {
            this.offset(new Point2D(x, y));
        }

        offset(v: Point2D) {
            let bd = this.bounds;
            bd.Min = bd.Min.add(v);
            bd.Max = bd.Max.add(v);
        }

        scale(sx: number, sy: number) {
            let bd = this.bounds,
                c = bd.Center,
                d = bd.Delta.multiply(new Point2D(sx, sy));

            this.bounds = MapDrawing.Bounds2D.FromCenterDimension(c, d);
        }

        zoom(z: number) {
            let bd = this.bounds,
                c = bd.Center,
                d = bd.Delta.mul(z);

            this.bounds = MapDrawing.Bounds2D.FromCenterDimension(c, d);
        }

        get scaleFactor(): number {
            let canvasWidth = this.canvasWidth,
                canvasHeight = this.canvasHeight,
                bd = this.bounds,
                delta = bd.Delta,
                w = delta.x,
                h = delta.y;

            return Math.min(canvasWidth / w, canvasHeight / h);
        }

        setTransform(ctx: CanvasRenderingContext2D) {

            let canvasWidth = this.canvasWidth,
                canvasHeight = this.canvasHeight,
                bd = this.bounds,
                min = bd.Min,
                delta = bd.Delta,
                w = delta.x,
                h = delta.y,
                scale = Math.min(canvasWidth / w, canvasHeight / h),
                offx = (canvasWidth / scale - w) * 0.5,
                offy = (canvasHeight / scale - h) * 0.5;
            
            ctx.setTransform(1, 0, 0, -1, 0, canvasHeight);

            ctx.scale(scale, scale);

            ctx.translate(offx - min.x, offy - min.y);
        }

    }

    export class ShapeModel extends ShapeObject {

        constructor(drawer?: Drawer) {
            super();
            this._drawer = drawer || null;
        }

        enabled: boolean = true;

        _drawer: Drawer;

        get drawer(): Drawer {
            return this._drawer;
        }

        set drawer(value: Drawer) {
            this._drawer = value;
            if (value)
                value.model = this;
        }

        removeFromParent(): void {
            if (this.drawer)
                this.drawer.model = null;
            this.drawer = null;
        }
    }

    export class Drawer {

        static GetInstance(canvasWidth: number, canvasHeight: number, className?: string, id?: string): Drawer {
            
            var canvas = document.createElement('canvas') as HTMLCanvasElement;

            canvas.id = id || "DrawerCanvas_" + spt.Utils.GenerateGuid();
            canvas.width = canvasWidth;
            canvas.height = canvasHeight;
            if (className)
                canvas.className = className;
            
            return new Drawer(canvas);
        }

        constructor(canvas: HTMLCanvasElement) {
            this.viewport = new Viewport(+canvas.width, +canvas.height);
            this.model = new ShapeModel(this);
            this.canvas = canvas;
        }
        
        private _canvas: HTMLCanvasElement;

        get canvas(): HTMLCanvasElement {
            return this._canvas;
        }

        set canvas(value: HTMLCanvasElement) {
            this._canvas = value;
            this.resetViewport();
            if (value) {
                this.context = value.getContext('2d');
                //this.context.imageSmoothingEnabled = true;
            }
        }

        viewport: Viewport;
        model: ShapeModel;
        context: CanvasRenderingContext2D;

        resetViewport() {
            if (!this.canvas)
                return;
            let canvas = this.canvas;
            this.viewport.updateCanvasSize(+canvas.width, +canvas.height);
        }

        setSize(w: number, h: number) {
            this.canvas.width = w;
            this.canvas.height = h;
            this.resetViewport();
        }

        draw(model?: ShapeModel) {

            if (!model)
                model = this.model;

            if (!this.canvas || !this.viewport || !model || !model.enabled)
                return;

            let canvas = this.canvas,
                w = canvas.width,
                h = canvas.height,
                ctx = this.context,
                vp = this.viewport;

            ctx.setTransform(1, 0, 0, 1, 0, 0);

            ctx.clearRect(0, 0, w, h);

            vp.setTransform(ctx);

            model.draw(ctx, vp);
        }

        getImageData() {
            var canvas = this.canvas,
                w = canvas.width,
                h = canvas.height,
                ctx = this.context;
            return ctx.getImageData(0, 0, w, h);
        }

        puImageDate(imgData: ImageData) {
            var ctx = this.context;
            ctx.putImageData(imgData, 0, 0);
        }

        toDataURL(type = "image/png") {
            return this.canvas.toDataURL(type);
        }

        copyToImage() {
            var img = document.createElement("img") as HTMLImageElement;

            img.src = this.toDataURL();

            return img;
        }

        appendTo<T extends Node>(parent: T): void {
            parent.appendChild(this.canvas);
        }
    }
}