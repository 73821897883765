module spt.Utils {

    export function arrayFrom<T>(arr: ArrayLike<T>): T[] {
        if (Array.from)
            return Array.from(arr);
        return Array.prototype.slice.call(arr);
    }

    export function saveObjectAsJsonFile(fileNameToSaveAs: string, object: any) {
        spt.Utils.saveTextAsFile(fileNameToSaveAs, [JSON.stringify(object, null, "\t")]);
    }

    export function saveTextAsFile(fileNameToSaveAs: string, textToWrite: BlobPart[]) {
        if (!textToWrite) {
            console.log("Nothing to download");
            return;
        }

        console.log("Creating blob to download");

        var textFileAsBlob = new Blob(textToWrite, {
            type: 'text/plain'
        });

        saveBlobAsFile(fileNameToSaveAs, textFileAsBlob);
    }

    export function saveBlobAsFile(fileNameToSaveAs: string, blob: Blob) {
        /* Saves a text string as a blob file*/
        var ie = navigator.userAgent.match(/MSIE\s([\d.]+)/),
            ie11 = navigator.userAgent.match(/Trident\/7.0/) && navigator.userAgent.match(/rv:11/),
            ieEDGE = navigator.userAgent.match(/Edge/g),
            ieVer = (ie ? ie[1] : (ie11 ? 11 : (ieEDGE ? 12 : -1)));

        if (ie && ieVer < 10) {
            console.log("No blobs on IE ver<10");
            return;
        }

        if (ieVer > -1) {
            console.log("Initiate msSaveBlob");
            window.navigator.msSaveBlob(blob, fileNameToSaveAs);

        } else {
            console.log("create anchor html link element");
            var downloadLink = document.createElement("a") as any;
            downloadLink.download = fileNameToSaveAs;
            downloadLink.href = window.URL.createObjectURL(blob);
            downloadLink.onclick = () => { document.body.removeChild(downloadLink); };
            downloadLink.style.display = "none";
            document.body.appendChild(downloadLink);
            console.log("initiate click on anchor element");
            downloadLink.click();
        }
    }

    export function SerializeTrianglesToObj(triangles: { x: number; y: number; z: number; }[][], name?: string, input?: ObjSerializedResult): ObjSerializedResult {
        var pts: { x: number; y: number; z: number; }[] = [];
        var indices: number[] = [];
        var idx = 0;
        triangles.forEach(triangle => {
            pts.push(triangle[0], triangle[1], triangle[2]);
            indices.push(idx, idx + 1, idx + 2);
            idx += 3;
        });

        return SerializePointsToObj(pts, indices, name, input);
    }

    export function SerializePointsToObj(points: { x: number; y: number; z: number; }[], indices: number[], name?: string, input?: ObjSerializedResult): ObjSerializedResult {
        var res = input || {
            output: [],
            indexCount: 0,
            vertexCount: 0,
            uvCount: 0
        };

        //if (!transformation)
        //    transformation = new THREE.Matrix4();

        //var normalTransform = new THREE.Matrix3().getNormalMatrix(transformation);

        var output = res.output;

        output.push("o " + (name || ("Geometry " + spt.Utils.GenerateGuid())));

        //var bounds = new THREE.Box3().setFromPoints(points);

        //var v = new THREE.Vector3();

        points.forEach(v => {

            //v.copy(p).applyMatrix4(transformation);

            output.push("v " + v.x.toFixed(6) + " " + v.y.toFixed(6) + " " + v.z.toFixed(6));
        });

        //points.forEach(v => {
        //    output += "vt " + x.toFixed(6) + " " + y.toFixed(6) + "\n";
        //});

        //points.forEach(v => {
        //    output += "vn " + v.x.toFixed(6) + " " + v.y.toFixed(6) + " " + v.z.toFixed(6) + "\n";
        //});

        var idxOffset = res.vertexCount + 1;

        for (var i = 0, j = indices.length; i < j; i += 3) {
            output.push("f " + (indices[i] + idxOffset) + " " + (indices[i + 1] + idxOffset) + " " + (indices[i + 2] + idxOffset));
        }

        output.push("s off");

        res.vertexCount += points.length;
        //res.uvCount += positions.length / 3;
        res.indexCount += indices.length;

        return res;
    }

    export function isPowerOfTwo(n: number) {
        return (n !== 0) && ((n & (n - 1)) === 0);
    }

    export function escapeHtml(text) {
        var map = {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            "'": '&#039;'
        };

        return text && text.length ? text.replace(/[&<>"']/g, (m) => map[m]) : "";
    }

    export class Stopwatch {

        startTime: number;
        endTime: number;
        elapsedMilliseconds: number;

        constructor() {
            this.startTime = 0;
            this.endTime = 0;
            this.elapsedMilliseconds = 0;
        }

        start() {
            this.startTime = performance.now();
            this.endTime = this.startTime;
            return this;
        }

        stop() {
            this.endTime = performance.now();
            this.elapsedMilliseconds = this.endTime - this.startTime;
        }

        restart() {
            this.startTime = performance.now();
        }

        log(name: string) {
            this.stop();
            console.log(name + " " + this.elapsedMilliseconds + " ms");
            this.restart();
        }
    }

    export function addGetParameterToUrl(url: string, name: string, val: string) {
        if (!url || !name || !val)
            return url;

        let rgex = new RegExp(`(\\?|&)(${name}=).*?(&|$)`, "i"),
            m = url.search(rgex);

        if (m !== -1)
            return url.replace(rgex, `$1${name}=${val}$3`);

        return url + (url.indexOf("?") !== -1 ? "&" : "?") + name + "=" + val;
    }

    export function addSwitchId(url: string, id: string): string {
        return addGetParameterToUrl(url, "_sw_id", id);
    }

    export function addTempId(url: string, id: string): string {
        return addGetParameterToUrl(url, "_tp_id", id);
    }

    export function GenerateGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    export function GuidEmpty() {
        return "00000000-0000-0000-0000-000000000000";
    }

    export function LoadImage(src: string, callBack: (img: HTMLImageElement) => void): HTMLImageElement {
        var img = new Image();

        img.onload = () => {
            callBack(img);
            img.onload = null;
        };

        img.src = src;

        return img;
    }

    export function SetFloatInInput(input: string | HTMLElement | JQuery, val: number) {
        if (input === undefined || input === null || input === "")
            return;

        var $input: JQuery = $(input as any);

        if (!$input.length)
            return;

        if (val === undefined || val === null)
            $input.val("");
        else if ((<any>$).global.culture)
            $input.val(('' + val).replace(".", (<any>$).global.culture.numberFormat["."]));
        else
            $input.val('' + val);
    }

    export interface IFloatFromInputOptions {
        min?: number;
        max?: number;
        precision?: number;
        notImperial?: boolean;
        isFeet?: boolean;
        applyArithmetic?: boolean;
        from?: string;
        to?: string;
        scale?: number;
    }

    export function GetFloatFromInput(input: string | HTMLElement | JQuery, options?: IFloatFromInputOptions) {
        if (input === undefined || input === null || input === "")
            return null;

        var $input: JQuery = $(input as any);

        if (!$input.length)
            return null;

        return spt.Utils.GetFloatFromInputValue($input.val() as any, options);
    }

    export function GetFloatFromInputValue(input: string, options?: IFloatFromInputOptions) {
        if (input === undefined || input === null || input === "")
            return options && options.min !== undefined ? options.min : null;

        var valStr = ('' + input);
        var value: number;
        if (options && options.applyArithmetic) {
            value = spt.Utils.ApplyNumberArithmetic(valStr, options.isFeet, options.notImperial);
        } else {
            value = spt.Utils.ConvertStringToValue(valStr, options && options.isFeet, options && options.notImperial);
        }

        if (isNaN(value) || !isFinite(value))
            return options && options.min !== undefined ? options.min : null;

        if (options && options.from && options.to)
            value = convertLengthUnit(value, options);

        if (options && options.scale)
            value = value * options.scale;

        if (options && (typeof options.min === "number" || typeof options.max === "number" || typeof options.precision === "number")) {
            value = spt.Utils.ApplyNumberconstraints(value, options);
        }

        return value;
    }

    export function ConvertStringToValue(val: number | string, isFeet?: boolean, notImperial?: boolean) {
        if (typeof val === "number")
            return val;
        if (val === undefined || val === null || val === "" || typeof val !== "string")
            return null;

        var newValue = ('' + val).replace(",", ".");
        if (UseImperialSystem && !notImperial)
            newValue = ImperialLengthToValue(newValue, !!isFeet);
        var nv = typeof newValue === "string" ? newValue.replace(",", ".") : newValue;
        var newValueAsNum = parseFloat(nv);
        return isNaN(newValueAsNum) ? null : newValueAsNum;
    }

    export function ApplyNumberconstraints(val: number, options: any) {
        if (typeof val !== "number" || typeof options !== "object" || options === null)
            return val;
        if (typeof options.min === "number")
            val = Math.max(options.min, val);
        if (typeof options.max === "number")
            val = Math.min(options.max, val);
        if (typeof options.precision === "number") {
            //Nachkommastellen
            var fac = Math.pow(10, options.precision);
            val = Math.round(val * fac) / fac;
        }
        return val;
    }

    export function ApplyNumberArithmetic(val: number | string, isFeet?: boolean, notImperial?: boolean) {
        var result = 0;
        if (typeof val === "string") {

            val = (<string>val).replace(/[\s]/g, '');

            if (val[0] !== '+' && val[0] !== '-')
                val = '+' + val;

            //only plus and minus
            var match = (<string>val).match(/[+-][^+-]+/g);
            var arr = [0];

            if (match && match.length)
                arr = match.map(v => (ConvertStringToValue(v, isFeet, notImperial) || 0));

            result = arr.reduce((p, c) => (p + c));

        } else if (typeof val === "number")
            result = val;
        return result;
    }

    //clamp angle between -180 and 180 degree
    export function ClampAngle(val: number) {
        return val < 0 ? (180 - ((Math.abs(val) + 180) % 360)) : (((val + 180) % 360) - 180);
    }

    export function ConvertCamelCase(txt: string) {
        if (!txt || !txt.length || typeof txt !== "string")
            return txt;
        var result = txt.replace(/([A-Z])/g, " $1");
        return result.charAt(0).toUpperCase() + result.slice(1);
    }

    export function SeperateUriParameters(url: string) {
        if (typeof url !== "string")
            return null;

        var components = url.split("?");
        var target = components[0];
        var params = null;
        if (components.length > 1) {
            params = {};
            components[1].split("&").forEach(part => {
                var item = part.split("=");
                params[decodeURIComponent(item[0])] = decodeURIComponent(item[1]);
            });
        }

        return {
            target: target,
            params: params,
            uri: url
        };
    }

    export function ApplyParamsObject(paramsObj: any, iteratorFn?: (key: any, val: any) => void) {
        if (typeof iteratorFn === "function") {
            for (var key in paramsObj) {
                iteratorFn(key, paramsObj[key]);
            }
        } else {
            for (var key in paramsObj) {
                this[key] = paramsObj[key];
            }
        }
    }

    export function getAllHtmlChildren(elem: HTMLElement) {
        var elems: HTMLElement[] = [elem];

        var result: HTMLElement[] = [];

        while (elems.length) {
            var el = elems.pop();
            result.push(el);
            if (el.children && el.children.length) {
                var childs = el.children;
                for (var k = 0, l = childs.length; k < l; k++) {
                    var child = childs[k];
                    if (child && child instanceof HTMLElement)
                        elems.push(child as HTMLElement);
                }
            }
        }

        return result;
    }

    export class ObservableValue {
        constructor(internalValue: number, options: any) {
            var value = internalValue || 0;
            this.internalUnit = "mm";
            this.viewUnit = "mm";

            for (var key in options) {
                if (options.hasOwnProperty(key))
                    this[key] = options[key];
            }

            this._internalValue = value;
            this._value = spt.Utils.ApplyNumberconstraints(convertLengthUnit(spt.Utils.ConvertStringToValue(value, this.internalUnit === "ft"), { from: this.internalUnit, to: this.viewUnit }), this);

            ko.defineProperty(this, 'value', {
                get: () => {
                    return this._value;
                },
                set: (v) => {
                    var val = spt.Utils.ConvertStringToValue(v, this.viewUnit === "ft");
                    if (val) {
                        this._value = spt.Utils.ApplyNumberconstraints(val, this);
                        this._internalValue = convertLengthUnit(val, { from: this.viewUnit, to: this.internalUnit });
                    } else {
                        //reset to init value
                        this._internalValue = this.initValue;
                        this._value = spt.Utils.ApplyNumberconstraints(convertLengthUnit(spt.Utils.ConvertStringToValue(this.initValue, this.viewUnit === "ft"), { from: this.internalUnit, to: this.viewUnit }), this);
                    }
                }
            });

            ko.defineProperty(this, 'internalValue', {
                get: () => {
                    return this._internalValue;
                },
                set: (v) => {
                    var val = spt.Utils.ConvertStringToValue(v, this.internalUnit === "ft");
                    if (val) {
                        this._internalValue = val;
                        this._value = spt.Utils.ApplyNumberconstraints(convertLengthUnit(val, { from: this.internalUnit, to: this.viewUnit }), this);
                    } else {
                        //reset to init value
                        this._internalValue = this.initValue;
                        this._value = spt.Utils.ApplyNumberconstraints(convertLengthUnit(spt.Utils.ConvertStringToValue(this.initValue, this.viewUnit === "ft"), { from: this.internalUnit, to: this.viewUnit }), this);
                    }
                }
            });

            ko.track(this);
            this.initValue = value;
        }

        internalUnit: string;
        viewUnit: string;
        private _internalValue: number;
        private _value: number;
        value: number;
        internalValue: number;
        initValue: number;
    }

    export interface ObjSerializedResult {
        output: string[];
        indexCount: number;
        vertexCount: number;
        uvCount: number;
    }

    export function arrayBufferToBase64(arrayBuffer: ArrayBuffer): string {
        let base64 = '';
        const encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

        const bytes = new Uint8Array(arrayBuffer);
        const byteLength = bytes.byteLength;
        const byteRemainder = byteLength % 3;
        const mainLength = byteLength - byteRemainder;

        let a;
        let b;
        let c;
        let d;
        let chunk;

        // Main loop deals with bytes in chunks of 3
        for (let i = 0; i < mainLength; i += 3) {
            // Combine the three bytes into a single integer
            chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

            // Use bitmasks to extract 6-bit segments from the triplet
            a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
            b = (chunk & 258048) >> 12; // 258048   = (2^6 - 1) << 12
            c = (chunk & 4032) >> 6; // 4032     = (2^6 - 1) << 6
            d = chunk & 63;        // 63       = 2^6 - 1

            // Convert the raw binary segments to the appropriate ASCII encoding
            base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
        }

        // Deal with the remaining bytes and padding
        if (byteRemainder === 1) {
            chunk = bytes[mainLength];

            a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2

            // Set the 4 least significant bits to zero
            b = (chunk & 3) << 4; // 3   = 2^2 - 1

            base64 += `${encodings[a]}${encodings[b]}==`;
        } else if (byteRemainder === 2) {
            chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

            a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
            b = (chunk & 1008) >> 4; // 1008  = (2^6 - 1) << 4

            // Set the 2 least significant bits to zero
            c = (chunk & 15) << 2; // 15    = 2^4 - 1

            base64 += `${encodings[a]}${encodings[b]}${encodings[c]}=`;
        }

        return base64;
    }

    export function base64ToArrayBuffer(input: string): ArrayBuffer {

        const keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

        //get last chars to see if are valid

        if (input.length >= 2 && input.charAt(input.length - 2) == "=")
            input = input.substring(0, input.length - 2);
        else if (input.length >= 1 && input.charAt(input.length - 1) == "=")
            input = input.substring(0, input.length - 1);

        var inputLength = input.length;
        var bytes = Math.floor((inputLength / 4) * 3);

        var arrayBuffer = new ArrayBuffer(bytes);

        var uarray = new Uint8Array(arrayBuffer);

        var i = 0;
        var j = 0;

        //input = input.replace(/[^A-Za-z0-9\+\/]/g, "");

        while (i < bytes) {
            //get the 3 octects in 4 ascii chars
            var enc1 = j < inputLength ? keyStr.indexOf(input.charAt(j++)) : 0;
            var enc2 = j < inputLength ? keyStr.indexOf(input.charAt(j++)) : 0;
            var enc3 = j < inputLength ? keyStr.indexOf(input.charAt(j++)) : 0;
            var enc4 = j < inputLength ? keyStr.indexOf(input.charAt(j++)) : 0;

            var chr1 = (enc1 << 2) | (enc2 >> 4);
            var chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
            var chr3 = ((enc3 & 3) << 6) | enc4;

            uarray[i++] = chr1;
            if (i < bytes)
                uarray[i++] = chr2;
            if (i < bytes)
                uarray[i++] = chr3;
        }

        return arrayBuffer;
    }

    //export function TestCompress() {

    //    var ba = new Int8Array([0, 2, 13, 16, 17, 32, 34]).buffer;
    //    var tt = arrayBufferToBase64(ba);
    //    var tt2 = Array.prototype.slice.call(new Int8Array(base64ToArrayBuffer(tt)));
    //    console.log(tt);
    //    console.log(tt2);

    //    var arr = [],
    //        i = 0;
    //    for (i = 0; i < 1000; i++) {
    //        arr.push(Math.floor((Math.random() * 1000000)));
    //    }

    //    console.log("array length: " + arr.length);
    //    console.log("array size: " + JSON.stringify(arr).length);
    //    console.log("array:");
    //    console.log(JSON.stringify(arr));

    //    var compressed = arrayBufferToBase64(FastIntegerCompression.compress(arr));

    //    console.log("compressed size: " + compressed.length);
    //    console.log("compressed:");
    //    console.log(compressed);

    //    var uncompressed = FastIntegerCompression.uncompress(base64ToArrayBuffer(compressed));

    //    console.log("uncompressed length: " + uncompressed.length);
    //    console.log("uncompressed size: " + JSON.stringify(uncompressed).length);
    //    console.log("uncompressed:");
    //    console.log(JSON.stringify(uncompressed));

    //    if (arr.length !== uncompressed.length) {
    //        console.log("failed!");
    //        return;
    //    }

    //    for (i = 0; i < arr.length; i++) {
    //        if (arr[i] !== uncompressed[i]) {
    //            console.log("failed!");
    //            return;
    //        }
    //    }
    //}

    export module FastIntegerCompression {

        function bytelog(val: number): number {
            if (val < (1 << 7)) {
                return 1;
            } else if (val < (1 << 14)) {
                return 2;
            } else if (val < (1 << 21)) {
                return 3;
            } else if (val < (1 << 28)) {
                return 4;
            }
            return 5;
        }

        // compute how many bytes an array of integers would use once compressed
        export function computeCompressedSizeInBytes(input: number[]): number {
            var c = input.length;
            var answer = 0;
            for (var i = 0; i < c; i++) {
                answer += bytelog(input[i]);
            }
            return answer;
        }

        // compress an array of integers, return a compressed buffer (as an ArrayBuffer)
        export function compress(input: number[]): ArrayBuffer {
            var c = input.length;
            var buf = new ArrayBuffer(computeCompressedSizeInBytes(input));
            var view = new Int8Array(buf);
            var pos = 0;
            for (var i = 0; i < c; i++) {
                var val = input[i];
                if (val < (1 << 7)) {
                    view[pos++] = val;
                } else if (val < (1 << 14)) {
                    view[pos++] = (val & 0x7F) | 0x80;
                    view[pos++] = val >>> 7;
                } else if (val < (1 << 21)) {
                    view[pos++] = (val & 0x7F) | 0x80;
                    view[pos++] = ((val >>> 7) & 0x7F) | 0x80;
                    view[pos++] = val >>> 14;
                } else if (val < (1 << 28)) {
                    view[pos++] = (val & 0x7F) | 0x80;
                    view[pos++] = ((val >>> 7) & 0x7F) | 0x80;
                    view[pos++] = ((val >>> 14) & 0x7F) | 0x80;
                    view[pos++] = val >>> 21;
                } else {
                    view[pos++] = (val & 0x7F) | 0x80;
                    view[pos++] = ((val >>> 7) & 0x7F) | 0x80;
                    view[pos++] = ((val >>> 14) & 0x7F) | 0x80;
                    view[pos++] = ((val >>> 21) & 0x7F) | 0x80;
                    view[pos++] = val >>> 28;
                }
            }
            return buf;
        }

        // from a compressed array of integers stored ArrayBuffer, compute the number of compressed integers by scanning the input
        //export function computeHowManyIntegers(input: ArrayBuffer): number {
        //    var view = new Int8Array(input);
        //    var c = view.length;
        //    var count = 0;
        //    for(var i = 0; i < c; i++) {
        //        count += (input[i]>>>7);
        //    }
        //    return c - count;
        //}

        // uncompress an array of integer from an ArrayBuffer, return the array
        export function uncompress(input: ArrayBuffer): number[] {
            var array = [];
            var inbyte = new Int8Array(input);
            var end = inbyte.length;
            var pos = 0;
            while (end > pos) {
                var c = inbyte[pos++];
                var v = c & 0x7F;
                if (c >= 0) {
                    array.push(v);
                    continue;
                }
                c = inbyte[pos++];
                v |= (c & 0x7F) << 7;
                if (c >= 0) {
                    array.push(v);
                    continue;
                }
                c = inbyte[pos++];
                v |= (c & 0x7F) << 14;
                if (c >= 0) {
                    array.push(v);
                    continue;
                }
                c = inbyte[pos++];
                v |= (c & 0x7F) << 21;
                if (c >= 0) {
                    array.push(v);
                    continue;
                }
                c = inbyte[pos++];
                v |= c << 28;
                array.push(v);
            }
            return array;
        }

        export function compressToBase64(input: number[]): CompressionResult {
            if (!input || !input.length)
                return null;

            var min = Math.min.apply(null, input);

            var data = arrayBufferToBase64(compress(input.map(v => v - min)));

            return new CompressionResult(data, min, 1);
        }

        //precision: numbers smaller than 1 (eg. 0.01) means higher precision. For units in [mm] 1 is ok.
        export function compressDoublesToBase64(input: number[], precision: number = 1): CompressionResult {
            var result = compressToBase64(input.map(v => Math.round(v / precision)));

            result.Scale = precision;

            return result;
        }

        export function uncompressFromBase64(data: string, bias: number = 0, scale: number = 1): number[] {
            return uncompress(base64ToArrayBuffer(data)).map(v => (v + bias) * scale);
        }

        export class CompressionResult {
            Data: string;
            Bias: number;
            Scale: number;

            constructor(data?: string, bias?: number, scale?: number) {
                this.Data = data || null;
                this.Bias = bias || 0;
                this.Scale = scale || 1;
            }
        }
    }

    var deferredList: { [key: string]: number } = {};

    export function DeferredCall(key: string, fn: () => void, timeout: number) {
        if (deferredList[key]) {
            clearTimeout(deferredList[key]);
            deferredList[key] = 0;
        }

        deferredList[key] = setTimeout(() => {
            fn();
            delete deferredList[key];
        }, timeout) as any;
    }
}

module ArrayHelper {

    export function replaceContent<T>(arr: T[], newContent: T[]): T[] {
        return arr.splice.apply(arr, [0, arr.length].concat(newContent || [] as any)) as T[];
    }

    export function removeContent<T>(arr: T[]): T[] {
        return arr.splice(0, arr.length);
    }

    export function Sum(arr: number[]): number {
        return arr.reduce((pv, cv) => pv + cv, 0);
    }

    export function byMax<T>(arr: T[], fn: (item: T) => number): T {
        let val = -Number.MAX_VALUE,
            res: T = null;
        for (var i = 0, j = arr.length; i < j; i++) {
            let item = arr[i],
                v = fn(item);
            if (v > val) {
                val = v;
                res = item;
            }
        }
        return res;
    }

    export function byMin<T>(arr: T[], fn: (item: T) => number): T {
        let val = Number.MAX_VALUE,
            res: T = null;
        for (var i = 0, j = arr.length; i < j; i++) {
            let item = arr[i],
                v = fn(item);
            if (v < val) {
                val = v;
                res = item;
            }
        }
        return res;
    }

    export function indexByMax<T>(arr: T[], fn: (item: T) => number): number {
        let val = -Number.MAX_VALUE,
            idx = -1;
        for (var i = 0, j = arr.length; i < j; i++) {
            let item = arr[i],
                v = fn(item);
            if (v > val) {
                val = v;
                idx = i;
            }
        }
        return idx;
    }

    export function indexByMin<T>(arr: T[], fn: (item: T) => number): number {
        let val = Number.MAX_VALUE,
            idx = -1;
        for (var i = 0, j = arr.length; i < j; i++) {
            let item = arr[i],
                v = fn(item);
            if (v < val) {
                val = v;
                idx = i;
            }
        }
        return idx;
    }

    export function Min<T>(arr: T[], fn: (item: T) => number): number {
        var val = Number.MAX_VALUE;
        for (var i = 0, j = arr.length; i < j; i++) {
            var v = fn(arr[i]);
            if (v < val)
                val = v;
        }
        return val;
    }

    export function Max<T>(arr: T[], fn: (item: T) => number): number {
        var val = -Number.MAX_VALUE;
        for (var i = 0, j = arr.length; i < j; i++) {
            var v = fn(arr[i]);
            if (v > val)
                val = v;
        }
        return val;
    }

    export function firstOrNull<T>(arr: T[], fn: (item: T) => boolean): T {
        for (var i = 0, j = arr.length; i < j; i++) {
            let item = arr[i];
            if (fn(item))
                return item;
        }
        return null;
    }

    export function count<T>(arr: T[], fn: (item: T) => boolean): number {
        var res = 0;
        for (var i = 0, j = arr.length; i < j; i++) {
            let item = arr[i];
            if (fn(item))
                res++;
        }
        return res;
    }

    export function searchIndex<T>(arr: T[], fn: (item: T) => boolean): number {
        for (var i = 0, j = arr.length; i < j; i++) {
            if (fn(arr[i]))
                return i;
        }
        return -1;
    }

    export function contains<T>(arr: T[], fn: (item: T) => boolean): boolean {
        for (var i = 0, j = arr.length; i < j; i++) {
            if (fn(arr[i]))
                return true;
        }
        return false;
    }

    export function forEachPairClosed<T>(arr: T[], fn: (item1: T, item2: T) => void): void {
        var l = arr.length;
        if (l < 2)
            return;
        for (var i = 1; i < l; i++) {
            fn(arr[i - 1], arr[i]);
        }
        fn(arr[arr.length - 1], arr[0]);
    }

    export function mapPairClosed<T, K>(arr: T[], fn: (item1: T, item2: T) => K): K[] {
        var l = arr.length,
            result: K[] = [];
        if (l < 2)
            return result;
        for (var i = 1, l = arr.length; i < l; i++) {
            result.push(fn(arr[i - 1], arr[i]));
        }
        result.push(fn(arr[arr.length - 1], arr[0]));
        return result;
    }

    export function iterateArray<T>(arr: T[], fn: (item: T, idx?: number) => void) {
        var len = arr.length;
        var it = Math.floor(len / 8);
        var l = len % 8;
        var i = 0;

        if (l > 0) {
            do {
                fn(arr[i], i++);
            } while (--l > 0);
        }
        if (it <= 0) return;
        do {
            fn(arr[i], i++);
            fn(arr[i], i++);
            fn(arr[i], i++);
            fn(arr[i], i++);
            fn(arr[i], i++);
            fn(arr[i], i++);
            fn(arr[i], i++);
            fn(arr[i], i++);
        } while (--it > 0);
    }

    export function iterateTimes(times: number, fn: (i: number) => void) {
        var it = Math.floor(times / 8);
        var l = times % 8;
        var i = 0;

        if (l > 0) {
            do {
                fn(i++);
            } while (--l > 0);
        }
        if (it <= 0) return;
        do {
            fn(i++);
            fn(i++);
            fn(i++);
            fn(i++);
            fn(i++);
            fn(i++);
            fn(i++);
            fn(i++);
        } while (--it > 0);
    }

    export function arraysEqual<T>(a: T[], b: T[]): boolean {
        if (a === b) return true;
        if (a == null || b == null) return false;
        if (a.length != b.length) return false;

        for (var i = 0; i < a.length; ++i) {
            if (a[i] !== b[i]) return false;
        }
        return true;
    }

    export function intervalsFromStringList(strings: string[]): number[][] {
        var result: number[][] = [];

        if (!strings || !strings.length)
            return result;

        for (var i = 0, j = strings.length; i < j; i++) {
            var str = strings[i];

            if (!str || !str.length)
                continue;

            var s = str.replace(/\s/g, "");
            if (!s || !s.length)
                continue;

            var minusIndex = s.indexOf('-');
            if (minusIndex > 0 && minusIndex < (s.length - 1)) {
                var split = s.split('-');
                if (split.length == 2 && split.every(t => !(!t || !t.length))) {

                    var v1 = Math.round(Math.max(0, parseFloat(split[0].replace(",", ".")))),
                        v2 = Math.round(Math.max(0, parseFloat(split[1].replace(",", "."))));

                    result.push([Math.min(v1, v2), Math.max(v1, v2)]);
                }
            } else {
                var ii = Math.round(Math.max(0, parseFloat(s.replace(",", "."))));
                result.push([ii, ii]);
            }
        }

        return result;
    }

    export function fillArray<T>(value: T, count: number): T[] {
        if ((<any>Array.prototype).fill)
            return (<any>new Array(count)).fill(value);
        var res: T[] = [];
        for (var i = 0; i < count; i++)
            res.push(value);
        return res;
    }

    export function removeItem<T>(arr: T[], item: T) {
        var index = arr.indexOf(item);
        if (index > -1)
            arr.splice(index, 1);
    }

    export function removeItems<T>(arr: T[], items: T[]) {
        for (var i = 0, j = items.length; i < j; i++) {
            var item = items[i];
            var index = arr.indexOf(item);
            if (index > -1)
                arr.splice(index, 1);
        }
    }

    export function removeAll<T>(arr: T[], fn?: (T) => boolean) {
        if (fn)
            removeItems(arr, arr.filter(fn));
        else
            arr.splice(0, arr.length);
    }

    export function groupBy<T>(arr: T[], fn: (T) => string | number): T[][] {
        var result: T[][] = [],
            g: { [k: string]: T[] } = {};

        for (var i = arr.length; i--;) {
            var o = arr[i],
                k = fn(o) as string,
                l = g[k];
            if (!l) {
                g[k] = l = [o];
                result.push(l);
            }
            else
                l.push(o);
        }

        return result;
    }

    function defaultCompare(a: any, b: any) {
        return a < b ? -1 : a > b ? 1 : 0;
    }

    export class TinyQueue<T> {

        compare: (a: T, b: T) => number;
        length: number;
        data: T[];

        constructor(data?: T[], compare?: (a: T, b: T) => number) {
            this.data = data || [];
            this.length = this.data.length;
            this.compare = compare || defaultCompare;

            if (this.length > 0) {
                for (var i = (this.length >> 1) - 1; i >= 0; i--) this._down(i);
            }
        }

        push(item: T): void {
            this.data.push(item);
            this.length++;
            this._up(this.length - 1);
        }

        pop(): T {
            if (this.length === 0) return undefined;

            var top = this.data[0];
            this.length--;

            if (this.length > 0) {
                this.data[0] = this.data[this.length];
                this._down(0);
            }
            this.data.pop();

            return top;
        }

        peek(): T {
            return this.data[0];
        }

        private _up(pos: number): void {
            var data = this.data;
            var compare = this.compare;
            var item = data[pos];

            while (pos > 0) {
                var parent = (pos - 1) >> 1;
                var current = data[parent];
                if (compare(item, current) >= 0) break;
                data[pos] = current;
                pos = parent;
            }

            data[pos] = item;
        }

        private _down(pos: number): void {
            var data = this.data;
            var compare = this.compare;
            var halfLength = this.length >> 1;
            var item = data[pos];

            while (pos < halfLength) {
                var left = (pos << 1) + 1;
                var right = left + 1;
                var best = data[left];

                if (right < this.length && compare(data[right], best) < 0) {
                    left = right;
                    best = data[right];
                }
                if (compare(best, item) >= 0) break;

                data[pos] = best;
                pos = left;
            }

            data[pos] = item;
        }
    }

    //returns possible combinations with lineSteppings (v1 .. vi) in form: [n1, n2, ... ni, length] with n1*v1 + n2*v2 + ... + vi*vi = length
    export function calculateCombinations(lineSteppings: number[], maxNumber = 5, maxCombinations = 256): number[][] {
        var len = lineSteppings.length;
        if (len <= 0)
            return [];

        var mn = maxNumber,
            count = Math.pow(mn, len);

        while (count > maxCombinations && mn > 1) {
            mn--;
            count = Math.pow(mn, len);
        }

        var numbers = lineSteppings.map(ls => 0 as number);
        var combinations = new Map<number, number[]>();

        for (var i = 1; i < count; i++) {

            var sum = 0;
            var comb = numbers.slice();
            var curCount = 0;

            for (var j = 0; j < len; j++) {
                var num = comb[j] = Math.floor((i / Math.pow(mn, j)) % mn);
                sum += lineSteppings[j] * num;
                curCount += num;
            }

            var pc = combinations.get(sum);

            if (!pc)
                combinations.set(sum, comb);
            else {
                var pCount = pc.reduce((acc, n) => acc + n);

                if (pCount < curCount)
                    continue;
                if (pCount === curCount) {
                    var ccs = comb.reduce((acc, n) => acc + Math.min(1, n), 0),
                        pcs = pc.reduce((acc, n) => acc + Math.min(1, n), 0);

                    if (pcs < ccs)
                        continue;

                    if (pcs === ccs) {
                        for (var k = comb.length; k--;) {
                            if (comb[k] > pc[k]) {
                                combinations.set(sum, comb);
                                break;
                            }
                        }
                    }
                    else
                        combinations.set(sum, comb);
                }
                else
                    combinations.set(sum, comb);
            }

        }

        var result: number[][] = [],
            maxLen = lineSteppings[len - 1] * (mn - 1);

        combinations.forEach((v, k) => {
            if (k < maxLen) {
                v.push(k);
                result.push(v);
            }
        });

        result.sort((a, b) => a[len] - b[len]);

        return result;
    }

    /*
     * Binary search.
     * Returns the index of of the element in a sorted array or (-n-1) where n is the insertion point for the new element.
     * Parameters:
     *     ar - A sorted array
     *     el - An element to search for
     *     compare_fn - A comparator function. The function takes two arguments: (a, b) and returns:
     *        a negative number  if a is less than b;
     *        0 if a is equal to b;
     *        a positive number of a is greater than b.
     * The array may contain duplicate elements. If there are more than one equal elements in the array, 
     * the returned value can be the index of any one of the equal elements.
     */
    export function binarySearch<T>(ar: T[], el: T, compare_fn: (a: T, b: T) => number) {
        var m = 0,
            n = ar.length - 1;

        while (m <= n) {
            var k = (n + m) >> 1,
                cmp = compare_fn(el, ar[k]);
            if (cmp > 0) {
                m = k + 1;
            } else if (cmp < 0) {
                n = k - 1;
            } else {
                return k;
            }
        }
        return -m - 1;
    }

    export function binarySearchClosestIndex<T>(ar: T[], value: number, fn: (T) => number) {
        if (!ar || !ar.length)
            return -1;

        if (ar.length === 1 || value <= fn(ar[0]))
            return 0;

        if (value >= fn(ar[ar.length - 1]))
            return ar.length - 1;

        let m = 0,
            n = ar.length - 1;

        for (var k = (m + n) >> 1; m < n - 1; k = (m + n) >> 1) {
            if (value < fn(ar[k]))
                n = k;
            else
                m = k;
        }

        return value - fn(ar[m]) <= fn(ar[n]) - value ? m : n;
    }

    export function binarySearchClosestArrayIndex(ar: number[], value: number) {
        if (!ar || !ar.length)
            return -1;

        if (ar.length === 1 || value <= ar[0])
            return 0;

        if (value >= ar[ar.length - 1])
            return ar.length - 1;

        let m = 0;
        let n = ar.length - 1;

        for (var middle = (m + n) >> 1; m < n - 1; middle = (m + n) >> 1) {
            if (value < ar[middle])
                n = middle;
            else
                m = middle;
        }

        return value - ar[m] <= ar[n] - value ? m : n;
    }

    export function distinctOnSorted<T>(ar: T[], compare_fn: (a: T, b: T) => number) {
        return ar.filter((el, index) => {
            return binarySearch(ar, el, compare_fn) === index;
        });
    }

    export function distinct<T>(ar: T[], compare_fn: (a: T, b: T) => number) {
        return distinctOnSorted<T>(ar.sort(compare_fn), compare_fn);
    }
}

(() => {
    if(window.keepDefaultConsole)
        return;
    try {
        var sptConsole = ((consoleHolder: Console) => {
            var disabled = false,
                t = 0,
                errQueue = [],
                c = {
                    SPTErrorLog: (msg: string) => {
                        if (!disabled && msg) {
                            errQueue.push(msg);
                            if (t) {
                                clearTimeout(t);
                                t = 0;
                            }
                            t = setTimeout(() => {
                                t = 0;
                                if (!errQueue || !errQueue.length)
                                    return;
                                disabled = true;
                                var errs = errQueue.join("\n").replace(/http:\/\/|https:\/\/|<|>/g, '');
                                errQueue = [];
                                $.ajax({
                                    url: "/StaticImages/LogClientError",
                                    type: "POST",
                                    //dataType: "html",
                                    context: document.body,
                                    data: { errs: errs, source: window.location.pathname },
                                    //contentType: "application/json",
                                    success: (html) => { },
                                    error: (jqXHR, textStatus, errorThrown) => { }
                                });
                                //if(!DManager.errorMessageIsShown())
                                //    DManager.showErrorMessage(GeneralErrorMessages['GeneralError'] + "<br /><br /><span style=\"font-size: 0.8em;\"><i>ERROR SOURCE: CLIENT - </i><b>" + GeneralErrorMessages['TryCtrlF5'] + "</b></span><br /><br />");
                            }, 10000) as any;
                        }
                    }
                };
            Object.keys(consoleHolder).forEach((k) => {
                if (typeof (consoleHolder[k]) === "function")
                    c[k] = consoleHolder[k].bind(consoleHolder);
                else
                    c[k] = consoleHolder[k];
            });

            c['error'] = function (message?: any, ...optionalParams: any[]) {
                c.SPTErrorLog(message && typeof (message) !== "string" ? JSON.stringify(message) : message);
                return consoleHolder.error.apply(consoleHolder, arguments);
            };

            window.addEventListener('error', (event: ErrorEvent) => {
                c.SPTErrorLog("" + (event && event.error && event.error.stack ? event.error.stack : (event && event.error && event.error.message ? event.error.message : (event.error ? event.error : event))));
            });

            return c;
        })(window.console);

        var ua = window.navigator.userAgent;

        var msie = ua.indexOf("MSIE ");

        //if not internet explorer
        if (!(msie > 0 || !!ua.match(/Trident.*rv\:11\./) || window.isDebugMode)) {
            //redefine the old console
            (<any>window).console = sptConsole;
        }
    } catch (e) {
        console.log(e);
    }
})();

//polyfill
// https://tc39.github.io/ecma262/#sec-array.prototype.find
if (!Array.prototype.find) {
    Object.defineProperty(Array.prototype, 'find', {
        value: function (predicate) {
            // 1. Let O be ? ToObject(this value).
            if (this == null) {
                throw new TypeError('"this" is null or not defined');
            }

            var o = Object(this);

            // 2. Let len be ? ToLength(? Get(O, "length")).
            var len = o.length >>> 0;

            // 3. If IsCallable(predicate) is false, throw a TypeError exception.
            if (typeof predicate !== 'function') {
                throw new TypeError('predicate must be a function');
            }

            // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
            var thisArg = arguments[1];

            // 5. Let k be 0.
            var k = 0;

            // 6. Repeat, while k < len
            while (k < len) {
                // a. Let Pk be ! ToString(k).
                // b. Let kValue be ? Get(O, Pk).
                // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                // d. If testResult is true, return kValue.
                var kValue = o[k];
                if (predicate.call(thisArg, kValue, k, o)) {
                    return kValue;
                }
                // e. Increase k by 1.
                k++;
            }

            // 7. Return undefined.
            return undefined;
        },
        configurable: true,
        writable: true
    });
}

if (!Array.prototype.findIndex) {
    Object.defineProperty(Array.prototype, 'findIndex', {
        value: function (predicate) {
            // 1. Let O be ? ToObject(this value).
            if (this == null) {
                throw new TypeError('"this" is null or not defined');
            }

            var o = Object(this);

            // 2. Let len be ? ToLength(? Get(O, "length")).
            var len = o.length >>> 0;

            // 3. If IsCallable(predicate) is false, throw a TypeError exception.
            if (typeof predicate !== 'function') {
                throw new TypeError('predicate must be a function');
            }

            // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
            var thisArg = arguments[1];

            // 5. Let k be 0.
            var k = 0;

            // 6. Repeat, while k < len
            while (k < len) {
                // a. Let Pk be ! ToString(k).
                // b. Let kValue be ? Get(O, Pk).
                // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                // d. If testResult is true, return k.
                var kValue = o[k];
                if (predicate.call(thisArg, kValue, k, o)) {
                    return k;
                }
                // e. Increase k by 1.
                k++;
            }

            // 7. Return -1.
            return -1;
        },
        configurable: true,
        writable: true
    });
}

if (!String.prototype.startsWith) {
    String.prototype.startsWith = function (searchString, position) {
        position = position || 0;
        return this.indexOf(searchString, position) === position;
    };
}

if (!String.prototype.endsWith) {
    String.prototype.endsWith = function(search, this_len) {
        if (this_len === undefined || this_len > this.length) {
            this_len = this.length;
        }
        return this.substring(this_len - search.length, this_len) === search;
    };
}

//ImageData() constructor pollyfill for ie
(() => {
    try {
        new (<any>window).ImageData(new Uint8ClampedArray([0, 0, 0, 0]), 1, 1);
    } catch (e) {
        function ImageDataPolyfill() {
            let args = [].slice.call(arguments) as any[], data;

            if (args.length < 2) {
                throw new TypeError(`Failed to construct 'ImageData': 2 arguments required, but only ${args.length} present.`);
            }

            if (args.length > 2) {
                data = args.shift();

                if (!(data instanceof Uint8ClampedArray)) {
                    throw new TypeError(`Failed to construct 'ImageData': parameter 1 is not of type 'Uint8ClampedArray'`);
                }

                if (data.length !== 4 * args[0] * args[1]) {
                    throw new Error(`Failed to construct 'ImageData': The input data byte length is not a multiple of (4 * width * height)`);
                }
            }

            const width = args[0],
                height = args[1],
                canvas = document.createElement('canvas'),
                ctx = canvas.getContext('2d'),
                imageData = ctx.createImageData(width, height);

            if (data)
                imageData.data.set(data);
            return imageData;
        };

        (<any>window).ImageData = ImageDataPolyfill;
    }
})();